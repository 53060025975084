<template>
   <div id="ContainerAddAdmission">
      <div class="page-height">
         <div class="col-12">
            <!--<MdTitle 
               v-bind:Text="'Edit '+TransactionType+' - ' + this.PatientTitle.fullName + ' - ' + this.$route.params.id"
               v-bind:isHide="!this.Mode"
               v-bind:Name="'IdPatient'"
            />
            <MdTitle 
               v-bind:Text="'Add Admission - ' + this.PatientTitle.fullName + ' - ' + this.$route.params.id"
               v-bind:isHide="this.Mode"
            />-->
             <!-- !! Important ,Added for testing purpose -->

      <h4 v-if="this.edit_mode" class="page-title text-truncate">
      {{ $t('Shared.Edit') }} {{TransactionType}} - 
         <!-- <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
          <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
          <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
          <span v-if="this.PatientTitle.age"> {{ $t('Shared.Age') }} {{this.PatientTitle.age}}</span>   -->  
          <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
          <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
          <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
          <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
          <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
          <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
          <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
          <span v-if="this.PatientTitle.dateFrom"> - {{this.PatientTitle.dateFrom}}  </span>
          <span v-if="this.PatientTitle.dateTo"> - {{this.PatientTitle.dateTo}}  </span>
          <span v-else v-show="IsClinical"> - {{ $t('Shared.Active') }}  </span>   
      </h4>
      <h4 v-if="!this.edit_mode" class="page-title text-truncate">
        {{ $t('Admission.AddAdmission') }} - 
          <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
          <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
          <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
          <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
          <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
          <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>       
      </h4>
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnlyAdmission">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
          </div>
        </div>
          <div style='display:none;'><MdEdit 
                v-bind:TypeProp="'text'" 
                v-model="this.$route.params.recordid "
                v-bind:Id="'input_admission_id'">                                     
              </MdEdit>
          </div>
          <!-- End -->
             <div class="flex-container">
             <b-row>           
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('Admission.DateFromAndTime')"
                  v-bind:Name="'admitDate'"
                  v-bind:TypeProp="'text'"                 
                  v-bind:Mask="MaskDateFrom"
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"
                  v-bind:isRequired="true"
                  v-bind:isValid="isDateFromValid"
                  v-model="DateFrom"
                  v-bind:isReadOnly="isReadOnlyAdmission || IsTransfer"
                  v-bind:Id="'input_admitDate_id'"
               />   
            </b-col>           
             <b-col lg="3" md="3" sm="12" v-if="this.edit_mode">
               <MdEdit 
                  v-bind:Label="$t('Admission.DateToAndTime')"
                  v-bind:Name="'dischargeDate'"
                  v-bind:TypeProp="'text'" 
                  v-bind:Mask="MaskDateFrom"
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"
                  v-model="DateTo"
                  v-bind:isReadOnly="true"
               />   
            </b-col>
             <b-col lg="3" md="3" sm="12">
               <MdLookup
                     v-bind:ModelCode="adm.programcode"
                     v-bind:ModelDescription="dProgram"
                     v-bind:Label="$tc('Shared.Program')"
                     v-bind:RefCode="'Program'"
                     v-bind:Fields="ProgramFields"
                     v-bind:Api="this.ApiProgram"
                     v-bind:FieldCode="'code'"
                     v-bind:AuxFields="this.ProgramAuxFields"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$tc('Shared.Program')"
                     :GetValues="onCodeConditionOk"
                      v-bind:isRequired="true"
                     v-bind:isValid="isProgramValid"
                     :InvalidFeedback="TextProgram"
                     :SearchFieldCode="'Code'"
                  />
            </b-col>
             <b-col md="3" lg="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('Admission.AdmissionSource')"
                  v-bind:Options="AdmissionSourceList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.admissionsource"  
                  v-bind:isRequired="true" 
                  v-bind:FeedBackText="$t('Admission.AdmissionRequiredText')"                                      
                  v-bind:Multiple="false"
                  v-bind:isValid="isAdmissionSourceValid"                  
                  :Input="ChangeDropDownAdmissionSource"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
               />
            </b-col>
        
          <b-col md="3" lg="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('Admission.AdmissionType')"
                  v-bind:Options="AdmissionTypeList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.admissiontype"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAdmissionType"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
               />
            </b-col>
            <b-col md="3" lg="3" sm="12" v-if="ProgramIsInPatient">
               <MdSelect
                  v-bind:Label="$t('Shared.Building')"
                  v-bind:Options="BuildinsList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="Admission.buildings"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownBuilding"
                  :IsDisabled="isReadOnlyAdmission"
               />
            </b-col>
            <b-col lg="3" md="3" sm="12" v-if="ProgramIsInPatient">         
               <MdLookup 
                     v-bind:ModelCode="adm.roomno"
                     v-bind:ModelDescription="rooms.description"
                     v-bind:Label="$t('Shared.Room')"
                     v-bind:RefCode="'Rooms'"   
                     v-bind:Fields="FieldsRooms"   
                     v-bind:AuxFields="this.BuildingAuxFields"              
                     v-bind:Api="'Rooms?Buildingcode='+Admission.buildings"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     v-bind:isRequired="ProgramIsInPatient && enforceRoom"
                     v-bind:isValid="isRoomValid"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Shared.Rooms')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidRoomCode')"
                     :SearchFieldCode="'Code'"                     
                  />
            </b-col>         
         </b-row>

             </div>

         <MdTitle v-bind:Text="$t('Shared.General')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
         <div class="flex-container">
         <b-row>
            <b-col lg="3" md="3" sm="12" v-if="ProgramIsInPatient">               
               <MdSelect
                  v-bind:Label="$t('Admission.Class')"
                  v-bind:Options="AdmissionClassList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.admissionclass"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAdmissionClass"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
                  v-bind:Name="'class_select'"
                  id="class_select"
               />
            </b-col>
             <b-col lg="3" md="3" sm="12" v-if="ProgramIsInPatient">
               <MdSelect
                  v-bind:Label="$t('Admission.VocationalEffort')"
                  v-bind:Options="AdmissionVocationalEffortsList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.prehopitalvocationeffort"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAdmissionVocationalEffort"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
                  v-bind:Name="'vocational_select'"
                  id="vocational_select"
               />
            </b-col>
             <b-col lg="3" md="3" sm="12" v-if="ProgramIsInPatient">
               <MdSelect
                  v-bind:Label="$t('Admission.VocationalCategory')"
                  v-bind:Options="AdmissionVocationalCategoryList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.prehospitalvocation"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAdmissionVocationalCategory"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
               />
            </b-col>
            
             <b-col lg="3" md="3" sm="12" v-if="ProgramIsInPatient">
               <MdEdit 
                  v-bind:Label="$t('Admission.ResidentNumber')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'residentnumber'"
                  v-model="adm.residentNumber"
                  v-bind:isReadOnly="isReadOnlyAdmission || IsTransfer"
                  v-bind:Id="'residentnumber'"
               >                                     
               </MdEdit>
            </b-col>

         <b-col lg="3" md="3" sm="12" v-if="ProgramIsInPatient">
             <MdSelect
                  v-bind:Label="$t('Admission.AdmissionFrom')"
                  v-bind:Options="AdmissionFromList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.admissionfrom"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAdmissionFrom"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
               />

            </b-col>
            <b-col md="3" lg="3" sm="12" v-if="ProgramIsInPatient">
               <MdSelect
                  v-bind:Label="$t('Admission.AdmissionFromLocation')"
                  v-bind:Options="AdmissionReferralList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.referralCode"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAdmittedFromLocationList"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
               />
            </b-col>            
            <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('Admission.Assessment')"
                  v-bind:Options="AdmissionAssessmentList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.assessmentCode"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAssessment"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer"
               />
            </b-col>
            <b-col lg="3" md="3" sm="12" v-if="ProgramIsInPatient">
                <MdSelect
                  v-bind:Label="$t('Admission.LivingWith')"
                  v-bind:Options="AdmissionLivingWithList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.prehospitalliving"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownLivingWith"
                  :IsDisabled="isReadOnlyAdmission || IsTransfer || isCode01"
               />
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('Shared.Status')"
                  v-bind:TypeProp="'text'"
                  v-bind:Name="'status'"
                  v-model.number="dstatus"
                  v-bind:isReadOnly="true"
                  v-bind:Id="'input_addStatus_id'"
               >                                     
               </MdEdit>
            </b-col>   
            <b-col lg="3" md="3" sm="12">
             <MdSelect
                  v-bind:Label="$t('Admission.Track')"
                  v-bind:Options="AdmissionTrackList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="adm.track"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownTrackList"
                  :IsDisabled="isReadOnlyAdmission"
               />             
            </b-col>
            <b-col lg="3" md="3" sm="12" v-show="ProgramIsInPatient">
               <MdEdit 
                  v-bind:Label="$t('Admission.CoveredDays')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'CoveredDays'"
                  v-model.number="adm.coveredDays"
                  v-bind:isReadOnly="isReadOnlyAdmission || IsTransfer"
               >                                     
               </MdEdit>
            </b-col>
             <b-col md="3" lg="3" sm="12" v-if="this.edit_mode">
                 <MdEdit 
                  v-bind:Label="$t('Admission.ChartNo')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'chartno'"
                  v-model="adm.chartNo"
                  v-bind:isReadOnly="true"
                  v-bind:Id="'input_chartNo_id'"
               >                                     
               </MdEdit>
            </b-col>
         </b-row>
      </div>
         <MdTitle v-bind:Text="$t('Admission.AdmissionDiagnosis')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
         <b-row>
         <b-col md="12" sm="12" lg="12">
            <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false">
          <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
            <div class="tab-pane fade show active" id="diag1_4" role="tabpanel">
              <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode1"
                     v-bind:ModelDescription="adm.diagnosis.diagCode1Description"
                     v-bind:RefCode="'DiagnosisAdmitting'"
                     v-bind:Label="$t('Admission.Diagnosis1Admitting')"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:FieldCode="'code'"
                     v-bind:Fields="BaseFields"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis1')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode2"
                     v-bind:ModelDescription="adm.diagnosis.diagCode2Description"
                     v-bind:Label="$t('Admission.Diagnosis2Primary')"
                     v-bind:Fields="BaseFields"
                     v-bind:RefCode="'DiagnosisPrimary'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis2')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode3"
                     v-bind:ModelDescription="adm.diagnosis.diagCode3Description"
                     v-bind:Label="$t('Admission.Diagnosis3')"
                     v-bind:RefCode="'Diagnosis3'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Fields="BaseFields"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis3')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode4"
                     v-bind:ModelDescription="adm.diagnosis.diagCode4Description"
                     v-bind:Label="$t('Admission.Diagnosis4')"
                     v-bind:RefCode="'Diagnosis4'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Fields="BaseFields"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis4')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
              </b-row>
            </div>
            <div class="tab-pane fade" id="diag5_8" role="tabpanel">
               <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode5"
                     v-bind:ModelDescription="adm.diagnosis.diagCode5Description"
                     v-bind:RefCode="'Diagnosis5'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:FieldCode="'code'"
                     v-bind:Fields="BaseFields"
                     v-bind:Label="$t('Admission.Diagnosis5')"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis5')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode6"
                     v-bind:ModelDescription="adm.diagnosis.diagCode6Description"
                     v-bind:Label="$t('Admission.Diagnosis6')"
                     v-bind:RefCode="'Diagnosis6'"
                     v-bind:Fields="BaseFields"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis6')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode7"
                     v-bind:ModelDescription="adm.diagnosis.diagCode7Description"
                     v-bind:RefCode="'Diagnosis7'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Label="$t('Admission.Diagnosis7')"
                     v-bind:Fields="BaseFields"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis7')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode8"
                     v-bind:ModelDescription="adm.diagnosis.diagCode8Description"
                     v-bind:RefCode="'Diagnosis8'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Label="$t('Admission.Diagnosis8')"
                     v-bind:Fields="BaseFields"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis8')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
              </b-row>
            </div>
            <div class="tab-pane fade" id="diag9_12" role="tabpanel">
            <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode9"
                     v-bind:ModelDescription="adm.diagnosis.diagCode9Description"
                     v-bind:RefCode="'Diagnosis9'"
                     v-bind:Fields="BaseFields"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:FieldCode="'code'"
                     v-bind:Label="$t('Admission.Diagnosis9')"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis9')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode10"
                     v-bind:ModelDescription="adm.diagnosis.diagCode10Description"
                     v-bind:Label="$t('Admission.Diagnosis10')"
                     v-bind:Fields="BaseFields"
                     v-bind:RefCode="'Diagnosis10'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis10')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode11"
                     v-bind:ModelDescription="adm.diagnosis.diagCode11Description"
                     v-bind:RefCode="'Diagnosis11'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Fields="BaseFields"
                     v-bind:Label="$t('Admission.Diagnosis11')"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'description'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis11')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode12"
                     v-bind:ModelDescription="adm.diagnosis.diagCode12Description"
                     v-bind:RefCode="'Diagnosis12'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Label="$t('Admission.Diagnosis12')"
                     v-bind:Fields="BaseFields"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis12')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
            </b-row>
            </div>
            <div class="tab-pane fade" id="diag13_16" role="tabpanel">
               <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode13"
                     v-bind:ModelDescription="adm.diagnosis.diagCode13Description"
                     v-bind:RefCode="'Diagnosis13'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Fields="BaseFields"
                     v-bind:FieldCode="'code'"
                     v-bind:Label="$t('Admission.Diagnosis13')"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis13')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode14"
                     v-bind:ModelDescription="adm.diagnosis.diagCode14Description"
                     v-bind:Label="$t('Admission.Diagnosis14')"
                     v-bind:RefCode="'Diagnosis14'"
                     v-bind:Fields="BaseFields"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis14')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode15"
                     v-bind:ModelDescription="adm.diagnosis.diagCode15Description"
                     v-bind:RefCode="'Diagnosis15'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Fields="BaseFields"
                     v-bind:Label="$t('Admission.Diagnosis15')"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyAdmission"    
                     :SearchTitle="$t('Admission.Diagnosis15')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="adm.diagnosis.diagCode16"
                     v-bind:ModelDescription="adm.diagnosis.diagCode16Description"
                     v-bind:RefCode="'Diagnosis16'"
                     v-bind:Api="'DiagnosisICD10Providers'"
                     v-bind:Fields="BaseFields"
                     v-bind:Label="$t('Admission.Diagnosis16')"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"
                     v-bind:SlotItems="SlotItemTeamMember"
                     :IsDisabled="isReadOnlyAdmission"
                     :SearchTitle="$t('Admission.Diagnosis16')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
              </b-row>
            </div>
          </div>  
        </MdTabList>
         </b-col>
         </b-row>
       








          <MdTitle v-bind:Text="$t('Admission.TeamMembers')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label= "$t('Admission.AddMembers')" 
              v-bind:ClassProp="this.isReadOnly || isReadOnlyAdmission ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"
              
              v-on:click="CallModalTable"
              v-bind:isReadOnly="this.isReadOnly || isReadOnlyAdmission">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <!--<div class="textlgetCodeConditionOKabel" :data-label="this.$t('Shared.SearchElements')">-->
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchTeamMember"   
                  @keyup.enter="FindTeamMember">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinput'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindTeamMember">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTablePaginationClient 
              v-bind:fields="FieldsTeamMember"
              v-bind:items="adm.teamMembers"
              v-bind:totalRows="totalRowsTeamMember"
              v-bind:isReadOnly="isReadOnly || isReadOnlyAdmission"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPageTeamMember"
              v-bind:perPage="perPageTeamMember"
              v-bind:pageOptions="pageOptionsTeamMember"
              :onFiltered="onFilteredTeamMember"
              :pageChanged="pageChangedTeamMember"
              v-bind:VisibleEditIcon="true"
              :RowClick="RowClick"
              :deleteIconEvent="RemoveTeamMember"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedTeamMember"
              :perPageFunction="PerPageFunctionTeamMember">
            </MdTablePaginationClient>
          </div>
    </div>

      <MdTitle v-bind:Text="$tc('Admission.Occurrence',2)" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('Admission.AddOccurrence')" 
              v-bind:ClassProp="this.isReadOnly || isReadOnlyAdmission? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"
              v-on:click="CallModalTableOccurrence"
              v-bind:isReadOnly="this.isReadOnly || isReadOnlyAdmission">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchOccurrence"   
                  @keyup.enter="FindOccurrence">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinput'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindOccurrence">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTablePaginationClient 
              v-bind:fields="FieldsOccurrence"
              v-bind:items="adm.occurrence"
              v-bind:totalRows="totalRowsOccurrence"
              v-bind:isReadOnly="isReadOnly || isReadOnlyAdmission"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPageOccurrence"
              v-bind:perPage="perPageOccurrence"
              v-bind:pageOptions="pageOptionsOccurrence"
              :onFiltered="onFilteredOccurrence"
              :pageChanged="pageChangedOccurrence" 
              :RowClick="RowClick"
              :perPageFunction="PerPageFunctionOccurrence"
              v-bind:VisibleEditIcon="true"
              :deleteIconEvent="RemoveOccurrence"
              :editIconEvent="EditOccurrence"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedOccurrence"
              >
            </MdTablePaginationClient>
          </div>
    </div>

    <ModalAdmissionTeamMembers
            v-bind:TitleWindow="$t('Admission.TeamMembers')"          
            @getCodeConditionOK="OnReturnData($event)"
            v-bind:ModalId="this.modalId">
   </ModalAdmissionTeamMembers>

   <ModalAdmissionOccurrence        
        v-bind:TitleWindow="$tc('Admission.Occurrence')" 
        v-bind:ModeEdit="ModalOccurrenceModeEdit"
        v-bind:DataEdit="DataEditOccurrence"       
        @getCodeConditionOK="OnReturnDataOccurrence($event)"
         v-bind:ModalId="this.modalIdOccurrence">
   </ModalAdmissionOccurrence>
           
            
            <div class="space-actions"></div>
            
         </div>
         <div class="page-actions">
               <MdButton
                  v-bind:Label="$t('Shared.Back')"
                  v-bind:Name="'btnpatientlist'"               
                  v-bind:ClassProp="this.$route.name.includes('clinical') ? 'secondary mx-2 hidden':'secondary mx-2 btn-login'"
                  v-on:click="GotoAdmissionList"/>            
               <MdButton
                  v-bind:Label="$t('Shared.Save')"
                  v-bind:ClassProp="(this.isReadOnlyAdmission)?'primary mx-2':'primary btn-login mx-2'"
                  v-on:click="SaveOrEdit"
                  v-bind:isReadOnly=this.isReadOnlyAdmission
                  v-bind:Name="'btn_save_admission'"
                  />
            </div>
      </div>
   </div>
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from "@/common/utils";
import GlobalService from '@/services/global.service' 
import { EventBus } from '@/event-bus.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
//import MdTableComponent from '@/components/MedEZComponents/table/MdTableComponent'

import AdmissionService from '@/services/admission.service'

let elemSaveBtn = '';
export default {
      
      beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.$store.state.isUsePatientAdmission;
            if(iPI) next()
            else next('/accessdenied');
        }) 
   },

  beforeRouteLeave(to, from, next) {
	if (!this.isReadOnlyAdmission && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('Admission.Admission'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(async value => {
            if(!value){
              next();
            } else {
             await this.ClickOffRoute();
             var f = this.WasSaved;             
              if(f){
                  next();
              }
            
            }
          })		
	} else {
		next()
	}
    },
  name: 'AddAdmission',
  props: ["editMode","HaveChanges"],
  mixins: [ShowMessage, DBFunctions/*,MdTableComponent*/],  
  data(){
    return {
    //  Mode:this.$attrs.editMode,
      enforceRoom:false,
      RegistrationInfo:{},
      IsClinical:false,
      edit_mode: this.editMode, 
      DoctorList:[],
      ReferralsList:[],
      MaskDateFrom:"##/##/#### ##:## AA",
      TransactionType:"Admission",
      WasSaved:false,
      isReadOnly:false,
      OffRoute:false,
      IsTransfer:false,
      EmployeesList:[],
      PatientContactsList:[],
      searchTeamMember:"",
      searchOccurrence:"",
      doctormodel:'',
      referralsmodel:'',
      employeesmodel:'',
      contactsmodel:'',
      FieldsTeamMember:[
         { key: 'entityName', sortable: true, label: this.$t('Admission.entityName')},
         { key: 'entityTypeDescription', sortable: true, label: this.$t('Shared.Type')},
         { key: 'entityType', sortable: true, label: this.$t('Shared.Type'),class:'hidden'},
         { key: 'entityPhone', sortable: true, label: this.$t('Shared.Phone')},
         { key: 'entityCode', sortable: true, label: this.$t('Shared.Code'),class:'hidden'},
         { key: 'recId', sortable: true, label: this.$t('Admission.recId'),class:'hidden'},
         { key: 'entityEmail', sortable: true, label: this.$t('Shared.Email')}
         ], 

          FieldsOccurrence:[
         { key: 'occurrenceDescription', sortable: true, label: this.$t('Admission.occurrenceDescription')},
         { key: 'recId', sortable: true, label: this.$t('Admission.recId'),class:'hidden'},
         { key: 'occurrenceCode', sortable: true, label: this.$t('Admission.occurrenceCode'),class:'hidden'},
         { key: 'revenueCode', sortable: true, label: this.$t('Admission.revenueCode'),class:'hidden'},
         { key: 'dateFrom', sortable: true, label: this.$t('Shared.datefrom')},
         { key: 'dateTo', sortable: true, label: this.$t('Shared.dateTo')},
         { key: 'occurrenceDate', sortable: true, label: this.$t('Admission.occurrenceDate')},
         { key: 'revenueDescription', sortable: true, label: this.$t('Admission.revenueDescription')}
         ],      
      TeamMemberCopy:[],
      OcurrenceCopy:[],
      DataEditOccurrence:{},
      Admission:{buildings:"",admitDate:null,dischargeDate:null}, 
      isProgramValid:true,   
      isDateFromValid:true, 
      isRoomValid:true,
      isAdmissionSourceValid:true, 
      isCode01:false,
      TextProgram: this.$t('Admission.ProgramIsRequired'),
      DateFrom:null,
      DateTo:null,
      adm:{
         admissionclass:"",
         admissionfrom:"",
         admissionsource:"",
         admissiontype:"",
         dateFrom:null,        
         dateTo:null,
         patientcode:this.$route.params.id,
         prehopitalvocationeffort:"",
         prehospitalliving:"",
         prehospitalvocation:"",
         programcode:"",
         roomno:"",         
         statusCode:"",
         coveredDays:null,
         track:"",
         residentNumber:"",
         assessmentCode:"",
         referralCode:"",
         chartNo:"",
         teamMembers:[],
         occurrence:[],
         diagnosis:{
            diagCode1: "",
            diagCode1Description:"",
            diagCode2: "",
            diagCode2Description:"",
            diagCode3: "",
             diagCode3Description:"",
            diagCode4: "",
             diagCode4Description:"",
            diagCode5: "",
             diagCode5Description:"",
            diagCode6: "",
             diagCode6Description:"",
            diagCode7: "",
             diagCode7Description:"",
            diagCode8: "",
             diagCode8Description:"",
            diagCode9: "",
             diagCode9Description:"",
            diagCode10: "",
             diagCode10Description:"",
            diagCode11: "",
             diagCode11Description:"",
            diagCode12: "",
             diagCode12Description:"",
            diagCode13: "",
             diagCode13Description:"",
            diagCode14: "",
             diagCode14Description:"",
            diagCode15: "",
             diagCode15Description:"",
            diagCode16: "",
             diagCode16Description:"",
         }
      },    
      dProgram:'', 
      drooms:'',
      rooms:{
         "description":""
      },     
      dstatus:'',
      modalId:"ModaladmissionTeamMembers",
      modalIdOccurrence :"MAdmissionOccurrence",
      ProgramIsInPatient:true,
      ResetModalOccurrence:false,    
      ProgramFields:[{ key: 'name', sortable: true, label: this.$t('Shared.Description') },{ key: 'isInPatient',class:'hidden', sortable: true, label: this.$t('Shared.IsInPatient')},{ key: 'code', sortable: true, label: this.$t('Shared.ProgramCode')},
      { key: 'inPatient', sortable: true, label: this.$t('Shared.Inpatient')}],
      BaseFields:[{ key: 'name', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
      FieldsRooms:[
         { key: 'name', sortable: true, label: this.$t('Admission.FieldsRoomsName') },
         { key: 'code', sortable: true, label: this.$t('Shared.Code') },
         { key: 'buildingName', sortable: true, label: this.$t('Shared.BuildingName') },
         { key: 'capacity', sortable: true, label: this.$t('Shared.Capacity') },
         { key: 'bedAvailable', sortable: true, label: this.$t('Admission.FieldsRoomsBedAvailable') },
         { key: 'isPrivateRoom', sortable: true, label: this.$t('Admission.FieldsRoomsPrivate') },
         
         ],
      SlotItemTeamMember:[{ key: 1, Name: this.$t('Shared.Date') },{ key: 2, Name: 'Other' }],
      isReadOnlyAdmission:false,
      pendingChanges:false,
      ShowMessageIsEmpty:false,
      ProgramAuxFields:['isInPatient'],
      //RoomsAuxFields:['bedAvailable'],
      BuildingAuxFields:['buildingName','buildingcode','bedAvailable'],
      AdmissionProgramList:[],
      AdmittedFromLocationList:[], 

      AdmissionSourceList:[],
      AdmissionClassList:[],      
      AdmissionTypeList:[], 
      AdmissionVocationalEffortsList:[],            
      AdmissionVocationalCategoryList:[],      
      AdmissionAssessmentList:[],      
      AdmissionLivingWithList:[],
      AdmissionReferralList:[],
      AdmissionFromList:[],    
      AdmissionTrackList:[],



      BuildinsList:[],
      RoomsList:[],
      ApiProgram:"",
      totalRowsTeamMember:0,
      currentPageTeamMember:1,
      perPageTeamMember:10,
      pageOptionsTeamMember: [5, 10, 15 ],  
      totalRowsOccurrence:0,
      currentPageOccurrence:1,
      perPageOccurrence:10,
      pageOptionsOccurrence: [5, 10, 15 ],  
      ModalOccurrenceModeEdit:false,
      selected:{},  
      OptionsRightMenu:{
        visible:true,
        ItemsList:[],
        btnvisible:true,
        itemdisabled: false,
        PatientCode:''         
      },
      PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:'',
       activitytypename:'',
       startdatetime:'',
       ageFormatted:''
     },   
     
      tablist: [
         { title: this.$t('Admission.Diagnoses') + ' 1-4', link: '#diag1_4', active: true},
         { title: this.$t('Admission.Diagnoses') + ' 5-8', link: '#diag5_8'},
         { title: this.$t('Admission.Diagnoses') + ' 9-12', link: '#diag9_12'},
         { title: this.$t('Admission.Diagnoses') + ' 13-16', link: '#diag13_16'}
      ],
    }
  },

  methods:{

   RowClick(){

   },

   async populateSelects(){
      
      await GlobalService.getIPFSetups()
                .then((response) => {
                    if(response.status == '200' && response.data.length > 0){  
                     this.enforceRoom =  response.data[0].enforceRoomAssignment;
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionSource'),error,"error");
                    }
                }); 


      if(this.$store.state.sourcewasloaded){
            this.AdmissionSourceList = this.$store.state.sourceList;
        }else{
         await AdmissionService.getAdmissionSources()
                .then((response) => {
                    if(response.status == '200'){    
                       this.AdmissionSourceList = response.data;                  
                       this.$store.commit('initSourcesList');
                       this.$store.commit('setsourcewasloaded',true);  
                       response.data.forEach(element => {
                         this.$store.commit('setSourceList',element);
                       });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionSource'),error,"error");
                    }
                }); 
        } 
      
        if(this.$store.state.classwasloaded){
         this.AdmissionClassList = this.$store.state.classList;
        }else{
         AdmissionService.getAdmissionClasses()
                .then((response) => {
                    if(response.status == '200'){
                      this.AdmissionClassList = response.data;
                      this.$store.commit('initClassList');
                      this.$store.commit('setclasswasloaded',true); 
                      response.data.forEach(element => {
                        this.$store.commit('setClassList',element);
                      });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Class'),error,"error");
                    }
                });
        }

        if(this.$store.state.typewasloaded){
         this.AdmissionTypeList = this.$store.state.typeList;
         }else{
            await AdmissionService.getAdmissionTypes()
                .then((response) => {
                    if(response.status == '200'){ 
                      this.AdmissionTypeList = response.data;
                      this.$store.commit('initTypeList');
                      this.$store.commit('settypewasloaded',true);
                      response.data.forEach(element => {
                        this.$store.commit('setTypeList',element);
                      });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionType'),error,"error");
                    }
                }); 
         }

         if(this.$store.state.vocationalEffortwasloaded){
            this.AdmissionVocationalEffortsList = this.$store.state.vocationalEffortList;
         }else{
            AdmissionService.getVocationalEffort()
                .then((response) => {
                    if(response.status == '200'){     
                        this.AdmissionVocationalEffortsList = response.data;
                        this.$store.commit('initVocationalEffortList');
                        this.$store.commit('setvocationalEffortwasloaded',true);
                        response.data.forEach(element => {
                          this.$store.commit('setVocationalEffortList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.VocationalEffort'),error,"error");
                    }
                });
         }

         if(this.$store.state.vocationalCategorywasloaded){
            this.AdmissionVocationalCategoryList = this.$store.state.vocationalCategoryList;
         }else{
            AdmissionService.getVocationalCategory()
                .then((response) => {
                    if(response.status == '200'){  
                     this.AdmissionVocationalCategoryList = response.data;
                      this.$store.commit('initVocationalCategoryList');
                      this.$store.commit('setvocationalCategorywasloaded',true);
                      response.data.forEach(element => {
                        this.$store.commit('setVocationalCategoryList',element);
                      });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.VocationalCategory'),error,"error");
                    }
                });
         }

         if(this.$store.state.assessmentwasloaded){
            this.AdmissionAssessmentList = this.$store.state.assessmentList;
         }else{
            AdmissionService.getAssessment()
                .then((response) => {
                    if(response.status == '200'){    
                       this.AdmissionAssessmentList = response.data;                 
                       this.$store.commit('initAssessmentList');
                       this.$store.commit('setassessmentwasloaded',true);
                       response.data.forEach(element => {
                         this.$store.commit('setAssessmentList',element);
                       });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Assessment'),error,"error");
                    }
                });
         }

         if(this.$store.state.livingWithwasloaded){
            this.AdmissionLivingWithList = this.$store.state.livingWithList;
         }else{
            AdmissionService.getLivingWith()
                .then((response) => {
                    if(response.status == '200'){   
                        this.AdmissionLivingWithList = response.data;                     
                        this.$store.commit('initLivingWithList');
                        this.$store.commit('setlivingWithwasloaded',true);
                        response.data.forEach(element => {
                          this.$store.commit('setLivingWithList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.LivingWith'),error,"error");
                    }
                });
         }

         if(this.$store.state.referralswasloaded){
            this.AdmissionReferralList = this.$store.state.referralsList;
         }else{
            AdmissionService.getReferrals()
                .then((response) => {
                    if(response.status == '200'){ 
                        this.AdmissionReferralList = response.data;
                        this.$store.commit('initReferralsList');
                        this.$store.commit('setreferralswasloaded',true);
                        response.data.forEach(element => {
                          this.$store.commit('setReferralsList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Referal'),error,"error");
                    }
                });
         }

         if(this.$store.state.fromwasloaded){
            this.AdmissionFromList = this.$store.state.fromList;
         }else{
            AdmissionService.getAdmissionFrom()
                .then((response) => {
                    if(response.status == '200'){    
                        this.AdmissionFromList = response.data;                  
                        this.$store.commit('initFromList');
                        this.$store.commit('setfromwasloaded',true);
                        response.data.forEach(element => {
                          this.$store.commit('setFromList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionFrom'),error,"error");
                    }
                });
         }

         if(this.$store.state.trackwasloaded){
            this.AdmissionTrackList = this.$store.state.trackList;
         }else{
            AdmissionService.getTrack()
                .then((response) => {
                    if(response.status == '200'){  
                        this.AdmissionTrackList = response.data;
                        this.$store.commit('initTrackList');
                        this.$store.commit('settrackwasloaded',true);
                       response.data.forEach(element => {
                         this.$store.commit('setTrackList',element);
                       });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Track'),error,"error");
                    }
                });
         }
   },

     onFilteredTeamMember(filteredItems){
      this.totalRows = filteredItems.length
      this.currentPage = 1
     },
     onFilteredOccurrence(){

     },

     FindOccurrence(){
        this.adm.occurrence = this.OcurrenceCopy;
        var arr = this.adm.occurrence;
        var temp = [];
        if(this.searchOccurrence){
         arr.forEach(element => {
           if(element.occurrenceDescription && element.occurrenceDescription.toUpperCase().includes(this.searchOccurrence.toUpperCase()) || element.occurrenceCode && element.occurrenceCode.toUpperCase().includes(this.searchOccurrence.toUpperCase())
           || element.revenueCode && element.revenueCode.toUpperCase().includes(this.searchOccurrence.toUpperCase()) || element.dateFrom && element.dateFrom.toUpperCase().includes(this.searchOccurrence.toUpperCase()) ||
           element.dateTo && element.dateTo.toUpperCase().includes(this.searchOccurrence.toUpperCase()) || element.occurrenceDate && element.occurrenceDate.toUpperCase().includes(this.searchOccurrence.toUpperCase()) ||
           element.revenueDescription && element.revenueDescription.toUpperCase().includes(this.searchOccurrence.toUpperCase())){
              temp.push(element);
           }
        });
        }else{
           temp = this.OcurrenceCopy;
        }
      
         this.adm.occurrence = temp;
         this.totalRowsOccurrence = this.adm.occurrence.length;

     },

     FindTeamMember(){
        this.adm.teamMembers = this.TeamMemberCopy;
        var arr = this.adm.teamMembers;
        var temp = [];
         if(this.searchTeamMember){
              arr.forEach(element => {
                 if(element.entityName && element.entityName.toUpperCase().includes(this.searchTeamMember.toUpperCase()) || element.entityTypeDescription && element.entityTypeDescription.toUpperCase().includes(this.searchTeamMember.toUpperCase())
                 || element.entityPhone && element.entityPhone.toUpperCase().includes(this.searchTeamMember.toUpperCase()) || element.entityEmail && element.entityEmail.toUpperCase().includes(this.searchTeamMember.toUpperCase())){
                      temp.push(element);
                 }
              });
         }else{
             temp = this.TeamMemberCopy;
         }

         this.adm.teamMembers = temp;
          this.totalRowsTeamMember = this.adm.teamMembers.length;
         
     },

     VerifyCodeExist(arr,code){
      for(var i = 0; i < arr.length; i++){
         if(arr[i].entityCode == code){
            return true;           
         }
      }
      return false;
     },

     VerifyCodeExistOccurrence(arr,code){
      for(var i = 0; i < arr.length; i++){
         if(arr[i].occurrenceCode == code){
            return true;           
         }
      }
      return false;
     },

     VerifyCodeExistOccurrenceCopy(arr,code){
      for(var i = 0; i < arr.length; i++){
         if(arr[i].occurrenceCode == code){
            return true;           
         }
      }
      return false;
     },

     OnReturnData(data){        
      this.checkUserAction();   
        var arr = this.adm.teamMembers;
        var r = data.referrals;
        var d = data.doctors;
        var e = data.employees;
        var c = data.contacts;
        var MaxrecId = arr.length > 0 ? arr[arr.length - 1].recId + 1 : 0;
        r.forEach(element => {
            if(!this.VerifyCodeExist(arr,element.code)){
               arr.push({recId:MaxrecId,entityName:element.name,entityTypeDescription:this.$t('Admission.Referal'),entityType:"Referral",entityPhone:element.cellPhone, entityEmail:element.email,entityCode:element.code});
               MaxrecId++;
            }            
        });

        d.forEach(element => {
         if(!this.VerifyCodeExist(arr,element.code)){
             arr.push({recId:MaxrecId,entityName:element.name,entityTypeDescription:"Doctor",entityType:"Doctor",entityPhone:element.phone, entityEmail:element.email,entityCode:element.code});
             MaxrecId++;
         }
        });

        e.forEach(element => {
         if(!this.VerifyCodeExist(arr,element.code)){
             arr.push({recId:MaxrecId,entityName:element.name,entityTypeDescription:this.$tc('Shared.Employee'),entityType:"Employee",entityPhone:element.cellPhone, entityEmail:element.eMail,entityCode:element.code});
             MaxrecId++;
         }
        });

        c.forEach(element => {
         if(!this.VerifyCodeExist(arr,element.code)){
             arr.push({recId:MaxrecId,entityName:element.name,entityTypeDescription:this.$t('Contact.PatientContact'),entityType:"PatientContact",entityPhone:element.cellPhone, entityEmail:element.email,entityCode:element.code});
            MaxrecId++;
         }
        });

      this.adm.teamMembers = arr;  
      this.TeamMemberCopy = arr;
      this.totalRowsTeamMember = this.adm.teamMembers.length;
      
     },

     UpdateOcurrenceInList(ObjUpdated){
        var arr =  this.adm.occurrence;
        var l = arr.length;

        for(var i = 0; i < l; i++){
           if(arr[i].recId === ObjUpdated.recId){
              arr[i].occurrenceDescription = ObjUpdated.Occurrencedescription;
              arr[i].occurrenceCode = ObjUpdated.Occurrencecode;
              arr[i].revenueCode = ObjUpdated.RevCode;
              arr[i].dateFrom = ObjUpdated.DateFrom;
              arr[i].dateTo = ObjUpdated.DateTo;
              arr[i].occurrenceDate = ObjUpdated.DateOcurrence;
              arr[i].revenueDescription = ObjUpdated.RevCodedescription; 
           }
        }

       this.adm.occurrence = arr;
      // this.OcurrenceCopy = arr;

      var arrcopy = this.OcurrenceCopy;
      var lcopy = arrcopy.length;

      for(var j = 0; j < lcopy; j++){
           if(arrcopy[j].recId === ObjUpdated.recId){
            arrcopy[j].occurrenceDescription = ObjUpdated.Occurrencedescription;
            arrcopy[j].occurrenceCode = ObjUpdated.Occurrencecode;
            arrcopy[j].revenueCode = ObjUpdated.RevCode;
            arrcopy[j].dateFrom = ObjUpdated.DateFrom;
            arrcopy[j].dateTo = ObjUpdated.DateTo;
            arrcopy[j].occurrenceDate = ObjUpdated.DateOcurrence;
            arrcopy[j].revenueDescription = ObjUpdated.RevCodedescription; 
           }
        }

        this.OcurrenceCopy = arrcopy;

     },

     OnReturnDataOccurrence(data){
        this.checkUserAction(); 
       // var arrtemp = [];  
         if(data.DateFrom){
            data.DateFrom = Utils.formatterDateToString(data.DateFrom);
         }
         if(data.DateTo){
            data.DateTo = Utils.formatterDateToString(data.DateTo);
         }
         if(data.DateOcurrence){
            data.DateOcurrence = Utils.formatterDateToString(data.DateOcurrence);
         }
         
        var MaxrecId = this.adm.occurrence.length > 0 ? this.adm.occurrence[this.adm.occurrence.length - 1].recId + 1 : 0;

        if(!data.ModeEdit){
         if(!this.VerifyCodeExistOccurrence(this.adm.occurrence,data.Occurrencecode)){
            this.adm.occurrence.push({
               occurrenceDescription:data.Occurrencedescription,
               occurrenceCode:data.Occurrencecode,
               revenueCode:data.RevCode,
               dateFrom:data.DateFrom,
               dateTo:data.DateTo,
               occurrenceDate:data.DateOcurrence,
               revenueDescription:data.RevCodedescription,
               recId:MaxrecId
            });
         }

         if(!this.VerifyCodeExistOccurrenceCopy(this.OcurrenceCopy,data.Occurrencecode)){
            this.OcurrenceCopy.push({
               occurrenceDescription:data.Occurrencedescription,
               occurrenceCode:data.Occurrencecode,
               revenueCode:data.RevCode,
               dateFrom:data.DateFrom,
               dateTo:data.DateTo,
               occurrenceDate:data.DateOcurrence,
               revenueDescription:data.RevCodedescription,
               recId:MaxrecId
            });
         }


            //arrtemp = this.adm.occurrence;
            //this.OcurrenceCopy = arrtemp;
        }else{
           this.UpdateOcurrenceInList(data);
        }      
      this.totalRowsOccurrence =  this.adm.occurrence.length;
     },

     

     getPosListTeamMember(id){
        for(var i = 0; i < this.adm.teamMembers.length; i++){
           if(this.adm.teamMembers[i].recId === id){
              return i;              
           }
        }
        return -1;
     },

     getPosListTeamMemberCopy(id){
        for(var i = 0; i < this.TeamMemberCopy.length; i++){
           if(this.TeamMemberCopy[i].recId === id){
              return i;              
           }
        }
        return -1;
     },

      getPosListOccurrence(id){
        for(var i = 0; i <  this.adm.occurrence.length; i++){
           if( this.adm.occurrence[i].recId === id){
              return i;              
           }
        }
        return -1;
     },

     EditOccurrence(row){
        this.DataEditOccurrence = 
        {
           "revenueCode":row.revenueCode,
           "dateFrom":row.dateFrom,
           "dateTo":row.dateTo,
           "occurrenceCode":row.occurrenceCode,
           "occurrenceDate":row.occurrenceDate,
           "occurrenceDescription":row.occurrenceDescription,
           "revenueDescription":row.revenueDescription,
           "recId":row.recId
        };

        var data = {
           dataEdit: this.DataEditOccurrence,
           ModeEdit:true
        }
         //this.ModalOccurrenceModeEdit = true;  
         this.$bvModal.show(this.modalIdOccurrence);     
         EventBus.$emit('EmitDefaultDate', data); 
     },

     getPosListOccurrenceCopy(id){
        for(var i = 0; i <  this.OcurrenceCopy.length; i++){
           if( this.OcurrenceCopy[i].recId === id){
              return i;              
           }
        }
        return -1;
     },

     RemoveOccurrence(e){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$tc('Admission.Occurrence'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value) {
       var i = this.getPosListOccurrence(e.recId);
        if(i !== -1){
            this.adm.occurrence.splice(i, 1);
           this.pendingChanges = true;
           this.totalRowsOccurrence =  this.adm.occurrence.length;
        }


        var j = this.getPosListOccurrenceCopy(e.recId);
        if(j !== -1){
            this.OcurrenceCopy.splice(j, 1);          
        }


        }
      })
      
     },

     RemoveTeamMember(e){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$t('Admission.TeamMember'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value) {
         this.checkUserAction();
       var i = this.getPosListTeamMember(e.recId);
        if(i !== -1){
           this.adm.teamMembers.splice(i, 1);         
           this.pendingChanges = true;
           this.totalRowsTeamMember = this.adm.teamMembers.length;        
        }

        var j = this.getPosListTeamMemberCopy(e.recId);
        if(j !== -1){
           this.TeamMemberCopy.splice(j, 1);
        }
        }
      })
      
     },

     onRowSelectedTeamMember(items) {    
     this.selected = items;
    },

    onRowSelectedOccurrence(){

    },

   getAdmissionByRecordId(){
     // this.$emit('load', false);
   },

    pageChangedTeamMember (page) {            
      this.currentPageTeamMember = page;  
    },

    pageChangedOccurrence(page){
      this.currentPageOccurrence = page;   
    },

    PerPageFunctionTeamMember(perpage){    
      this.perPageTeamMember = perpage;  
      this.currentPageTeamMember = 1;
    },

    PerPageFunctionOccurrence(perpage){
      this.perPageOccurrence = perpage;  
      this.currentPageOccurrence = 1;
    },

   CallModalTable: async function() {         
      this.$emit('load', true);
      this.$bvModal.show(this.modalId);
      this.$emit('load', false);
     },

     CallModalTableOccurrence : async function() { 
      this.ModalOccurrenceModeEdit = false;    
      this.$bvModal.show(this.modalIdOccurrence);  
       var data = {
           dataEdit: {},
           ModeEdit:false
        }      
      EventBus.$emit('EmitDefaultDate', data);
     },

     onCodeConditionOk(result){
         var type = result.FieldName;
         this.checkUserAction(); 
         if(type.indexOf('Diagnosis') >= 0) {
             
             switch (type) {
                case "DiagnosisAdmitting": 
                  this.adm.diagnosis.diagCode1 = result.code;   
                  //this.descriptions.ddiag1 = result.description;  
                  this.adm.diagnosis.diagCode1Description = result.description;                    
                  break;
                case "DiagnosisPrimary": 
                  this.adm.diagnosis.diagCode2 = result.code;   
                  //this.descriptions.ddiag2 = result.description; 
                  this.adm.diagnosis.diagCode2Description = result.description;    
                  break;
                case "Diagnosis3": 
                  this.adm.diagnosis.diagCode3 = result.code;   
                  //this.descriptions.ddiag3 = result.description;  
                  this.adm.diagnosis.diagCode3Description = result.description;   
                  break;
                case "Diagnosis4": 
                  this.adm.diagnosis.diagCode4 = result.code;   
                  //this.descriptions.ddiag4 = result.description;  
                  this.adm.diagnosis.diagCode4Description = result.description;   
                  break;
                case "Diagnosis5": 
                  this.adm.diagnosis.diagCode5 = result.code;   
                  //this.descriptions.ddiag5 = result.description;  
                  this.adm.diagnosis.diagCode5Description = result.description;   
                  break;
                case "Diagnosis6": 
                  this.adm.diagnosis.diagCode6 = result.code;   
                  this.adm.diagnosis.diagCode6Description = result.description;   
                  //this.descriptions.ddiag6 = result.description;  
                  break;
                case "Diagnosis7": 
                  this.adm.diagnosis.diagCode7 = result.code;   
                  this.adm.diagnosis.diagCode7Description = result.description;   
                  //this.descriptions.ddiag7 = result.description;  
                  break;
                case "Diagnosis8": 
                  this.adm.diagnosis.diagCode8 = result.code; 
                  this.adm.diagnosis.diagCode8Description = result.description;     
                  //this.descriptions.ddiag8 = result.description;  
                  break;
                case "Diagnosis9": 
                  this.adm.diagnosis.diagCode9 = result.code;   
                 // this.descriptions.ddiag9 = result.description; 
                 this.adm.diagnosis.diagCode9Description = result.description;    
                  break;
                case "Diagnosis10": 
                  this.adm.diagnosis.diagCode10 = result.code;   
                  //this.descriptions.ddiag10 = result.description;  
                  this.adm.diagnosis.diagCode10Description = result.description;   
                  break;
                case "Diagnosis11": 
                  this.adm.diagnosis.diagCode11 = result.code;   
                 // this.descriptions.ddiag11 = result.description;
                   this.adm.diagnosis.diagCode110Description = result.description;     
                  break;
                case "Diagnosis12": 
                  this.adm.diagnosis.diagCode12 = result.code;   
                  //this.descriptions.ddiag12 = result.description;  
                    this.adm.diagnosis.diagCode120Description = result.description;   
                  break;
                case "Diagnosis13": 
                  this.adm.diagnosis.diagCode13 = result.code;   
                  //this.descriptions.ddiag13 = result.description;  
                    this.adm.diagnosis.diagCode13Description = result.description;   
                  break;
                case "Diagnosis14": 
                  this.adm.diagnosis.diagCode14 = result.code;   
                  //this.descriptions.ddiag14 = result.description;  
                    this.adm.diagnosis.diagCode14Description = result.description;   
                  break;
                case "Diagnosis15": 
                  this.adm.diagnosis.diagCode15 = result.code;   
                  //this.descriptions.ddiag15 = result.description;  
                    this.adm.diagnosis.diagCode15Description = result.description;   
                  break;
                default:
                  this.adm.diagnosis.diagCode16 = result.code;  
                  this.adm.diagnosis.diagCode16Description = result.description;   
                  //this.descriptions.ddiag16 = result.description; 
             }
         }else if(type.indexOf('Program') >= 0){
             switch (type) {
               default:
                  if(result.code){
                  this.adm.programcode = result.code;  
                  this.dProgram = result.description; 
                  this.ProgramIsInPatient = result.AuxFields[0];                  
                  if(!this.ProgramIsInPatient){
                     this.adm.roomno = "";  
                     this.adm.buildingCode = null;
                     this.rooms.description = ""; 
                     this.adm.admissionclass = null;
                     this.adm.prehopitalvocationeffort = null;
                     this.adm.prehospitalvocation = null;
                     this.adm.residentNumber = null;
                     this.adm.admissionfrom = null;
                     this.adm.referralCode = null;
                     this.adm.prehospitalliving = null;
                     this.adm.coveredDays = null;
                  }                 
                  this.PopulateBuildings("");
                  }else{
                     this.ProgramIsInPatient = false;
                  }
             }
         }else if(type.indexOf('Rooms')  >= 0 ){
              switch (type) {                           
               default:    
                  if(Object.prototype.hasOwnProperty.call(result, "AuxFields")){
                     if(result.AuxFields[2] > 0){
                        this.adm.roomno = result.code;  
                        this.rooms.description = result.description; 
                        var buildingCode = result.AuxFields[1];
                        this.Admission.buildings = this.Admission.buildings ? this.Admission.buildings :  buildingCode;    
                     }else{
                        this.showMessage(this.$t('Shared.Rooms'),this.$t('Admission.BedAvailables'),"error");
                        this.ResetRooms();
                     }                     
                  }else{                      
                     this.ResetRooms();
                  }
                            
             }
         }
     },

     ResetRooms(){
         setTimeout(() => this.ClearRooms(),100); 
     },

     ClearRooms(){
        this.adm.roomno = "";  
        this.rooms.description = "";
        this.$set(this.rooms, 'description', '');
        document.getElementById("Rooms").value = "";

        if(this.adm.roomno !== ""){
         this.showMessage(this.$t('Shared.Rooms'),this.$t('Admission.BedAvailables'),"error");
        }
     },

   ValidateDiagnosis(){
      if(this.adm.diagnosis.diagCode1 !== '' || this.adm.diagnosis.diagCode2 !== '' || this.adm.diagnosis.diagCode3 !== '' 
      || this.adm.diagnosis.diagCode4 !== '' || this.adm.diagnosis.diagCode5 !== '' || this.adm.diagnosis.diagCode6 !== ''
      || this.adm.diagnosis.diagCode7 !== '' || this.adm.diagnosis.diagCode8 !== '' || this.adm.diagnosis.diagCode9 !== ''
      || this.adm.diagnosis.diagCode10 !== '' || this.adm.diagnosis.diagCode11 !== '' || this.adm.diagnosis.diagCode12 !== ''
      || this.adm.diagnosis.diagCode13 !== '' || this.adm.diagnosis.diagCode14 !== '' || this.adm.diagnosis.diagCode15 !== ''
      || this.adm.diagnosis.diagCode16 !== ''){
         return true;
      }

      return false;
   },



    ChangeDropDownBuilding(e){    
      this.Admission.buildings = e !== null ? e.code : "";        
      this.adm.roomno = "";
      this.rooms.description = "";
    },
    ChangeDropDownRooms(){
    },

    ChangeDropDownStatus(e){
      this.adm.statusCode = e.code;
    },

    ChangeDropDownAdmissionClass(e){
       this.adm.admissionclass = e.code;
    },

   ChangeDropDownAdmissionFrom(e){
       this.adm.admissionfrom = e.code;
    },

    ChangeDropDownLivingWith(e){
      this.adm.prehospitalliving = e.code;
    },

    ChangeDropDownAdmissionSource(e){
      this.adm.admissionsource = e !== null ? e.code : "";  
      if(e !== null && e.code === "1"){
         this.adm.prehospitalliving = "";
         this.isCode01 = true;         
      }else{
         this.isCode01 = false;
      }    
    },

    ChangeDropDownAdmissionVocationalEffort(e){
       this.adm.prehopitalvocationeffort = e.code;
    },

    ChangeDropDownAdmissionVocationalCategory(e){
      this.adm.prehospitalvocation = e.code;
    },

    ChangeDropDownAdmissionType(e){
      this.adm.admissiontype = e.code;
    },

    ChangeDropDownAdmittedFromLocationList(e){
      this.adm.referralCode = e.code;
    },

    ChangeDropDownTrackList(e){
      this.adm.track = e.code;
    },

    ChangeDropDownAdmissionProgram(){
    },

    ChangeDropDownAssessment(e){
      this.adm.assessmentCode = e.code;
    },

    onProgramConditionOk(){

    },
    onClassConditionOk(){

    },

    onVocEffConditionOk(){

    },

    onSourceConditionOk(){

    },

    onVocCatConditionOk(){

    },

    onFromConditionOk(){

    },

    onLivWtConditionOk(){

    },

    onStatusConditionOk(){

    },

    SearchAdmission(){

    },

    GotoAdmissionList(){
      if (this.$route.name.includes('appointments-admission-')) {
         this.$router.push({ name: 'appointments-admissionList', params: { id: this.$route.params.id, actId: this.$route.params.actId }});
      } else
         this.$router.push({ name: "patient-admissionList", params: { id: this.$route.params.id } }).catch(()=>{});
    },

     beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		},

      formatterDate(value){
         return Utils.formatterDateTimeToString(value);
      },

     PrepareAdmission(){
        
        this.adm.occurrence.forEach(element => {
            element.dateFrom = Utils.formatterToISO(element.dateFrom, Utils.getShortDateFormat());            
           element.dateTo = Utils.formatterToISO(element.dateTo, Utils.getShortDateFormat());
           element.occurrenceDate = Utils.formatterToISO(element.occurrenceDate, Utils.getShortDateFormat());
        });

         if(this.DateFrom){
            this.adm.dateFrom = Utils.formatterToISO(this.DateFrom, Utils.getDateTimeFormat());
         }

         if(this.DateTo){
             this.adm.dateTo = Utils.formatterToISO(this.DateTo, Utils.getDateTimeFormat());
         }
      },

     AddAdmission(){   
       //  this.adm.teamMembers = this.TeamMemberCopy;
       //  this.adm.occurrence = this.OcurrenceCopy;
         AdmissionService.AddAdmission(this.adm)
                .then((response) => {
                    if(response.status == '200'){
                        this.adm = response.data;   

                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }
                      
                          setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }
                    this.restoreFormat();                             
                })
                .catch((error) => {   
                    elemSaveBtn.removeAttribute("disabled");
                    if (!error.response) {
                   this.showMessage(this.$t('Admission.AddAdmission'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Admission.AddAdmission'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('Admission.AddAdmission'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Admission.AddAdmission'),error.response.data.errorMessage,"error");
                     } 

                     this.restoreFormat();
                    this.$emit('load', false);
                }); 

      },

      UpdateAdmission(){ 
        // this.adm.teamMembers = this.TeamMemberCopy;
        // this.adm.occurrence = this.OcurrenceCopy;
          AdmissionService.UpdateAdmission(this.$route.params.recordid, this.adm)
                .then((response) => {
                    if(response.status == '200'){                       
                    this.$emit('load', false);  
                    this.pendingChanges = false; 

                    if(!this.$route.name.includes('clinical')){
                     if(!this.OffRoute){
                           this.GotoAdmissionList();
                        } 
                    }

                    if(this.$route.name.includes('admission-clinical')){
                     this.getInfoClinicalAdmission()
                    }
                      

                       setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 


                    }  

                    this.restoreFormat();
                    this.$emit('load', false);                  
                })
                .catch((error) => {
                  elemSaveBtn.removeAttribute("disabled");
                  if (!error.response) {
                   this.showMessage(this.$t('Admission.UpdateAdmission'),this.$t('Msg.NotAbleToUpdate'),"error");
                 }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Admission.UpdateAdmission'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Admission.UpdateAdmission'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Admission.UpdateAdmission'),error.response.data.errorMessage,"error");
                     }  


                    this.restoreFormat();
                    this.$emit('load', false);
                });
      },

      restoreFormat() {
        if(this.adm.dateFrom){
            this.DateFrom = this.formatterDate(this.adm.dateFrom);
        }
        if(this.adm.dateTo){
            this.DateTo = this.formatterDate(this.adm.dateTo);
        }
            
        if(this.adm.occurrence && this.adm.occurrence.length > 0){
            this.adm.occurrence.forEach(element => {
                element.dateFrom = element.dateFrom ? Utils.formatterDateToString(element.dateFrom) : "";  
                element.dateTo = element.dateTo ? Utils.formatterDateToString(element.dateTo) : "";  
                element.occurrenceDate = element.occurrenceDate ? Utils.formatterDateToString(element.occurrenceDate) : "";                         
            });
        }
      },

      DoAction(){      
         this.WasSaved = true; 
         this.PrepareAdmission();
         !this.edit_mode ? this.AddAdmission() : this.UpdateAdmission(); 
      },

     async ClickOffRoute(){
         this.OffRoute = true;
         await this.SaveOrEdit();
      },
     

       validate_required() {
        this.isDateFromValid = this.DateFrom ? true : false;
        this.isProgramValid = this.adm.programcode && this.adm.programcode.length > 0 ? true : false;      
        this.isRoomValid = (this.ProgramIsInPatient && this.enforceRoom) && (this.adm.roomno && this.adm.roomno.length > 0) ? true : false;      
       
       if(!this.ProgramIsInPatient || !this.enforceRoom){
          this.isRoomValid = true;
       }

         this.TextProgram = this.adm.programcode === "" ? this.$t('Admission.ProgramIsRequired') : this.$t('transfer.InvalidProgramCode');
         
        
        return this.isDateFromValid  && this.isProgramValid && this.isRoomValid;
      },


     validateDate(){
      var result = Utils.IsValidDateAndRange(this.DateFrom, Utils.getDateTimeFormat()) ? true : false;
      return result;
    },

      SaveOrEdit(){ 
         elemSaveBtn.setAttribute('disabled','');
         
         this.$emit('load', true);
         if (this.validate_required()) {
            if(this.validateDate()){
               this.DoAction();
            }else{
               this.$emit('load', false);
               elemSaveBtn.removeAttribute("disabled");
               this.showMessage(this.$t('Admission.Admission'), this.$t('Msg.InvalidDate'), "errorAutoHide");          
            }
               
         } else {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            this.showMessage(this.$t('Admission.Admission'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }

      },

      

      getDateTime() { 
         if(!this.edit_mode){
             GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                //this.DateFrom = this.formatterDate(response.data);
                this.DateFrom = Utils.formatterDateTimeToString(response.data);
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('Admission.AdmissionGetDateTime'),error,"error");
            }
         }); 
         }
        
      },

      PopulateDropDowns(){ 
         if(!this.edit_mode){
             AdmissionService.getStatus()
                .then((response) => {
                    if(response.status == '200' && response.data){
                      this.dstatus = response.data[0].description;
                      this.adm.statusCode = response.data[0].code;
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionStatus'),error,"error");
                    }
                });
         }

      },
     async PopulateBuildings(code){
         if(this.ProgramIsInPatient){
            await AdmissionService.getBuildings()
                .then((response) => {
                    if(response.status == '200'){
                        this.BuildinsList = response.data; 
                        if(this.edit_mode){
                          this.Admission.buildings = code !== null ? code : "";
                        }
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionBuildings'),error,"error");
                    }
                });
         }
      },


     getPosition(code){
        for(var i = 0; i < this.FieldsTeamMember.length; i++){
           if(this.FieldsTeamMember[i].key === code){
              return i;
           }
        }

        return -1;
     },

   

    async getAdmission(){
      if(this.edit_mode){
         AdmissionService.GetAdmission(this.$route.params.recordid)
                .then((response) => {
                    if(response.status == '200'){
                        this.adm = response.data;                       
                        if(this.adm.isInpatient){
                           this.ProgramIsInPatient = true; 
                           this.PopulateBuildings(this.adm.buildingCode);
                        }else{
                           this.ProgramIsInPatient = false; 
                        }                   
                        this.totalRowsTeamMember = this.adm.teamMembers && this.adm.teamMembers.length > 0 ? this.adm.teamMembers.length : 0;                           
                        this.dProgram = this.adm.programDescription;
                        this.dstatus = this.adm.statusDescription;
                        this.rooms.description = this.adm.roomnoDescription;                       
                        if(this.adm.dateFrom){
                           this.DateFrom = this.formatterDate(this.adm.dateFrom);
                        }
                        if(this.adm.dateTo){
                           this.DateTo = this.formatterDate(this.adm.dateTo);
                        }
                        if(this.adm.transactionType === 'TRANSFER'){
                            this.TransactionType = 'Transfer';
                            this.IsTransfer = true;
                        }         

                        this.OcurrenceCopy = this.adm.occurrence;
                        this.TeamMemberCopy = this.adm.teamMembers;

                        if(this.adm.occurrence && this.adm.occurrence.length > 0){
                           this.adm.occurrence.forEach(element => {
                              element.dateFrom = element.dateFrom ? Utils.formatterDateToString(element.dateFrom) : "";  
                              element.dateTo = element.dateTo ? Utils.formatterDateToString(element.dateTo) : "";  
                              element.occurrenceDate = element.occurrenceDate ? Utils.formatterDateToString(element.occurrenceDate) : "";                         
                           });
                        }                     
                        this.$emit('load', false);                         
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.GetAdmission'),error,"error");
                    }
                     this.$emit('load', false);
                });
         }
        
     },

   async getDiagnosis(){
      if(!this.edit_mode){
         var Diagnosis = await this.getPatientDiagnosis(this.$route.params.id);       
         var l = Diagnosis.length;
         for(var i = 0; i < l; i++){
            var pos = i + 1;
            var diag = "diagCode"+pos;         
           var ddiag = "diagCode"+pos+"Description";
            this.adm.diagnosis[diag] = Diagnosis[i].diagCode;
            this.adm.diagnosis[ddiag] =  Diagnosis[i].diagName;          
         }
      }	     
	},
   
   PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);	
      if(this.$route.name.includes('admission-clinical')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = Utils.formatterDateToString(this.RegistrationInfo.dob);
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }     
    },

    async getInfoClinicalAdmission(){      
      if(this.$route.name.includes('clinical')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  Utils.formatterDateToString(this.RegistrationInfo.dob);

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
    }
    this.PopulatePatientTitle();
    },
 
    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = Utils.formatterDateToString(infoList[0].dob);
          }  
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
          this.patient = infoList[0];
      }
       if(!this.edit_mode){
          this.$emit('load', false); 
       }

      /* if(this.$route.name.includes('patient')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }*/

    },

   checkUserAction() {
      if (elemSaveBtn.disabled) {
         elemSaveBtn.removeAttribute('disabled');
      }
   },

   async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }

  },

  async mounted(){ 
     this.isReadOnlyAdmission = await this.$store.state.isPatientAdmissionEHRReadOnly;      
      EventBus.$emit('onSetReadOnly', this.isReadOnlyAdmission);
      this.$emit('load', true); 
      this.PopulateDropDowns(); 
      this.getDiagnosis();
      this.ApiProgram = this.edit_mode ? "Programs" : "Programs?ApplyProgramException=true";
      this.getDateTime();
     
      this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-admission-') ? EnumService.AppointmentItems : EnumService.PatientItems;
      
      this.OptionsRightMenu.Mode = this.edit_mode;
      this.OptionsRightMenu.visible = true;
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
      this.GetPatientByCode();     
      this.getAdmission();    

      this.getInfoClinicalAdmission();
      if (this.$route.name.includes('appointments-admission-add') || this.$route.name.includes('appointments-admission-edit'))
         await this.getCurrentAppointment()
    
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_admission');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );
  },

   async created(){
      await this.populateSelects();
      if (!this.editMode) {
         if (this.AdmissionSourceList && this.AdmissionSourceList.length == 1) {
            this.adm.admissionsource = this.AdmissionSourceList[0].code
         }
         if (this.AdmissionTypeList && this.AdmissionTypeList.length == 1) {
            this.adm.admissiontype = this.AdmissionTypeList[0].code
         }
      }
    EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed;
          this.checkUserAction();
     }.bind(this));  
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
   
};
</script>


