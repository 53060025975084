import i18n from '@/plugins/i18n';
import  Vue from 'vue';
// import store from '@/globalvar.js'

i18n.locale = localStorage.language 
            ? localStorage.language 
            : i18n.locale;

const EnumService = Vue.observable({

    Titles: [
        { value: null, text: '' },  
        { value: 'Mr.', text: 'Mr.' },
        { value: 'Ms.', text: 'Ms.' },         
        { value: 'Mrs.', text: 'Mrs.' },         
        { value: 'Dr.', text: 'Dr.' },
    ],

    RouteNameList:[
        { value: null, text: '' },  
        { value: 'Bucally', text: i18n.t('ENUM.RouteNameList.Bucally') },
        { value: 'By Mouth', text: i18n.t('ENUM.RouteNameList.ByMouth') },         
        { value: 'Cervically', text: i18n.t('ENUM.RouteNameList.Cervically') }
    ],

    ActionList:[
        { value: null, text: '' },  
        { value: 'Apply', text: i18n.t('ENUM.ActionList.Apply') },
        { value: 'Chew', text: i18n.t('ENUM.ActionList.Chew') },
        { value: 'Cruch', text: i18n.t('ENUM.ActionList.Cruch') }
    ],

    WhenList:[
        { value: null, text: '' },  
        { value: 'AC', text: 'AC' },
        { value: 'HC', text: 'HC' },
        { value: 'PC', text: 'PC' }
    ],

    ProductTypeSelectList:[ 
        { value: 'Prescription Drug', text: i18n.t('ENUM.ProductType.PresDrug') },
        { value: 'Over The Counter', text: i18n.t('ENUM.ProductType.OvConunter') },
        { value: 'Standardized Allergenic', text: i18n.t('ENUM.ProductType.StandAllerg') }
    ],

    SubstanceScheduleSelectList:[
        { value: 'CI', text: 'CI' },
        { value: 'CII', text: 'CII' },
        { value: 'CIV', text: 'CIV' },
        { value: 'CV', text: 'CV' },
    ],

    PatientListStatus:[
        { value: true, text: i18n.t('Shared.Active'), selected:true },
        { value: false, text: i18n.t('Shared.Inactive'),selected:false },
        { value: 'All', text: i18n.t('Shared.All'),selected:false },
    ],

    Sexs: [
        { value: 'M', text: i18n.t('Shared.Male') },
        { value: 'F', text: i18n.t('Shared.Female') },
        { value: 'U', text: i18n.t('ENUM.MarSt.U') },
    ],

    SexsMaleFemaleOnly: [
        { value: 'M', text: i18n.t('Shared.Male') },
        { value: 'F', text: i18n.t('Shared.Female') }      
    ],

    YesNoOptions: [
        { value: 'Y', text: i18n.t('Shared.TitleYes') },
        { value: 'N', text: 'No'  }         
    ],

    YesNoOptionsInverted: [
        { value: 'N', text: i18n.t('Shared.TitleYes') },
        { value: 'Y', text: 'No'  }         
    ],

    MaritalStatus: [
        { value: 'A', text: i18n.t('ENUM.MarSt.A') },
        { value: 'D', text: i18n.t('ENUM.MarSt.D') },  
        { value: 'I', text: i18n.t('ENUM.MarSt.I') },
        { value: 'L', text: i18n.t('ENUM.MarSt.L') },
        { value: 'Y', text:i18n.t('ENUM.MarSt.M') },
        { value: 'P', text: i18n.t('ENUM.MarSt.P') },
        { value: 'N', text: i18n.t('ENUM.MarSt.N') },
        { value: 'T', text: i18n.t('ENUM.MarSt.Dp') },
        { value: 'W', text: i18n.t('ENUM.MarSt.W') },
        { value: 'O', text: i18n.t('ENUM.MarSt.U') },       
    ],

    Employment: [
        { value: '1', text: i18n.t('ENUM.Employment.Ft') },
        { value: '2', text: i18n.t('ENUM.Employment.Pt') },  
        { value: '3', text: i18n.t('ENUM.Employment.N') },
        { value: '4', text: i18n.t('ENUM.Employment.S') },
        { value: '5', text:i18n.t('ENUM.Employment.R') },
        { value: '6', text: i18n.t('ENUM.Employment.ActM') },
        { value: '7', text: i18n.t('ENUM.Employment.H') },
        { value: '8', text: i18n.t('ENUM.Employment.U') },
    ],

    Student: [
        { value: 'Y', text: i18n.t('Shared.TitleYes') },
        { value: 'N', text: 'No' },
        { value: 'F', text: i18n.t('ENUM.Employment.Ft') },
        { value: 'P', text: i18n.t('ENUM.Employment.Pt') },  
    ],
    
    AcceptAssign: [
        { value: null, text: '' },  
        { value: 'C', text: i18n.t('ENUM.AcceptAssign.C') },
        { value: 'S', text: i18n.t('ENUM.AcceptAssign.S') },         
        { value: 'M', text: i18n.t('ENUM.AcceptAssign.M') },         
        { value: 'B', text: i18n.t('ENUM.AcceptAssign.b') },
        { value: 'P', text: i18n.t('ENUM.AcceptAssign.p') },
    ],

    InfoRelease: [
        { value: null, text: '' },  
        { value: 'Y', text: i18n.t('ENUM.InfoRelease.Y') },
        { value: 'M', text: i18n.t('ENUM.InfoRelease.M') },         
        { value: 'N', text: i18n.t('ENUM.InfoRelease.N') },         
    ],

    
    PatientItems:[
        {value:'PatientSearch',text: i18n.t('ENUM.PITEMS.PatientList')},
        {value:'PatientSummary',text: i18n.t('ENUM.PITEMS.Summary')},
        {value:'Admission',text:i18n.t('ENUM.PITEMS.Admissions')},
        {value:'Appointments',text:i18n.t('ENUM.PITEMS.Appointments')},
        {value:'PatientAllergy',text:i18n.t('ENUM.PITEMS.PatientAllergy')},
        {value:'PatientAsam',text:i18n.t('ENUM.PITEMS.PatientAsam')},
        {value:'CHARTREVIEWPATIENT',text:i18n.t('ENUM.PITEMS.CHARTREVIEWPATIENT')},
        {value:'PatientContact',text:i18n.t('ENUM.PITEMS.PatientContact')},
        {value:'Diagnosis',text:i18n.t('ENUM.PITEMS.Diagnosis')},
        {value:'PatientDocument',text:i18n.t('ENUM.PITEMS.PatientDocument')},      
        {value:'PatientInsurance',text:i18n.t('ENUM.PITEMS.PatientInsurance')},       
        {value:'PatientInfo',text:i18n.t('ENUM.PITEMS.PatientInfo')},
        {value:'PatientSpecialPrecautions',text:i18n.t('ENUM.PITEMS.PatientSpecialPrecautions')},
    ],
    
    ClinicalItems:[
        {value:'PatientListClinical',text:i18n.t('ENUM.PITEMS.AdmissionList')},
        {value:'ClinicalSummary',text: i18n.t('ENUM.PITEMS.Summary')},
        {value:'ADMISSIONINFOCLINICAL',text:i18n.t('ENUM.PITEMS.Admission')},
        {value:'AllergyClinical',text:i18n.t('ENUM.PITEMS.PatientAllergy')},
        {value:'ClinicalAppointments',text:i18n.t('ENUM.PITEMS.Appointments')},
        {value:'ClinicalAsam',text:i18n.t('ENUM.PITEMS.PatientAsam')},
        {value:'CHARTREVIEWCLINICAL',text:i18n.t('ENUM.PITEMS.CHARTREVIEWPATIENT')},
        {value:'ClinicalNotes',text:i18n.t('ENUM.CITEMS.ClinicalNotes')},       
        {value:'ContactClinical',text:i18n.t('ENUM.PITEMS.PatientContact')},
        {value:'DailyNotes',text:i18n.t('ENUM.CITEMS.DailyNotes')},
        {value:'DiagnosisClinical',text:i18n.t('ENUM.PITEMS.Diagnosis')},
        {value:'Discharge',text:i18n.t('ENUM.CITEMS.Discharge')},
        {value:'DocumentClinical',text:i18n.t('ENUM.PITEMS.PatientDocument')},     
        {value:'ePharmacyClinical',text:i18n.t('ENUM.CITEMS.ePrescribing')},        
        {value:'InsuranceClinical',text:i18n.t('ENUM.PITEMS.PatientInsurance')},  
        {value:'LogNotes',text:i18n.t('ENUM.CITEMS.LogNotes')},
        {value:'Medications',text:i18n.t('ENUM.CITEMS.Medications')},
        {value:'PatientInfo',text:i18n.t('ENUM.PITEMS.PatientInfo')},
        {value:'SchedulerClinical',text:i18n.t('ENUM.CITEMS.SchedulerNotes')},   
        {value:'Transfer',text:i18n.t('ENUM.CITEMS.Transfer')},
        {value:'TreatmentPlanClinical',text:i18n.t('ENUM.CITEMS.TP')},
        //{value:'WYTREATMENTPLAN',text:i18n.t('ENUM.CITEMS.WyTplan')},
        //{value:'MDTREATMENTPLAN',text:i18n.t('ENUM.CITEMS.MdTplan')},
        {value:'ClinicalVitalSign',text:i18n.t('ENUM.CITEMS.ClinicalVitalSign')},
        {value:'ClinicalSpecialPrecautions',text:i18n.t('ENUM.PITEMS.PatientSpecialPrecautions')},
    ],
    AppointmentItems:[
        {value:'AppointmentList',text:i18n.t('ENUM.ApooItems.AppoList')},
        {value:'SchedulerSummary',text: i18n.t('ENUM.PITEMS.Summary')},
        {value:'Admission',text:i18n.t('ENUM.PITEMS.Admissions')},
        {value:'PatientAllergy',text:i18n.t('ENUM.PITEMS.PatientAllergy')},
        {value:'Appointment',text:i18n.t('ENUM.PITEMS.Appointment')},
        {value:'PatientAsam',text:i18n.t('ENUM.PITEMS.PatientAsam')},
        //{value:'CancelAppt',text:i18n.t('ENUM.ApooItems.CancelAppt')},   
        {value:'CHARTREVIEWPATIENT',text:i18n.t('ENUM.PITEMS.CHARTREVIEWPATIENT')},
        //{value:'ConfirmAppt',text:i18n.t('ENUM.ApooItems.ConfirmAppt')}, 
        {value:'PatientContact',text:i18n.t('ENUM.PITEMS.PatientContact')},
        {value:'Diagnosis',text:i18n.t('ENUM.PITEMS.Diagnosis')},
        {value:'Discharge',text:i18n.t('ENUM.CITEMS.Discharge')},        
        {value:'PatientDocument',text:i18n.t('ENUM.PITEMS.PatientDocument')},
        {value:'ePharmacyClinical',text:i18n.t('ENUM.CITEMS.ePrescribing')},
        {value:'Medications',text:i18n.t('ENUM.CITEMS.Medications')},   
        {value:'AppointmentInsurance',text:i18n.t('ENUM.PITEMS.PatientInsurance')},  
        {value:'PatientInfo',text:i18n.t('ENUM.PITEMS.PatientInfo')},
        {value:'SchedulerNotes',text: i18n.t('SchedulerNotes.SNTitle')},
        {value:'Transfer',text:i18n.t('ENUM.CITEMS.Transfer')}, 
        //{value:'ShowUpAppt',text:i18n.t('schedulerDB.dropdown.ShowUp')},
        //{value:'Activities',text: i18n.t('ActList.Activities')},  
        {value:'TreatmentPlanScheduler',text:i18n.t('ENUM.CITEMS.TP')},
        {value:'ClinicalVitalSign',text:i18n.t('ENUM.CITEMS.ClinicalVitalSign')},
        {value:'PatientSpecialPrecautions',text:i18n.t('ENUM.PITEMS.PatientSpecialPrecautions')},
    ],

    SchedulerNotesShow:[
        {value:'ShowAllType',text:i18n.t('ENUM.ScheNotesShow.ShowAllType')},
        {value:'ShowAllNote',text:i18n.t('ENUM.ScheNotesShow.ShowAllNote')},       
    ],

    ClaimBatchItems:[
        {value:'ClaimBatchList',text:i18n.t('ENUM.ClaimBaItems.ClaimBaList')},
        {value:'PatientInfoClaimBatch',text:i18n.t('Patient.patientInfo')},        
        {value:'DiagnosisWorksSheet',text:i18n.t('ENUM.ClaimBaItems.DiagWorksSheet')},       
        {value:'Doctors',text: i18n.t('Shared.Doctors')},
        {value:'Insurances',text: i18n.tc('Shared.Insurance',2)}
    ],

    // Clinical Dashboard Filter
    Types:[
        {value:true,name:  i18n.t('Shared.Inpatient')},
        {value:false,name: i18n.t('ENUM.Types.OutPa')},
        {value:'',name: i18n.t('Shared.All')},
    ],
    Status:[
        {value:'Active',name: i18n.t('Shared.Active')},
        {value:'Transfer',name: i18n.t('transfer.Transfer')},
        {value:'Inactive',name: i18n.t('Shared.Inactive')},
        {value:'',name: i18n.t('Shared.All')},
    ],
    ClinicalMedicatiosRxStatus:[
        {value:'All',name: i18n.t('Shared.All')},
        {value:'ActiveDuration',name: i18n.t('ENUM.CliMediRxStatus.ActDuration')},
        {value:'Active',name: i18n.t('Shared.Active')},       
        {value:'Inactive',name: i18n.t('Shared.Inactive')},
        {value:'Past',name: i18n.t('ENUM.CliMediRxStatus.Past')}
    ],
    Asam:[
         {value:'true',name: i18n.t('ENUM.Asam.W')},
         {value:'false',name: i18n.t('ENUM.Asam.N')},
         {value:'',name: i18n.t('Shared.All')},
    ],
    TreatmentPlan:[
         {value:true,name: i18n.t('ENUM.TreatmentPlan.W')},
         {value:false,name: i18n.t('ENUM.TreatmentPlan.N')},
         {value:'',name: i18n.t('Shared.All')},
    ],
    Program:[
        {name: i18n.t('ENUM.Program.P1')},
        {name: i18n.t('ENUM.Program.P2')},
        {name: i18n.t('ENUM.Program.P3')},
        {name: i18n.t('ENUM.Program.P4')},
    ],    
    Therapies:[
        {value:'A',name: i18n.t('ChartReview.Admissions')},
        {value:'B',name: i18n.t('ENUM.Therapies.DI')},
        {value:'C',name: i18n.t('ENUM.Therapies.GN')},
        {value:'D',name: i18n.t('ENUM.Therapies.N')},
        {value:'E',name: i18n.t('ENUM.Therapies.M')},
        {value:'F',name: i18n.t('ENUM.Therapies.IF')},
    ],
    YesNoOptionsNumericThree:[
        {value: '1', text: i18n.t('Shared.TitleYes')},
        {value: '0', text: 'No'},
        {value: '2', text: 'N/A'}
    ],
    YesNoOptionsNumericTwo:[
        {value: '1', text: i18n.t('Shared.TitleYes')},
        {value: '0', text: 'No'},
    ],
    NoteTypes:[
        {value:'A',name: i18n.t('ENUM.NoteTypes.AA')},
        {value:'B',name: i18n.t('ENUM.NoteTypes.AO')},
        {value:'C',name: i18n.t('ENUM.NoteTypes.AOF')},
        {value:'D',name: i18n.t('ENUM.NoteTypes.CF')},
        {value:'E',name: i18n.t('ENUM.NoteTypes.CT')},
        {value:'F',name: i18n.t('ENUM.NoteTypes.DF')},
        {value:'G',name: i18n.t('ENUM.NoteTypes.FC')},
        {value:'H',name: i18n.t('ENUM.NoteTypes.SP')}
    ],
    Therapist:[
        {value:'A',name: i18n.t('ENUM.Therapist.DB')},
        {value:'B',name: i18n.t('ENUM.Therapist.DBr')},
        {value:'C',name: i18n.t('ENUM.Therapist.DD')},
        {value:'D',name: i18n.t('ENUM.Therapist.DJ')},       
    ],
    NoteStatus:[
        {value:0,name: i18n.t('Shared.All')},
        {value:1,name: i18n.t('ENUM.NoteStatus.Pending')},
        {value:2,name:i18n.t('employee.NeedCosigner')},
        {value:3,name: i18n.t('ENUM.NoteStatus.Finalized')},
    ],
    StatusGroupNotes:[
        {value:'',name:i18n.t('Shared.All')},
        {value:'N',name:i18n.t('ENUM.StGrNotes.N')},
        {value:'P',name:i18n.t('ENUM.NoteStatus.Pending')},
        {value:'F',name:i18n.t('ENUM.NoteStatus.Finalized')}        
    ],

    SchedulerDashboardNoteStatus:[
        //{value:i18n.t('Shared.All'),name:'<ALL>'},
        {value:'NoNotes',name:i18n.t('ENUM.ScheDashNoteStatus.NoNotes')},
        {value:'PendingNotes',name:i18n.t('ENUM.ScheDashNoteStatus.PendingNotes')},
        {value:'PendingGroupNotes',name:i18n.t('ENUM.ScheDashNoteStatus.PendingGroupNotes')},
        {value:'PendingCosignNotes',name:i18n.t('ENUM.ScheDashNoteStatus.PendingCosignNotes')},
        {value:'FinalizedNotes',name:i18n.t('ENUM.ScheDashNoteStatus.FinalizedNotes')},
    ],

    SchedulerDashboardApptStatus:[
        //{value:i18n.t('Shared.All'),name:'<ALL>'},
        {value:'New',name:i18n.t('ENUM.AppointmentStatus.New')},
        {value:'Confirmed',name: i18n.t('ENUM.AppointmentStatus.Confirmed')},
        {value:'Showed Up',name:i18n.t('ENUM.AppointmentStatus.ShowedUp')},
        {value:'Missed',name: i18n.t('ENUM.AppointmentStatus.Missed')},
    ],

    ReportClinicalNotesActions:[
        {value:'EXPPDFPAT',name:i18n.t('ENUM.RepCliNotesAct.EXPPDFPAT')},
        {value:'EXPPDFNOT',name:i18n.t('ENUM.RepCliNotesAct.EXPPDFNOT')},
        {value:'EXPPDFDAT',name:i18n.t('ENUM.RepCliNotesAct.EXPPDFDAT')},
    ],
    
    ReportClinicalNotesSortOrder:[
        {value:'DATE_TIME_PATIENTCODE_THERAPISTSCODE',name: i18n.t('ENUM.ReporSort.A')},
        {value:'DATE_TIME_THERAPISTCODE_PATIENTCODE',name: i18n.t('ENUM.ReporSort.B')},
        {value:'PATIENTCODE_DATE_TIME_THERAPISTCODE',name: i18n.t('ENUM.ReporSort.C')},
        {value:'PATIENTCODE_THERAPISTCODE_DATE_TIME',name: i18n.t('ENUM.ReporSort.D')},
        {value:'THERAPISTCODE_DATE_TIME_PATIENTCODE',name: i18n.t('ENUM.ReporSort.E')},
        {value:'THERAPISTCODE_PATIENTCODE_DATE_TIME',name: i18n.t('ENUM.ReporSort.F')},
        {value:'DATEDESC_TIMEDESC_PATIENTCODE_THERAPISTCODE',name: i18n.t('ENUM.ReporSort.G')},
        {value:'DATEDESC_TIMEDESC_THERAPISTCODE_PATIENTCODE',name: i18n.t('ENUM.ReporSort.H')},
        {value:'PATIENTCODE_DATEDESC_TIMEDESC_THERAPISTCODE',name: i18n.t('ENUM.ReporSort.I')},
        {value:'PATIENTCODE_THERAPISTCODE_DATEDESC_TIMEDESC',name: i18n.t('ENUM.ReporSort.J')},
        {value:'THERAPISTCODE_DATEDESC_TIMEDESC_PATIENTCODE',name: i18n.t('ENUM.ReporSort.K')},
        {value:'THERAPISTCODE_PATIENTCODE_DATEDESC_TIMEDESC',name: i18n.t('ENUM.ReporSort.L')},
        {value:'DATE_TIME_PATIENTLASTNAME_THERAPISTSCODE',name: i18n.t('ENUM.ReporSort.M')},
    ],

    ReportEmployeeCaseLoadSortOrder:[
        {value:'DATE_TIME_THERAPISTSCODE',name: i18n.t('ENUM.RepEmplSort.A')},
        {value:'THERAPISTCODE_DATE_TIME',name: i18n.t('ENUM.RepEmplSort.B')},
        {value:'DATEDESC_TIMEDESC_THERAPISTCODE',name: i18n.t('ENUM.RepEmplSort.C')},
        {value:'THERAPISTCODE_DATEDESC_TIMEDESC',name: i18n.t('ENUM.RepEmplSort.D')}
    ],

    Characterts:[
        {value:'Sharp',name: i18n.t('ENUM.Characterts.Sharp')},
        {value:'Dull',name: i18n.t('ENUM.Characterts.Dull')},
        {value:'Stabbing',name: i18n.t('ENUM.Characterts.Stabbing')},
        {value:'Burning',name: i18n.t('ENUM.Characterts.Burning')},
        {value:'Crushing',name: i18n.t('ENUM.Characterts.Crushing')},
        {value:'Deep',name: i18n.t('ENUM.Characterts.Deep')},
        {value:'Sore',name: i18n.t('ENUM.Characterts.Sore')},
        {value:'Aching',name: i18n.t('ENUM.Characterts.Aching')},
        {value:'Colic',name: i18n.t('ENUM.Characterts.Colic')},
        {value:'Throbbing',name: i18n.t('ENUM.Characterts.Throbbing')},
        {value:'Numb',name: i18n.t('ENUM.Characterts.Numb')},
        {value:'Shooting',name: i18n.t('ENUM.Characterts.Shooting')},
        {value:'Pressing',name: i18n.t('ENUM.Characterts.Pressing')},
        {value:'Tight',name: i18n.t('ENUM.Characterts.Tight')},
        {value:'Pulling',name: i18n.t('ENUM.Characterts.Pulling')},
        {value:'Squeezing',name: i18n.t('ENUM.Characterts.Squeezing')}
    ],
    Frequencys:[
        {value:'Constant',name: i18n.t('ENUM.Frequencys.Constant')},
        {value:'Intermittent',name: i18n.t('ENUM.Frequencys.Intermittent')},
    ],
    Interventions:[ 
        i18n.t('ENUM.Interv.Heatpacks'),
        i18n.t('ENUM.Interv.Coldpacks'),
        i18n.t('ENUM.Interv.RepTurning'),
        i18n.t('ENUM.Interv.Ambulation'),
        i18n.t('ENUM.Interv.RelExercises'),
        i18n.t('ENUM.Interv.DeepBreathing'),
        i18n.t('ENUM.Interv.RhyBreathing'),
        i18n.t('ENUM.Interv.Medication')
    ],

    /*Enums for Vitals Signs*/
    TempMethod:[
        {value:'AXILLARY',name: i18n.t('ENUM.TempMethod.AXILLARY')},
        {value:'GROIN',name: i18n.t('ENUM.TempMethod.GROIN')},
        {value:'NOSE',name: i18n.t('ENUM.TempMethod.NOSE')},
        {value:'OESOPHAGUS',name: i18n.t('ENUM.TempMethod.OESOPHAGUS')},
        {value:'ORAL',name: i18n.t('ENUM.TempMethod.ORAL')},
        {value:'PULMONARY ARTERY',name: i18n.t('ENUM.TempMethod.PULMONARYARTERY')},
        {value:'RECTAL',name: i18n.t('ENUM.TempMethod.RECTAL')},
        {value:'SKIN SURFACE',name: i18n.t('ENUM.TempMethod.SKINSURFACE')},
        {value:'TEMPORAL',name: i18n.t('ENUM.TempMethod.TEMPORAL')},
        {value:'TRACHEA',name: i18n.t('ENUM.TempMethod.TRACHEA')},
        {value:'TYMPANIC',name: i18n.t('ENUM.TempMethod.TYMPANIC')},
        {value:'URINARY BLADDER',name: i18n.t('ENUM.TempMethod.URINARYBLADDER')},
        {value:'URINE',name: i18n.t('ENUM.TempMethod.URINE')},
    ],

    Position:[
        {value:'STANDING',name: i18n.t('ENUM.Position.STANDING')},
        {value:'SITTING',name: i18n.t('ENUM.Position.SITTING')},
        {value:'SUPINE',name: i18n.t('ENUM.Position.SUPINE')},
    ],

    ClinicalMedicationRxStatus:[
        {value:'Active',name:i18n.t('Shared.Active')},
        {value:'Inactive',name:i18n.t('Shared.Inactive')},
        {value:'Past',name: i18n.t('ENUM.CliMediRxStatus.Past')},
        {value:'Void',name:i18n.t('MdNote.Void')},
    ],

    IncidentStatusOptions:[
        { value: 'all', name: i18n.t('Shared.All') },
        { value: 'Finalized', name: i18n.t('ENUM.NoteStatus.Finalized') },
        { value: 'CompletedDraft', name: i18n.t('Incident.IncCompleteDraft') },
        { value: 'Incomplete', name: i18n.t('ENUM.IncStatusOpt.Incomplete') },
        { value: 'Voided', name: i18n.t('ENUM.IncStatusOpt.Voided') },
    ],

    IncidentStatusOptionsModalReport:[
        { value: 'all', name: i18n.t('Shared.All') },
        { value: 'Finalized', name: i18n.t('ENUM.NoteStatus.Finalized') },
        { value: 'CompletedDraft', name: i18n.t('Incident.IncCompleteDraft') },
        { value: 'Incomplete', name: i18n.t('ENUM.IncStatusOpt.Incomplete') },
        { value: 'Voided', name: i18n.t('ENUM.IncStatusOpt.Voided') },
    ],

    ClinicalMedicationUsage:[
        {value:'Internal',name: i18n.t('ENUM.CliMedicUsage.Internal')},
        {value:'Home',name: i18n.t('ENUM.CliMedicUsage.Home')},
    ],

    SchedulerStatus:[
        {value:'All',name:i18n.t('Shared.All')},
        {value:'Confirmed',name: i18n.t('schedulerDailyRep.Confirmed')},
        {value:'NotConfirmed',name:i18n.t('schedulerDailyRep.NoConfirmed')},
        {value:'ShowedUp',name:i18n.t('schedulerDailyRep.ShowedUp')},
        {value:'NotShowedUp',name:i18n.t('schedulerDailyRep.NoShow')},
        {value:'Cancelled',name:i18n.t('ENUM.ScheDashShow.Cancelled')},
    ],
    Location:[
        {value:'APICAL',name: i18n.t('ENUM.Location.APICAL')},
        {value:'BRACHIAL',name: i18n.t('ENUM.Location.BRACHIAL')},
        {value:'CAROTID',name: i18n.t('ENUM.Location.CAROTID')},
        {value:'DORSALIS',name: i18n.t('ENUM.Location.DORSALIS')},
        {value:'FEMORAL',name: i18n.t('ENUM.Location.FEMORAL')},
        {value:'POPTEAL',name: i18n.t('ENUM.Location.POPTEAL')},
        {value:'POSTENOR TIBIALIS',name: i18n.t('ENUM.Location.POSTENORTIBIALIS')},
        {value:'RADIAL',name: i18n.t('ENUM.Location.RADIAL')},
        {value:'SUBCLAVIAN',name: i18n.t('ENUM.Location.SUBCLAVIAN')},
        {value:'ULNAR',name: i18n.t('ENUM.Location.ULNAR')}
    ],
    CuffLocation:[
        {value:'RIGHT ARM',name: i18n.t('ENUM.CuffLocation.RIGHTARM')},
        {value:'LEFT ARM',name: i18n.t('ENUM.CuffLocation.LEFTARM')},
        {value:'RIGH LEG',name: i18n.t('ENUM.CuffLocation.RIGHLEG')},
        {value:'LEFT LEG',name: i18n.t('ENUM.CuffLocation.LEFTLEG')}
    ],
    O2Delivery:[
        {value:'NASAL CANULA',name: i18n.t('ENUM.O2Delivery.NASALCANULA')},
        {value:'FACE MASK',name: i18n.t('ENUM.O2Delivery.FACEMASK')}
    ],

    // Insurance List
    StatusInsurance:[
        {value:'A',name: i18n.t('Shared.Active')},      
        {value:'I',name: i18n.t('Shared.Inactive')},
        {value:'',name: i18n.t('Shared.All')},
    ],

    IncidentItems:[
        {value:'incident-report',text: i18n.t('Incident.IncList')},
        {value:'incident-dashboard',text:i18n.t('ENUM.IncItems.incDashboard')},
        {value:'incident-report-general-info',text: i18n.t('ENUM.IncItems.incRepGeneralInf')},
        {value:'incident-categories',text:i18n.t('ClinicalNotes.CATEGORIES')},       
        {value:'incident-involved',text: i18n.t('ENUM.IncItems.incInvolved')},
        {value:'incident-participants',text: i18n.t('ENUM.IncItems.incParticipants')},
    ],

    IncidentWorkflowStatusOptions:[
        { value: 'all', name: i18n.t('Shared.All') },
        { value: 'pending', name: i18n.t('ENUM.NoteStatus.Pending') },
        { value: 'completed', name: i18n.t('ENUM.IncWorkflowStOpt.completed') },
        { value: 'started', name: i18n.t('ENUM.IncWorkflowStOpt.started') },
        { value: 'new', name: i18n.t('ENUM.StGrNotes.N') },
        { value: 'voided', name: i18n.t('ENUM.IncStatusOpt.Voided') },
    ],

    IncidentWorkflowNotesStatusOptions:[
        { value: 'all', name: i18n.t('Shared.All') },
        { value: 'pending', name: i18n.t('ENUM.NoteStatus.Pending') },
        { value: 'completed', name: i18n.t('ENUM.IncWorkflowStOpt.completed') },
        { value: 'started', name: i18n.t('ENUM.IncWorkflowStOpt.started') },
        { value: 'new', name: i18n.t('ENUM.StGrNotes.N') },
    ],

    AppointmentsStatus:[
         {value:'Confirmed',name: i18n.t('schedulerDailyRep.Confirmed')},
         {value:'NotConfirmed',name: i18n.t('schedulerDailyRep.NoConfirmed')},
         {value:'Cancelled',name: i18n.t('ENUM.ScheDashShow.Cancelled')},
         {value:'NotCancelled',name: i18n.t('ENUM.ScheDashShow.NotCancelled')},
         {value:'ShowedUp',name:i18n.t('schedulerDailyRep.ShowedUp')},
         {value:'NotShowedUp',name:i18n.t('ENUM.ScheDashShow.NotShowedUp')},     
     ],
    
     ChartReviewViewType: [
        { value: 'NoteType', text: i18n.t('Shared.NoteType') },
        { value: 'Date', text: i18n.t('Shared.Date')  }         
    ],
});

 export default EnumService