<template>
  <div>
      <b-row>
        <b-col md="12">
            <b-table
                :ref="refTable"
                selectable
                :small="small"
                :sticky-header="stickyHeader"
                class="medezTables cutescroll"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :current-page="cPage"
                :show-empty="showMessageIsEmpty"
                @row-selected="rowSelected"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"
                @row-dblclicked="ondblClicked"
                :tbody-tr-class="trClassFn"
                :empty-text="$t('MdTable.nRecShow')"
            >     
                <template v-if="editIconEvent || deleteIconEvent || viewIconEvent || otherIconEvent" #cell(actions)="row">              
                    <b-icon v-if="editIconEvent && isReadOnly" icon="eye-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="editIconEvent && !isReadOnly" icon="pencil-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Delete')" class="rounded-circle tableActionBTN-danger ml-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="viewIconEvent" icon="play-fill" @click="viewButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.View')" class="rounded-circle tableActionBTN" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="otherIconEvent" :icon="otherIcon" @click="otherButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>                     
                </template>

                 <!-- <template #cell(action)>               
                   <b-button-group>
                      <b-dropdown right text="Menu">
                      <b-dropdown-item>Item 1</b-dropdown-item>
                      <b-dropdown-item>Item 2</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item>Item 3</b-dropdown-item>
                      </b-dropdown>   
                    </b-button-group>
                   <select style="width:100%">
                      <option></option>
                      <option>Patient Info</option>
                      <option>Doctor</option>
                      <option>Insurance</option>
                    </select>
                 </template>-->


            </b-table>
        </b-col>    
    </b-row>
   
    <b-row>
      <b-col v-if="pageOptions" sm="7" md="6" class="my-1">
        <b-pagination 
          v-model="cPage"
          :total-rows="totalRows"
          :per-page="pPage"
          align="fill"
          size="sm"
          class="my-0"
          @change="fpageChanged"
        ></b-pagination>
      </b-col>
      <b-col v-if="pageOptions" align="right" class="mr-2">
          <b-form-group
            >{{ $t('Shared.Show') }}
              <b-form-select
                v-model="pPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                style="width:auto;"
                @change="pageFunction($event)"
              ></b-form-select>
              {{ $t('Shared.Of') }} {{totalRows}}
            </b-form-group>
        </b-col>
      </b-row>
  </div>
</template>

<script>



import ShowMessage from '@/components/messages/ShowMessage.js'

import { EventBus } from '@/event-bus.js';

  export default {
    mixins: [ShowMessage],
    props: {
     items:  Array,   
     fields: Array,
     totalRows: Number,
     totalRowsSubItems:Number, 
     editIconEvent: Function,
     deleteIconEvent: Function,
     showMessageIsEmpty: Boolean,
     ShowDetails:Boolean,
     onRowSelected: Function,
     ondblClick:Function,
     currentPage: Number,
     currentPageSubItems:Number,
     onFiltered: Function,
     perPage: Number,
     perPageSubItems:Number,
     pageChanged: Function,
     pageChangedSubItems:Function,
     pageOptions: Array,
     pageOptionsSubItems: Array,
     perPageFunction: Function,
     perPageFunctionSubItems: Function,
     otherIconEvent: Function,
     otherIcon: String,
     viewIconEvent: Function,
     refTable: String,
     subItems: Array,
     multiSelect: Boolean,
     avisar:Function,
     CheckClick:Function,
     small: Boolean,
     stickyHeader: Boolean,
     isReadOnly:Boolean,
     trClassFn: Function,
     useAddTime:[Boolean],
     IsChartReview:[Boolean],
     IsViewDate:[Boolean]    
   },
    data() {
      return {
          filter: null,
          filterOn: [],          
          pPage: this.perPage,
          pPageSubItems: this.perPageSubItems,
          cPage: this.currentPage,
          cPageSubItems: this.currentPageSubItems,         
          fFields: this.fields,
          fMultiSelect: 'single',
          CountNotesOpened:0,
          CountSubItems:0,
          DateTimeNow:null,
          TRSubItems:0,
          CPSubItems:0,
          CurrentPage : 1          
      }
    },
    
    methods:{

    editButton(row) {
        this.editIconEvent(row);
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },

    rowSelected(items) {     
        this.onRowSelected(items);
    },

    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },

    SubItemsPageChanged(e){     
    this.pageChangedSubItems(e);
    },

    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    pageFunctionSubItems(perpage) {
        this.perPageFunctionSubItems(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    viewButton(row) {   
      this.viewIconEvent(row);
    },


    AddTime(SubItems){        
      this.getheader(SubItems);     
      
    },

    ondblClicked(item){    
       this.ondblClick(item);
    },

 },
  created() {    
    if(this.subItems) {
      this.fFields.unshift({ key: 'show_details'});
    }    

    if(this.editIconEvent || this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent) {
      if(!this.isReadOnly){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
      }      
    }
    EventBus.$on("onSetReadOnly", function (readOnly) {       
      if(!readOnly){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
      } 
     }.bind(this));  
    this.fMultiSelect = this.multiSelect ? 'multi' : 'single';
  }, 
}

</script>


