<template>
    <div id="AppoinmentTypeLIST">
     <h4 class="page-title text-truncate">
           {{$t('menu.Appointment Types') }}                 
      </h4>
      <div class="row">
                <div class="col-sm-12 col-md-3"  v-if="permission.length > 0 && permission[0].roleCanAdd">                      
                      <MdButton
                        v-bind:Label="$t('Shared.Add')" 
                        v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                        v-bind:IconClass="'ti-plus pr-1'"
                        v-on:click="AddAppoinmentType"                   
                        v-bind:Name="'btn_addAppoinmentType'">
                      </MdButton>
                  </div>
                  <div v-bind:class="permission.length > 0 && permission[0].roleCanAdd ? 'col-sm-12 col-md-9 mb-4':'col-sm-12 col-md-12 mb-4'">
                      <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                          <div class="input-group">
                              <input 
                                type="text" 
                                class="form-control"
                                v-model="parameter.SearchCriteria"   
                                @keyup.enter="SearchAppoinmentType"                            
                              >
                              <div class="input-group-append">
                                  <div class="input-group-append">
                                  <MdButton 
                                    v-bind:Name="'btnsearchinput'"
                                    v-bind:VariantProp="'btn btn-sm search-attached'"
                                    v-bind:IconClass="'mdi mdi-magnify'"
                                    v-on:click="SearchAppoinmentType">
                                  </MdButton>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  
              </div> 
  
        <div class="row">
          <div class="col-12 mt-2">
          <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <div class="row">
              <div class="col-md-12">
                <MdTable 
                  v-bind:fields="fields"
                  v-bind:items="items"
                  v-bind:totalRows="totalRows"
                  v-bind:isReadOnly="false"
                  v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                  v-bind:currentPage="currentPage"
                  v-bind:perPage="perPage"
                  v-bind:pageOptions="pageOptions"
                  :editIconEvent="EditAppoinmentType"
                  v-bind:VisibleEditIcon="true"
                  :deleteIconEvent="RemoveAppoinmentType"
                  v-bind:VisibleDeleteIcon="true"
                  :onRowSelected="onRowSelected"
                  :onFiltered="onFiltered"
                  :pageChanged="pageChanged"
                  :perPageFunction="PerPageFunction">
                </MdTable>
              </div>
            </div>
          </div>
        </div>
          
    </div>
</template>
  
  <script>
  import DBFunctions from '@/common/DBFunctions.js'
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import AppoinmentTypeService from '@/services/appointmenttypes.service'
  
  export default {
      beforeRouteEnter(to, from, next) {
          next(async vm => {
              const iPI = await vm.CanView();
              if(iPI) next()
              else next('/accessdenied');
          }) 
    },
    name: 'AppoinmentTypeLIST',
    mixins: [ShowMessage, DBFunctions],
    data(){
      return{
        permission: [],
        Menu : this.$store.state.leftMenu,
        parameter:{
          SearchCriteria:'',
          PageNumber : 0,
          PageSize:0, 
          Type:''        
        },
         OptionsRightMenu:{
            visible:false,
            ItemsList:[],
            btnvisible:false,
            itemdisabled: false,
            PatientCode:'',
            Mode:this.edit_mode,
            favButtonAtion:false          
          },
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        ShowMessageIsEmpty:false,
        pageOptions: [5, 10, 15 ],
        items:[],
        typeModel:{text:this.$t('Shared.All'),value:'All'},
        TypeList:[
          {text:this.$t('Shared.All'),value:'All'},
          {text:this.$t('Shared.CPT'),value:'CPT'},
          {text:this.$t('Shared.HCPCS'),value:'HCPCS'},
          {text:this.$t('cpt.Revenue'),value:'Revenue'},
          {text:this.$t('cpt.UserDefined'),value:'UserDefined'},
        ],
        fields: [
          { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },      
          { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth'},  
          { key: 'therapyCode', sortable: true, label: this.$t('insuranceSetup.tCode'), class: 'text-rigth'},       
          { key: 'duration', sortable: true, label: this.$t('MdPainAssessmentNotesOutCome.Duration'), class: 'text-rigth' }, 
          { key: 'billableAppt', sortable: true, label: this.$t('appoTypeList.bAppt'), class: 'text-rigth',formatter: value => {return value ? 'Y' : 'N'} },       
          ],
      }
    },
  
    methods:{
  
      ChangeType(type){
        this.typeModel = type;
        this.getAppoinmentType();
      },
  
      AddAppoinmentType(){
         this.emitDataforFavorites(); 
         this.$router.push({ name: "appointmenttypes-add", params: { id: this.$route.params.id } }).catch(()=>{});
      },
  
      CanView(){
        return this.permission[0].roleCanView;
    },
  
  
    SearchAppoinmentType(){
        this.items = []
        this.currentPage = 1;
        this.parameter.PageNumber = 1;
        this.totalRows = this.items.length;
        this.getAppoinmentType();
      },
  
      getAppoinmentType(){
        this.$emit('load', true);
        this.parameter.PageNumber = this.currentPage;           
        this.parameter.PageSize = this.perPage;      
        this.parameter.Type = this.typeModel ? this.typeModel.value:'';     
        AppoinmentTypeService.getAllEmployees(this.parameter)
          .then((response) => {
              if(response.status == '200' && response.data){
                this.items = response.data;                        
                this.$emit('load', false);
                let headerString = response.headers["x-pagination"];
                this.totalRows = JSON.parse(headerString).TotalCount;
                }               
          })
          .catch((error) => {                    
              if (!error.response) {             
                this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.retrieveInfo'),"error");
            }                      
              else if(error.response.status === 400 ){
                this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
              }
              else if(error.response.status === 405 ){             
                    this.$router.push({name: "Main"});    
                      setTimeout(() => 
                          this.showPermissionMessage()
                          , 50);            
              } 
              else if(error.response.status === 500){
                this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
                this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
              } 
              this.$emit('load', false);            
          }); 
      },
  
      EditAppoinmentType(row){ 
         this.$router.push({ name: "appointmenttypes-details", params: { id: row.code } });    
      },
  
      RemoveAppoinmentType(row){
         const Id = row.code;
             this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
                      title:  this.$tc('Shared.DelRecord'),
                      okTitle: this.$t('Shared.TitleDelete'),
                      cancelTitle: this.$t('Shared.BtnCancel'),
                      footerClass: 'p-2',
                      titleClass: 'modal-title modal-type-warning',
            id: 'modal_delete_AppoinmentType'
                  })
            .then(value => {
              if(value){
                this.$emit('load', true);  
                AppoinmentTypeService.Delete(Id)
                  .then(() => {
                    const index = this.items.findIndex(item => item.code === Id) 
                    if (~index) {
                      this.items.splice(index, 1) 
                      this.getAppoinmentType(); 
                    }
                  })
                  .catch((error) => {
                    this.$emit('load', false);  
                      if(error.response.status === 400 ){
                       this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
                       }else{
                          this.showMessage(this.$t('Shared.AppointmentType'),error,"error");
                       }  
                    /*if (!error.response) {
                      // network error
                      this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.NotAbleToDelete'),"error");
                    } else {
                      this.showMessage(this.$t('Shared.AppointmentType'),error,"error");
                    }*/
                  });
              }
            })
      },
  
      onRowSelected(){
  
      },
      onFiltered(){
  
      },
      pageChanged(page){
        this.currentPage = page;    
        this.getAppoinmentType();  
      },
      PerPageFunction(perpage){
        this.perPage = perpage;  
        this.currentPage = 1;
        this.getAppoinmentType();  
      }
    },
    
     created(){
       this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
    },
  
    mounted(){
          this.OptionsRightMenu.ItemsList = [];
         let layoutStatus = this.$store.state.layoutStatus;
         switch(layoutStatus) {
          case 'L0R1':
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
          break;
          case 'L1R0':
          case 'L1R1':
              this.OptionsRightMenu.btnvisible = false;
              this.$emit('getVisibleMenu', this.OptionsRightMenu);
          break;
          default:
              this.OptionsRightMenu.favButtonAtion = false;
              this.$emit('getVisibleMenu', this.OptionsRightMenu);
        }
      this.getAppoinmentType();
    },
  
     destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
     
  };
  </script>
  
  