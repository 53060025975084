<template>
  <div>
   <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalAddSignature" @close="this.emitEventCancel" @ok="handleOk" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
  
        <div class="card bg-light">
        <div class="card-body">  
            <form ref="form" @submit.stop.prevent="handleSubmit">                 
                <b-row>        
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$t('modalProviderSelector.Provider')"
                            v-bind:Options="ProviderList"
                            v-bind:FieldDescription="'therapistName'"
                            v-bind:FieldCode="'therapistCode'"
                            v-bind:Model="ProviderModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownProvider"
                        />                           
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'checkboxAdd-Sign'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"              
                        v-model="isSigned"
                        v-on:change="onSignatureTP"
                        v-bind:Tag="'SignAddTreatmentPlanSignature'"          
                        />
                    </b-col>                         
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$t('MdNote.Cosignedby')"
                            v-bind:Options="CoProviderList"
                            v-bind:FieldDescription="'coTherapistName'"
                            v-bind:FieldCode="'coTherapistCode'"
                            v-bind:Model="CoProviderModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownCoProvider"
                        />
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'Co-checkboxAdd-Sign'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"              
                        v-model="isCosigned"
                        v-on:change="onCoSignatureTP"
                        v-bind:Tag="'CoSignAddTreatmentPlanSignature'"          
                        />
                    </b-col> 
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$tc('Incident.IncPartClient')"
                            v-bind:Options="ClientList"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ClientModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownClient"
                        />
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'checkbox-Client'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"                       
                        v-bind:UncheckedValue="false"              
                        v-bind:isReadOnly="disabledCheckClient"                      
                        v-model="ClientCheckModel"                 
                        v-on:change="onSignatureClient($event)"
                        v-bind:Tag="'TreatmentPlanSignatureClient'"            
                        />  
                    </b-col> 
                </b-row>
                <!--
                <b-row>
                    <b-col md="3" lg="3" sm="12">
                        <MdEdit 
                            v-bind:Label="'Provider Signed Date'"
                            v-bind:TypeProp="'text'" 
                            v-bind:Name="'signedDate'"                
                            v-model="signedDate"                    
                            v-bind:Tag="'input_treatmentplan_signature_id'"
                            v-bind:Id="'input_treatmentplan_signature_id'"
                    >                                     
                    </MdEdit>
                    </b-col>
                    <b-col md="3" lg="3" sm="12">
                        <MdEdit 
                            v-bind:Label="'CoProvider Signed Date'"
                            v-bind:TypeProp="'text'" 
                            v-bind:Name="'CosignedDate'"                
                            v-model="coSignedDate"                     
                            v-bind:Tag="'input_treatmentplan_coSign_id'"
                            v-bind:Id="'input_treatmentplan_coSign_id'"
                    >                                     
                    </MdEdit>
                    </b-col>  
                    <b-col lg="2" md="2" sm="12">         
                        <MdEdit 
                            v-bind:Label="'Patient Signed Date'"
                            v-bind:TypeProp="'text'" 
                            v-bind:Name="'signedDate'"                
                            v-model="patientSignedDate"                    
                            v-bind:Tag="'input_treatmentplan_patientsigneddate_id'"
                            v-bind:Id="'input_treatmentplan_patientsigneddate_id'"
                    />              
                    </b-col> 
                    <b-col lg="3" md="3" sm="12">         
                        <MdEdit 
                            v-bind:Label="'Contact Signed Date'"
                            v-bind:TypeProp="'text'" 
                            v-bind:Name="'signedDate'"                
                            v-model="ContactDate"                   
                            v-bind:Tag="'input_treatmentplan_guardiansigneddate_id'"
                            v-bind:Id="'input_treatmentplan_guardiansigneddate_id'"
                    />              
                    </b-col> 
                </b-row>-->
            </form>
        </div>
        </div>
   
     
   </b-modal>  
   <MdPasswordDlg v-if="showSignPassword" 
   v-bind:Type="TypePassWord"
   v-bind:UserId="UserIdSelected"
   @getModalPassword="onModalPasswordResult($event)"
/>
<wyModalSignPatientGuardian
    @ModalTPSignPatientGuardian="OnReturnSign($event)" 
    @ModalTPSignPatientGuardianWizardOK="OnReturnOKWizard($event)"
    v-if="showModalSignPatientGuardian" 
    v-bind:Option="option"
    v-bind:ModalId="'modalIdSignPatientGuardian'" 
    v-bind:ItemChecked="itemChecked" 
    v-bind:TitleWindow="labelSign"
    v-bind:ShowGuardianDrop="ShowGuardianDrop"
/>
  </div>
</template>

<script>
//import Utils from '@/common/utils'
import ShowMessage from '@/components/messages/ShowMessage.js'
import TreatmentPlan from '@/services/treatmentplan.service'
import EmployeeService from '@/services/employee.service'
import LocalStorage from '@/services/storage.service'


export default {
 name: 'ModalAddSignature',
 mixins: [ShowMessage],  
 props: {
   ModalId: String,
   TitleWindow: String,
   ProblemRecId:String,
   groupId:Number,
   headerId:String,
   probCode:String,   
 },
 
 data(){
   return {
    option:"",
    labelSign:"",
    EmployeeCode:"",
    ShowGuardianDrop:false,
    ProviderList:[],
    ClientList:[
            {code:"PatientSigned",name:this.$t('modalAddSignature.pSigned')},
            {code:"PatientRefused",name:this.$t('modalAddSignature.pRefused')},
            {code:"GuardianSigned",name:this.$t('modalAddSignature.gSigned')},
        ],
        ClientModel:{code:"",name:""},
    isSigned:false,
    TypePassWord:"",
    itemChecked:{},  
    DataModalGuardianSig:{}, 
    UserIdSelected:"",   
    showSignPassword: false,  
    isCosigned:false,
    ClientCheckModel:false, 
    showModalSignPatientGuardian:false,   
    refuseSigned:false,
    isPatientSigned:false,
    disabledCheckClient:false,
    CoProviderList:[],
    CoProviderModel:{coTherapistCode:"",coTherapistName:""},
    GuardianList:[],
    ProviderModel:{therapistCode:"",therapistName:""},
    GuardianModel:"",
    guardianSigned:false,
    signedDate:null,
    coSignedDate:null,
    patientSignedDate:null,
    ContactDate:null,
    
    objectivedescriptionBlank:"",
    ObjectiveList:[],
    ObjectiveModel:[],
    Objectivedata:[],
   }
 },

 methods:{
   emitEventOK(/*bvModalEvent*/){  
    this.AddSignature();       
   },

   handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

    checkFormValidity() {  
         return this.isSigned || this.isCosigned || this.ClientCheckModel;
      },

    handleSubmit() {       
        !this.checkFormValidity() ? this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Add')}),this.$t('modalAddSignature.atLeastMsg'),"error"):this.emitEventOK();
      },

   ChangeDropDownClient(value){ 
            this.ClientModel = value ? value : {code:"",name:""};      
            this.showModalSignPatientGuardian = false;       
            if(value){
                if(value.code === "PatientRefused"){                  
                    this.disabledCheckClient = true;
                    this.option = this.$tc('Shared.Patient');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                    this.ClientCheckModel = false;
                }else{
                    this.disabledCheckClient = false;
                } 

                if(value.code === "GuardianSigned"){
                    this.option = this.$t('modalAddSignature.guardian');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$t('modalAddSignature.guardian')});
                    this.ShowGuardianDrop = true;
                }

                if(value.code === "PatientSigned"){
                    this.option = this.$tc('Shared.Patient');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                    this.ShowGuardianDrop = false;
                }
                
                if(value.code === "GuardianSigned" && this.ClientCheckModel){
                    this.showModalSignPatientGuardian = true;
                }

                else if(value.code === "PatientSigned" && this.ClientCheckModel){
                    this.showModalSignPatientGuardian = true;
                }
                
                else{
                    this.showModalSignPatientGuardian = false;
                }



            }
        },

   ChangeDropDownProvider(value){
    this.ProviderModel = value ? value : {therapistCode:"",therapistName:""};
   },

   ChangeDropDownCoProvider(value){
    this.CoProviderModel = value ? value : {coTherapistCode:"",coTherapistName:""};
   },

   onSignatureClient(value){          
            this.showModalSignPatientGuardian = value && (this.ClientModel.code === "GuardianSigned" || this.ClientModel.code === "PatientSigned") ? true : false;
        },


   OnReturnSign(data){       
        if(data.option === this.$tc('Shared.Patient')){
            data.item.isPatientSigned = false; 
            data.item.CheckGuardianReadOnly = false;
            //CheckPatientReadOnly
            data.item.CheckRefuseReadOnly = false;
            data.item.SelectGuardianReadOnly = false;
        }
        if(data.option === this.$t('modalAddSignature.guardian')){
            data.item.guardianSigned = false;            
            data.item.CheckPatientReadOnly = false;
            data.item.CheckRefuseReadOnly = false;
            data.item.SelectGuardianReadOnly = false;
        }
       
        this.showModalSignPatientGuardian = false;
    },

    /*checkFormValidity() {
       const valid = this.ProviderModel.therapistCode === '' && this.ProviderModel.therapistCode === null && this.isSigned;
       return valid
      },

      handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

      handleSubmit() {      
        if (!this.checkFormValidity()) {
          this.emitEventOK();
          return
        }       
      },*/

    AddSignature(){         
        let data = {
            headerId: this.headerId,
            notesId: this.$route.params.notesid,
            therapistCode: this.ProviderModel.therapistCode,
            coTherapistCode: this.CoProviderModel.coTherapistCode,
            patientCode: this.$route.params.id,
            isSigned: this.isSigned,
            isCosigned: this.isCosigned,
            isPatientSigned: this.ClientCheckModel === true && this.ClientModel.code === "PatientSigned" ? true : false,
            guardianSigned: this.ClientCheckModel === true && this.ClientModel.code === "GuardianSigned" ? true : false,
            refuseSigned: this.ClientModel.code === "PatientRefused" ? true : false,          
            contactRecNo : this.DataModalGuardianSig.guardianCode !== "" && this.DataModalGuardianSig.guardianCode !== null  ? parseInt(this.DataModalGuardianSig.guardianCode) :null,
            image:this.DataModalGuardianSig.base64,
        };

        //if( data.therapistCode !== "" && data.therapistCode !== null){
           // if(data.isSigned){
                TreatmentPlan.AddSignature(data)
                .then((response) => { 
                if(response.data){
                    this.$emit('RefreshGetTP');
                    setTimeout(() => 
                            this.$bvToast.toast(this.$t('treatmentplan.SignatureSaved'), {
                            title: this.$t('Msg.Saving'),
                            autoHideDelay: 5000,
                            variant: "success"
                            })
                            , 5); 
                }             
                })
                .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Add')}),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Add')}),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Add')}),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Add')}),error.response.data.errorMessage,"error");
                     }    
            });       
            /*}else{
                this.showMessage("Add Signature", "The provider must sign, please", "error");
                return;
            }*/
        /*}else{
            this.showMessage("Add Signature", "You must select the provider, please", "error");
            return;
        }*/


      
   
        },


    OnReturnOKWizard(data){
        console.log(data);
        this.DataModalGuardianSig = data;
    },

   onCoSignatureTP(value){
        this.TypePassWord = 'Cosign'; 
        //this.UserIdSelected = "MEDEZ";
        this.UserIdSelected = this.CoProviderModel.coTherapistCode;
        this.showSignPassword = value;
    },

    onSignatureTP(value){  
        this.TypePassWord = 'Sign';       
       // this.UserIdSelected = "MEDEZ";
       this.UserIdSelected = this.ProviderModel.therapistCode;
        this.showSignPassword = value;
    },

   ChangeDropDownGuardian(){

   },

   onSignatureGuardian(){

   },

   EmployeeCodeIsinList(EmpCode){
            let result = false;
            for(var i = 0; i < this.ProviderList.length; i++){
                if(this.ProviderList[i].therapistCode === EmpCode){
                    result = true;
                    break;
                }                    
            }
            return result;
        },

   getEmployeeProvider(){
      const UserId = LocalStorage.getUserId();
      EmployeeService.getEmployees(UserId)
            .then((response) => { 
              if(response.data){             
                let result = response.data;                    
                for(var i = 0; i < result.length; i++){
                    this.ProviderList.push({therapistCode:result[i].code,therapistName:result[i].name});
                }
                 
                if(this.EmployeeCodeIsinList(this.EmployeeCode)){
                    this.ProviderModel.therapistCode = this.EmployeeCode;
                    this.ProviderModel.therapistName = LocalStorage.getName();
                }  
                 
              }             
            })
            .catch((error) => {
                this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    }
            });
    },

    getEmployeeCoProvider(){      
      EmployeeService.getEmployeeCosigner()
            .then((response) => { 
              if(response.data){                
                let result = response.data;                    
                for(var i = 0; i < result.length; i++){
                    this.CoProviderList.push({coTherapistCode:result[i].code,coTherapistName:result[i].name});
                }
                 
              }             
            })
            .catch((error) => {
                this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    }
            });
    },

    onModalPasswordResult(result){ 
          if(result.type === 'Sign'){
            if(result.Event === "Ok"){
                if(result.status !== true) {
                    this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");   
                    this.isSigned = false;                  
                }                   
            }else{
                this.isSigned = false;
            }
                  
        }
        if(result.type === 'Cosign'){
            if(result.Event === "Ok"){
                if(result.status !== true) {
                    this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");  
                    this.isCosigned = false;          
                }               
            }
            else{
                this.isCosigned = false;       
            }
        } 

        this.showSignPassword = false;
    },

   onSignaturePatient(){

   },

   onSignatureRefused(){

   },
 
   emitEventCancel(){
    this.$emit('ModalTPAddSignature');
  },    
 },

 created(){
    this.EmployeeCode = this.$store.state.employeeCode; 
    this.getEmployeeProvider();
    this.getEmployeeCoProvider();
   },

 mounted(){
 
 },
};
</script>

