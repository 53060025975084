<template>
  <div id="ContainerPatientVitalSign">
    <div class="row">
      <div class="col-12">
        <h4 class="page-title text-truncate">
					{{ $t('VitalSignList.PatientVitalSign') }} - 
                <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
                <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
                <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
                <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                <span v-if="this.PatientTitle.dateFrom"> - {{this.PatientTitle.dateFrom}}  </span>
                <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
				</h4>
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('VitalSignList.AddVitalsSigns')"                 
              v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"
              v-on:click="AddVitalSign"
              v-bind:isReadOnly=this.isReadOnly
              v-bind:Name="'btn_add_vitals'">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="parameter.SearchCriteria"   
                  @keyup.enter="SearchVitalSign">

                <div class="input-group-append">
                  <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'"
                    v-on:click="SearchVitalSign">
                  </MdButton>
                </div>
              </div>
            </div>
          </div>
        </div>              
       
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div class="row">
          <div class="col-md-12">
            <MdTable 
              v-bind:fields="fields"
              v-bind:items="items"
              v-bind:totalRows="totalRows"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:pageOptions="pageOptions"
              :editIconEvent="EditVS"
              v-bind:VisibleEditIcon="true"
              v-bind:FieldsDate="FieldsDateArr"
              :deleteIconEvent="RemoveVS"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelected"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction"
              :otherIcon="'printer-fill'"
              :otherIconEvent="showReport">
            </MdTable>
          </div>
        </div>
      </div>
    </div>
    <div class="space-actions"></div>
    <div class="page-actions">
      <MdButton
        v-bind:isReadOnly="items.length == 0"
        v-bind:Label="$t('Shared.Report')"
        v-bind:Name="'btnpatientlist'"
        v-bind:ClassProp="'secondary mx-2 btn-login'"
        v-on:click="showReport()"/>
    </div>

    <VitalSignsRep v-if="isReportVisible" 
      :closeWindow="closeParamRepWindow"
      :patientCode="this.$route.params.id"
      :recNo="recNoToReport"
      :patientName="this.RegistrationInfo.patientName"
      />
  </div>
</template>

<script>
import VitalSignService from '@/services/vitalsigns.service'
import EnumService from '@/services/enum.service' 
//import PatientService from '@/services/patient.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from "@/common/utils";
import Companies from '@/services/companies.service'

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  mixins: [ShowMessage, DBFunctions],
  name: 'VitalsSigns',
  data() {
    return {
      patient:{},
      FieldsDateArr:['transDate'],
      ShowMessageIsEmpty:false,
      Loading:true,
      RegistrationInfo:{},
      PatientInfo:{},
      permission:[],
      Menu : this.$store.state.leftMenu,
      OptionsRightMenu:{
        visible:true,
        ItemsList:[],
        btnvisible:true,
        itemdisabled: false,
        PatientCode:''         
      },  
      vsId:'',   
      Mode:'', 
      unitSys: 'U',         
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'transDate', sortable: true, label: this.$t('Shared.Date'), class: 'text-rigth' },    
        { key: 'temp', sortable: true, label: this.$t('VitalSignList.Temperature'), class: 'text-rigth' },       
        { key: 'pulse', sortable: true, label: this.$t('VitalSignList.Pulse'), class: 'text-rigth'},       
        { key: 'systolic', sortable: true, label: this.$t('VitalSign.Systolic'), class: 'text-rigth' },      
        { key: 'diastolic', sortable: true, label: this.$t('VitalSign.Diastolic'), class: 'text-rigth' }, 
        { key: 'resp', sortable: true, label: this.$t('VitalSignList.Resp'), class: 'text-rigth' }, 
        { key: 'o2sat', sortable: true, label: this.$t('VitalSignList.O2Saturation'), class: 'text-rigth' }, 
        { key: 'o2inhaled', sortable: true, label: this.$t('VitalSignList.O2Inhaled'), class: 'text-rigth' },
        { key: 'recNo', sortable: true, label: 'RecNo', class: 'hidden'}, 
        { key: 'weight1', sortable: true, label: this.$t('VitalSignList.Weight'), class: 'text-rigth' },
        { key: 'weight2', sortable: true, label: this.$t('VitalSignList.Weight'), class: 'text-rigth' },
        { key: 'height1', sortable: true, label: this.$t('VitalSignList.Height'), class: 'text-rigth' },
        { key: 'height2', sortable: true, label: this.$t('VitalSignList.Height'), class: 'text-rigth' },
        { key: 'sugarLevel', sortable: true, label: this.$t('VitalSignList.SugarLevel'), class: 'text-rigth' },       
      ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      isReportVisible: false,
      recNoToReport: null,
      currentAppointmentDB:{
        guid:'',
        activitytypecode:'',
        employee:{
        firstname:"",
            lastname:""
        }
      },
      PatientTitle:{      
        programName:'',
        fullName:'',
        admitDate:'',
        dischargeDate:'',
        dob:'',
        activitytypename:'',
        startdatetime:'',
        ageFormatted:''
     }
    }
  },

  computed: {
    sortOptions() {       
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        });
    }    
  },
    
  methods: {
    refreshFieldsLabel() {
        this.fields[2]['label'] = this.$t('VitalSignList.Temperature') + (this.unitSys === 'U' ? ' (°F)' :  ' (°C)');
        this.fields[10]['label'] = this.$t('VitalSignList.Weight') + (this.unitSys === 'U' ? ' (lbs)' :  ' (Kgs)');
        this.fields[11]['label'] = this.$t('VitalSignList.Weight') + (this.unitSys === 'U' ? ' (oz)' :  ' (g)');
        this.fields[12]['label'] = this.$t('VitalSignList.Height') + (this.unitSys === 'U' ? ' (ft)' :  ' (cm)');
        this.fields[13]['label'] = this.$t('VitalSignList.Height') + (this.unitSys === 'U' ? ' (in)' :  ' (mm)');
    },

    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = Utils.formatterDateToString(infoList[0].dob);
          }   
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
      }
    },

    SearchVitalSign(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getVitalSign();
    },

    CanView(){
      return this.permission[0].roleCanView;
  },

    getVitalSign() { 
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.$route.params.id;
      
      VitalSignService.getAll(this.parameter)
        .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
                this.items = response.data;  
                let headerString = response.headers["x-pagination"];
                this.Header = JSON.parse(headerString);
                this.totalRows = this.Header.TotalCount;
                this.items.forEach(function(item) {
                    if (item.transDate)	
                        item.transDate = Utils.formatterDateTimeToString(item.transDate);
                });
            }
            if(this.items.length === 0){
                this.ShowMessageIsEmpty = true;
            }

            this.Loading = false;
            this.$emit('load', this.Loading);
        })
        .catch(error => {
          this.Loading = false;
          this.$emit('load', this.Loading);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('VitalSignList.PatientVitalsSigns'),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$t('VitalSignList.PatientVitalsSigns'),error,"error");
          }
        });
    },

    EditVS(row){
       this.vsId = row.recNo;
       this.Mode = "Edit";
       if (this.$route.name.includes('appointments-vital-sign')) {
          this.$router.push({
            name: "appointments-vs-details",
            params: { 
                id: this.$route.params.id, 
                actId: this.$route.params.actId,
                vsid: this.vsId 
            },
          });
       } else {
        this.$router.push({ name: "vs-details", params: { vsid: this.vsId } });
       }
       
    },

    RemoveVS(row){
      const recNo = row.recNo
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$t('VitalSignList.DeleteVitalSign'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning',
        id:'modal_delete_vitals'
      })
      .then(value => {
        if(value) {
          VitalSignService.deleteVital(recNo)
            .then(() => {
                const index = this.items.findIndex(item => item.recNo === recNo) // find the post index 
                if (~index) {// if the post exists in array 
                  this.items.splice(index, 1) //delete the post and refresh view
                  this.getVitalSign(); //refresh listing
                }
            })
            .catch((error) => {
              if (!error.response) {
                this.showMessage(this.$t('VitalSignList.PatientVitalsSigns'),this.$t('Msg.NotAbleToDelete'),"error");
              } else {
                this.showMessage(this.$t('VitalSignList.PatientVitalsSigns'),error,"error");
              }
            });
        }
      })
    },

    AddVitalSign(){    
       this.Mode = "Add";
       if (this.$route.name.includes('appointments-vital-sign')) {
          this.$router.push({
            name: "appointments-vs-add",
            params: { 
                id: this.$route.params.id, 
                actId: this.$route.params.actId 
            },
          });
       } else {
            this.$router.push({ name: "vs-add", params: { id: this.$route.params.id } });
       }
       
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getVitalSign();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.getVitalSign();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    showReport(item) {
      if(this.items.length > 0){
        if (item && item.recNo) {
          this.recNoToReport = item.recNo
        }
        this.isReportVisible = true
      } else {
        setTimeout(() => 
          this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
            title:  this.$t('VitalSignList.PatientVitalsSigns'),
            autoHideDelay: 3000,
            variant: "success"
          }),
        50);
      }
    },

    closeParamRepWindow() {
      this.isReportVisible = false
      this.recNoToReport = null
    },

    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    },

    PopulatePatientTitle(){
      if(this.$route.name.includes('Clinical-vital-sign')){ 
            this.PatientTitle.programName = this.RegistrationInfo.programName;
            this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
            this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
            this.PatientTitle.fullName = this.RegistrationInfo.patientName;
            this.PatientTitle.dob = this.RegistrationInfo.dob;
            this.PatientTitle.age = this.RegistrationInfo.age;
            this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
    },
  },

 async mounted() {
    this.$emit('load', this.Loading);    
    this.getVitalSign();
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-vital-sign') ? EnumService.AppointmentItems : EnumService.ClinicalItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
    this.isReadOnly = this.$store.state.isVitalSignEHRReadOnly; 
    this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
    if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);	

    if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  Utils.formatterDateToString(this.RegistrationInfo.dob);

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
      
      if (this.$route.name.includes('appointments-vital-sign'))
        await this.getCurrentAppointment()
      
      this.PopulatePatientTitle();
      this.GetPatientByCode();
      Companies.getAll().then((response) => {
        var data=response.data;
        if(data && data.length > 0){
            this.unitSys = data[0].unitSystem ?? 'U';
            this.refreshFieldsLabel();
        }
      });
  },

  created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },
  
    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
};

</script>