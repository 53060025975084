<template>	
    <div id="ModalErrorClaim">
    <b-modal id="ModalErrorClaimBatch" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
     <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdTable 
                sticky-header
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:refTable="'refTableModalClaimBatch'"
                :viewIconEvent="this.getAction"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction"
                :trClassFn="trClassFn">
            </MdTable>
          </b-col>
      </b-row>
       <!--<b-row>
          <b-col lg="12" md="12" sm="12">
            <MdRadioGroup
               v-bind:Label="'Anwers'"
               v-model="anwers"
               v-bind:Options="options"
               v-bind:Name="'radio'"
               v-bind:Stacked="true"
               v-on:change="setValueResultList($event)"
            />
          </b-col>
      </b-row>-->
        <MdButton v-if="VisibleTest === true"
              v-bind:Label="this.$t('modalClaimBatchError.Try')"
              v-bind:ClassProp="'btn btn-primary btn-login mb-2 mx-2 pull-right'"
              v-bind:Name="'btn_saveTest'"
              v-on:click="SetTest"
             />  
    </b-modal>   
    </div>   
</template>

<script>

import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'modalClaimBatchError',
    props: {     
     TitleWindow:String
   },
    mixins: [DBFunctions],
    data() {
		return {
      VisibleTest:true,
       selected: 0,
       options: ['Yes','No'],
        resultList: [],
        anwers: '',		
      fields: [       
        { key: 'Code',  sortable: true,label: this.$t('Shared.Code'), class: 'hidden text-rigth' },
        { key: 'Description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },
      ],
      items: [],
      ShowMessageIsEmpty:true,
      currentPage: 1,
		}
	},

	async mounted() {    
   this.getCallApi();	
	},

  created(){ 
  },
	methods:{

    getCallApi(){
       this.items = [{'Code':1,'Description':'Error Test Error Test Error Test Error Test'},{'Code':2,'Description':'Error Test Error Test Error Test Error Test'}];
       this.fillResultList(this.items);       
    },

    handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

      onRowSelected(items) {      
      if(items[0]){
        this.selected = items[0].Code;       
      }
    },

    onFiltered() {
    },

    pageChanged(){  
    },

    PerPageFunction(){    
    },

    fillResultList(items){
      this.resultList = ( items != undefined) ? items.map(function(item){ 
            let optionItem = {};
            optionItem['Code'] = item.Code;
            optionItem['sectiondesc'] = item.Description;
            optionItem['value'] = -1;
            return optionItem;
      }): null;
    },

     SetTest(){
      let itemSel = this.selected;
      let answered = 0 ; 
      this.resultList.map(function(item){ 
        if(item.Code == itemSel){
            item.value = Number(1);
        }
        if(item.value != -1) {
          answered = answered + 1;         
        }
       // return item;
      })
      this.answered = answered;
   
    },

    getAction(){
     
    },

    isAnswered(Code){
      let answeredItem = false;
      if(this.resultList.length > 0){
        this.resultList.forEach(element => {
        if(element.Code == Code && element.value > -1) {
          answeredItem = true
        }
      });
      }      
      return answeredItem;
    },

     trClassFn(item, rowType){
      if (rowType === 'row' && this.isAnswered(item.Code)) {
        return "answered"
      }
    },

    emitEventCancel(){
        setTimeout(() => this.ResetObjOccurrence(),200); 
      }, 
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          this.emitEventOK();
          return
        }
        this.emitEventOK();
      },
     emitEventOK(){        
       if(this.Obj.Occurrencecode === ''){
         this.showMessage(this.$t('Admission.AddOccurrence'),this.$t('ModalAdmissionOccurrence.FieldRequired'),"error");
       }else if(this.Obj.DateFrom === ''){
         this.showMessage(this.$t('Admission.AddOccurrence'),this.$t('ModalAdmissionOccurrence.DateFromFieldRequired'),"error");
       }else if(this.Obj.DateTo === ''){
           this.showMessage(this.$t('Admission.AddOccurrence'),this.$t('ModalAdmissionOccurrence.DateToFieldRequired'),"error");
       }else{
          var send = {};
       send.Occurrencecode = this.Obj.Occurrencecode;
       send.Occurrencedescription = this.Obj.Occurrencedescription;
       send.RevCode = this.Obj.RevCode;
       send.RevCodedescription = this.Obj.RevCodedescription;
       send.DateFrom = this.Obj.DateFrom;
       send.DateTo = this.Obj.DateTo;
       send.DateOcurrence = this.Obj.DateOcurrence;
       send.ModeEdit = this.ModeEdit;
      // send.recId = !this.ModeEdit ? this.getUniqueID() : this.DataEdit.recId;
       send.recId = this.DataEdit.recId;
       this.$emit('getCodeConditionOK', send);
         this.$nextTick(() => {
          this.$bvModal.hide(this.ModalId);
        })
      
      setTimeout(() => this.ResetObjOccurrence(),200); 
       } 
      },
	}
}


</script>


