<template>
<div>
  <b-modal :id="this.ModalId" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true" no-stacking scrollable centered :title="TitleWindow" :visible="true" @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')">
   <div id="ContainerPainAssessment">
    <b-row class="mt-2">
        <b-col md="12">
            <div class="text-center text-md-right mt-3 container-button">
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:VariantProp="'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
            />
            </div>
        </b-col>
    </b-row>
     <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Shared.WrittenBy')"
                data-field="therapist"
                v-bind:Options="therapistList"
                v-bind:FieldDescription="'fullName'"
                v-bind:FieldCode="'code'"
                v-bind:Model="painAssessment.TherapistCode"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownTherapistCode"
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdCheckBox
                v-bind:Label="$t('MdNote.Sign')"
                v-bind:Name="'checkbox-Sign'"
                v-bind:ClassProp="'AlignCheck'"
                v-bind:CheckedValue="true"
                v-bind:UncheckedValue="false"
                v-model="painAssessment.IsSigned"
                v-on:change="onSignPassword"
            />
        </b-col>
     </b-row>
     <MdTitle v-bind:Text="$t('MdPainAssessmentNotesOutCome.ASSESSMENT')" v-bind:isSubtitle="true" v-bind:Name="'T_Assessment'"/>
     <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Shared.Date')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'date'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="painAssessment.AssessmentDate">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Incident.IncTime')"
                v-bind:TypeProp="'time'" 
                v-bind:Name="'time'"
                v-model="painAssessment.AssessmentTime">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <div class="textlabel" :data-label="this.$t('MdPainAssessmentNotesOutCome.Intensity')">
              <b-form-spinbutton id="Intensity" v-model="painAssessment.PainIntensity" min="1" max="100"></b-form-spinbutton>
            </div>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('VitalSign.Location')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'Location'"
                v-model="painAssessment.Location"
                v-bind:Id="'input_location_id'">                                     
            </MdEdit>
        </b-col>
     </b-row>
     <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('MdPainAssessmentNotesOutCome.Character')"
                data-field="Character"
                v-bind:Options="charactertList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'value'"
                v-bind:Model="painAssessment.PainCharacter"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownCharacter"
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Shared.Frequency')"
                data-field="Frequency"
                v-bind:Options="frequencyList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'value'"
                v-bind:Model="painAssessment.Frequency"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownFrequency"
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdPainAssessmentNotesOutCome.Duration')"
                v-bind:TypeProp="'number'" 
                v-bind:Name="'Duration'"
                v-model="painAssessment.Duration">                                     
            </MdEdit>
        </b-col>
     </b-row>
     <MdTitle v-bind:Text="$t('MdPainAssessmentNotesOutCome.Interv')" v-bind:isSubtitle="true" v-bind:Name="'T_Interventions'"/>
     <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdCheckListBox
                v-model="painAssessment.Intervention"
                v-bind:Options="interventionList"
                v-bind:style="'marginLeft: 30px'" 
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdPainAssessmentNotesOutCome.Other')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'Other'"
                v-model="painAssessment.OtherIntervention">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Incident.IncTime')"
                v-bind:TypeProp="'number'" 
                v-bind:Name="'Time'"
                v-model="painAssessment.InterventionTime">                                     
            </MdEdit>
        </b-col>
     </b-row>
     <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_Notes'"/>
     <b-row>
        <b-col md="12" lg="12" sm="12">
            <MdMemo
                v-bind:Name="'textarea_notes'"
                v-bind:Rows="'3'"
                v-bind:MaxRows="'6'"
                v-bind:MaxLength="'500'"
                v-model="painAssessment.Notes"
            />
        </b-col>
     </b-row>
   </div>

  </b-modal>
  <MdPasswordDlg v-if="showSignPassword"
    v-bind:ShowPopup="showSignPassword"
    @getModalPassword="onModalPasswordResult($event)"
  />
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils.js'
import EnumService from "@/services/enum.service";
import TherapistService from '@/services/therapist.service'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'PainAssessmentNotes', 
  mixins: [ShowMessage, DBFunctions],
  props: {
     ModalId: String,
     TitleWindow: String
   },
  data() {
    return {
      ShowMessageIsEmpty:false,
      Loading:true,
      parameter:{
        PageNumber : 0,
        PageSize:0 ,       
      },
      // fields: [
      //   { key: 'allergy', sortable: true, label: 'Allergy', class: 'text-rigth' },                  
      //   ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      therapistList: [],
      charactertList: EnumService.Characterts,
      frequencyList: EnumService.Frequencys,
      interventionList: EnumService.Interventions,
      painAssessment: [],
      showSignPassword: false,
      pendingChanges: false,
      clickOK: false
    }
  },

  methods: {
    emitEventOK(){      
        this.clickOK = true
      },

      emitEventHide(handled){
        if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
              this.$emit('getCodeConditionOK', this.getTextResult());
              this.$emit('input',this.getTextResult());
            } else {
              this.$emit('getCancel');
              this.ResetProps();
            }
        }
      },

      ResetProps(){
        this.parameter.SearchCriteria = '';
        // this.showListing(true);
      },

      getTextResult() {
        let textResult = '';
        let allergy = '';
        let active = '';
        let severity = '';
        this.items.map(item => (
            allergy = item.allergy ? item.allergy: '',
            active = item.isActive ? 'ACTIVE': '',
            severity = item.severity ? item.severity: '',
            textResult = textResult + allergy + ' - ' + active+', SEVERITY - ' + severity +"\n\n"
        ))
        return textResult
      },

    ChangeDropDownTherapistCode(value){
        this.painAssessment.TherapistCode = value ? value.code : '';
    },

    ChangeDropDownCharacter(value){
        this.painAssessment.PainCharacter = value ? value.code : '';
    },

    ChangeDropDownFrequency(value){
        this.painAssessment.Frequency = value ? value.code : '';
    },

    getTherapists(){
        TherapistService.getAllTherapist()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              let arr = response.data;
              for(var i = 0; i<arr.length; i++){
                arr[i].fullName = arr[i].firstName+" "+arr[i].lastName;
              }
              this.therapistList = arr;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdPainAssessmentNotesOutCome.GetTherapistsMsg'),error,"error");
            } 
          });  
      },

      onSignPassword(value) {
        if(this.painAssessment.TherapistCode) {
            this.showSignPassword = value;
        } else {
            this.painAssessment.IsSigned = false
            this.showMessage(this.$t('Shared.VerifyPassword'),this.$t('MdPainAssessmentNotesOutCome.TheraRequiredMsg'),"error");
        }
      },

      onModalPasswordResult(result) {
        this.painAssessment.IsSigned = result;
        if(result != true) {
          this.showMessage(this.$t('Shared.VerifyPassword'),result,"error");
        }
        this.showSignPassword = false;
      },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getAllergies();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.getAllergies();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

      onCodeDescriptionOk(result){
        this.Allergy.allergyCode = result.code;
        this.getLookupFieldsDesc(result.code);
      },

      formatterDate(value){
         return Utils.formatterDate(value);
      },

      SaveOrEdit(){
        if(this.painAssessment.IsSigned) { 
          this.pendingChanges = false 
            // if (!this.isNewAllergy) { 
            //    this.UpdateAllergy()           
            // }else{            
            //    this.CreateAllergy()
            // }
        } else {
            this.showMessage(this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),
                          this.$t('MdPainAssessmentNotesOutCome.SingBeforeMsg'),"error");
        }
      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
  },

  mounted() {
    this.getTherapists()
    this.$emit('load', this.Loading); 
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
};

</script>