<template>
    <div >
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('schedulerSingleRep.RepTitle')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="reportSchedulerSingle"
          :ReportName="'SingleScheduler'"
          :ReportTitle="$t('schedulerSingleRep.ReportTitle')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'

    export default Vue.extend({
        name: "SchedulerSingleRep",
        mixins: [ ShowMessage ],
        components: { DxPopup },
        props: {
          closeWindow: Function,
          activityId: String
        },
        data() {
            return {
                isParamVisible: true,
                isReportVisible: true,
                reportParams: [],
                parameter:{
                  SearchCriteria:'',
                  PageNumber : 0,
                  PageSize:0,
                  Status: true,       
                },
            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.isReportVisible = false
              this.closeWindow()
            },


            onClickClose() {
              this.closeWindow()
              this.isParamVisible = false;
            },

            onClickShowReport() {
              this.isParamVisible = false

              this.reportParams = []
              this.reportParams.push({ paramName: 'ActivityId', paramValue: this.activityId});
              this.reportParams.push({ paramName: 'showedup', paramValue: "all"});
              this.reportParams.push({ paramName: 'confirmed', paramValue: "all"});
              this.isReportVisible = true
              this.$refs.reportSchedulerSingle.buildDocument(this.reportParams)
            }
        },
        
         mounted () {
           if (this.activityId)
              this.onClickShowReport()
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
