<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card activeCard">
      <div class="card-body">                
        <div class="d-flex align-items-start justify-content-between pointer">
          <h6 v-on:click="GoToAppointment" class="card-title flex-grow lower-line text-truncate">{{$t('MdCurrentAppointment.CurrentAppointment')}}</h6>
          <i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="GoToAppointment" ></i>
        </div>
        <div class="float-left col-md-4 text-muted p-item" v-if="this.currentAppointmentData.activitytypename">
          <small>{{ $t('Shared.AppointmentType') }}</small><br>
          <b> {{this.currentAppointmentData.activitytypename}}</b>
        </div>
        <div class="float-left col-md-4 text-muted p-item" v-if="this.currentAppointmentData.transdate">
          <small>{{ $t('MdCurrentAppointment.StarTime') }}</small><br>
          <b>{{this.currentAppointmentData.transdate}}</b>
        </div>
        <div class="float-left col-md-4 text-muted p-item" v-if="this.currentAppointmentData.enddate">
          <small>{{ $t('MdCurrentAppointment.EndTime') }}</small><br>
          <b>{{this.currentAppointmentData.enddate}}</b>
        </div>
        <div class="float-left col-md-4 text-muted p-item" v-if="this.currentAppointmentData.status">
          <small>{{ $t('Shared.Status') }}</small><br>
          <b>{{this.currentAppointmentData.appointmentStatus}}</b>
        </div>
        <div class="float-left col-md-4 text-muted p-item" v-if="this.currentAppointmentData.employee && this.currentAppointmentData.employee.firstname || this.currentAppointmentData.employee && this.currentAppointmentData.employee.lastname">
          <small>{{ $tc('Shared.Employee') }}</small><br>
          <b>{{this.currentAppointmentData.employee.firstname }} {{this.currentAppointmentData.employee.lastname }}</b>
        </div>         
        <div class="float-left col-md-4 text-muted p-item" v-if="this.currentAppointmentData.programName">
          <small>{{ $tc('Shared.Program') }}</small><br>
          <b>{{this.currentAppointmentData.programName }}</b>
        </div>         

      </div>
    </div>
  </div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'
export default {
  props: {      
      ActivityId:String,
      currentAppointmentObj:Object,
      ExecuteCall:Boolean
 },
 mixins: [DBFunctions],
  data() {
    return {  
      currentAppointmentData:this.currentAppointmentObj,  
     /*currentAppointmentData:{
      employee:{
            firstname:"",
            lastname:""
        }
     },*/
    }
  },
  
methods:{

  GoToAppointment(){
    this.$router.push({ name: "Appointment-Editor", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
  },
  
  async getAppoinment(){
      this.currentAppointmentData = await this.geCurrentAppointment(this.ActivityId);	

      if (this.currentAppointmentData.transdate) {
        this.currentAppointmentData.transdate =  `${Utils.formatterDateToString(this.currentAppointmentData.transdate)} ${this.currentAppointmentData.timein}`;
      }
      if (this.currentAppointmentData.enddate) {
        this.currentAppointmentData.enddate =  `${Utils.formatterDateToString(this.currentAppointmentData.enddate)} ${this.currentAppointmentData.timeout}`; 
      }
         
      return this.currentAppointmentData;
  },
},
 mounted() {
  this.currentAppointmentData = this.ExecuteCall === true ? this.getAppoinment() : this.currentAppointmentObj; 
 }
}

</script>