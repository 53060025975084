<template>
    <div class="row page-height">
		<div class="col-12">
			<h4 class="page-title text-truncate">
				{{ $t('Clinical.ClinicalDashboard') }} - 
				
				<span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
				<span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
				<span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
				<span v-if="this.RegistrationInfo.ageFormatted"> - {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
				<span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
				<span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
				<span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
				<span v-else>- {{ $t('Shared.Active') }} </span>
			</h4>
			<div class="row">
				<MdPatientRegistrationInfo
					v-bind:RecordId="this.$route.params.recordid">
				</MdPatientRegistrationInfo>

				<MdPatientInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientInfoBox>

				<MdPatientAllergies
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientAllergies>

                <MdPatientSpecialPrecautions						
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientSpecialPrecautions>

				<MdPatientDiagnosis
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientDiagnosis>

				<MdPatientRegistrationsHistory
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientRegistrationsHistory>

			</div>
			<div class="space-actions"></div>
			<div class="page-actions">
				<MdButton 
					v-bind:Label="$t('Shared.Back')"
					v-bind:Name="'btnpatientlist'"
					v-bind:ClassProp="'btn btn-primary mx-2 btn-login'"
					v-bind:IconClass="'ti-back-left pr-1'"
					v-on:click="GotoClinical"/>
			</div>
		</div>
	</div>
   
</template>

<script>
 import EnumService from '@/services/enum.service'
 import Utils from '../../../common/utils'
 import DBFunctions from '@/common/DBFunctions.js'

export default {
	
  name: 'DashBoardClinical',
   mixins: [DBFunctions],
  data() {
      return {
		Allergies:[],
		Diagnosis:[],
		InfoPatient:{},
		RegistrationInfo:{},
		Loading:false,
		OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,
			PatientCode:''         
		}
		}
	},
	methods:{

		formatterDateToString(date){
				return Utils.formatterDateToString(date);
			},
		GoToPatientInfo(){
			this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } }).catch(()=>{});
		},

		GotoClinical(){
			this.checkExistFavorite('/app/clinical');
			this.$router.push('/app/clinical').catch(()=>{});
		}
	},
	async mounted(){
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
		this.$emit('getVisibleMenu', this.OptionsRightMenu); 
		this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
		
		this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
		

		if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);
		if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  Utils.formatterDateToString(this.RegistrationInfo.dob);  
	},
    destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
};
</script>


