<template>
  <div :class="UseColumnDinamic ? Column + ' grid-margin stretch-card': 'col-md-6 grid-margin stretch-card'">
    <div class="card activeCard">
      <div class="card-body">    
        <div class="d-flex align-items-start justify-content-between">
          <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('MdHistoricalAppointment.HistoricalAppointment') }}</h6>
        </div>
        <div class="text-muted p-fixed cutescroll">
          <p v-if="historicalAppointment.length === 0">{{ $t('MdHistoricalAppointment.NoHistAppointment') }}</p>
          <ul>							
            <li v-for="(h, index) in historicalAppointment" :key="`h.id-${index}`">
            {{h.startTime}} - {{h.endTime}} - {{h.appointmentTypeName}} - {{h.employeeName}}
            </li>						
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
export default {
  props: {
      PatientCode:String,
      UseColumnDinamic:Boolean,
      Column:String,
 },
 mixins: [DBFunctions],
  data() {
    return {     
      historicalAppointment:[]
    }
  },
  
methods:{
 async getAppointments(){
      this.historicalAppointment = await this.geHistoricalAppointment(this.PatientCode);		
  },

},
 mounted() {
  this.getAppointments();
 }
}

</script>