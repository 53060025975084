<template> 
        <fragment>
            <div :id="targetId" oncontextmenu="return false;">
            <div v-if="!isReadOnly && cmpLoaded">
                <ejs-contextmenu :id="targetId" :target="menuTargetId" :items='menuItems' :select='onSelect'></ejs-contextmenu>
            </div>  
            <div :class="this.classValue" :data-label="Label+ this.invalidFeedback" :id="'dv_'+Id">
                <input
                    v-mask="internalMask"
                    :type="internalPropertyType"
                    :maxlength="MaxLengthProp"          
                    :readonly="isReadOnly"
                    :min="Min"
                    :max="Max"
                    :step="StepProp"
                    :ref="Name"
                    :id="Id"
                    class="form-control" 
                    :placeholder="internalPlaceholder"
                    :autocomplete="autocomplete"
                    v-on:blur="onBlur"
                    v-on:change="OnChange"
                    v-on:keyup.enter="PressEnter"
                    :aria-describedby="Name"
                    v-bind:value="formattedValue"
                    v-on:keypress="$emit('keypress', $event)"
                    v-on:input="onInput"
                    v-on:focus="$emit('focus', $event.target.value)"
                    v-on:keydown="$emit('keydown', $event)"
                    >
            </div>
            </div>      
        </fragment>         
</template>

<script>
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);

export default {
    name: 'MdEdit',
    props: {     
        TypeProp:String,
        StepProp:String,
        MaxLengthProp: Number,
        isReadOnly:Boolean,
        Min:String,
        Max:String,
        isRequired:Boolean,
        value: [String, Number, Date],
        Name:String,
        Id:String,
        isValid:Boolean,
        Label:String,
        Placeholder:String,
        Autocomplete:Boolean,
        Mask: String,
        Tag: String,
        OnPressEnter: Function,
        OnAuxFunc:Function,
        IsAux:Boolean,
        NoValidateChanges:Boolean,
        isValidDoB:Boolean,
    },

    data(){
        return {
            Count:0,
            classValue:"textlabel",
            autocomplete:this.Autocomplete ? 'on' : 'off',
            invalidFeedback:"",
            menuItems:[],
            targetId: "target-"+this.Id,
            menuTargetId:"#target-"+this.Id,
            cmpLoaded: false,
            formattedValue: this.fromISO(this.value),
            loaded: false
        }
    },

    computed: {
        internalPropertyType() {
            if (this.isCalendar() && !Utils.useBrowserBuildItCalendar()) {
                return 'text';
            }
            
            return this.TypeProp;
        },

        internalMask() {
            if (this.isCalendar() && !Utils.useBrowserBuildItCalendar()) {
                let propertyType = this.TypeProp?.toLowerCase(); 
                if (propertyType === 'datetime-local') {
                    return '##/##/#### ##:##:##';
                } else if (propertyType === 'date') {
                    return '##/##/####';
                }
            }

            return this.Mask;
        },

        internalPlaceholder() {
            let propertyType = this.TypeProp?.toLowerCase();
            if (propertyType === 'datetime-local') {
                return Utils.getFullDateTimeFormat();
            } else if (propertyType === 'date') {
                return Utils.getShortDateFormat();
            }

            return this.Placeholder;
        },

        format() {
            if (this.isCalendar() && !Utils.useBrowserBuildItCalendar()) {
                return this.TypeProp == 'date' ? Utils.getShortDateFormat() : Utils.getFullDateTimeFormat();
            }

            return this.TypeProp == 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss';
        },

        shortFormat() {
            if (this.isCalendar() && !Utils.useBrowserBuildItCalendar()) {
                return this.TypeProp == 'date' ? Utils.getShortDateFormat() : Utils.getSchedulerDateTimeFormat();
            }

            return this.TypeProp == 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm';
        },
    },

    watch: {    
        isValid: function (newValue) {
            this.classValue= newValue ? "textlabel" : "textlabel-invalid"
            this.invalidFeedback = newValue ? "": ": "+this.$t('Msg.RequiredField')
        },
        isValidDoB: function (newValue) {
            this.classValue= newValue ? "textlabel" : "textlabel-invalid"
            this.invalidFeedback = newValue ? "": ": "+this.$t('Msg.InvalidField')
        },
        value(newVal) {
            let value = newVal
            if (this.isCalendar()) {
                value = this.fromISO(newVal);
                if (value !== '') {
                    this.formattedValue = value;
                }
            } else {
                this.formattedValue = value;
            }
        }
    },

    methods: {
        isCalendar() {
            let propertyType = this.TypeProp?.toLowerCase();
            return propertyType === 'datetime-local' || propertyType === 'date';
        },

        toISO(value) { 
            if (!this.isCalendar() || Utils.useBrowserBuildItCalendar()) {
                return value;
            }
            
            let propertyType = this.TypeProp?.toLowerCase();
            let newValue = value;
            if (value != '') {
                if (propertyType === 'date') {
                    newValue = null;
                    if (Utils.isValidDate(value, this.format, true)) {
                        newValue = Utils.formatterDate(value, this.format);
                    }
                } else if (propertyType === 'datetime-local') {
                    newValue = null;
                    if (Utils.isValidDate(value, this.format, true) || Utils.isValidDate(value, Utils.getSchedulerDateTimeFormat(), true)) {
                        newValue = Utils.formatterToISO(value, this.format);  
                    }  
                }
            }

            return newValue;          
        },

        fromISO(value) {
            if (!this.isCalendar() || Utils.useBrowserBuildItCalendar()) {
                return value;
            }

            let propertyType = this.TypeProp?.toLowerCase();
            let newValue = value;
            if (value != '') {
                if (propertyType === 'date') {
                    newValue = this.formattedValue;
                    if (Utils.isValidDate(value)) {
                        newValue = Utils.formatterDateToString(this.value);    
                    }
                } else if (propertyType === 'datetime-local') {
                    newValue = this.formattedValue;
                    if (Utils.isValidDate(value)) {
                        newValue = Utils.formatterDateTimeAndSecondsToString(this.value);  
                    } 
                } 
            }
            
            return newValue;
        },

        setValid(state){       
            this.classValue= state ? "textlabel" : "textlabel-invalid"
            this.invalidFeedback = state ? "": ": "+this.$t('Msg.RequiredField')
        },

        PressEnter(){
            if(this.OnPressEnter){
                this.OnPressEnter();
            }
        },

        checkIsRequired(evt){
            let Invalid = false;
            if(evt.target.value || !evt.target.validity.valid){
                if(this.TypeProp == 'text') {
                    Invalid = evt.target.value.trim() == "" && this.isRequired ? true : false;
                    this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.RequiredField'): ""
                }
                else if(this.TypeProp == 'date') {
                    Invalid = !evt.target.validity.valid || (!Utils.isValidDate(evt.target.value, this.format, true) && this.isRequired) ? true : false;
                    this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.InvalidField')+ " " + Utils.getShortDateFormat(): ""
                }
                else if(this.TypeProp == 'datetime-local') {
                    Invalid = !evt.target.validity.valid || (!(Utils.isValidDate(evt.target.value, this.format, true) || Utils.isValidDate(evt.target.value, this.shortFormat, true))) && this.isRequired ? true : false;
                    this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.InvalidField') + " " + Utils.getFullDateTimeFormat(): ""
                }
                else if(this.TypeProp == 'email') {
                    //eslint-disable-next-line
                    const reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
                    if (evt.target.value.length > 0) {
                        Invalid = (reg.test(evt.target.value)) ? false : true;         
                    }
                    this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.InvalidField'): ""        
                }
            }
            this.classValue= Invalid ? "textlabel-invalid" : "textlabel"
        },

        checkIsRequiredSave(value,type,id,label){      
            let Invalid = false; 
            if(type == 'email') {
                //eslint-disable-next-line
                const reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
                if (value.length > 0) {
                    Invalid = (reg.test(value)) ? false : true;         
                }       
                var div = document.getElementById('dv_'+id);       
                div.setAttribute("data-label", Invalid ? label+": "+ this.$t('Msg.InvalidField'): label);        
            }
            document.getElementById('dv_'+id).className = Invalid ? "textlabel-invalid" : "textlabel";  
        },

        validateRangeDate(date) {
            if (this.Max && this.Min && date) {
                let format = this.format;
                let isValid = (Utils.isValidDate(date, format, true) || (this.TypeProp == 'datetime-local' && Utils.isValidDate(date, this.shortFormat, true))) ? true : false;
                if (!isValid) {
                    if (!this.isRequired) {
                        this.classValue = !isValid ? "textlabel-invalid" : "textlabel"
                        this.invalidFeedback = ": "+ this.$t('Msg.InvalidField') + " " + Utils.getShortDateFormat();
                    }
                } else {
                    isValid = Utils.isValidDateAndRangeWith (date, this.Min, this.Max, format) || (this.TypeProp == 'datetime-local' && Utils.isValidDateAndRangeWith (date, this.Min, this.Max, this.shortFormat));
                    //this.classValue =  (date > this.Max || date < this.Min) ? "textlabel-invalid" : "textlabel"
                    this.classValue = !isValid ? "textlabel-invalid" : "textlabel"
                    this.invalidFeedback = !isValid ? " - " + this.$t('Msg.DateOutRange'): ""
                }
            }
            if (!date) {
                this.invalidFeedback = ''
            }
        },

        OnChange(evt){
            this.checkIsRequired(evt)

            this.formattedValue = evt.target.value;
            let value = this.toISO(evt.target.value) ?? '';
            this.$emit('change', value)
        },

        onBlur(evt) {
            this.checkIsRequired(evt)
            if(this.isCalendar() && evt.target.validity.valid) {
                this.validateRangeDate(evt.target.value)
            }
            
            this.$emit('blur', evt.target.value)
        },

        setFocus(){
            this.$refs[this.Name].focus();
        },

        onInput(evt) {
            let value = this.toISO(evt.target.value) ?? '';
            
            this.Count++;
            if (this.Count > 1 || (this.isCalendar() && Utils.useBrowserBuildItCalendar()) || (!this.isCalendar() && !this.Mask)) {
                this.$emit('input', value)

                var changed = {
                    "Changed":true,
                    "ComponentName":this.Name,
                    "Tag": this.Tag,
                    "Value" : value,
                } 
                
                if(!this.NoValidateChanges && this.loaded){
                    EventBus.$emit('onChanged', changed, this.Count);
                }   

                if(this.IsAux && this.loaded){
                    this.OnAuxFunc();
                }
            }
        },

        onSelect: function(args ) {
            if(args.item.id === 'recording') {
                EventBus.$emit('speechBarLabel', 'stop recording');
                this.$root.$refs.MdTextSpeech.toggleSpeechBar()
                this.$root.$refs.MdTextSpeech.startSpeechToText(this.Name);
            }
            if(args.item.id === 'speech') {
                EventBus.$emit('speechBarLabel', 'stop speech');
                this.$root.$refs.MdTextSpeech.toggleSpeechBar()
                this.$root.$refs.MdTextSpeech.startSpeech(this.value);
            }
        },

        loadMenuItems() {
            if(this.$root.$refs.MdTextSpeech.isSpeechRecognitionSupported) {
                this.menuItems.push({text: this.$t('MdTextSpeech.record'), id: "recording"})
            }
            this.menuItems.push({text: this.$t('MdTextSpeech.speech'), id: "speech"})
        },
        
        setValueS2T( sp ){
            let sp2t = ''
            var changed = {}
            if(this.value) {
                sp2t += Array.from (this.value + ""+sp).join("");
            } else {
                sp2t = Array.from (sp).join("");
            }
            this.Value = sp2t
            this.$emit('input', sp2t);
            changed = {
                "Changed":true,
                "ComponentName":this.Name,
                "Tag": this.Tag,
                "Value" : sp2t
            } 

            EventBus.$emit('onChanged', changed);
        }
    },

    mounted(){
        this.cmpLoaded = 'MdTextSpeech' in Vue.options.components
        if (this.cmpLoaded) this.loadMenuItems()
        this.loaded = true;
    },
    
    created() {
        EventBus.$on("onCheckValid", function (value,type,id,label) {
            this.checkIsRequiredSave(value,type,id,label);
        }.bind(this));

        EventBus.$on("receivedS2T", function (event) {
            if( event.ControlId == this.Name) { 
                this.setValueS2T( event.finalSentence )
            }
        }.bind(this))
    }
};

</script>

<style>
 @import '../../../assets/css/Patients/Patient.css';
</style>


