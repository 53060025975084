<template>
   <div id="ContainerContact">
      <div class="page-height">
         <div class="col-12">
            <!--<MdTitle 
               v-bind:Text="'Edit Patient Contact - ' + this.PatientTitle.fullName + ' - ' + this.$route.params.id"
               v-bind:isHide="!this.Mode"
               v-bind:Name="'IdPatient'"
            />
            <MdTitle 
               v-bind:Text="'Add Patient Contact - ' + this.PatientTitle.fullName + ' - ' + this.$route.params.id"
               v-bind:isHide="this.Mode"
            />-->

      <h4 v-if="this.Mode" class="page-title text-truncate">
        {{ $t('Contact.EditPatientContact') }} -
        <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
        <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
        <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
        <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
        <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
        <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
        <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
        <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
        <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
        <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>     
      </h4>
      <h4 v-if="!this.Mode" class="page-title text-truncate">
        {{ $t('Contact.AddPatientContact') }} -
        <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
        <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
        <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
        <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
        <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
        <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
        <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
        <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
        <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
        <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>   
      </h4>
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
         <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
         <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
         </div>
      </div>


            <!-- !! Important ,Added for testing purpose -->
            <div style='display:none;'><MdEdit 
                  v-bind:TypeProp="'text'" 
                  v-model="this.ContactId "
                  v-bind:Id="'input_contact_id'">                                     
               </MdEdit>
            </div>
            <!-- End -->
            <b-row class="mt-3">
            <b-col md="3">
               <MdCheckBox
                  v-bind:Label="$t('Contact.PersonResponsible')"
                  v-bind:Name="'checkbox-1'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="Contact.primContact"
                  v-bind:isReadOnly=this.isReadOnly
               />
            </b-col>
            </b-row>
            <b-row>
               <b-col md="3">
                  <MdEdit 
                     v-bind:Label="$t('Shared.FirstName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'firstName'"
                     v-bind:MaxLengthProp="50"
                     v-bind:isRequired="true"
                     v-model="Contact.firstName"
                     v-bind:isReadOnly="this.isReadOnly"
                     v-bind:isValid="this.isFirstNameValid"
                     v-bind:Id="'input_contact_first_name'">                                     
                  </MdEdit>
               </b-col>
               <b-col md="3">
                  <MdEdit 
                     v-bind:Label="$t('Shared.LastName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'lastName'"
                     v-bind:MaxLengthProp="50"
                     v-bind:isRequired="true"
                     v-model="Contact.lastName"
                     v-bind:isReadOnly="this.isReadOnly"
                     v-bind:isValid="this.isLastNameValid"
                     v-bind:Id="'input_contact_last_name'">                                     
                  </MdEdit>
               </b-col>
               <b-col lg="3" md="3" sm="12" class="mb-3">                 
                  <MdEdit 
                     v-bind:Label="$t('Shared.Dob')"
                     v-bind:TypeProp="'date'" 
                     v-bind:Name="'dob'"
                     v-bind:Autocomplete="true"
                     v-bind:Min="'1900-01-01'"
                     v-bind:Max="'2200-12-31'"
                     v-bind:isValidDoB="this.isDOBValid"
                     v-model="Contact.dob"
                     v-bind:isReadOnly=this.isReadOnly>                                     
                  </MdEdit>
                 
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('Contact.ContactType')"
                  v-bind:Options="ContactTypeList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="Contact.contactType"                       
                  v-bind:Multiple="false"
                  :IsDisabled=this.isReadOnly
                  :Input="ChangeDropDownType"
                  />
               </b-col>
            </b-row>
            <b-row>
            <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Shared.Relation')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'relation'"
                  v-bind:MaxLengthProp="50"
                  v-model="Contact.relation"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_relation'">                                     
               </MdEdit>
            </b-col>
            <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Contact.SSN')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'ssn'"
                  v-bind:MaxLengthProp="11"
                  v-model="Contact.ssn"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Mask="'###-##-####'"
                  v-bind:Id="'input_ssn'">                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">             
               <MdRadioGroup
                  v-bind:Label="$t('Contact.Sex')"
                  v-model="Contact.sex"
                  v-bind:Options="options"
                  v-bind:Name="'radio-inline'"
                  v-bind:isReadOnly=this.isReadOnly
               />
            </b-col>  
            </b-row>
            <MdTitle v-bind:Text="$t('Contact.ContactInformation')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-row>
               <b-col md="3">
                  <MdEdit 
                     v-bind:Label="$t('Shared.CellPhone')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'cellPhone'"
                     v-bind:MaxLengthProp="20"
                     v-model="Contact.cellPhone"
                     v-bind:isReadOnly=this.isReadOnly
                     v-bind:Id="'input_cellphone'">                                     
                  </MdEdit>
               </b-col>
               <b-col md="3">
                  <MdEdit 
                     v-bind:Label="$t('Shared.DayPhone')"
                     v-bind:TypeProp="'text'"
                     v-bind:Name="'dayPhone'"
                     v-bind:MaxLengthProp="20"
                     v-model="Contact.dayPhone"
                     v-bind:isReadOnly=this.isReadOnly
                     v-bind:Id="'input_dayPhone'">                                     
                  </MdEdit>
               </b-col>
               <b-col md="3">
                  <MdEdit 
                     v-bind:Label="$t('Shared.NightPhone')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'nightPhone'"
                     v-bind:MaxLengthProp="20"
                     v-model="Contact.nightPhone"
                     v-bind:isReadOnly=this.isReadOnly
                     v-bind:Id="'input_nightphone'">                                     
                  </MdEdit>
               </b-col>
               <b-col md="3">
                  <MdEdit 
                     v-bind:Label="$t('Shared.Email')"
                     v-bind:TypeProp="'email'" 
                     v-bind:Name="'email'"
                     v-bind:MaxLengthProp="100"
                     v-bind:isRequired="true"
                     v-model="Contact.email"
                     v-bind:isValid="this.emailValid"
                     v-bind:isReadOnly=this.isReadOnly
                     v-bind:Id="'input_contact_email'">                                     
                  </MdEdit>
               </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('Contact.Address')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-row class="mt-3">
               <b-col md="3">
                  <MdCheckBox
                     v-bind:Label="$t('Contact.UsePatientAddress')"
                     v-bind:Name="'checkbox-adress'"
                     v-bind:ClassProp="'AlignCheck'"
                     v-bind:CheckedValue="true"
                     v-bind:UncheckedValue="false"
                     @change="UsePatientAddress($event)"
                     v-bind:isReadOnly=this.isReadOnly
                  />
               </b-col>
            </b-row>
            <b-row>
               <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Shared.Address1')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'address1'"
                  v-bind:MaxLengthProp="30"
                  v-model="Contact.address1"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_addrs1'">                                     
               </MdEdit>
               </b-col>
               <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Shared.Address2')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'address2'"
                  v-bind:MaxLengthProp="30"
                  v-model="Contact.address2"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_addrs2'">                                     
               </MdEdit>
               </b-col>
               <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Shared.Zip')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'zip'"
                  v-bind:MaxLengthProp="10"
                  v-model="Contact.zip"
                  v-bind:isReadOnly=this.isReadOnly
                  v-on:blur="contactZipBlur(Contact.zip)"
                  v-on:change="contactZipChange($event)"
                  v-bind:Id="'input_zip'">                                     
               </MdEdit>
               </b-col>
               <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Shared.City')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'city'"
                  v-bind:MaxLengthProp="25"
                  v-model="Contact.city"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_city'">                                     
               </MdEdit>
               </b-col>
            </b-row>
            <b-row>
               <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Shared.State')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'state'"
                  v-bind:MaxLengthProp="20"
                  v-model="Contact.state"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_state'">                                     
               </MdEdit>
               </b-col>
               <b-col md="3">
               <MdEdit 
                  v-bind:Label="$t('Shared.Country')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'country'"
                  v-bind:MaxLengthProp="25"
                  v-model="Contact.country"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_country'">                                     
               </MdEdit>
               </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('Contact.AditionalInformation')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                     v-bind:Label="$t('Contact.ActiveReleaseOnFile')"
                     v-bind:Options="ActiveReleaseOnFile"
                     v-bind:FieldDescription="'text'"
                     v-bind:FieldCode="'value'"
                     v-bind:Model="Contact.releaseOnFile"                       
                     v-bind:Multiple="false"
                     :IsDisabled=this.isReadOnly
                     :Input="ChangeDropDownFile"
                     />
               </b-col>
               <b-col lg="3" md="3" sm="12" class="mb-3">                 
                  <MdEdit 
                     v-bind:Label="$t('Contact.ActiveReleaseOnFileEndDate')"
                     v-bind:TypeProp="'date'" 
                     v-bind:Name="'ActiveReleaseOnFileEndDate'"
                     v-bind:Autocomplete="true"
                     v-bind:Min="'1900-01-01'"
                     v-bind:Max="'2200-12-31'"
                     v-model="Contact.endDateforActiveRelease"
                     @input="ChangeDateActiveOnRelease"
                     v-on:blur="OnActiveReleaseBlur(Contact.endDateforActiveRelease)"
                     v-bind:isReadOnly="this.isReadOnly || Contact.releaseOnFile !== 'Y'">                                     
                  </MdEdit>                 
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                        v-bind:Label="$t('Contact.gPortalAccess')"
                        v-bind:Options="YesNoOptions"
                        v-bind:FieldDescription="'text'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="Contact.portalAccess"                       
                        v-bind:Multiple="false"
                        :IsDisabled="this.isReadOnly || Contact.releaseOnFile !== 'Y'"
                        :Input="ChangeDropDownGrantPortalAccess"
                        />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                     <MdSelect
                           v-bind:Label="$t('Contact.rPortalNo')"
                           v-bind:Options="YesNoOptions"
                           v-bind:FieldDescription="'text'"
                           v-bind:FieldCode="'value'"
                           v-bind:Model="Contact.portalNotifications"                       
                           v-bind:Multiple="false"
                           :IsDisabled="this.isReadOnly || Contact.releaseOnFile !== 'Y'"
                           :Input="ChangeDropDownPortalNotifications"
                           />
               </b-col>                    
            </b-row>
            <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                        v-bind:Label="$t('Contact.rAppoNo')"
                        v-bind:Options="YesNoOptions"
                        v-bind:FieldDescription="'text'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="Contact.appointmentNotifications"                       
                        v-bind:Multiple="false"
                        :IsDisabled="this.isReadOnly || Contact.releaseOnFile !== 'Y'"
                        :Input="ChangeDropDownAppointmentNotifications"
                        />
                  </b-col>
         <b-col lg="3" md="3" sm="12">
         <MdSelect
               v-bind:Label="$t('Contact.MayHavePhoneCalls')"
               v-bind:Options="YesNoOptionsNumericThree"
               v-bind:FieldDescription="'text'"
               v-bind:FieldCode="'value'"
               v-bind:Model="Contact.mayHavePhoneCalls"                       
               v-bind:Multiple="false"
               :IsDisabled=this.isReadOnly
               :Input="ChangeDropDownPhoneCalls"
               />
         </b-col>
         <b-col lg="3" md="3" sm="12">
            <MdSelect
               v-bind:Label="$t('Contact.SupervisedPhoneCalls')"
               v-bind:Options="YesNoOptionsNumericThree"
               v-bind:FieldDescription="'text'"
               v-bind:FieldCode="'value'"
               v-bind:Model="Contact.supervisedPhoneCalls"                       
               v-bind:Multiple="false"
               :IsDisabled=this.isReadOnly
               :Input="ChangeDropDownSupervisedCalls"
               />
         </b-col>
         <b-col lg="3" md="3" sm="12">
            <MdSelect
               v-bind:Label="$t('Contact.VisitationPermission')"
               v-bind:Options="YesNoOptionsNumericTwo"
               v-bind:FieldDescription="'text'"
               v-bind:FieldCode="'value'"
               v-bind:Model="Contact.visitationPerm"                       
               v-bind:Multiple="false"
               :IsDisabled=this.isReadOnly
               :Input="ChangeDropDownVisitationPerm"
               />
         </b-col>
            </b-row>
            <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                     v-bind:Label="$t('Contact.MayVisit')"
                     v-bind:Options="YesNoOptionsNumericThree"
                     v-bind:FieldDescription="'text'"
                     v-bind:FieldCode="'value'"
                     v-bind:Model="Contact.mayVisit"                       
                     v-bind:Multiple="false"
                     :IsDisabled="this.isReadOnly || Contact.visitationPerm === '0'"
                     :Input="ChangeDropDownMayVisit"
                     />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                     v-bind:Label="$t('Contact.UnsupervisedVisitsOnGrounds')"
                     v-bind:Options="YesNoOptionsNumericThree"
                     v-bind:FieldDescription="'text'"
                     v-bind:FieldCode="'value'"
                     v-bind:Model="Contact.unsupVisitsOnGrounds"                       
                     v-bind:Multiple="false"
                     :IsDisabled="this.isReadOnly || Contact.visitationPerm === '0'"
                     :Input="ChangeDropDownVisitsOnGrounds"
                     />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                     v-bind:Label="$t('Contact.UnsupervisedVisitsOffGrounds')"
                     v-bind:Options="YesNoOptionsNumericThree"
                     v-bind:FieldDescription="'text'"
                     v-bind:FieldCode="'value'"
                     v-bind:Model="Contact.unsupVisitsOffGrounds"                       
                     v-bind:Multiple="false"
                     :IsDisabled="this.isReadOnly || Contact.visitationPerm === '0'"
                     :Input="ChangeDropDownVisitsOffGrounds"
                     />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                     v-bind:Label="$t('Contact.NeedProbationForOffGrounds')"
                     v-bind:Options="YesNoOptionsNumericThree"
                     v-bind:FieldDescription="'text'"
                     v-bind:FieldCode="'value'"
                     v-bind:Model="Contact.needProbApprForOffGrounds"                       
                     v-bind:Multiple="false"
                     :IsDisabled="this.isReadOnly || Contact.visitationPerm === '0'"
                     :Input="ChangeDropDownApprForOffGrounds"
                     />
               </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-row>
               <b-col md="12" lg="12">
                  <MdMemo
                  v-bind:Name="'textarea_notes'"
                  v-bind:Rows="'3'"
                  v-bind:MaxRows="'6'"
                  v-bind:MaxLength="'500'"
                  v-model="Contact.note"
                  v-bind:isReadOnly=this.isReadOnly
                  />                                     
               </b-col>
            </b-row>            
            <div class="space-actions"></div>
            
         </div>
         <div class="page-actions">
               <MdButton
                  v-bind:Label="$t('Shared.Back')"
                  v-bind:Name="'btnpatientlist'"
                  v-bind:ClassProp="'secondary mx-2 btn-login'"
                  v-on:click="DiscardChanges"/>
            
               <MdButton
                  v-bind:Label="$t('Shared.Save')"
                  v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                  v-on:click="ClickSaveCall"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Name="'btn_save_contact'"/>
            </div>
      </div>
   </div>
</template>

<script>

import EnumService from '@/services/enum.service' 
import ContactService from '@/services/contact.service'
import GlobalService from '@/services/global.service' 
import Utils from '../../../../common/utils'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';

let elemSaveBtn = '';
export default {

   beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUsePatientContact;
            if(iUA) next()
            else next('/accessdenied');
        }) 
   },

   beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Contact.PatientContact'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],  
   name: 'ContactDetails',
    data() {
      return { 
       ClickSave:false,
       patientAddress:{},
        Mode:this.$attrs.editMode,  
        Loading:true,
        DisabledOptionsPortal:false,
        RegistrationInfo:{},
        infoList:[],
        IsClinical:false,
        Contact:{
            firstName: '',
            lastName: '',
            primContact: false,
            dob: null,
            contactType: '',
            relation: '',
            ssn: '',
            sex: '',
            cellPhone: '',
            dayPhone: '',
            nightPhone: '',
            email: '',
            address1: '',
            address2: '',
            zip: '',
            city: '',
            state: '',
            country: '',
            note: '',
            releaseOnFile: '',
            mayHavePhoneCalls: '',
            supervisedPhoneCalls: '',
            visitationPerm: '',
            mayVisit: '',
            unsupVisitsOnGrounds: '',
            unsupVisitsOffGrounds: '',
            needProbApprForOffGrounds: '',
            portalAccess:'',
            endDateforActiveRelease:null,
            portalNotifications:'',
            appointmentNotifications:'',
        },
       OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },
        
     PatientTitle:{      
         programName:'',
         fullName:'',
         admitDate:'',
         dischargeDate:'',
         dob:'',
         activitytypename:'',
         startdatetime:'',
         ageFormatted:''
     },      
        ContactTypeList:[],
        ActiveReleaseOnFile: EnumService.YesNoOptions,
        YesNoOptions : EnumService.YesNoOptions,
        YesNoOptionsNumericThree: EnumService.YesNoOptionsNumericThree,
        YesNoOptionsNumericTwo: EnumService.YesNoOptionsNumericTwo,
        checkedUsePatientAddress: false,
        contactZipChanged:false,
        shippingData:[],
        options: [
          { text: this.$t('Shared.Male'), value: 'M' },
          { text: this.$t('Shared.Female'), value: 'F' }         
      ],
      formatted: '',
        selected: '',
      isFirstNameValid: true,
      isLastNameValid: true,
      isReadOnly: false,
      counterModelTypeList:0,
      //eslint-disable-next-line
      reg: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, 
      pendingChanges: false,
      emailValid: false,
      ContactId: '',
      isDOBValid: true,
      currentAppointmentDB:{
         guid:'',
         activitytypecode:'',
         employee:{
            firstname:"",
            lastname:""
         }
      },
      }
    },


    methods:{     
      DiscardChanges() {  
         if(this.$route.name === 'contact-details' || this.$route.name === 'patient-contact-add'){
            this.$router.push({
               name: "patient-contacts",
               params: { id: this.$route.params.id },
            });
         }
         else if(this.$route.name === 'appointments-contact-add' || this.$route.name === 'appointments-contact-details'){
            this.$router.push({
               name: "appointments-contacts",
               params: { id: this.$route.params.id, actId: this.$route.params.actId },
            });
         }
         else{
            this.$router.push({
                  name: "Clinical-cont",
                  params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
            });
         }        
      },
       ChangeDropDownType(value){
         this.Contact.contactType = value ? value.code : '';         
      },
      ChangeDropDownFile(value){
         this.Contact.releaseOnFile = value ? value.value : '';
         if(value && value.code !== 'Y'){
            this.Contact.endDateforActiveRelease = null;
            this.Contact.portalAccess = "";
            this.Contact.portalNotifications = "";
            this.Contact.appointmentNotifications = "";
         }
      },
      ChangeDropDownPhoneCalls(value){
         this.Contact.mayHavePhoneCalls = value ? value.value : '';
      },
      ChangeDropDownSupervisedCalls(value){
         this.Contact.supervisedPhoneCalls = value ? value.value : '';
      },
      ChangeDropDownVisitationPerm(value){
         this.Contact.visitationPerm = value ? value.value : '';
         if (this.Contact.visitationPerm === '0') {
            this.Contact.mayVisit = '0';
            this.Contact.unsupVisitsOnGrounds = '0';
            this.Contact.unsupVisitsOffGrounds = '0';
            this.Contact.needProbApprForOffGrounds = '0';
         }
      },
      ChangeDropDownMayVisit(value){
         this.Contact.mayVisit = value ? value.value : '';
      },
      ChangeDropDownVisitsOnGrounds(value){
         this.Contact.unsupVisitsOnGrounds = value ? value.value : '';
      },
      ChangeDropDownVisitsOffGrounds(value){
         this.Contact.unsupVisitsOffGrounds = value ? value.value : '';
      },
      ChangeDropDownApprForOffGrounds (value){
         this.Contact.needProbApprForOffGrounds = value ? value.value : '';
      },
      ChangeDropDownGrantPortalAccess(item){
         this.Contact.portalAccess = item ? item.value : "";
      },
      ChangeDropDownPortalNotifications(item){
         this.Contact.portalNotifications = item ? item.value : "";
      },
      ChangeDropDownAppointmentNotifications(item){
         this.Contact.appointmentNotifications = item ? item.value : "";
      },
      ChangeDateActiveOnRelease(){
        /* if(date == null || date == ''){
            this.Contact.portalAccess = "";
            this.Contact.portalNotifications = "";
            this.Contact.appointmentNotifications = "";
         }
         this.Contact.endDateforActiveRelease = date;*/
      },
      OnActiveReleaseBlur(){
        /* console.log(value);
         if(value == null || value == ''){
            this.Contact.portalAccess = "";
            this.Contact.portalNotifications = "";
            this.Contact.appointmentNotifications = "";
         }*/
      },
      onContextContactDOB(ctx) {         
            this.formatted = ctx.selectedFormatted       
            this.selected = ctx.selectedYMD        

            if(ctx.selectedFormatted!="No date selected"){
                this.Contact.dob= ctx.selectedFormatted  ;            
            }  
        },

      async UsePatientAddress(event){
          this.checkedUsePatientAddress = event;
          
          if(this.checkedUsePatientAddress){
            if(this.$route.name.includes('Clinical')){     
            var infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0){
               this.patientAddress = infoList[0];
               this.Contact.address1 = this.patientAddress.address1;
               this.Contact.address2 = this.patientAddress.address2;
               this.Contact.zip =  this.patientAddress.zip;
               this.Contact.city = this.patientAddress.city;        
               this.Contact.state = this.patientAddress.state;
               this.Contact.country = this.patientAddress.country;
              
            }
        }else{
            this.Contact.address1 = this.patient.address1;
            this.Contact.address2 = this.patient.address2;
            this.Contact.zip =  this.patient.zip;
            this.Contact.city = this.patient.city;        
            this.Contact.state = this.patient.state;
            this.Contact.country = this.patient.country;
        }
            
          }else{
             this.Contact.address1 = "";
             this.Contact.address2 = "";
             this.Contact.zip = "";
             this.Contact.city = "";
             this.Contact.state = "";
             this.Contact.country = "";
          }
       },

       resetModal() {
         if (this.Mode== "Add"){
            this.checkedUsePatientAddress = false;
         }
      },
      
      formatterDate(value){
         return Utils.formatterDate(value);
      },

      async GetPatientByCode(){
         this.infoList = await this.getInfoTitle(this.$route.params.id);
         if(this.infoList.length > 0){
            this.PatientTitle.programName = '';
            this.PatientTitle.admitDate = '';
            this.PatientTitle.dischargeDate = '';
            this.PatientTitle.age = this.infoList[0].age;
            this.PatientTitle.ageFormatted = this.infoList[0].ageFormatted;
            if(this.infoList[0].dob){
               this.PatientTitle.dob = Utils.formatterDateToString(this.infoList[0].dob);
            }   
            this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
            this.patient = this.infoList[0];
         }
    },

      getContactById(){
         if (this.ContactId) {
         ContactService.get( this.ContactId )
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.Contact = response.data;
                 this.Contact.dob = this.formatterDate(this.Contact.dob); 
                 this.Contact.endDateforActiveRelease = this.formatterDate(this.Contact.endDateforActiveRelease); 
               }
            })
            .catch((error) => {
               if (!error.response) {
                  // network error
                  this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
               }
               });
         }
      },

      AddContact(){
         this.Contact.patientCode = this.$route.params.id; 
         ContactService.addContact(this.Contact)
         .then((response) => {           
            if(response.status == '200'){
               this.$emit('load', true);
               this.pendingChanges = false;   
               if(this.ClickSave){             
               if(this.$route.name === 'patient-contact-add'){
                  this.$router.push({
                     name: "patient-contacts",
                     params: { id: this.$route.params.id },
                  });
               }
               else if(this.$route.name === 'appointments-contact-add'){
                     this.$router.push({
                        name: "appointments-contacts",
                        params: { id: this.$route.params.id, actId: this.$route.params.actId },
                     });
               }
               else{
                  this.$router.push({
                     name: "Clinical-cont",
                     params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                  });
               }    
            }               
               setTimeout(() => 
                  this.$bvToast.toast(this.$t('Contact.ContactSavedSuccessfully'), {
                     title: this.$t('Contact.SavingContact'),
                     autoHideDelay: 5000,
                     variant: "success"
                  })
                  , 50); 
                }
            })
         .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            if (!error.response) {
               this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.NotAbleToInsert'),"error");
            } else if (error.response.status === 400){
               this.showMessage(this.$tc('Contact.PatientContact',2),error.response.data.errorMessage,"error");
            } else if(error.response.status === 500 ){
               this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.ThereWereSomeError'),"error");
            } else {
               this.showMessage(this.$tc('Contact.PatientContact',2),error.response.data.errorMessage,"error");
            }
         });
         this.checkedUsePatientAddress = false;
      },

      UpdateContact(){
         this.Contact.patientCode = this.$route.params.id; 
         ContactService.update(this.ContactId, this.Contact)
        .then((response) => {
            //  this.$router.push({ name: "patient-contacts", params: { id: this.Contact.patientCode } })             
            if(response.status == '200'){
               this.$emit('load', true);
               this.pendingChanges = false;   
               if(this.ClickSave){             
               if(this.$route.name === 'contact-details'){
                     this.$router.push({
                        name: "patient-contacts",
                        params: { id: this.$route.params.id },
                     });
                  }
               else if(this.$route.name === 'appointments-contact-details'){
                     this.$router.push({
                        name: "appointments-contacts",
                        params: { id: this.$route.params.id, actId: this.$route.params.actId },
                     });
                  }  
               else{
                  this.$router.push({
                     name: "Clinical-cont",
                     params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                  });
               }       
            }             
                  setTimeout(() => 
                     this.$bvToast.toast(this.$t('Contact.ContactSavedSuccessfully'), {
                        title: this.$t('Contact.SavingContact'),
                        autoHideDelay: 5000,
                        variant: "success"
                     })
                     , 50); 
                  
                }
            })
        .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            if (!error.response) {
               this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.NotAbleToUpdate'),"error");
            } else if (error.response.status === 400){
               this.showMessage(this.$tc('Contact.PatientContact',2),error.response.data.errorMessage,"error");
            } else if(error.response.status === 500 ){
               this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.ThereWereSomeError'),"error");
            } else {
               this.showMessage(this.$tc('Contact.PatientContact',2),error.response.data.errorMessage,"error");
            }
        });
      },

    async getContactType(){
      await GlobalService.getContactType()
            .then((response) => {
               if(response.status == '200' && response.data){
                  this.ContactTypeList = response.data;
               }
                this.Loading = false;
                this.$emit('load', this.Loading);                
            })
            .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
               }
            });
    },

    ClickSaveCall(){
      this.ClickSave = true;
      this.SaveOrEdit();
    },

      SaveOrEdit(){
        if(!this.isReadOnly) {
           if(this.validate_entries()){
            if(this.validate_required()) {

               elemSaveBtn.setAttribute("disabled","disabled");
               this.$emit('load', true);
               this.pendingChanges = false         
               if (this.Mode) { 
                  this.UpdateContact()           
               }else{            
                  this.AddContact()
               }
            } else {
               this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.CheckRedLabeledFields'),"errorAutoHide");
            }
         } else {
            this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.InvalidFieldValuesDetected'),"errorAutoHide");
         }
        }
      },

      contactZipBlur: function (value) {
         if (this.contactZipChanged) {
            this.$emit('load', true); 
            GlobalService.getCityAndStateByZipCode(value)
               .then((response) => {
                  if(response.status == '200' && response.data){
                     var obj = response.data
                     if(obj.city) {
                           this.Contact.city = obj.city;
                           this.Contact.state = obj.state;
                     }
                     this.patientZipChanged = false;
                  }  
                  this.$emit('load', false);                          
               })
               .catch((error) => {
                  this.$emit('load', false); 
                  this.Contact.city = '';
                  this.Contact.state = '';
               if(error.response.status !== 404 && error.response.status !== 500){
                     if(error.response) {
                     this.showMessage(this.$t('Contact.GetCityandState'),error,"error");
                  }
               }
                  
               });  
            }
      },

        contactZipChange: function() {
           this.contactZipChanged = true;
        },

        validate_required() {
         this.isFirstNameValid = this.Contact.firstName && this.Contact.firstName.trim() != "" && this.Contact.firstName.length > 0 ? true : false;
         this.isLastNameValid = this.Contact.lastName && this.Contact.lastName.trim() != "" && this.Contact.lastName.length > 0 ? true : false;
         return this.isFirstNameValid && this.isLastNameValid
      },

      validate_entries() {
         if(this.Contact.dob) {
            this.isDOBValid = Utils.validateBirthDate(this.Contact.dob) ? true : false;
         }
         this.emailValid = (this.Contact.email == "" || this.Contact.email == null)? true : (this.reg.test(this.Contact.email)) ? true : false;
         if( this.emailValid && this.isDOBValid) {
               return true;
            } else {
               return false;   
            }
      },
      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
      ,
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      },
      PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);	
      if(this.$route.name.includes('Clinical')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = this.RegistrationInfo.dob;
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-contact-add') || this.$route.name.includes('contact-details')
         || this.$route.name.includes('appointments-contact-add') || this.$route.name.includes('appointments-contact-details')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          if(this.infoList.length > 0){
            //this.PatientTitle.dob = this.infoList[0].dob;             
            this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
          }          
      }
    },
    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }
    },

    async mounted(){
      this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical') ? EnumService.ClinicalItems : this.$route.name.includes('appointments-contacts') ? EnumService.AppointmentItems : EnumService.PatientItems;
      this.OptionsRightMenu.visible = true;
      this.$emit("getVisibleMenu", this.OptionsRightMenu);

      this.isReadOnly = await this.$store.state.isPatientContactsEHRReadOnly;
       this.$emit('load', this.Loading);
       await this.getContactType();      
       this.OptionsRightMenu.Mode = this.Mode;       
       this.ContactId =  this.$route.params.contactid;
      
      
    if(this.$route.name.includes('patient-contact-add') || this.$route.name.includes('contact-details')
      || this.$route.name.includes('appointments-contact-add') || this.$route.name.includes('appointments-contact-details')){  
        this.GetPatientByCode();
    }
    
    if (this.$route.name.includes('appointments-contact-add') || this.$route.name.includes('appointments-contact-details'))
      await this.getCurrentAppointment()

    if(this.$route.name.includes('Clinical')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  Utils.formatterDateToString(this.RegistrationInfo.dob);

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;

    }

    this.PopulatePatientTitle();
       
       this.getContactById();
       setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_contact');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );
    },

    created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
          this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  } 
</script>


