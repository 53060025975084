<template>
 <div id="ContainerAdmissionList">
  <div class="row">
      <div class="col-12">
        <h4 class="page-title text-truncate"> {{ $t('AdmissionList.PatientAdmissionTitle') }}  - 
        <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
        <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
        <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
        <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
        <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
        <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
        </h4>
        <div class="row">
          <div class="col-sm-12 col-md-12 mb-12">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="parameter.SearchCriteria"   
                  @keyup.enter="SearchAdmission">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinput'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="SearchAdmission">
                      </MdButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>              
        </div>
      </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchElements')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fields"
              v-bind:items="items"
              v-bind:totalRows="totalRows"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:pageOptions="pageOptions"
              :onRowSelected="onRowSelected"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction"
              :viewIconEvent="GotoDischargeTransfer">
            </MdTable>
          </div>
        </div>
      </div>
    </div>
 </div>
</template>

<script>
import EnumService from '@/services/enum.service' 
import ClinicalService from '@/services/clinical.service'
import Utils from '@/common/utils'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'ScheduleDBAdmission',
 mixins: [ShowMessage, DBFunctions],
  data(){
      return {
        FieldsDateArr:['dateFrom','dateTo'],
        AdmissionId:'',
        isReadOnly:false,
        Loading:true,
        Mode:'',     
        parameter:{
        SearchCriteria:'',
        PageNumber : 1,
        PageSize:0 ,
         
      },
       OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
        },
      PatientTitle:{      
        programName:'',
        fullName:'',
        admitDate:'',
        dischargeDate:'',
        dob:'',
        activitytypename:'',
        startdatetime:'',
        ageFormatted:''
     },  
      ShowMessageIsEmpty:false,
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],         
      fields: [
        { key: 'programName', sortable: true, label: this.$tc('Shared.Program'), class: 'text-rigth' },
        { key: 'dateFrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth' },
        { key: 'dateTo', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth' },   
        { key: 'transactionType', sortable: true, label: this.$t('AdmissionList.TransactionType'), class: 'text-rigth' },       
        { key: 'programCode', sortable: true, label: this.$t('Shared.ProgramCode'), class: 'text-center hidden' },
        { key: 'status', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth' },
        { key: 'recordId', sortable: true, label: this.$t('AdmissionList.RecordId'), class: 'text-center hidden' } ,
        { key: 'chartNo', sortable: true, label: this.$t('AdmissionList.ChartNo'), class: 'text-center' },       
        { key: 'isInpatient', sortable: true, label: this.$t('Shared.Inpatient'), class: 'text-center' },
        { key: 'buildingName', sortable: true, label: this.$t('Shared.Building'), class: 'text-center' },
        { key: 'roomName', sortable: true, label: this.$t('Shared.Room'), class: 'text-center' },              
      ],
      currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee:{
          firstname:"",
              lastname:""
          }
      },
    }
  },
  methods:{

    GotoDischargeTransfer(item) {
      if (this.$route.name.includes('appointments-discharge-admission')) {
        this.$router.push({ name: "appointments-discharge", 
        params: { 
          id: this.$route.params.id,
          recordid: item.recordId, 
          actId: this.$route.params.actId
        } 
      });
      } else {
        this.$router.push({ name: "appointments-transfer", 
        params: { 
          id: this.$route.params.id,
          recordid: item.recordId, 
          actId: this.$route.params.actId
        } 
      });
      } 
      
    },

    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = Utils.formatterDateToString(infoList[0].dob);
          }   
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
      }
    },

    SearchAdmission(){
      this.Loading = true;
      this.$emit('load', this.Loading)        
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.$route.params.id;
       ClinicalService.getAllByPatientCode(this.parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data;
                       if(response.data.length > 0){                        
                        this.items.forEach(function(element) {
                          if (element.dob)	
                              element.dob = Utils.formatterDateToString(element.dob);  
                          if (element.dateFrom)	
                              element.dateFrom = Utils.formatterDateToString(element.dateFrom);        
                          if (element.dateTo)
                              element.dateTo =  Utils.formatterDateToString(element.dateTo);        
                        });
                        let headerString = response.headers["x-pagination"];
                        this.Header = JSON.parse(headerString);
                        this.totalRows = this.Header.TotalCount;
                       }
                     }  
                      this.Loading = false;
                      this.$emit('load', this.Loading);
                })
                .catch((error) => {
                    this.Loading = false;
                    this.$emit('load', this.Loading);
                    if (!error.response) {
                      this.showMessage(this.$t('AdmissionList.ClinicalProgramFilter'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                      this.showMessage(this.$t('AdmissionList.ClinicalProgramFilter'),error,"error");
                    }
                }); 
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.SearchAdmission();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.SearchAdmission();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }

  },

 async mounted() {
    this.OptionsRightMenu.visible = true;    
    this.OptionsRightMenu.btnvisible = true;
    this.OptionsRightMenu.itemdisabled = false;
    this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);

    this.GetPatientByCode();
    await this.getCurrentAppointment()
    
    this.isReadOnly = true; 
    this.SearchAdmission();
  },

  destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
  }
   
};
</script>


