<template>
    <div>
        <a :class="{ 'isDisabled': itemdisabled, rmActiveItem : active_el == it.value  }" 
        v-for="(it,index) in this.searchInMenuItems(this.ItemsListAuthorized,patient)" :key="index" @click="triggerClick(it.value,it.text,it.href, $event)">        
                 {{it.text}}    
        </a>        
    </div>   
</template>

<script>
//import Enum from '@/services/enum.service'
import ShowMessage from '@/components/messages/ShowMessage.js'


import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'ItemRightBarCNContainer',
  mixins: [ShowMessage, DBFunctions],
 data(){
    return{
        ItemsList:[],      
        Class:'',       
        itemdisabled : false,
        Appointment:{},
        CheckInsAppt:{
            timeCheckIn:"",
        },
      
        PatientCode : '',
        patient: '',
        ItemsListAuthorized:[
            {text:"Christina Hodupp",value:0,href:""},
            {text:"Harold Smith",value:1,href:""},
            {text:"Cordell Foster",value:2,href:""},
            {text:"Sara Coffin",value:3,href:""},
            {text:"Noah Timmons",value:4,href:""},
            {text:"Izabelle Bunch",value:5,href:""},
            {text:"Thomas Pearish",value:6,href:""},
            {text:"Lillian Englehart",value:7,href:""},
            {text:"Haydin Girlder",value:8,href:""}
        ],
        parameter: {
          UserId: "",
        },
        active_el:'',
        message:this.$t('Layout.displayedScreen')
    }
  },
 
  methods: {  
    triggerClick(){

    }
},

created(){
    EventBus.$on("onCNContainerSearchPatient", function (value) {
      this.patient = value
	}.bind(this));
}, 



 mounted() {
   
 },
 destroyed(){
	EventBus.$off('onCNContainerSearchPatient');
		
}
}
    

</script>

<style>
.isDisabled {
  cursor: not-allowed!important;
  opacity: 0.5!important;
}
.isDisabled > a {
  color: currentColor!important;
  display: inline-block!important;  /* For IE11/ MS Edge bug */
  pointer-events: none!important;
  text-decoration: none!important;
}
.rmActiveItem {
  background-position: -230px !important;
  color: #FFF !important;
}
</style>


