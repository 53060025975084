<template>
    <div id="ContainerRole" class="row page-height">
      <div class="col-12">
          <h4 class="page-title text-truncate"> {{$tc('userRoleDetails.rSetup',2)}}</h4>
          <b-row>
            <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('userRoleDetails.rName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'description'"
                     v-bind:isRequired="true"
                     v-bind:Id="'input_role_name'"
                     v-bind:isValid="isRoleIdValid"
                    v-model="Rol.description"
                    v-bind:MaxLengthProp="100"
                     >                                     
               </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" sm="12">
               <MdTitle v-bind:Text="$t('modalProviderSelector.Provider')" v-bind:isSubtitle="true" />
              <DxTreeList
                  :ref="treeListProRefKey"
                  :data-source="this.provDataSource"
                  :show-borders="true"
                  :column-auto-width="true"
                  :word-wrap-enabled="true"
                  key-expr="itemId"
                  @saving="SaveOrEdit($event)"
                  class="column-view-check"
                >
                  <DxEditing :allow-updating="true" mode="batch"/>
                  <DxSearchPanel :visible="true" :width="250" :placeholder="$t('Shared.Search')"/>
                  <DxSorting mode="none"/> <!-- or "multiple" | "none" -->
                  <DxToolbar>
                    <DxItem name="searchPanel" :width="250" />
                  </DxToolbar>
                 
                  <DxColumn
                    width="30px"
                    data-field="roleCanView"
                    data-type="boolean"
                    headerCellTemplate ="headerCellTemplateAllP"
                    css-class="headercell-view-padding"
                  />
                  <template #headerCellTemplateAllP="{ data }">
                    <DxCheckBox 
                      :value="false"
                      text=""
                      iconSize="16px"
                      @value-changed="onValueChangedCheckP($event, data)"/>
                  </template>
                  <DxColumn
                    data-field="itemName"
                    :caption="$t('modalProviderSelector.Provider')"
                    :read-only="true"
                    :allow-editing="false"
                    css-class="cell-provider-align"
                />
              </DxTreeList>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" sm="12" class="my-3">
              <div>
                <MdButton
                  v-bind:Label="$t('Shared.Save')"
                  v-bind:Name="'btnSaveProvider'"
                  v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"
                  v-on:click="treeListPro.saveEditData()"/>   
              </div>
              <div>
                <MdButton
                  v-bind:Label="$t('Shared.BtnCancel')"
                  v-bind:Name="'btnCancelProv'"
                  v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"
                  v-on:click="treeListPro.cancelEditData()"/>   
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" sm="12">
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="collapseBTN" :disabled="isDisabled" v-on:click="getDataSource($event)">{{$t('userRoleDetails.Menu')}}</b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
      
                      <DxTreeList
                        id="treeMenu"
                        :ref="treeListRefKey"
                        :data-source="this.dataSource"
                        :show-borders="true"
                        :column-auto-width="true"
                        :word-wrap-enabled="true"
                        key-expr="itemId"
                        parent-id-expr="parentId"
                        @saving="SaveOrEditPerm($event)"
                        @editor-preparing="onEditorPreparingPerm"
                        @cell-prepared="cellPrepared">

                        <DxSearchPanel :visible="true" :width="250" :placeholder="$t('Shared.Search')"/>
                        <DxSorting mode="none"/> <!-- or "multiple" | "none" -->
                        <DxToolbar>
                          <DxItem
                            location="before"
                            template="groupingTemplate"
                          />
                          <DxItem name="searchPanel" :width="250" />
                        </DxToolbar>
                        <template #groupingTemplate >
                          <MdSelect v-if="provtypes.length > 1"
                              v-bind:Label="$t('userRoleDetails.pTypes')"
                              v-bind:Options="provtypes"
                              v-bind:FieldDescription="'types'"
                              v-bind:FieldCode="'types'"
                              :Input="onChangeDataSource"
                              v-bind:Model="provtypes[0]"
                          />
                        </template>
                        <DxEditing :allow-updating="true" mode="batch"/>
                                     
                        <DxColumn
                          :width="300"
                          data-field="itemName"
                          :caption="$t('userRoleDetails.accTo')"
                          :allow-editing="false"
                        />

                        <DxColumn
                          data-field="roleAllowAll"
                          :set-cell-value="setCellValue"
                          headerCellTemplate ="headerCellTemplateAll"
                         
                        />
                        <template #headerCellTemplateAll="{ data }">
                          <DxCheckBox 
                            :value="false"
                            :text="$t('userRoleDetails.aAll')"
                            iconSize="16px"
                            @value-changed="onValueChangedCheck($event, data)"/>
                        </template>

                        <DxColumn
                          data-field="roleCanView"
                          headerCellTemplate ="headerCellTemplate"
                          />
                        <template #headerCellTemplate="{ data }">
                          <DxCheckBox 
                            :value="false"
                            :text="$t('Shared.View')"
                            iconSize="16px"
                            @value-changed="onValueChangedCheck($event, data)"/>
                        </template>

                        <DxColumn
                          data-field="roleCanAdd"
                          data-type="boolean"
                          headerCellTemplate ="headerCellTemplateAdd"
                          />
                        <template #headerCellTemplateAdd="{ data }">
                          <DxCheckBox 
                            :value="false"
                            :text="$t('Shared.Add')"
                            iconSize="16px"
                            @value-changed="onValueChangedCheck($event, data)"/>
                        </template>

                        <DxColumn
                          data-field="roleCanEdit"
                          data-type="boolean"
                          headerCellTemplate ="headerCellTemplateEdit"
                        />
                        <template #headerCellTemplateEdit="{ data }">
                          <DxCheckBox 
                            :value="false"
                            :text="$t('Shared.Edit')"
                            iconSize="16px"
                            @value-changed="onValueChangedCheck($event, data)"/>
                        </template>

                        <DxColumn
                          data-field="roleCanDelete"
                          data-type="boolean"
                          headerCellTemplate ="headerCellTemplateDel"
                        />
                        <template #headerCellTemplateDel="{ data }">
                          <DxCheckBox 
                            :value="false"
                            :text="$t('Shared.Delete')"
                            iconSize="16px"
                            @value-changed="onValueChangedCheck($event, data)"/>
                        </template>

                        <DxColumn :visible="false" data-field="hasAdd" data-type="boolean"/>
                        <DxColumn :visible="false" data-field="hasDelete" data-type="boolean"/>
                        <DxColumn :visible="false" data-field="hasEdit" data-type="boolean"/>
                      </DxTreeList>
                      <b-row>
                      <b-col md="12" sm="12" class="my-3">
                      <div>
                        <MdButton
                          v-bind:Label="$t('Shared.BtnSave')"
                          v-bind:Name="'btnSaveRole'"
                          v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"
                          v-on:click="treeList.saveEditData()"/>   
                      </div>
                      <div>
                        <MdButton
                          v-bind:Label="$t('Shared.BtnCancel')"
                          v-bind:Name="'btnCancelRole'"
                          v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"
                          v-on:click="treeList.cancelEditData()"/>   
                      </div>
                      </b-col>
                    </b-row>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
          <div class="space-actions"></div>
           
      </div>
      <div class="page-actions">
            <MdButton
                v-bind:Label="$tc('userRoleDetails.rList')"
                v-bind:Name="'btnrollist'"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="DiscardChanges"/>           
          </div>
    </div>
</template>

<script>
import UserTplService from '@/services/users.tpl.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
import {
  DxTreeList,
  DxColumn,
  DxEditing,
  DxToolbar,
  DxItem,
  DxSearchPanel,
  DxSorting   
} from 'devextreme-vue/tree-list';
import { DxCheckBox } from 'devextreme-vue/check-box';


const treeListRefKey = 'myTreeList';
const treeListProRefKey = 'myTreeListProv';

/* eslint-disable vue/no-unused-components */
export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isReadOnly && this.pendingChanges) {
      this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
            title: this.$t('userHeader.uSetup'),
            okTitle: this.$t('Shared.Save'),
            cancelTitle: this.$t('Shared.BtnDismiss'),
            footerClass: 'p-2',
            titleClass: 'modal-title modal-type-warning'
          })
            .then(value => {
              if(!value){
                this.checkExistFavorite('/app/roles');
                next();
              } else {
                this.SaveOrEdit();
                next();
              }
            })		
    } else {
      next()
    }
  },
  mixins: [ShowMessage,DBFunctions],
  components: {
    DxTreeList,
    DxColumn,
    DxSearchPanel,
    DxEditing,
    DxToolbar,
    DxItem,
    DxCheckBox,
    DxSorting 
  },
  data() {
    return {
      showProviderType: false, 
      dataSource:[],
      provDataSource:[],
      permission:[],
      Menu : this.$store.state.leftMenu,
      provtypes : [],
      checkBoxValue: false,
      Mode:this.$attrs.editMode, 
      treeListRefKey,
      treeListProRefKey,
     
      dataRolProvider: {
        providers:[]
      },
      dataRolPermision: {
        userRoleMenuItems:[],
        roleTemplateCode: ''
      },
      RolId:'',
      Rol:{
        description:""
      },
      isRoleIdValid:true,
      pendingChanges: false,
      disabled: true,
      triggerOnChange: false, 
    };
  
  },
  methods: {

    CanView(){
      return this.permission[0].roleCanView;
  },

    getProviders() {
      this.$emit('load', true); 
      UserTplService.getProviders(this.$route.params.id)
      .then((response) => { 
            if(response.data){
              this.provDataSource = response.data
              if (this.Mode) {
                  this.Rol.description = response.data.description;
                  this.provDataSource = response.data.providers;
                  this.RolId = response.data.code
                  this.disabled = false
              }
            }
            this.$emit('load', false);              
          })
          .catch((error) => {
            this.$emit('load', false); 
            if (error.response) {
              this.showMessage(this.$t('userHeader.uSetup'),error,"error");
            }
          });
    },

    DiscardChanges(){
        if(!this.pendingChanges)
          this.checkExistFavorite('/app/roles');
        this.$router.push({name: "roles"});
    },

    PrepareRolePovider(e){
      this.dataRolProvider.description = this.Rol.description;
      this.dataRolProvider.providers = e.component.getDataSource().store()._array;
    },

    SaveOrEdit(e){
      this.pendingChanges = false;
        if(!this.isReadOnly) {
            if (this.validate_required()) {     
              this.$emit('load', true); 
              this.PrepareRolePovider(e);      
            if (this.Mode) { 
              this.UpdateRolProvider();
            }else{  
                this.AddRolProvider();
            }
          } else {
           this.$emit('load', false);  
           this.showMessage(this.$tc('userRoleDetails.rSetup'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");           
          }
         
        } 
    },

    _rolError(e) {
      if (!e.response) {
          this.showMessage(this.$tc('userRoleDetails.rSetup'),this.$t('Msg.NotAbleToSave'),"error");
      }                      
      else if(e.response.status === 400 ){
        this.showMessage(this.$tc('userRoleDetails.rSetup'),e.response.data.errorMessage,"error");
      } else if(e.response.status === 500){
        this.showMessage(this.$tc('userRoleDetails.rSetup'),this.$t('Msg.ThereWereSomeError'),"error");
      } else{
          this.showMessage(this.$tc('userRoleDetails.rSetup'), e.response.data.errorMessage,"error");
      }
      this.$emit('load', false);
    },

    AddRolProvider(){
      UserTplService.add(this.dataRolProvider)
        .then((response) => { 
            if (response.status == '200') {   
              this.pendingChanges = false; 
              this.$emit('load', false);
              this.disabled = false;  
              this.RolId = response.data.code
              setTimeout(() => 
                  this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                    title: this.$t('Msg.Saving'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                  , 50); 
            }
            this.dataRolProvider.providers = []; 
            this.dataRolProvider.description = ''; 
        })
        .catch(error => { 
          this.dataRolProvider.providers = []; 
          this.dataRolProvider.description = ''; 
          this._rolError( error)
        },this);   
    },

    UpdateRolProvider(){
      UserTplService.update(this.$route.params.id,this.dataRolProvider)
        .then((response) => {      
            if (response.status == '200') {   
              this.pendingChanges = false; 
              this.$emit('load', false);
                setTimeout(() => 
                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                      title: this.$t('Msg.Saving'),
                      autoHideDelay: 5000,
                      variant: "success"
                    })
                    , 50); 
            }
            this.dataRolProvider.providers = []; 
            this.dataRolProvider.description = ''; 
        })
        .catch(error => {
          this.dataRolProvider.providers = []; 
          this.dataRolProvider.description = '';  
          this._rolError( error)
        },this);
    },

    onValueChangedCheckP(e, d) {
      let changes = this.treeListPro.option("editing.changes");
      let dataField =  d.column.dataField

      this.treeListPro.forEachNode((node) => {
        let curNode = changes.find((el) => {
          return node.key === el.key;
        });
        if (curNode) {
          curNode.data[dataField] = e.value;
        } else {
          changes.push({
            key: node.key,
            data: { [dataField] : e.value },
            type: "update",
          });
        }
      });
      this.treeListPro.option("editing.changes", changes);
    },

    // --------------Code for permmission treelist ----------------------
    getDataSource(e) {
      if (e.srcElement.ariaExpanded == 'false'){
        this.$emit('load', true); 
        UserTplService.getProvidersTypes()
        .then((response) => { 
              if(response.data && response.data.length > 0){
                this.provtypes = response.data;
                var paramType = this.dataRolPermision.moduleCode ? this.triggerOnChange == true : this.provtypes[0].types
                
                UserTplService.getDataSource(paramType,this.RolId)
                  .then((response) => { 
                      if(response.data && response.data.length > 0){
                        this.dataSource = response.data.map(items => {
                          return { ...items, itemName: this.$t('menu.'+items.itemName) }; // Retorna una copia del objeto con la descripción actualizada.
                        });
                      }
                      this.$emit('load', false);              
                    })
                    .catch((error) => {
                      this.$emit('load', false); 
                      if (error.response) {
                        this.showMessage(this.$tc('userRoleDetails.rSetup'),error,"error");
                      }
                    });
              }
            })
            .catch((error) => {
              if (error.response) {
                this.showMessage(this.$tc('userRoleDetails.rSetup'),error,"error");
              }
            });
      }
    },

    onChangeDataSource(e) {
      this.$emit('load', true); 
      UserTplService.getDataSource(e.types,this.RolId)
        .then((response) => { 
            if(response.data && response.data.length > 0){
              this.dataSource = response.data;
            }
            this.dataRolPermision.moduleCode = e.types;
            this.triggerOnChange = true;
            this.$emit('load', false);              
          })
          .catch((error) => {
            this.$emit('load', false); 
            if (error.response) {
              this.showMessage(this.$tc('userRoleDetails.rSetup'),error,"error");
            }
          });``
        
    },
    
    PrepareRolPermision(e){
      if(!this.triggerOnChange) 
        this.dataRolPermision.moduleCode = this.provtypes[0].types;

      this.dataRolPermision.roleTemplateCode = this.RolId;
      this.dataRolPermision.deviceCode = "WEB"
      this.dataRolPermision.userRoleMenuItems = e.component.getDataSource().store()._array;
    },

    SaveOrEditPerm(e){
      this.pendingChanges = false;
        if(!this.isReadOnly) {
            if (this.validate_required()) {     
              this.$emit('load', true); 
              this.PrepareRolPermision(e);
              this.AddRolPermision();
            }      
          } else {
           this.$emit('load', false); 
           this.showMessage(this.$tc('userRoleDetails.rSetup'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");           
          }
    },

    AddRolPermision(){
      UserTplService.addPermision(this.dataRolPermision)
        .then((response) => { 
            if (response.status == '200') {   
              this.pendingChanges = false; 
              this.$emit('load', false);
              setTimeout(() => 
                  this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                    title: this.$t('Msg.Saving'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                  , 50); 
            }
        })
        .catch(error => { 
          this._rolError(error);
        }, this);   
    },

    onEditorPreparingPerm(e) {
      if (e.parentType ==="dataRow") {
        if(e.dataField === "roleCanEdit" ){
            e.editorOptions.disabled = e.row.data && e.row.data.hasEdit === false;
        }
        else if(e.dataField === "roleCanAdd"){
            e.editorOptions.disabled = e.row.data && e.row.data.hasAdd === false;
        }
        else if (e.dataField === "roleCanDelete"){
            e.editorOptions.disabled = e.row.data && e.row.data.hasDelete === false;
        } 
        else if (e.dataField === "roleAllowAll" && e.row.data){
             e.editorOptions.disabled = true;
            if (e.row.data.hasDelete === true || e.row.data.hasAdd === true || e.row.data.hasEdit === true)
              e.editorOptions.disabled = false;
        }
      }
      if (e.dataField === "roleAllowAll") {
            e.editorOptions.onValueChanged = function (ev) {  
              if (e.row.data.hasAdd)
                e.component.cellValue(e.row.rowIndex, "roleCanAdd", ev.value);
              if (e.row.data.hasEdit)
                e.component.cellValue(e.row.rowIndex, "roleCanEdit", ev.value);
              if (e.row.data.hasDelete)
                e.component.cellValue(e.row.rowIndex, "roleCanDelete", ev.value);
              e.setValue(ev.value);
          }
        }    
    },
    cellPrepared (e) {  
      if(e.rowType === "data") {
        if (e.column.dataField == 'roleCanEdit' && e.data.hasEdit === false) {
          e.cellElement.querySelector(".dx-treelist-checkbox-size").style.display = 'none';
        } 
        else if(e.column.dataField == 'roleCanAdd' && e.data.hasAdd === false) {
          e.cellElement.querySelector(".dx-treelist-checkbox-size").style.display = 'none';
        }
        else if(e.column.dataField == 'roleCanDelete' && e.data.hasDelete === false) {
          e.cellElement.querySelector(".dx-treelist-checkbox-size").style.display = 'none';
        }
        else if (e.column.dataField === "roleAllowAll"){
            if (e.data.hasDelete === false || e.data.hasAdd === false || e.data.hasEdit === false)
              e.cellElement.querySelector(".dx-treelist-checkbox-size").style.display = 'none';
        }
      }
    },  
    setCellValue(rowData, value) {
      rowData.roleAllowAll = value;
      rowData.roleCanView = value;
    },


    onValueChangedCheck(e, d) {
      
      let changes = this.treeList.option("editing.changes");
      let dataField =  d.column.dataField

      this.treeList.forEachNode((node) => {
        var nodeData = node.data;
        let dataNodeHasAdd =  nodeData.hasAdd;
        let dataNodeHasEdit =  nodeData.hasEdit;
        let dataNodeHasDelete =  nodeData.hasDelete;
        let curNode = changes.find((el) => {
          return node.key === el.key;
        });
   
        if (curNode) {
          if (dataField === 'roleCanAdd' && curNode.data.hasAdd === true){
            curNode.data[dataField] = e.value;
          } else if (dataField === 'roleCanEdit' && curNode.data.hasEdit === true){
            curNode.data[dataField] = e.value;
          } else if (dataField === 'roleCanDelete'&& curNode.data.hasDelete === true){
            curNode.data[dataField] = e.value;
          } else if(dataField === 'roleAllowAll'){
              curNode.data[dataField] = e.value;
              curNode.data.roleCanAdd = (e.value ? curNode.data.hasAdd === true: false);
              curNode.data.roleCanEdit = (e.value ? curNode.data.hasEdit === true: false);
              curNode.data.roleCanDelete = (e.value ? curNode.data.hasDelete === true: false);
              curNode.data.roleCanView = e.value;
           
          } else if( dataField === 'roleCanView'){
            curNode.data[dataField] = e.value;
          }
        } else if((dataField === 'roleCanAdd' && node.data.hasAdd === true) ||
              (dataField === 'roleCanEdit' && node.data.hasEdit === true) ||
              (dataField === 'roleCanDelete' && node.data.hasDelete === true) ||
              ( dataField === 'roleCanView')
          ){
          changes.push({
            key: node.key,
            data: { 
              [dataField] : e.value ,
              hasAdd : dataNodeHasAdd,
              hasEdit : dataNodeHasEdit,
              hasDelete : dataNodeHasDelete,
            },
            type: "update",
          });
        } else if(
              dataField === 'roleAllowAll'
          ){
            changes.push({
              key: node.key,
              data: { 
                [dataField] : e.value ,
                hasAdd : dataNodeHasAdd,
                hasEdit : dataNodeHasEdit,
                hasDelete : dataNodeHasDelete,
                roleCanAdd : (e.value ? node.data.hasAdd === true: false),
                roleCanEdit : (e.value ? node.data.hasEdit === true: false),
                roleCanDelete : (e.value ? node.data.hasDelete === true: false),
                roleCanView : e.value,
              },
              type: "update",
            });
          } 
      });
      this.treeList.option("editing.changes", changes);
    },

    validate_required() {
      this.isRoleIdValid = this.Rol.description ? true : false;
      return this.isRoleIdValid;
    },

    beforeWindowUnload(e) {
      if (this.pendingChanges) {
        e.preventDefault()
        e.returnValue = ''	
      }
    },
  },
  mounted(){
     this.getProviders();
     
  },
  created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
      EventBus.$on("onChanged", function (e) {       
        this.pendingChanges = e.Changed
    }.bind(this));
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  computed: {
      treeList: function() {
          return this.$refs[treeListRefKey].instance;
      },
      treeListPro: function() {
        return this.$refs[treeListProRefKey].instance;
      },
      isDisabled: function(){
        return this.disabled;
      }
  }
};
</script>
<style>
#tasks {
  max-height: 540px;
}

#tasks .dx-treelist-rowsview td {
  vertical-align: middle;
}

.dx-data-row .cell-provider-align {
    vertical-align: middle !important;
    padding-left: 10px !important;
}

.dx-data-row .headercell-view-padding {
   padding: 5px 5px 7px 5px !important;
}

.column-view-check .dx-treelist-rowsview .dx-treelist-empty-space {
  width: 0px !important;
}

#accordion-1 {
   /*padding-top: 10px !important;*/
}
</style>



