<template>
 <div id="ContainerPatientAsam">
  <Asam v-bind:PatientCode="this.$route.params.id"/>
</div>

</template>

<script>
import Asam from "@/components/asam/Asam";
import EnumService from '@/services/enum.service'

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUseASAM;
            if(iUA) next()
            else next('/accessdenied');
        }) 
  },

  components: { Asam },
  data() {
    return {
      OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
      itemdisabled: false,
      allowAccess: false,
      isUseAsam: false
		}
    }
  },
 
methods:{
    MenuVisible(){
        this.OptionsRightMenu.visible = true; 
        this.OptionsRightMenu.btnvisible = true;      
        this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-asam') ? EnumService.AppointmentItems : EnumService.PatientItems;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.$emit('getBtnRightMenu', this.OptionsRightMenu); 
    },
},

  mounted() {
   this.MenuVisible();
  },
  
  updated(){
  this.MenuVisible();
  },
  destroyed(){
     this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
  }
}

</script>





