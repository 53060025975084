<template>	
    <div id="ModalHistoricalAppt">
    <b-modal :id="modalId" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
       <LoadHistoricalNotes v-bind:IsLoading="this.Load" /> 
     
 
       <b-row>
        <b-col md="3">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateFrom'"
            v-model="DateFrom"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateFromChange($event)"
            v-bind:Id="'input_date_from'">                                     
          </MdEdit>
        </b-col>
        <b-col md="3">
          <MdEdit 
            v-bind:Label="$t('Shared.dateTo')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateTo'"
            v-model="DateTo"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateToChange($event)"
            v-bind:Id="'input_date_to'">                                     
          </MdEdit>
        </b-col>
       </b-row>
       <br>

        <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:EventScheduler="true"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                v-bind:refTable="refTable"
                v-bind:FieldsDate="FieldsDateArr"               
                :onRowSelected="RowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction">
            </MdTable>
          </b-col>
      </b-row>     
    </b-modal>     
    </div>   
</template>

<script>
//import EnumService from '@/services/enum.service'  :viewIconEvent="OpenNote"
import Utils from '@/common/utils'
import AppointmentService from '@/services/appointment.service'
import DBFunctions from '@/common/DBFunctions.js'
import LoadHistoricalNotes from '@/components/template/loading/Loading'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'modalHistoricalAppointment',
  components: { 
    LoadHistoricalNotes
  },
    props: {     
     TitleWindow:String,
     ModalId: String,
   },
    mixins: [DBFunctions],
    data() {
		return {
        Load:false,
        modalId:this.ModalId,
        VisibleTest:true,   
        Header:{},
        DateFrom:null,
        DateTo:null,
        FieldsDateArr:[],
        pageOptions: [5, 10, 15 ],
        totalRows: 0,
        refTable: 'selectableTableHistoricalAppt',
        currentPage: 1,
        perPage: 10,
        filterOn: [],
       items: [],    
        fields: [            
            { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },
            { key: 'transDate', sortable: true, label: this.$t('MHisAppointment.TransDate'), class: 'text-rigth' }, 
            { key: 'serviceDate', sortable: true, label: this.$t('MHisAppointment.ServiceDate'), class: 'text-rigth' }, 
            { key: 'activityId',  sortable: true,label: 'activityId', class: 'hidden text-rigth' },
           // { key: 'therapistCode',  sortable: true,label: 'therapistCode', class: 'hidden text-rigth' },
            { key: 'admissionRecordId', sortable: true, label: 'admissionRecordId', class: 'hidden text-rigth' },
            { key: 'notesID', sortable: true, label: 'notesID', class: 'hidden text-rigth' }, 
            { key: 'headerID', sortable: true, label: 'headerID', class: 'hidden text-rigth' },            
            { key: 'noteStatus', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth' },             
           // { key: 'activityTypeName', sortable: true, label: 'Type Name', class: 'text-rigth' },              
            { key: 'apptType', sortable: true, label: this.$t('MHisAppointment.apptType'), class: 'text-rigth' }, 
           // { key: 'therapistName', sortable: true, label: 'Therapist Name', class: 'text-rigth' },            
        ],      
      ShowMessageIsEmpty:false,      
	}
	},

    methods:{

handleOk(bvModalEvt) {      
    bvModalEvt.preventDefault();      
    this.handleSubmit()
  },

  DateFromChange(){
    this.getNotes();
  },
  DateToChange(){
    this.getNotes();
},
  SetIcon(noteStatus){
		var obj = {
			icon:"",
			description:"",
		}
		if(noteStatus == 'New'){	
			obj.icon = "icon-doc mr-2";	
			obj.description = this.$t('Shared.IconDescN');
		}
		else if(noteStatus == 'Voided'){			
			obj.icon = "icon-ban mr-2";	
			obj.description = this.$t('Shared.IconDescVN');
		}
		else if(noteStatus == 'Pending Signature'){			
			obj.icon = "icon-note mr-2";	
			obj.description = this.$t('Shared.IconDescPS');							
		}
		else if(noteStatus == 'Pending Cosigner Signature'){
			obj.icon = "icon-user mr-2";	
			obj.description = this.$t('Shared.IconDescPCS');				
		}else{
			obj.icon = "icon-check mr-2";	
			obj.description = this.$t('Shared.IconDescF');										
		}
		return obj;
    },

  async getNotes() { 
    this.Load = true;
      var params = {
          /*PageSize: this.perPage,          
          PageNumber : 1, */
          PageSize: this.perPage,  
          selelected:{},        
          PageNumber : this.currentPage,      
          PatientCode: this.$route.params.id,   
          ActivityId: "",
          ActivityTypeCodeList:"",
          NoteStatus:0, 
          NoteIdList:"",
          TherapistCode:"",       
          DateFrom:this.DateFrom,
          DateTo:this.DateTo,
      };		
      await AppointmentService.getNotesByActId(params)
              .then((response) => {
                if(response.status == '200' && response.data){
                  this.items = response.data;                  
                  this.items.forEach(function(element) {                         

                    var obj = {
                    icon:"",
                    description:"",
                    }

                    if(element.transDate)	
                        element.transDate = Utils.formatterDateToString(element.transDate);
                    if (element.serviceDate)	
                        element.serviceDate = Utils.formatterDateToString(element.serviceDate); 
                    
                    element.Id = element.headerID;
					element.date = element.transDate;
					element.Time = element.noteTime;
					element.notesId = element.notesID;
                    if(element.noteStatus == 'New'){	
                        obj.icon = "icon-doc mr-2";	
                        obj.description = this.$t('Shared.IconDescN');
                    }
                    else if(element.noteStatus == 'Voided'){			
                        obj.icon = "icon-ban mr-2";	
                        obj.description = this.$t('Shared.IconDescVN');
                    }
                    else if(element.noteStatus == 'Pending Signature'){			
                        obj.icon = "icon-note mr-2";	
                        obj.description = this.$t('Shared.IconDescPS');							
                    }
                    else if(element.noteStatus == 'Pending Cosigner Signature'){
                        obj.icon = "icon-user mr-2";	
                        obj.description = this.$t('Shared.IconDescPCS');				
                    }else{
                        obj.icon = "icon-check mr-2";	
                        obj.description = this.$t('Shared.IconDescF');										
                    }
				
                    element.icon = obj.icon;
                    element.descriptionIcon = obj.description;                          
                    });

                    let headerString = response.headers["x-pagination"];
                    this.Header = JSON.parse(headerString);
                    this.totalRows = this.Header.TotalCount;

                }               
                this.ShowMessageIsEmpty = response.data.length === 0 ? true : false;                
                this.Load = false;
              })
              .catch(error => {
				this.Load = false;
                if (!error.response) {                
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),error,"error");
                }
              });
            },

    getObjectByNoteId(Id){
		for(var i = 0; i < this.items.length; i++){			
			if(this.items[i].notesID === Id){
				return this.items[i];
			}	
		}
		return null;
	},

    RowSelected(row) {  
        var obj = this.getObjectByNoteId(row[0].notesID);    
       this.selected = obj;       
    },

OpenNote(){

},
onFiltered() {
},

pageChanged(page){  
  this.currentPage = page;    
  this.getNotes();     
},

PerPageFunction(perpage){
    this.perPage = perpage;  
    this.currentPage = 1;    
    this.getNotes();
}, 

getAction(){
 
},  

emitEventCancel(){

}, 

handleSubmit() {

},

emitEventOK(){        
    EventBus.$emit('onSelectNoteinModalHistorical', this.selected);   
},
},

mounted() {  
     
    EventBus.$on("onOpenHistoricalAppt", function () { 
        this.getNotes();
     }.bind(this));
},

  created(){ 
    
  },
    beforeDestroy () {     
        EventBus.$off('onOpenHistoricalAppt');       
    },
	
}


</script>


