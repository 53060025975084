<template>
    <div>
     <b-modal size="md" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditObjectiveWiley" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
      <b-row>       
        <b-col md="6">
          <MdEdit 
            v-bind:Label="$t('WileyModalityObCN.aDate')" v-bind:Name="'AchievedDate'" v-bind:TypeProp="'text'"                 
            v-bind:Mask="'##/##/#### ##:## AA'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"            
            v-model="ObjectiveEditWiley.dateAchieve" v-bind:Id="'input_AchievedDateWiley_id'"/>   
        </b-col>
        <b-col md="6">
          <MdSelect 
            v-bind:Label="$t('Shared.Status')" v-bind:Options="StatusList" v-bind:FieldDescription="'description'" 
            v-bind:FieldCode="'code'" v-bind:Model="ObjectiveEditWiley.status" v-bind:Multiple="false" 
            :Input="ChangeDropDownStatus" v-bind:Name="'status_select'" :id="'status_Wileyselect_'" />
        </b-col>
    </b-row>
     </b-modal>  
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 //import moment from 'moment'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 //import TreatmentPlan from '@/services/treatmentplan.service'
 

 export default {
   name: 'modalEditWileyModalityObj',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String,
     ObjectiveEditWiley:Object
   },
   
   data(){
     return {
        goaldescriptionBlank:"",
        GoalsList:[],
        GoalsModel:[],
        StatusList: [
                { code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS') },
                { code: "MET", description: this.$t('modalEditGoal.MET') },
                { code: "UNMET", description: this.$t('modalEditGoal.UNMET') },
                { code: "REVISED", description: this.$t('modalEditGoal.REVISED') },
                { code: "EXTENDED", description: this.$t('modalEditGoal.EXTENDED') },
                { code: "DEFERRED", description: this.$t('modalEditGoal.DEFERRED') },
                { code: "REFERRED", description: this.$t('modalEditGoal.REFERRED') }
            ],
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
        //Do Action Data and this.$emit('RefreshGetTP');
        this.DoActionSave();
     },

     DoActionSave(){
        this.ObjectiveEditWiley.probCode = this.probCode;
        this.ObjectiveEditWiley.ProblemRecId = this.ProblemRecId;
        this.$emit('UpdateDataObjWiley',this.ObjectiveEditWiley);
        this.$emit('RefreshGetTP');
     },

     ChangeDropDownStatus(value){
      this.ObjectiveEditWiley.status = value ? value : {code:"",description:""};
     },

     
     

     OnChangeGoals(){

     },


     emitEventCancel(){
        this.$emit('ModalTPEditObjectiveWiley');
    },    
   },
 
   mounted(){
   
   },
 };
 </script>

