<template>
   <div id="ContainerLogNote">
      <div class="row page-height">
         <div class="col-12">

            <h4 v-if="this.Mode" class="page-title text-truncate">
               {{ $t('LogNote.EditLn') }} -
                  <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
                  <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
                  <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
                  <span v-if="this.RegistrationInfo.ageFormatted"> - {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                  <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                  <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                  <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                  <span v-else>- {{ $t('Shared.Active') }} </span>        
            </h4>
            <h4 v-if="!this.Mode" class="page-title text-truncate">
               {{ $t('LogNote.AddLn') }} -
                  <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
                  <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
                  <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
                  <span v-if="this.RegistrationInfo.ageFormatted"> - {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                  <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                  <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                  <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                  <span v-else>- {{ $t('Shared.Active') }} </span>      
            </h4>
            <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
               <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
               <div class="flex-grow-1">
                 <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
               </div>
             </div>
            <MdTitle v-bind:Text="this.NoteTypeName ? this.NoteTypeName : null" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-row>
               <b-col lg="6" md="6" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('Shared.Date')"
                     v-bind:Name="'date'"
                     v-bind:TypeProp="'text'" 
                     v-model="intakeDateModel"
                     v-bind:isReadOnly="true">                                     
                  </MdEdit>
               </b-col>   
               <b-col lg="6" md="6" sm="12">
                  <MdEdit 
                     v-bind:Label="$tc('Shared.Employee')"
                     v-bind:Name="'text'"
                     v-bind:TypeProp="'text'" 
                     v-model="employeeNameModel"
                     v-bind:isReadOnly="true">                                     
                  </MdEdit>
               </b-col>
            </b-row>
            <b-row>
               <b-col v-for="item in LogNote.fields" v-bind:key="item.code" md="12" lg="12">                                                
                  <MdSelect v-if="item.valueType == 'P'"
                     :Id="item.code"
                     v-bind:Label="item.description"
                     v-bind:Options="getOptions(item.pickListArray)"
                     v-bind:FieldDescription="'description'"
                     v-bind:FieldCode="'value'"
                     v-bind:Multiple="false" 
                     :ref="'Ref_'+item.code"
                     v-bind:isRequired="item.isRequired"   
                     v-bind:isValid="item.isValid"          
                     v-bind:AllowEmpty="false"
                     v-bind:Model="modelList[item.code]"
                     v-bind:Name="item.code"
                     v-bind:Tag="item.code"
                     v-bind:IsDisabled="((LogNote.employeeId !== EmployeeCode) && Mode)"
                     :Input="changedValue"
                  />
                  <MdEdit v-else
                     :Id="item.code"
                     v-bind:Label="item.description"
                     v-bind:Name="item.code"
                     :ref="'Ref_'+item.code"
                     v-bind:Model="modelList[item.code]"
                     v-bind:isValid="item.isValid"
                     v-bind:isRequired="item.isRequired"
                     v-bind:TypeProp="getControlType(item.valueType)" 
                     :value="item.intakeValueFormatted"                     
                     v-on:change="changedValue"
                     v-bind:Tag="item.code"
                     v-bind:isReadOnly="((LogNote.employeeId !== EmployeeCode) && Mode)">
                  </MdEdit>
                  <b-tooltip :target="item.code" triggers="hover" v-if="item.comments">
                        {{item.comments}}
                  </b-tooltip>  
               </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'Notes'"/>
            <b-row>
               <b-col md="12" lg="12">
                  <MdMemo 
                     v-bind:Label="$t('LogNote.LnList')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'notes'"
                     v-bind:Rows="'3'"
                     v-bind:MaxRows="'6'" 
                     v-model="CommentsModel"
                     v-bind:Tag="'Comments'"
                     v-bind:isReadOnly="((LogNote.employeeId !== EmployeeCode) && Mode)">                                     
                  </MdMemo>
               </b-col>
            </b-row>            
         </div>
      </div>

      <div class="space-actions"></div>
      <div class="page-actions">
         <MdButton
         v-bind:Label="$t('Shared.Back')"
         v-bind:Name="'btn_list_lognotes'"
         v-bind:ClassProp="'secondary mx-2 btn-login'"
         v-on:click="DiscardChanges"/>
   
      <MdButton
         v-bind:Label="$t('Shared.Save')"
         v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
         v-on:click="SaveOrEdit"
         v-bind:isReadOnly="this.isReadOnly"
         v-bind:Name="'btn_save_lognote'"/>
      </div>
   </div>
</template>

<script>

import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
import EnumService from '@/services/enum.service'
import LocalStorage from '@/services/storage.service';
import MedicalSignNotesService from "@/services/medicalsignnotes.service";
import Utils from '@/common/utils'
import MedicalSignsService from "@/services/medicalsigns.service";

let elemSaveBtn = '';
export default {
   beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUseLogNotes;
            if(iUA) next()
            else next('/accessdenied');
        }) 
   },

   beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('LogNote.Ln'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],  
   name: 'LogNoteDetails',
    data() {
      return { 
        Mode:this.$attrs.editMode, 
        EmployeeCode:"", 
        Loading:true,
        isReadOnly: false,
        pendingChanges: false,
        OptionsRightMenu:{
         visible:true,
         ItemsList:[],
         btnvisible:true,
         itemdisabled: false,
         PatientCode:''         
        },
        RegistrationInfo: {},
        CommentsModel: null,
        intakeDateModel: null,
        employeeNameModel: null,
        LogNote: {},
        NoteTypeName: '',
        style:{
         boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
         width: '100%',
         backgroundImage: '-webkit-linear-gradient(268deg, #FAFAFA, #FAFAFAEE)',
         borderRadius: '0 0 14px 14px',
         borderTop: '1px solid #EEE',
         padding: '10px',
         textAlign: 'right'     
         },
        showSignPassword: false,
        TypePassWord:'',
        UserIdSelected:'',
        UserId: LocalStorage.getUserId(),
        LogNotesParams:{
         SearchCriteria: '',
         PageNumber: 3,
         PageSize: 3,
         HeaderID: null,
         NotesID: null,
         IntakeDate: null        
        },
        modelList: {},
        valuesChanged: {} 
      }
    },


methods:{

      changedValue() {
       // if(item.isRequired)
      /* if(item !== null && item !== undefined){
         item.isValid = item.isRequired ? true : false;
       }*/
      },

      getOptions(pickListArray) {      
            let obj = {}
            let arrOptions = []           
            pickListArray.forEach(element => {
            obj = {}            
            obj['description'] = element
            obj['value'] = element
            arrOptions.push(obj)
         });            
            return arrOptions         
      
      },

      getControlType(value) {
         switch(value) {
            case 'A':
               return 'date'
            case 'T':
               return 'time'
            case 'D':
               return 'datetime-local'
            case 'F':
               return 'text'
            case 'S':
               return 'text'
            case 'I':
               return 'number'
            case 'M':
               return 'text'
            default:
               return 'text'
         }
      },

      DiscardChanges() {
         this.$router.push({
            name: "Clinical-log-note",
            params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
         });
      },

      async getLogNoteById(){
         this.LogNotesParams.PageNumber = this.currentPage;
         this.LogNotesParams.PageSize = this.perPage;
         this.LogNotesParams.HeaderID = this.$route.params.recordid;
         this.LogNotesParams.NotesID = this.$route.params.lognoteid;
         this.LogNotesParams.IntakeDate = this.$route.params.intakedate;

         await MedicalSignNotesService.getAll(this.LogNotesParams)
         .then((response) => {
            if (response.status == "200" && response.data && response.data.length > 0) {
               this.LogNote = response.data[0];         
               console.log(this.LogNote)   
               this.LogNote.intakeDate = Utils.formatterDateToString(this.LogNote.intakeDate) + ' ' + Utils.formatTime(this.LogNote.intakeDate)
               this.$emit("load", false);
            }
            this.$emit("load", false);
         })
         .catch((error) => {
            this.$emit("load", false);
            if (!error.response) {
               // network error
               this.showMessage(
               this.$t('LogNote.Ln'),
               this.$t('Msg.retrieveInfo'),
               "error"
               );
            } else {
               this.showMessage(this.$t('LogNote.Ln'), error, "error");
            }
         });
      },

      addNewField(item) {
         let objField = {}
         objField['code'] = item.code
         objField['comments'] = item.comments
         objField['description'] = item.description
         objField['isRequired'] = item.isRequired
         objField['isRequired'] = item.isRequired
         objField['valueType'] = item.valueType
         objField['pickListArray'] = item.pickListArray
         objField['isValid'] = true
         if (!this.LogNote.fields) {
            this.LogNote.fields = []
         }
         this.LogNote.fields.push(JSON.parse(JSON.stringify(objField)))
      },

      async getFields(){
         this.LogNotesParams.PageNumber = this.currentPage;
         this.LogNotesParams.PageSize = this.perPage;
         this.LogNotesParams.NotesID = this.$route.params.lognoteid;

         await MedicalSignsService.getAll(this.LogNotesParams)
         .then((response) => {
            if (response.status == "200" && response.data) {
               let rows = response.data
               rows.forEach(element => {
                  this.addNewField(element)
               });
               this.$emit("load", false);
            }
            this.$emit("load", false);
         })
         .catch((error) => {
            this.$emit("load", false);
            if (!error.response) {
               // network error
               this.showMessage(
               this.$t('LogNote.Ln'),
               this.$t('Msg.retrieveInfo') + error,
               "error"
               );
            } else {
               this.showMessage(this.$t('LogNotes.Ln'), error, "error");
            }
         });
      },

      populateModelList() {
         if (this.Mode) {
            this.CommentsModel = this.LogNote.comments
            this.intakeDateModel = this.LogNote.intakeDate
            this.employeeNameModel = this.LogNote.employeeName
         } else {
            this.CommentsModel = ''
            this.intakeDateModel = Utils.formatterDateTimeToString(Date())
            this.employeeNameModel = LocalStorage.getName()
         }
         this.LogNote.fields.forEach(element => {
            this.modelList[element.code] = element.intakeValue ? element.intakeValue : ''
         });
      },
      

      AddLogNote(){        
         this.$emit('load', true);
         let data = {}
         data.HeaderID = this.$route.params.recordid;
         data.Comments = this.CommentsModel;
         data.values = this.modelList;
         data.PatientCode = this.$route.params.id;
         data.NotesId = this.LogNotesParams.NotesID;
         MedicalSignNotesService.add(data)
         .then((response) => {           
             if(response.status == '200'){
                  this.$emit('load', false);
                  this.pendingChanges = false;                          
                  this.$router.push({
                      name: "Clinical-log-note",
                      params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
                  });     
                
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('LogNote.LnSavedSuccessfully'), {
                         title: this.$t('LogNote.SavingLn'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
            }
         })
         .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");           
            if (!error.response) {
               this.showMessage(this.$t('LogNote.Ln'),this.$t('Msg.NotAbleToUpdate'),"error");
            }     
            else if(error.response.status === 400 ){
               this.showMessage(this.$t('LogNote.Ln'),error.response.data.errorMessage,"error");
            }   
            else if(error.response.status === 500){
               this.showMessage(this.$t('LogNote.Ln'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
               this.showMessage(this.$t('LogNote.Ln'),error.response.data.errorMessage,"error");
            } 
         });
      
      
      },

      UpdateLogNote(){
         let data = {};      
         data.Comments = this.CommentsModel
         data.values = this.modelList
         MedicalSignNotesService.update(this.$route.params.recordid,this.$route.params.intakedate,data)
         .then((response) => {
             if(response.status == '200'){
                this.$emit('load', false);
                this.pendingChanges = false;
                if(this.$route.name === 'lognote-details'){
                   this.$router.push({
                      name: "Clinical-log-note",
                      params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
                   });     
                }                    
                   setTimeout(() => 
                      this.$bvToast.toast(this.$t('LogNote.LnSavedSuccessfully'), {
                          title: this.$t('LogNote.SavingLn'),
                           autoHideDelay: 5000,
                           variant: "success"
                      })
                   , 50); 
                 }
             })
         .catch((error) => {
             this.$emit('load', false);
             elemSaveBtn.removeAttribute("disabled");      
            if (!error.response) {
               this.showMessage(this.$t('LogNote.Ln'),this.$t('Msg.NotAbleToUpdate'),"error");
            }     
            else if(error.response.status === 400 ){
               this.showMessage(this.$t('LogNote.Ln'),error.response.data.errorMessage,"error");
            }   
            else if(error.response.status === 500){
               this.showMessage(this.$t('LogNote.Ln'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
               this.showMessage(this.$t('LogNote.Ln'),error.response.data.errorMessage,"error");
            } 
         });
      },

      SaveOrEdit(){
        if(!this.isReadOnly) {
         this.$emit('load', true);
         elemSaveBtn.setAttribute("disabled",'');
         this.pendingChanges = false;
         if(this.validate_entries()){
            if (this.Mode) { 
               this.UpdateLogNote()           
            }else {            
               this.AddLogNote()
            }
         }else{
            this.$emit('load', false);
            this.showMessage(this.$t('LogNote.Ln'),this.$t('Msg.CheckRequiredFields'),"errorAutoHide");
         }         
         
        }
      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
      },

      CountRequired(){
         let count = 0;
         this.LogNote.fields.forEach(element => {
            if(element.isRequired){
              count++;
            }
         });
         return count;
      },
      
      validate_entries() {        
         let countModel = 0;   
         let countRequired = this.CountRequired();
         this.LogNote.fields.forEach(element => {
            if(element.isRequired){
               if(this.modelList[element.code].trim() !== ""){
                  countModel++;                 
                  this.$refs['Ref_'+element.code][0].setValid(true);                               
               }else{
                  element.isValid = false;                 
                  this.$refs['Ref_'+element.code][0].setValid(false);                   
               }
            }
         });
         return countModel === countRequired;
      },
      
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      },
    },

    async mounted(){
      this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
      this.NoteTypeName = this.$route.params.noteTypeName,
      this.isReadOnly = await this.$store.state.isLogNotesEHRReadOnly;      
      this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
      if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);	
      if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  Utils.formatterDateToString(this.RegistrationInfo.dob);

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
      if (this.Mode) {
         this.$emit('load', true);
         await this.getLogNoteById()
         this.populateModelList() 
      } else {
         await this.getFields()
         this.populateModelList()
      }
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_lognote');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );
    },

    async created () {
      this.EmployeeCode = this.$store.state.employeeCode;    
       EventBus.$on("onChanged", function (e) { 
         if(e.Tag !== undefined){
            if (e.Tag !== 'Comments')
              this.modelList[`${e.Tag}`] = e.Value.value ? e.Value.value : e.Value 
            this.pendingChanges = e.Changed
            
         }   
         this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  } 
</script>


