import ApiService from '@/services/api.service'


const SchedulersService = {
    getAll(parameter) {          
      const requestData = {
        method: 'get',
        url: "/Schedulers/",
        headers: {
        },
        params: { 
          Code: parameter.Code,
          DateFrom: parameter.DateFrom,          
          DateTo : parameter.DateTo,
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    /*getApptCurrentDayByEmployee(parameter) {          
      const requestData = {
        method: 'get',
        url: "/Schedulers/",
        headers: {
        },
        params: { 
          EmployeeCodeList: parameter.EmployeeCodeList,
          IsAppointmentByDay: true, 
        }
      }     
      return ApiService.customRequest(requestData);         
    },*/

    /*getAppointmentsByPatient(parameter) {
      const requestData = {
        method: 'post',
        url: "/Schedulers/",
        headers: {
        },
        params: parameter
      }     
      return ApiService.customRequest(requestData);         
    },*/

    getApptCurrentDayByEmployee(data){
      return ApiService.post(`/Schedulers`,data)
    },

    getCreateMeetingOnDemand(data){
      return ApiService.post(`/Schedulers/CreateMeetingOnDemand`,data)
    },

    getAppointmentsByPatient(data){
      return ApiService.post(`/Schedulers`,data)
    },
  
    get( id) {
      return ApiService.get(`/Schedulers/${id}`);
    },

    getAppointmentEditorNotes(parameter) { 
      const requestData = {
        method: 'get',
        url: "/Schedulers/GetAppointmentEditorNotes/",
        headers: {
        },
        params: { 
          PatientCode: parameter.PatientCode,
        }
      }     
      return ApiService.customRequest(requestData);         
    },
    getAppointmentEditorPatientInfo(PatientCode, ActivityId) { 
      const requestData = {
        method: 'get',
        url: "/Schedulers/GetAppointmentEditorPatientInfo/",
        headers: {
        },
        params: { 
          PatientCode: PatientCode,
          ActivityId: ActivityId,
        }
      }     
      return ApiService.customRequest(requestData);
    },
    
    notifyPatientOnDemand(data){
      return ApiService.post(`/Schedulers/NotifyPatientOnDemand`,data)
    },

    SendNotificationMessage(data) {
      return ApiService.post(`/Schedulers/SendAppointmentsNotifications`,data)
    },

    schedulerUpdAdd(data) {
      return ApiService.post(`/Schedulers/crud`,data)
    },
  }
  
  export default SchedulersService;