<template>
    <div>
     <b-modal :width="500" :id="ModalId" :visible="true" scrollable centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalDosageTime" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
      
     <div v-for="(item,index) in marsTimesLocal" :key="index">
      <b-row>
          <b-col lg="6" md="6" sm="12">
              <MdEdit 
              v-bind:Label="$t('Medication.Dosage')+unitOfMeasure"
              v-bind:Name="'Days'"
              v-bind:TypeProp="'number'"                      
              v-bind:Min="'1'"
              v-bind:Max="'1000'"
              v-model.number="item.dosage"             
            />   
          </b-col> 
          <b-col md="6" lg="6" sm="12">
            <MdEdit 
                v-bind:Label="$t('Medication.Time')"
                v-bind:TypeProp="'time'"             
                v-bind:Name="'adminTime'"
                v-model="item.adminTime"                        
                v-on:change="ChangeTime">                                 
            </MdEdit>
        </b-col>   
        </b-row>
     </div>
       
     </b-modal>  
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import moment from "moment";
 

 export default {
   name: 'ModalDosageTime',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     marsTimes:Array,
     unitOfMeasure:String
   },
   
   data(){
     return {
      marsTimesLocal:this.marsTimes.slice()
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){

      //1899-12-30T21:00:00
     // adminTime:"1899-12-30T06:00:00"
//qty:1
//recNo:40148
//var date_result = moment(date).format('YYYY-MM-DDTHH:mm:ss');
      let arr = [];

      for(var i = 0; i < this.marsTimesLocal.length; i++){
        
        arr.push(
          {
            dosage:this.marsTimesLocal[i].dosage,           
            adminTime:moment("1899-12-30T"+this.marsTimesLocal[i].adminTime).format('YYYY-MM-DDTHH:mm:ss')
          })
      }

        this.$emit('RefreshDosageTime',arr);
     },

     ChangeTime(){

     },



     emitEventCancel(){
      this.$emit('OnModalDosageTimeCancel');
    },    
   },
 
  mounted(){
   
   },
 };
 </script>

