<template>
      <div v-bind:style="style" class="cursor-context-menu" oncontextmenu="return false;">
      <div :id="targetId" :data-label="controlCaption" class="textlabel">
        <div v-if="!ReadOnly && !NoteIsVoid && !IsChartReview">
          <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
        </div>
        <div v-bind:style="styleRadioGroup" class="cutescroll">
          <b-row :class="this.ControlMax > 4 ? 'mx-1' : ''">
        <div :class="'col-md-'+ColumnSize"  v-for="check in RadioString" :key="check">
          <b-form-radio-group
            v-b-tooltip.hover :title="Hint"
            v-model="radiovalue"      
          >
          <b-form-radio    
             :options="Options"
             :name="'radio-'+controlId"
              size="sm"
             :disabled="ReadOnly || NoteIsVoid || IsChartReview"
             :value="check"
             v-on:change="OnChange"            
             v-bind:style="radio"
              >
             <div v-bind:style="radio">{{check}}</div>
          </b-form-radio>
          </b-form-radio-group>
        </div>         
          </b-row>
        </div>
      </div>
      <MdGetLastAnswers v-if="showGetLastAnswers"
          v-bind:ShowPopup="showGetLastAnswers"
          @getCancel="onCancelGetLastAnswers($event)"
          @getOK="onOKGetLastAnswers($event)"
          v-bind:ControlId="controlId"
          v-bind:PatientCode="PatientCode"
          v-bind:TransDate="TransDate"
      /> 
  </div>
</template>

<script>
  import { EventBus } from '../../event-bus.js';
  import Vue from 'vue';
  import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

  Vue.use(ContextMenuPlugin);
export default {
  name: 'MdRadioGroupCN',
  mixins: [EventBus],
   props: {
     Label:     String,
     Options:   Array,
     isReadOnly:Boolean,
     value: String,
     TransDate: String,
     NoteId: String,
     id: [String, Number],
     PatientCode: String,
     IsCommonPart: Boolean 
   }, 
   data(){
     return{
      ControlMax:1,      
      ColumnSize:12,
      IsChartReview:this.$attrs.IsChartReview,
      NoteIsVoid:false,
      radiovalue:this.value,
      RadioString:this.$attrs.control.allValues,
      RadioList:[],
       radio:{
        fontSize:'',
      },
      controlId:String(this.$attrs.control.controlID),
      style: {
        position:'absolute',
        border: 'solid 1px #e0e0e0 !important',
        borderRadius: '5px',
        padding: '0',
        backgroundImage: 'linear-gradient(130deg, white, rgb(241, 253, 255))',
        top: this.$attrs.control.controlTop+'px',
        width:this.$attrs.control.controlWidth+'px',
        height:this.$attrs.control.controlHeight+'px',
        left:this.$attrs.control.controlLeft+'px',
        fontSize:'',
        fontWeight: '',
        fontFamily:'',
        color:'',
        overflow:'hidden',
        textDecorationLine:'',
        fontStyle:'',
      },
      styleRadioGroup: {
        overflowY:'auto',
        overflowX: 'hidden',
        height:this.$attrs.control.controlHeight - 30 +'px',
        marginLeft: '10px'
      },
      ReadOnly:this.$attrs.control.isReadOnly || this.isReadOnly ? true : false,
      isRequired: this.$attrs.control.isRequired,
      Hint: this.$attrs.control.controlHint,
      showGetLastAnswers: false,
      //PatientCode: this.$route.params.id,
      controlCaption: this.$attrs.control.controlCaption,
      menuItems:[],
      targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID
     }
   },
  methods:{
    OnChange(value){
      this.style.border = value.length == 0 && this.isRequired ? 'solid 1px #dc3545 !important': '1px solid rgb(224, 224, 224)'
      this.$emit('input', value);
      this.setChangedStatus()
    },

    ClearSelection() {
    this.radiovalue = undefined;
    this.$emit('input', "");
    this.setChangedStatus()
   },
    
    InitializeVariables(){
      if(this.$attrs.control.controlMaxLenght === null){
        this.ControlMax = 1;
        this.ColumnSize = 12;
      }else{
         this.ColumnSize = 12 / this.$attrs.control.controlMaxLenght;
         this.ControlMax = this.$attrs.control.controlMaxLenght;
      }
      this.RadioList = this.$attrs.control.allValues;

      
       if(this.$attrs.control.controlFontForHtml){
         this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
         var size = parseInt(this.controlFont[1]) + 1;
         this.style.fontFamily = this.controlFont[0];
         this.style.fontSize = size+'px !important';
         this.radio.fontSize = size+'px !important';
         this.style.color = this.controlFont[3];
         
        var Decorations = this.controlFont[2];
        this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
        this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
        this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
        this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       
       }


    },
    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onOKGetLastAnswers(value) {
      this.radiovalue = value;
      this.$emit('input', value);
      this.setChangedStatus()
      this.showGetLastAnswers = false
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    handlerClick(evt){
      if(!this.ReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state){       
        this.style.border = state ? 'solid 1px #dc3545 !important': '1px solid rgb(224, 224, 224)'
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
       if(args.item.id === 'clearselection') {
         this.ClearSelection()
       }
    },

    loadMenuItems() {
      this.menuItems = [
        {
          text: this.$t('Shared.ClearSelection'),
          id: "clearselection"
        },
      ]
      if (!this.IsCommonPart) {
        this.menuItems.unshift({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    }
  },

      created(){
   EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));
  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }

},
 
  mounted(){    
    this.InitializeVariables();
    this.loadMenuItems()
  }


};
</script>

<style>
.scroll{
	overflow: hidden !important;
}
</style>


