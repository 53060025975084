<template>
  <div id="ReportEmployeeCaseLoad">
    <MdTitle v-bind:Text="this.$t('employeeCaseLoad.EmpCaLoadRep')" />
      <b-row>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateFrom'"
            v-model="DateFrom"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateFromChange($event)"
            v-bind:isValid="this.isDateFromValid">                                     
          </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.dateTo')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateTo'"
            v-model="DateTo"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateToChange($event)"
            v-bind:isValid="this.isDateToValid">                                     
           </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$tc('Shared.Program',2)"
            v-bind:Options="ProgramList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"
            v-bind:Model="ProgramModel"  
            :Input="ChangeDropDownPrograms"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$tc('Shared.Therapist',2)"
            v-bind:Options="therapistList"
            v-bind:Model="Therapists"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"             
            :Input="ChangeTherapists"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
      </b-row> 
      <b-row>
        <!-- 
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="'Facilities'"
            v-bind:Options="facilitiesOptions"
            v-bind:FieldDescription="'company'"
            v-bind:FieldCode="'code'"
            v-bind:Model="FacilitiesModel"  
            v-bind:Multiple="true" 
            :Input="ChangeFacilities"/>
        </b-col>
        -->
        <b-col lg="3" md="3" sm="12">                      
          <MdSelect
            v-bind:Label="$t('Shared.Status')"
            v-bind:Options="PatientListStatus"
            v-bind:ModelArray="status"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"                      
            :Input="ChangeStatus"/>
        </b-col>
      </b-row>
      
      <div class="page-actions">
         <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="reportList"
          v-bind:Name="'btn_ReportList'"/> 
        <MdButton
          v-bind:Label="$t('Shared.Report')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="print"
          v-bind:Name="'btn_Print'"/>   
      </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('employeeCaseLoad.EmpCaLoadRep')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="employeeCaseLoad"
          :ReportName="'EmployeeCaseLoad'"
          :ReportTitle="$t('employeeCaseLoad.EmpCaLoadRep')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
  </div>
</template>

<script>
import Utils from '@/common/utils'
import EnumService from '@/services/enum.service' 
import PatientService from '@/services/patient.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import ProgramService from '@/services/programs.services'
import ClinicalService from '@/services/clinical.service'
import EmployeeService from '@/services/employee.service';
import { DxPopup } from 'devextreme-vue/popup';

export default {
  name: 'EmployeeCaseLoad',
  mixins: [ShowMessage,Utils],
  components: { DxPopup },
  data(){
    return{
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
        isReportVisible: false,
        facilitiesOptions: [],
        therapistList:[],
        Therapists: '',
        DateFrom: Utils.formatterDate(Date(), "LLLL"),
        DateTo: Utils.formatterDate(Date(), "LLLL"),
        ProgramModel:'',
        FacilitiesModel:'',
        ProgramList:[],
        TherapiesList:[],
        status:{
        selected:true,
        value:true,
        text:this.$t('Shared.Active')
      },
      PatientListStatus:EnumService.PatientListStatus,       
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: true,       
      },
        isDateFromValid: true,
        isDateToValid: true,
        reportParams: [],
        IncludeFiltersInReport: false,
        params: {
          "ProgramCode": [],
          "Therapists": [],
          "Facilities": [],
          "DateFrom": Utils.formatterDate(Date(), "LLLL"),
          "DateTo": Utils.formatterDate(Date(), "LLLL"),
          "Status": ''
        },
    }
  },
  methods:{

    customLabelNameCode ({ name, code }) {
      return `${name} – ${code}`
    },

    customLabelPatients ({ patientName, patientcode }) {
      return `${patientName} – ${patientcode}`
    },

    print() {
      this.isDateFromValid = true
      this.isDateToValid = true
      
      if (this.DateFrom && this.DateTo) {
        if (Utils.isDateFromAndDateTo(this.DateFrom,this.DateTo)) {
          this.onClickShowReport()
        } else {
          this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),this.$t('Msg.DateOutRange'),"error");
        }
      } else {
        this.isDateFromValid = this.DateFrom ? true: false
        this.isDateToValid = this.DateTo ? true : false
        this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),this.$t('Msg.CheckRedLabeledFields'),"error");
      }
    },

    onHiddenReport() {
      this.isReportVisible = false
      //this.closeWindow()
    },

    onShowing(e) {
      let contentElement = e.component.content();
      let titleElement = e.component._$title[0]
      contentElement.style.padding = '0px';
      titleElement.style.padding = '0px 20px'
    },

    getAllEmployee() {
      EmployeeService.getAllEmployees()
        .then((response) => { 
        if(response.data && response.data.length > 0){
          this.therapistList = response.data;
        }             
        })
        .catch((error) => {
        if (error.response) {
          this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),error,"error");
        }
        });
    },
    
    DateFromChange(value) {
      this.params.DateFrom = value
    },

    DateToChange(value) {
      this.params.DateTo = value
    },

    ChangeDropDownPatients(values) {
      this.params.PatientCode = this.getItemsByComma(values, 'patientcode')
    },

    ChangeDropDownPrograms(values) {
      this.params.ProgramCode = this.getItemsByComma(values, 'code')
    },

    ChangeTherapists(values) {
      this.params.Therapists = this.getItemsByComma(values, 'code')
    },

    ChangeDropDownTherapies(values) {
      this.params.TherapiesCode = this.getItemsByComma(values, 'code')
    },

    ChangeGroupBy(item) {
      this.params.GroupBy = item.value
    },

    ChangeSource(item) {
      this.params.Source = item.value
    },

    ChangeShow(item) {
      this.params.Show = item.value
    },

    ChangeStatus(status){
     // this.status.value = status ? status.value : '';
      this.status = { value:''};
      if(status){
           this.status.value = status.value;
           this.status.text = status.text;
           this.status.selected = status.selected;
      }
      this.items = [];
      this.totalRows = 0;
      this.getPatients();
    },

    ChangeFacilities(values) {
      this.params.Facilities = this.getItemsByComma(values, 'code')
      this.FacilitiesModel = this.getItemsByComma(values, 'code')
    },

    ChangeIncludeFiltersInReport(value) {
      this.params.IncludeFiltersInReport = value
    },

    getPatients() {              
     this.parameter.PageSize = this.perPage;
     this.parameter.PageNumber = this.currentPage;        
      PatientService.getAll(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.PatientsList = response.data; 
        this.PatientsList.forEach(function(element) {
            element.patientName = element.firstname + " " + element.lastname;   
          });
        }      
        this.$emit('load', false);
      })
      .catch(error => {
        //this.Loading = false;
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),error,"error");
        }
      });
    },

      getTherapies() {
        ClinicalService.getTherapies()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.TherapiesList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),error,"error");
            } 
          });  
      },

      getPrograms() {
        ProgramService.getAll(this.parameter)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
                this.ProgramList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),error,"error");
            } 
          });  
      },

      getFacilities() { 
        PatientService.getFacilities('')
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.facilitiesOptions = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('employeeCaseLoad.EmplServLoad'),error,"error");
            }
          });
      },

      getItemsByComma(values, field) {
        let len = values.length;
        let valuesSeparated = [];
        if (len > 0){
          var i = 0;
          values.forEach(function(e) {   
          if(i < len-1){
            valuesSeparated += e[field]+",";
          }else{
            valuesSeparated += e[field];
          }
          i++;                    
          });
          return valuesSeparated;
        }else{
          return [];
        }
      },

      reportList() {
        this.$router.push({ name: "reportListEmployeeCaseLoad" }).catch(()=>{});
      },
    
      onClickShowReport() {

        this.reportParams = []
        
        this.reportParams.push({ paramName: 'programCode', paramValue: this.params.ProgramCode})
        this.reportParams.push({ paramName: 'Therapists', paramValue: this.params.Therapists})
        this.reportParams.push({ paramName: 'facilities', paramValue: this.params.Facilities})
        this.reportParams.push({ paramName: 'dateFrom', paramValue: this.params.DateFrom})
        this.reportParams.push({ paramName: 'dateTo', paramValue: this.params.DateTo})
        this.reportParams.push({ paramName: 'status', paramValue: this.params.Status})

        this.isReportVisible = true
        this.$refs.employeeCaseLoad.buildDocument(this.reportParams)
      }

 },

  created() {
    this.getPatients();
    this.getPrograms();
    this.getAllEmployee();
    //this.getFacilities()
  },

  mounted() {
    this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
  }
};
</script>
<style>
  #fileList{
    padding: 1em;
  }
</style>