
import moment from 'moment'
import i18n from '@/plugins/i18n';
// import store from '@/globalvar.js'

i18n.locale = localStorage.language
            ? localStorage.language  
            : i18n.locale;

// initialisation of voiceReco
const isSpeechRecognitionSupported = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
let recognition = null;
let recognizing = false;

if(isSpeechRecognitionSupported) {
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognition = new window.SpeechRecognition();
    recognizing = false;
}

const fDefaultDate = "1899-12-30T00:00:00"; //1899-12-30 00:00:00.000

const Utils = {

    getShortDateFormat() {
        return (localStorage.dateFormat??"").trim().toUpperCase() === 'DD/MM/YYYY' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    },

    getDateTimeFormat() {
        return (localStorage.dateFormat??"").trim().toUpperCase() === 'DD/MM/YYYY' ? 'DD/MM/YYYY hh:mm A' : 'MM/DD/YYYY hh:mm A';
    },

    getFullDateTimeFormat() {
        return (localStorage.dateFormat??"").trim().toUpperCase() === 'DD/MM/YYYY' ? 'DD/MM/YYYY HH:mm:ss' : 'MM/DD/YYYY HH:mm:ss';
    },

    getSchedulerDateTimeFormat() {
        return (localStorage.dateFormat??"").trim().toUpperCase() === 'DD/MM/YYYY' ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm';
    },

    getMomentLocaleString() {
        return (localStorage.dateFormat??"").trim().toUpperCase() === 'DD/MM/YYYY'  ? 'es' : 'en';
    },

    HTML2PlainText(HTML) {
        let div = document.createElement("div");
        div.innerHTML = HTML;
        return div.textContent || div.innerText || "";
    },

    strToTitleCase(str) {
        if (str) {
            str = str.toLowerCase();
            str = str.split(' ');
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
              }
            return str.join(' ');
        }
        return '';
    },

    arrToTitleCase(response, fieldName) {
        if (response) {
         let str = '';
         response.map(function(newArray) {
            str = newArray[fieldName].toLowerCase();
            str = str.split(' ');
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
              }
            newArray[fieldName] = str.join(' ');
            return newArray;    
        })
        return response;
        }
        return []
    },

     dateIsValid(date) {
        return !Number.isNaN(new Date(date).getTime());
      },

    IsValidFieldDate(date){
        var valid = false;
        if(date !== ''){
            var arr = date.split('-');
            valid = parseInt(arr[0]) > 1900 && parseInt(arr[1]) > 0 && parseInt(arr[1]) <= 12 && parseInt(arr[2]) > 0 && parseInt(arr[2]) <= 31?true:false;
        }
        return valid;
    },

    formatterSSN(inSSN, outSSN){
        if(inSSN == null){
            inSSN = "";
        }    
        if(inSSN.length == 2 || inSSN.length == 5){
            outSSN = outSSN+"-";
        }if(inSSN.length == 11){
            outSSN = outSSN.slice(0, -1);
        }
        return outSSN;
    },

    formatterDate(value, format){
        if(value){
            return moment(value, format).format('YYYY-MM-DD')
        }else{
            return null;
        }
    },

    formatterNowToDate() {
        return moment().format('YYYY-MM-DD');
    },

    formatDateTime(value, format){
        if(value){
            return moment(value, format).format('YYYY-MM-DD HH:mm:ss')
        }else{
            return null;
        }
    },

    DateIsEmpty(value) {		
		return fDefaultDate === value;
	},

    formatterDateToShortLocaleString(value, format) {
        if (value) {
            return moment(value, format).locale(this.getMomentLocaleString()).format("ddd, ll");
        } else {
            return null;
        }
    },

    formatterDateToLocaleString(value, format) {
        if (value) {          
            return moment(value, format).locale(this.getMomentLocaleString()).format("LLLL");
        } else {
            return null;
        }
    },

    formatterDateToString(value){
        if(value){
            return moment(value).format(this.getShortDateFormat());
        }
    },

    formatterToString(value) {
        if(value){
            const mmt = moment(value);
            var mmtMidnight = mmt.clone().startOf('day');
            var diffMinutes = mmt.diff(mmtMidnight, 'minutes');
            if (diffMinutes == 0) {
                return mmt.format(this.getShortDateFormat());
            }

            return mmt.format(this.getDateTimeFormat());
        }
    },

    isDateFromAndDateTo(DateFrom,DateTo){
        let result = false
        if(Date.parse(DateFrom) < Date.parse(DateTo) || 
            Date.parse(DateTo) > Date.parse(DateFrom) ||
            Date.parse(DateFrom) == Date.parse(DateTo))
                result = true
        return result
    },

    formatterDateTimeToString(value){
        if(value){
            return moment(value).format(this.getDateTimeFormat())
        }
    },

    formatterDateTimeAndSecondsToString(value){
        if(value){
            return moment(value).format(this.getFullDateTimeFormat())
        }
    },

    formatterToISO(value, format) {
        if (value) {
            return moment(value, format).format('YYYY-MM-DDTHH:mm:ss');
        }

        return null;
    },

    formatDateCustom(value){
        if(value){
            return moment(value).format('YYYYMMDD')
        }
    },

    formatTimeCustom(value){
        if(value){
            return moment(value).format('HHmmss')
        }
    },

    formatTime(value, format){
        if(value){
            return moment(value, format).format('hh:mm A')
        }
    },

    formatTime2(value){ //Input format (09:30)
        if(value){
            return moment(value, "HH:mm").format("hh:mm A")
        }
    },

    formatTime3(value){ //Input format (09:30 AM, output: 09:30)
        if(value){
            return moment(value, "hh:mm A").format("HH:mm")
        }
    },

    formatTimeWSec(value){
        if(value){
            return moment(value).format('hh:mm:ss A')
        }
    },

    formatTimeHm(value){
        if(value){
            return moment(value).format('HH:mm')
        }
    },

    setTimeToDateTime(date,time,format) { //return 2018-06-25T20:10:55-04:00 with format param empty
        let timeArr = time.split(':')
        let newDate = new Date(date);     
        newDate.setHours(timeArr[0],timeArr[1])
        return moment(newDate).format(format);
    },

    getCurrentDateTime() {
        let currentDateTime = new Date();
        return moment(currentDateTime).format();
    },

    ValidateDate(date, format){ 
        //if(this.dateIsValid(date)){
        if(this.isValidDate(date, format)){
            //var ReturnDate = new Date(Date.parse(date)).toISOString();
            var ReturnDate = moment(date, format).toISOString();
            return ReturnDate;       
        }else{
            return false;
        }
    },

    isValidDate(date, format, strict) {
        try {
            const newDate = moment(date, format, strict);
            return newDate.isValid();
        }
        catch {
            return false;
        }
    },

    ValidateDateSchedulerEditor(startTime, endTime) {
        let result = true
        
        let stTime = moment(startTime).format(this.getSchedulerDateTimeFormat())
        let enTime = moment(endTime).format(this.getSchedulerDateTimeFormat())
        
        if (stTime === enTime || stTime > enTime) 
            result = false

        return result
    },

    isValidDateAndRangeWith(date, min, max, format) {
        let data = this.ValidateDate(date, format);
        if (data && (typeof data === 'string')) {
            return moment(data).isBetween(min, max);
        } 
        else {
            return false;
        }
    },
    
    IsValidDateAndRange(date, format) {
        return this.isValidDateAndRangeWith(date, '1899-12-30', '2300-12-30', format);
        //let data = this.ValidateDate(date, format);     
        //if (data && (typeof data === 'string')) {
        //    return moment(data).isBetween('1899-12-30', '2300-12-30');
            /*var arr = [];
            if(date){         
                arr = date.includes('/') ? date.split('/') : date.split('-');
            }
            if(arr.length >= 3){
                var m = arr[0];
                var d = arr[1];
                var y = arr[2].split(' ');
                var datefull = y[0]+"-"+m+"-"+d;
                return moment(datefull).isBetween('1899-12-30', '2300-12-30');
            }else{
                return false;
            }*/
        //}
        //else {
        //    return false;
        //}
    },

    convertTo24Hour(time) { // 2024-02-12 12:20 am --> 00:20 || 2024-02-12 02:20 pm --> 14:20
        return moment(time, 'YYYY-MM-DD hh:m A').format('HH:mm')
    },

    getType(ext){
        let result = "";
      switch (ext) {
         case ".JPG" :case ".PNG" :case ".TIF" :case ".TIFF" :case ".BMP" :case ".GIF" :case ".HEIC" :case ".SVG" :case ".JPEG" :case ".WEBP":case ".DIB":case ".RLE":case ".TGA":case ".PCX":case ".PSD":case ".CPT":case ".AI":case ".PPM":case ".CEL":case ".PIC":case ".RAW":case ".DDS":case ".PSPIMAGE":case ".THM":case ".YUV":case ".RGB":case ".RGBA":case ".EPS":
          result = "graphics";
          break;
         case ".DOCX" :case ".DOC" :case ".PAGES" :case ".TEX" :case ".WPD" :case ".WPS" :case ".MSG" :case ".LOG" :case ".TXT" :case ".RTF" :case ".ODT" :case ".DOCM" :case ".DOTX" :case ".DOTM" :case ".DOT" :case ".PDF":case ".INDD":case ".PCT": case ".PPT": case ".PPTX": case ".PPS": case ".PPSX":
          result = "documents";
          break;
         case ".ZIP" :case ".RAR" :case ".SITX" :case ".7Z" :case ".GZ" :case ".TPSR" :case ".TAR" :case ".LZMA" :case ".MPKG" :case ".SIFZ": case ".FZPZ" :case ".PUB" :case ".GZIP" :case ".LZ4" :case ".ZLIB" :case ".LZFSE":case ".CBR":case ".DEB":case ".PKG":case ".RPM":case ".TAR.GZ":case ".ZIPX":case ".RTE":case ".ICE":case ".XAPK":case ".SIT":
          result =  "compressed";
          break;
         case ".AIF" :case ".IFF" :case ".M3U" :case ".M4A" :case ".MID" :case ".MP3" :case ".MPA" :case ".WAV" :case ".WMA": case ".OGG":
          result = "audio";
          break;
         case ".3G2" :case ".3GP" :case ".ASF" :case ".AVI" :case ".FLV" :case ".M4V" :case ".MOV" :case ".MP4" :case ".MPG": case ".MKV": case ".RM": case ".STR": case ".SWF": case ".VOB": case ".WMV":
          result = "video";
          break;
         case ".3DM" :case ".3DS" :case ".MAX" :case ".OBJ" :case ".X" :case ".3DMF" :case ".OFF" :case ".C4D" :case ".LWO": case ".LW": case ".MA": case ".DWG": case ".DXF":
          result = "models";
          break;
         case ".XLR" :case ".XLS" :case ".XLSX" :case ".123" :case ".GNUMERIC" :case ".DEX" :case ".DEF" :case ".LX" :case ".NUMBERS": case ".NB": case ".NUMBERS-TEF": case ".PMVX": case ".XLTM": case ".XLSB": case ".SXC": case ".ODS": case ".IMP": case ".MAR": case ".BKS": case ".EFU": case ".OTS": case ".PRESTO": case ".EDXZ": case ".CELL": case ".RDF": case ".XAR": case "._XLS": case ".PMD": case ".SDC": case ".FP": case ".WQ2": case "._XLSX": case ".QPW": case ".GSHEET": case ".FODS": case ".OGWU": case ".WKU":
          result = "spreadsheets";
          break;
         case ".BIN" :case ".CUE" :case ".DMG" :case ".ISO" :case ".MDF" :case ".TOAST" :case ".VCD" :case ".CSO" :case ".DAX": case ".DMGPART": case ".ROM": case ".VDI": case ".QCOW": case ".VMDK": case ".IMG": case ".DVD": case ".MDS": case ".DSK": case ".D00": case ".BWS": case ".MBI": case ".SDI": case ".WLZ": case ".I02": case ".L01": case ".NRG": case ".MD0": case ".DAA": case ".DCF": case ".IMA": case ".TIB": case ".OMG": case ".UIF": case ".CISO": case ".UDF": case ".IMAGE": case ".MD2": case ".IBDAT":
          result = "diskimages";
          break;
        default:
          result = "unknown";
      }
      return result;
    },

    generateUUID() { // Public Domain/MIT
        let d = new Date().getTime();//Timestamp
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },

    generateRecID() {
        let d = new Date().getTime();
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
        return 'xxxxxxxxxxxxxxxy'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16;
            if(d > 0){
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },

    validateBirthDate( date ) {
        const birthDate = moment(date, 'YYYY-MM-DD');
        if (!birthDate.isValid()) {
          return false;
        }
        const birthDateYear = birthDate.year();
        const birthDateMonth = birthDate.month() + 1;
        const birthDateDay = birthDate.date();
        const currentDate = moment();
        const currentYear = currentDate.year();
        const currentMonth = currentDate.month() + 1;
        const currentDay = currentDate.date();
        if (
            birthDateYear > currentYear ||
          (birthDateYear === currentYear && birthDateMonth > currentMonth) ||
          (birthDateYear === currentYear && birthDateMonth === currentMonth && birthDateDay > currentDay)
        ) {
          return false;
        }
        return true;
    },
    toggleSpeechBar() {
        if(isSpeechRecognitionSupported) {
            var speechBar = document.getElementById('speechBar');
            if(speechBar.classList.contains("active")){
                speechBar.classList.remove('active');
            } else {
                speechBar.classList.add('active');
            }
        }
    },
    stopSpeechToText() {
        var self = this;
        if(isSpeechRecognitionSupported) {
            self.toggleSpeechBar()
            recognizing = false;
            recognition.stop();
        }
    },
    
    startSpeechToText(elemId) {
        if(elemId) {
            var self = this;
            // Stop voice reconigtion if is on
            // self.stopSpeech();

            if(isSpeechRecognitionSupported) {
                recognition.lang = i18n.locale;
                recognition.continuous = true;
                recognition.interimResults = true;
                recognition.maxAlternatives = 1;
                var elemById = document.getElementById(elemId);
                
                recognition.onend = () => {
                    if(recognizing) {
                        self.toggleSpeechBar()
                    }
                    recognizing = false;
                };
                
                recognition.onresult = (event) => {
                    if (typeof event.results === "undefined") {
                        recognizing = false;
                        recognition.stop();
                        return;
                    }
                    for (var i = event.resultIndex; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            // let finalSentence = event.results[i][0].transcript;
                            // elemById.value += (this.capitalizeFirstLetter(finalSentence));
                            elemById.value += Array.from (event.results[i][0].transcript)
                                .join ("");
                            //simulation of trigger keypress
                            elemById.dispatchEvent(
                                new Event("input", { bubbles: true, cancelable: true })
                            ); elemById.dispatchEvent(new Event("change"));
                        }
                    }
                };
                if (!recognizing) {
                    recognition.start();
                    recognizing = true;
                } else {
                    recognition.stop();
                    recognizing = false;
                }
            }
        } else {
            return;
        }
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    startSpeech(elemId) {
        if(elemId) {
            var self = this;
            // Stop voice reconigtion if is on
            if( recognizing ) {
                self.stopSpeechToText()
            }
            var elemById = document.getElementById(elemId);
            if(isSpeechRecognitionSupported && elemById.value.length > 0 ) {
                if (speechSynthesis.speaking) {
                    // SpeechSyn is currently speaking, cancel the current utterance(s)
                    speechSynthesis.cancel();
                }
                else {
                    // Good to go
                    var binding = new SpeechSynthesisUtterance(elemById.value);
                    binding.lang = i18n.locale;
                    speechSynthesis.speak(binding);

                    binding.onend = () => {
                        self.stopSpeech();
                    }
                }
            }
        } else {
            return;
        }
    },
    stopSpeech() {
        var self = this;
        // Stop voice reconigtion if is on
        if(isSpeechRecognitionSupported ) {
            self.toggleSpeechBar()
            // SpeechSyn is currently speaking, cancel the current utterance(s)
            speechSynthesis.cancel();
           
        }
    },
    getBrowserLanguage() {
        // Get the user's preferred languages from the browser
        const languages = navigator.languages || [navigator.language]; 

        // Return the first language (most preferred)
        return languages[0]; 
    },
    useBrowserBuildItCalendar() {
        const dateFormat = this.getShortDateFormat().toUpperCase();
        const lang = localStorage.language.toLowerCase();
        const browser = this.getBrowserLanguage().toLowerCase().split('-');
        return (lang == browser[0]) &&
            ((lang === 'es' && dateFormat === 'DD/MM/YYYY') || (lang !== 'es' && dateFormat === 'MM/DD/YYYY'));
    },
}

export default Utils;