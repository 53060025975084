<template>
  <div>
            <div class="table-notes-title">
              <div class="text-truncate t-header">{{this.Title}}</div>
              <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Table options">              
                <b-button :hidden="TableIsReadOnly || isReadOnly" size="sm" @click="addNewEmptyRecord(false)" class="table-title-btn">{{ $t('Shared.Add')}}</b-button>
                <b-button :hidden="TableIsReadOnly || isReadOnly" size="sm" @click="DeleteRecord" class="table-title-btn">{{ $t('Shared.Delete')}}</b-button>               
              </div>
            </div>
            <b-table
                sticky-header
                selectable
                class="table table-sm  medezTables noteTable cutescroll"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :current-page="cPage"                
                @row-selected="rowSelected"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"
                :empty-text="$t('MdTable.nRecShow')"               
            >     
         <template v-slot:[gomycell(b.key)]="row" v-for="b in SlotItems">  
            <select class="cutescroll" v-on:focus="OnFocusRow(row)"  
              :disabled="isReadOnly" :key="b.key" 
              v-if="row.type === 'dropdown'" style="width:100%;" 
               v-model="row.item.code" v-on:input="onInput">               
              <option v-for="val in b.options" :key="val.code" :value="val.code">{{val.name}}</option>             
            </select>  

             <b-form-input :key="b.key" v-if="b.type === 'input'" style="width:100%;"
               :min="1"
                v-model="row.item.units"
               :readonly="isReadOnly"
               type="number"  
               v-on:focus="OnFocusRow(row)"
               v-on:input="onInput"/>                  
        </template>
   </b-table>
  </div>
</template>

<script>

import ShowMessage from '@/components/messages/ShowMessage.js'
//import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
  export default {
    name:"MdTableComponent",
    mixins: [ShowMessage],
    props: {  
     fields: Array,
     items:Array,
     ObjectItems:[Object],     
     totalRows: Number,    
     isReadOnly: Boolean,
     deleteIconEvent: Function,
     onRowSelected: Function,
     currentPage: Number,
     onFiltered: Function,
     perPage: Number,
     pageChanged: Function,
     perPageFunction: Function,
     value: [String, Number, Object, Array],
     noteStatus: String,
     defaultUnit: Number,
     transCode: String,
     Tag: String,
     SlotItems:Array,
   },
    data() {
      return {
          filter: null,
          filterOn: [],
          fitems : this.items,
          Title:'',
          modeldrop:'',
          modelinput:'',
          modellookupcode:'',
          modellookupdescription:'',
          pPage: this.perPage,
          cPage: this.currentPage,
          fFields:this.fields,
                BaseFields:[{ key: 'description', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
          ModelSelect:{
            
          },
          TableIsReadOnly:false,
          TableIsrequired:false,       
          fMultiSelect: 'single',
          pageOptions: [5, 10, 15 ],
          lookUpValues:{},
          MaxRecId:-1,
          PositionsByRows:[],
          ColumnsCalulated:[],
          cal:"",
          isNewRecord: false,
          index: -1
      }
    },
    
  methods:{
    OnFocusRow(row){       
        this.index = row.index;      
    },

    gomycell(key) {
      return `cell(${key})`;
    },

    ResetPositions(){
        for(var i = 0; i < this.PositionsByRows.length; i++){
          if(this.PositionsByRows[i] !== -1){
            this.PositionsByRows[i] = -1;
            break;
          }
        }
    },

    ChangeDropDown(){

    },

    IfContaintColumn(){
        var e = this.cal;
        var result = false;
         this.fFields.forEach(function(element) {
           if(e.includes(element.key)){
             result = true;            
           }
          }); 
          return result;
    },

    OnChange(){
 
    },

    onCodeConditionOk(){

    },


    editButton(row) {
      this.editIconEvent(row);
    },

    deleteButton(row) {
      this.deleteIconEvent(row);
    },

    rowSelected() {

    },

    filtered(filteredItems) {
      this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
      this.pageChanged(page);
    },

    pageFunction(perpage) {
      this.perPageFunction(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    viewButton(row) {
      this.viewIconEvent(row);
      EventBus.$emit('shwoHideFavorite', false);
      EventBus.$emit('onUpdatedMenu', '');
    },

    addNewEmptyRecord(firstTime) {
      if(!firstTime){
        this.setChangedStatus()
      }
      let newFields = {};
     // var maxRec = this.MaxRecId;
      this.fFields.forEach(element => {      
        newFields[element.key] = element.model?element.model:'';
      // newFields[element.key] = '';
        /*if(element.key.toLowerCase() === 'RecID'.toLowerCase()){
           newFields[element.key] = maxRec;
        }*/
      });
      this.items.unshift(newFields);
    },

    Refresh(){

    },

    Clear(){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteAllRecord'), {
          title: this.$tc('Shared.DelRecord',2),
					okTitle: this.$t('Shared.TitleDeleteAll'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value)
               this.items = [];
               this.setChangedStatus()
          })
    },

    DeleteRecord(){
      if(this.items.length > 0 && this.items[this.index].billed) {
        this.showMessage(this.$t('Shared.Therapies'),this.$t('Msg.CanNotModifythisEntry'),'error');
      } else {
        if(this.index !== -1){
        this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title :this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value){
                this.items.splice(this.index, 1);
                this.setChangedStatus()               
            }
          })
        }
      }  
    },

    Save(){

    },

    cellChange() {

    },

    resizeColumn: function () {
      var thElm;
      var startOffset;
      Array.prototype.forEach.call(
      document.querySelectorAll("table th"),
      function (th) {
          var grip = document.createElement('div');
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = '5px';
          grip.style.position = 'absolute';
          grip.style.cursor = 'col-resize';
          grip.addEventListener('mousedown', function (e) {
              thElm = th;
              startOffset = th.offsetWidth - e.pageX;
          });
          th.appendChild(grip);
      });
      document.addEventListener('mousemove', function (e) {
          if (thElm) {
              thElm.style.width = startOffset + e.pageX + 'px';
          }
      });
      document.addEventListener('mouseup', function () {
          thElm = undefined;
      });
    },

    delFirstEmptyRow() {
      let busyCell = 0
      this.fFields.forEach(element => {
        if(element.key != 'RecID')
           if(this.items[0][element.key].length != 0)
            busyCell = busyCell + 1
        });
        if(busyCell == 0) {
          this.items.splice(0, 1)
          this.setFirstCellPos
        }
        this.isNewRecord = false
    },

    setFirstCellPos() {
      this.$refs[this.fFields[0].key+'_'+0].focus()
    },

    keyDown(cellID) {
      let fieldName = cellID.split("_")[0] 
      let currIndex = cellID.split("_")[1]
      let nextIndex = parseInt(currIndex) + 1
      if(this.isNewRecord && currIndex == 0) {
        this.delFirstEmptyRow()
      } else {
        if(this.$refs[fieldName+'_'+nextIndex])
          this.$refs[fieldName+'_'+nextIndex].focus()
        else {
          this.addNewEmptyRecord(false)
          this.isNewRecord = true
          this.setFirstCellPos()
        }
      }
    },

    keyUp(cellID) {
      let fieldName = cellID.split("_")[0] 
      let currIndex = cellID.split("_")[1]
      let prevIndex = parseInt(currIndex) - 1
      if(this.$refs[fieldName+'_'+prevIndex])
          this.$refs[fieldName+'_'+prevIndex].focus()
    },

    keyEsc(cellID) {
      let currIndex = cellID.split("_")[1]
      if(this.isNewRecord && currIndex == 0)
        this.delFirstEmptyRow()
    },

    setChangedStatus(){
      var changed = {
        "Changed":true,
        "ComponentName":'MdEditTable',
        "Tag": this.Tag
        }
        EventBus.$emit('onChanged', changed);
    },

    onInput(){
      this.setChangedStatus()
    }
 },
  created() {
    if(this.deleteIconEvent ) {
      this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
    }
  },
  mounted(){
  
  }
}

</script>
<style>
.hidden_header {
  visibility: collapse;
}
</style>