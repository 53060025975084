<template>
  <div class="row page-height">
    <div class="col-12">
      <h4 v-if="this.editMode" class="page-title text-truncate">
        {{ $t('Insurance.EditPatientInsurance') }} - 
          <!--<span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
          <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
          <span v-if="this.PatientTitle.dob"> - Dob {{this.PatientTitle.dob}}</span>
          <span v-if="this.PatientTitle.age"> Age {{this.PatientTitle.age}}</span>-->
                    <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
                    <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                    <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                    <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                    <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                    <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
                    <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
                    <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
        <a v-if="Insurance.company">{{ ": " + Insurance.company }}</a>
      </h4>
      <h4 v-if="!this.editMode" class="page-title text-truncate">
        {{ $t('Insurance.AddPatientInsurance') }} -
          <!--<span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
          <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
          <span v-if="this.PatientTitle.dob"> - Dob {{this.PatientTitle.dob}}</span>
          <span v-if="this.PatientTitle.age"> Age {{this.PatientTitle.age}}</span>-->
                    <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
                    <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                    <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                    <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                    <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                    <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
                    <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
                    <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
        <a v-if="Insurance.company">{{ ": " + Insurance.company }}</a>
      </h4>
      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
               <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
               <div class="flex-grow-1">
                 <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
               </div>
             </div>
      <!-- !! Important ,Added for testing purpose -->
          <div style='display:none;'><MdEdit 
                v-bind:TypeProp="'text'" 
                v-model="this.InsuranceCodeTable "
                v-bind:Id="'input_insurance_id'">                                     
              </MdEdit>
          </div>
          <!-- End -->
      <b-row>
        <b-col md="3">
          <MdLookup
            v-bind:Label="$t('Insurance.InsuranceCode')"
            v-bind:ModelCode="PatientInsurance.insCode"
            v-bind:ModelDescription="Insurance.company"
            v-bind:RefCode="'InsuranceCode'"
            v-bind:Api="'Insurances'"
            v-bind:FieldCode="'insurancecode'"
            v-bind:FieldDescription="'company'"
            :IsDisabled="this.isReadOnly"
            v-bind:Fields="fields"    
            :SearchTitle="$tc('Insurance.Insurance',2)"
            :GetValues="getInsuranceCodeValues"
            :InvalidFeedback="$t('Insurance.InvalidInsuranceCode')"
            :SearchFieldCode="'InsuranceCode'"
            v-bind:isValid="this.isInsCodeValid"
            v-bind:isRequired="true"                     
          />
        </b-col>
        <b-col md="3">
          <MdEdit 
            v-bind:Label="$t('Insurance.EffectiveDate')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'effectiveDate'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="PatientInsurance.effectiveDate"
            v-bind:isRequired="true"
            v-bind:isReadOnly=this.isReadOnly
            v-bind:Id="'effective_date'">                                     
          </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
              v-bind:Label="$t('Insurance.CoPay')"
              v-bind:TypeProp="'text'" 
              v-bind:Name="'CurrencyValue'"
              v-bind:MaxLengthProp="11"
              v-model="formattedCurrencyValue"
              v-bind:isReadOnly=this.isReadOnly
              v-on:blur="focusOut"
              v-on:focus="focusIn"
              v-bind:Id="'input_copay'">                                     
          </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
              v-bind:Label="$t('Insurance.Discount')"
              v-bind:TypeProp="'text'" 
              v-bind:Name="'CurrencyValue1'"
              v-model="formattedCurrencyValue1"
              v-bind:isReadOnly=this.isReadOnly
              v-on:blur="focusOut1"
              v-on:focus="focusIn1"
              v-bind:Id="'input_Discount'">                                     
          </MdEdit>
        </b-col>
      </b-row>
      <b-row>
        
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
              v-bind:Label="$t('Insurance.PolicyNo')"
              v-bind:TypeProp="'text'" 
              v-bind:MaxLengthProp="20"
              v-bind:Name="'insNo'"
              v-model="PatientInsurance.insNo"
              v-bind:isReadOnly=this.isReadOnly
              v-bind:isRequired="true"
              v-bind:isValid="this.isInsNoValid"
              v-bind:Id="'policy_no'">                                     
          </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
              v-bind:Label="$t('Insurance.Coverage')"
              v-bind:TypeProp="'text'" 
              v-bind:MaxLengthProp="25"
              v-bind:Name="'coverage'"
              v-model="PatientInsurance.coverage"
              v-bind:isReadOnly=this.isReadOnly
              v-bind:Id="'input_coverage'">                                     
          </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
              v-bind:Label="$t('Insurance.InsGroup')"
              v-bind:TypeProp="'text'" 
              v-bind:MaxLengthProp="15"
              v-bind:Name="'insGroup'"
              v-model="PatientInsurance.insGroup"
              v-bind:isReadOnly=this.isReadOnly
               v-bind:Id="'input_ins_group'">                                     
          </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
              v-bind:Label="$t('Insurance.InsuranceGroupName')"
              v-bind:TypeProp="'text'" 
              v-bind:MaxLengthProp="30"
              v-bind:Name="'insGroupName'"
              v-model="PatientInsurance.insGroupName"
              v-bind:isReadOnly=this.isReadOnly
              v-bind:Id="'input_insgname'">                                     
          </MdEdit>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <MdEdit 
              v-bind:Label="$t('Insurance.InsuranceCategory')"
              v-bind:TypeProp="'text'" 
              v-bind:MaxLengthProp="4"
              v-bind:Name="'InsuranceCategory'"
              v-model="InsuranceCategory"
              v-bind:isReadOnly='true'
              v-bind:Id="'input_inscat'">                                     
          </MdEdit>
        </b-col>
        <b-col md="3">
          <MdSelect
            v-bind:Label="$t('Insurance.InsType')"
            v-bind:Options="InsuranceTypesList"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="PatientInsurance.insType"
            v-bind:Multiple="false"
            :IsDisabled="this.isReadOnly"
            :Input="ChangeDropDownInsType"
            v-bind:isRequired="true"
            v-bind:isValid="this.isInsTypeValid"
            v-bind:FeedBackText="$t('Insurance.InsuranceTypeRequired')"
            v-bind:Name="'ins_type_select'"
            />
        </b-col>
        <b-col md="3">
            <MdLookup
              v-bind:Label="$t('Insurance.MedicareInsType')"
              v-bind:ModelCode="Insurance.medicareType"
              v-bind:ModelDescription="MedicareInsuranceType.description"
              v-bind:RefCode="'MedicareInsureType'"
              v-bind:Api="'InsuranceMedicareTypes'"
              v-bind:FieldCode="'code'"
              :IsDisabled="this.isReadOnly"    
              :SearchTitle="$tc('Insurance.Insurance',2)"
              :GetValues="getMedicareInsuranceTypeValues"
              :InvalidFeedback="$t('Insurance.InvalidMedicareCode')"
              :SearchFieldCode="'Code'"
              v-bind:Fields="BaseFieldsMed"                      
            />
        </b-col>
        <b-col md="3">
          <MdSelect
            v-bind:Label="$t('Shared.Relation')"
            v-bind:Options="PatientInsuranceRelations"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="PatientInsurance.relation"
            v-bind:Multiple="false"
            :IsDisabled="this.isReadOnly"
            :Input="ChangeDropDownRelation"
            v-bind:isRequired="true"
            v-bind:isValid="this.isRelationValid"
            v-bind:FeedBackText="$t('Insurance.InsuranceRelationRequired')"
            v-bind:Name="'relation_select'"
            />
        </b-col>
      </b-row>

      <div v-if="!enabledInsuredChecked">
          <MdTitle v-bind:Text="$t('Insurance.InsuredInformation')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-col md="3">
          <MdCheckBox
            v-bind:Label="$t('Insurance.UsePatientInfo')"
            v-bind:Name="'checkbox-1'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="checkedInfo" 
            @change="usePatientInfo($event)"
            
        />
          </b-col>
          <b-row class="mt-3">
            <b-col md="3">
              <MdEdit 
                  v-bind:Label="$t('Shared.FirstName')"
                  v-bind:TypeProp="'text'" 
                  v-bind:MaxLengthProp="50"
                  v-bind:Name="'insFirstname'"
                  v-model="PatientInsurance.insFirstname"
                  v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                  v-bind:Id="'input_ins_fn'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                  v-bind:Label="$t('Insurance.Initial')"
                  v-bind:TypeProp="'text'" 
                  v-bind:MaxLengthProp="1"
                  v-bind:Name="'initial'"
                  v-model="PatientInsurance.initial"
                  v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                  v-bind:Id="'input_isuI'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                  v-bind:Label="$t('Shared.LastName')"
                  v-bind:TypeProp="'text'" 
                  v-bind:MaxLengthProp="50"
                  v-bind:Name="'insLastname'"
                  v-model="PatientInsurance.insLastname"
                  v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                  v-bind:Id="'input_isu_ln'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.Dob')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'dob'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="PatientInsurance.dob"
                v-bind:isValidDoB="this.isDOBValid"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <MdEdit 
                  v-bind:Label="$t('Shared.DayPhone')"
                  v-bind:TypeProp="'text'" 
                  v-bind:MaxLengthProp="15"
                  v-bind:Name="'workPhone'"
                  v-model="PatientInsurance.workPhone"
                  v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                  v-bind:Id="'input_isudf'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                  v-bind:Label="$t('Shared.NightPhone')"
                  v-bind:TypeProp="'text'" 
                  v-bind:MaxLengthProp="15"
                  v-bind:Name="'homePhone'"
                  v-model="PatientInsurance.homePhone"
                  v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                  v-bind:Id="'input_isunf'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
                <MdEdit 
                  v-bind:Label="$t('Shared.Address1')"
                  v-bind:TypeProp="'text'" 
                  v-bind:MaxLengthProp="30"
                  v-bind:Name="'address1'"
                  v-model="PatientInsurance.address1"
                  v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                  v-bind:Id="'input_isu_addrs1'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
                <MdEdit 
                  v-bind:Label="$t('Shared.Address2')"
                  v-bind:TypeProp="'text'" 
                  v-bind:MaxLengthProp="30"
                  v-bind:Name="'address2'"
                  v-model="PatientInsurance.address2"
                  v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                  v-bind:Id="'input_isu_addrs2'">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.Zip')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'zip'"
                v-bind:MaxLengthProp="10"
                v-model="PatientInsurance.zip"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-on:blur="patientLegalZipBlur(PatientInsurance.zip)"
                v-on:change="patientLegalZipChange($event)"
                v-bind:Id="'input_isu_zip'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.City')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'city'"
                v-bind:MaxLengthProp="25"
                v-model="PatientInsurance.city"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_isu_city'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.State')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'state'"
                v-bind:MaxLengthProp="2"
                v-model="PatientInsurance.state"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_isu_state'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.Country')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'country'"
                v-bind:MaxLengthProp="25"
                v-model="PatientInsurance.country"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_isu_country'">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
                <MdEdit 
                v-bind:Label="$t('Insurance.PlaceOfEmployment')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'employment'"
                v-bind:MaxLengthProp="30"
                v-model="PatientInsurance.employment"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_isu_poe'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdSelect
                v-bind:Label="$t('Shared.Gender')"
                v-bind:Options="sex_list"
                v-bind:FieldDescription="'text'"
                v-bind:FieldCode="'value'"
                v-bind:Model="PatientInsurance.sex"
                v-bind:Multiple="false"
                :IsDisabled="this.enabledInsuredChecked || this.isReadOnly"
                :Input="ChangeDropDownSex"/>
            </b-col>
          </b-row>
      </div>


      <div v-if="PatientInsurance.showPriorAuthorization" id="showPriorAuthorization">
        <MdTitle v-bind:Text="$t('Insurance.PriorAuthorization')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <b-col md="3" lg="3" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Insurance.PriorAuthorizationNot')" 
            v-bind:Name="'checkbox-prior'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="PatientInsurance.disablePriorAuth"
            v-bind:isReadOnly="this.isReadOnly"
          />
     </b-col>
        <b-row>
            <div class="col-sm-12 col-md-3">                      
              <MdButton
                v-bind:Label="$t('Insurance.AddPriorAuthor')" 
                v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                v-bind:IconClass="'ti-plus pr-1'"                
                v-on:click="CallModalTable"
                v-bind:Name="'btnaddPriorAuthor'"
                v-bind:isReadOnly="this.isReadOnly">
              </MdButton>
            </div>
            <div class="col-sm-12 col-md-9 mb-4">
              <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                  <input 
                    type="text" 
                    class="form-control"
                    v-model="searchPrior"   
                    @keyup.enter="FindPrior">
                    <div class="input-group-append">
                      <div class="input-group-append">
                        <MdButton 
                          v-bind:Name="'btnsearchinput'"
                          v-bind:VariantProp="'btn btn-sm search-attached'"
                          v-bind:IconClass="'mdi mdi-magnify'"
                          v-on:click="FindPrior">
                        </MdButton>
                      </div>
                    </div>
                  </div>
          </div>
        </div>
        </b-row>
        <div class="row">
          <div class="col-md-12">
              <MdTablePaginationClient 
              v-bind:fields="visibleFields"
              v-bind:items="PatientInsurance.priorAuthorizations"
              v-bind:totalRows="totalRows" 
              v-bind:IsInsurancePrior="true"  
              v-bind:isReadOnly="false"          
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:pageOptions="pageOptions"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              v-bind:VisibleEditIcon="true"
              :deleteIconEvent="Remove"
              :editIconEvent="Edit"
              :RowClick="RowClick"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelected"
              :perPageFunction="PerPageFunction">
            </MdTablePaginationClient>
          </div>
    </div>
      </div>

      <ModalPriorAutho
        v-bind:TitleWindow="$t('Insurance.PriorAuthorization')"  
        v-bind:ModeEdit="ModalPriorModeEdit"       
        @getCodeConditionOK="OnReturnData($event)"
        v-bind:ModalId="this.modalId">
      </ModalPriorAutho>

      <div class="space-actions"></div>
        <div class="page-actions">
            <MdButton
              v-bind:Label="$t('Shared.Back')"
              v-bind:Name="'btnpatientlist'"
              v-bind:ClassProp="'secondary mx-2 btn-login'"
              v-on:click="DiscardChanges"/>
            <MdButton
              v-bind:Label="$t('Insurance.SelfPay')"
              v-bind:ClassProp="'primary btn-login mx-2'"
              v-on:click="SelfPay()"
              v-bind:isReadOnly=this.isReadOnly
            />
            <MdButton
              v-bind:Label="$t('Shared.Save')"
              v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
              v-on:click="ClickSaveCall"
              v-bind:isReadOnly=this.isReadOnly
              v-bind:Name="'btn_save_patient_insurance'"
            />
        </div>
      </div>
  </div>
</template>

<script>
import EnumService from "@/services/enum.service";
import InsuranceService from "@/services/insurance.service";
import PriorAuthorizationService from "@/services/priorauthorization.service";
import ShowMessage from "@/components/messages/ShowMessage.js";
import Utils from "@/common/utils";
import DBFunctions from '@/common/DBFunctions.js'
import GlobalService from '@/services/global.service'
import { EventBus } from '@/event-bus.js';

let elemSaveBtn = '';
export default {

  beforeRouteEnter(to, from, next) {
    next(async vm => {
      const iPIn = await vm.$store.state.isUsePatientInsurance;
      if (iPIn) next()
      else next('/accessdenied');
    })
  },

  beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Insurance.Insurance',1),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
             this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

  name: "InsuranceDetails",
  mixins: [ShowMessage, DBFunctions],
  props: {
    editMode: Boolean,
    insCode: String
  },
  data() {
    return {
      ShowMessageIsEmpty:false,
      InsuranceByCode:{},
      modalId:"idModalPriorAutho",
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      ClickSave:false,
      items:[],
      PriorAuthoListCopy:[],
      sex_list: EnumService.Sexs,
      searchPrior:"",
      RegistrationInfo:{},
      IsClinical:false,
      Loading: true,
      counter: 0,
      MedicareInsuranceType: { "description": '' },
      ActiveReleaseOnFile: EnumService.YesNoOptions,
      YesNoOptionsNumericThree: EnumService.YesNoOptionsNumericThree,
      YesNoOptionsNumericTwo: EnumService.YesNoOptionsNumericTwo,
      options: [
        { text: this.$t('Shared.Male'), value: "M" },
        { text: this.$t('Shared.Female'), value: "F" },
      ],
      PatientInsuranceRelations: [],
      InsuranceMedicareTypes: {},
      PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     },
      Insurance: { },
      PatientCode: this.$route.params.id,
      ResourceParameter: "",
      FieldsParameter: [],
      TypeOption: "",
      PatientInsurance: { 
        address1:"",
        address2:"",
        catcode: "",
        city:"",
        country:"",
        coverage: "",
        disablePriorAuth:false,
        discount:null,
        dob:null,
        effectiveDate:null,
        eligibility: "",
        eligibilityDate: null,
        employment: "",
        homePhone:"",
        initial: "",
        insCode:"",
        insFirstname: "",
        insGroup: "",
        insGroupName: "",
        insLastname:"",
        insNo: "",
        insType: "",
        isEligible: "",
        medicareType: "",
        patientcode: "",
        paymentpercent: null,
        reason: "",
        relation: "",
        sex: "",
        state: "",
        workPhone:"",
        zip: "",
        showPriorAuthorization:false,
        priorAuthorizationBaseOn:"",
        priorAuthorizations:[]
      },
      ClassColumn:"text-rigth",
      InsuranceCategory: '',
      InsuranceTypesList: [],
      formattedCurrencyValue: "$ 0.00",
      formattedCurrencyValue1: "$ 0.00",
      PatientInsuranceAll: {},
      parameterInsuranceAll: {
        PatientCode: this.$route.params.id
      },
      OptionsRightMenu: {
        visible: true,
        btnvisible:true,
        ItemsList: [],
      },
      lookupFieldChanged: false,
      isReadOnly: false,
      ModalPriorModeEdit:false,
      isInsTypeValid: true,
      isRelationValid: true,
      isInsCodeValid: true,
      isInsNoValid: true,
      enabledInsuredChecked: true,
      checkedUsePatientInfo: true,
      patient: {},
      patientAddress:{},
      patientLegalZipChanged: false,
      shippingData: [],
      checkedInfo:false,
      pendingChanges: false,
      InsuranceCodeTable: '',
      fields: [
         { key: 'company', sortable: true, label: this.$t('Insurance.colInsuranceName'), class: 'text-rigth' },
         { key: 'insurancecode', sortable: true, label: this.$t('Insurance.InsuranceCode'), class: 'text-rigth' },         
         { key: 'insuranceTypeDescription', sortable: true, label: this.$t('Insurance.colInsuranceType'), class: 'text-rigth' },
         { key: 'categoryDescription', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' },
         { key: 'contact', sortable: true, label: this.$tc('Contact.Contact'), class: 'text-rigth' },         
         { key: 'address1', sortable: true, label: this.$t('Insurance.colAddress1'), class: 'text-rigth' },
         { key: 'address2', sortable: true, label: this.$t('Insurance.colAddress2'), class: 'text-rigth' },         
         { key: 'city', sortable: true, label: this.$t('Shared.City'), class: 'text-rigth' },            
      ],
      FieldsPrior:[
        { key: 'entityDescription', sortable: true, label: this.$t('Shared.Description'), class:'text-rigth',visible:true }, 
        { key: 'entityCode', sortable: true, label: this.$t('Shared.Code'), class:'text-rigth',visible:true  },          
        { key: 'datefrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth',visible:true  },
        { key: 'dateto', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth',visible:true  },  
        { key: 'status', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth',visible:true  }, 
        { key: 'isActive', sortable: true, label: 'Status', class: 'hidden',visible:false }, 
        { key: 'unit', sortable: true, label: this.$t('Insurance.DayUnit'), class: 'text-rigth',visible:true  },  
        { key: 'quantityUsed', sortable: true, label: this.$t('Insurance.QTYUsed'), class: 'text-rigth',visible:true  },            
        { key: 'recid', sortable: true, label: 'recid', class: 'hidden',visible:false },
        { key: 'key', sortable: true, label: 'key', class: 'hidden',visible:false },
        { key: 'priorauthorization', sortable: true, label: this.$t('Insurance.PriorAuthorizationNo'), class: 'text-rigth',visible:true },   
      ],
      isDOBValid: true,
      BaseFieldsMed:[
          { key: 'code', sortable: true, label: this.$t('Shared.Code')},
          { key: 'description', sortable: true, label: this.$t('Shared.Description')}
      ]
    };
  },



  computed: {
      visibleFields() {     
        return this.FieldsPrior.filter(field => field.visible)
      }
    },

  methods: {

    RowClick(){
      
    },

    FormatDate(){
      var arr = this.PatientInsurance.priorAuthorizations;     
      arr.forEach(element => {
        if (element.datefrom)	
            element.datefrom = Utils.formatterDateToString(element.datefrom);
        if (element.dateto)	
            element.dateto = Utils.formatterDateToString(element.dateto); 
      });
      this.PatientInsurance.priorAuthorizations = arr; 
    },

    OnReturnData(datapro){
        let data = {...datapro};  
        if (data.datefrom)	
            data.datefrom = Utils.formatterDateToString(data.datefrom);
        if (data.dateto)	
            data.dateto = Utils.formatterDateToString(data.dateto);

      if(!this.ExistKeyinListCopy(data.key)){    
        this.PriorAuthoListCopy.push(data);
       }
         
      if(!this.ExistKeyinList(data.key)){       
        this.PatientInsurance.priorAuthorizations.push(data);
       // this.PriorAuthoListCopy.push(data);
        //this.FormatDate();
      }else{       
        this.UpdatePriorAuthoList(data);
      }  

      //this.PriorAuthoListCopy = this.PatientInsurance.priorAuthorizations;
      this.totalRows = this.PatientInsurance.priorAuthorizations.length;
    },

    ExistKeyinList(key){
      var result = false;
      var arr = this.PatientInsurance.priorAuthorizations;     
      arr.forEach(element => {
        if(element.key === key){
         result = true;
        }
      });
      return result;
    },

    ExistKeyinListCopy(key){
      var result = false;
      var arr = this.PriorAuthoListCopy;     
      arr.forEach(element => {
        if(element.key === key){
         result = true;
        }
      });
      return result;
    },

    UpdatePriorAuthoList(data){
      var arr = this.PatientInsurance.priorAuthorizations;
      arr.forEach(element => {
        if(element.key === data.key){
         element.datefrom =  data.datefrom;
         element.dateto =  data.dateto;
         element.entityCode =  data.entityCode;
         element.status = data.status,
         element.entityDescription =  data.entityDescription;
         element.isActive =  data.isActive;
         element.priorauthorization =  data.priorauthorization;
         element.quantityUsed =  data.quantityUsed;
         element.recid =  data.recid;
         element.key =  data.key;
         element.unit =  data.unit;
         element.priorAuthorizationBaseOn =  data.priorAuthorizationBaseOn;  
        }
      });
      this.PatientInsurance.priorAuthorizations = arr;


      var arrcopy = this.PriorAuthoListCopy;
      arrcopy.forEach(element => {
        if(element.key === data.key){
         element.datefrom =  data.datefrom;
         element.dateto =  data.dateto;
         element.entityCode =  data.entityCode;
         element.status = data.status,
         element.entityDescription =  data.entityDescription;
         element.isActive =  data.isActive;
         element.priorauthorization =  data.priorauthorization;
         element.quantityUsed =  data.quantityUsed;
         element.recid =  data.recid;
         element.key =  data.key;
         element.unit =  data.unit;
         element.priorAuthorizationBaseOn =  data.priorAuthorizationBaseOn;  
        }
      });
      this.PriorAuthoListCopy = arrcopy;


      //this.FormatDate();
    },

    CallModalTable(){
      this.ModalPriorModeEdit = false;
      this.DataPriorAutho = 
        {          
           "datefrom":"",
           "dateto":"",
           "entityCode":"",
           "entityDescription":"",
           "isActive":true,
           "status":"",
           "priorauthorization":"",
           "quantityUsed":"",
           "recid":"",
           "key":Math.floor(new Date().valueOf() * Math.random()),
           "unit":0,
           "priorAuthorizationBaseOn":this.PatientInsurance.priorAuthorizationBaseOn
        };
        var data = {
           row: this.DataPriorAutho,
           ModeEdit:false
        } 
      EventBus.$emit('OnReceivedData',data); 
      this.$bvModal.show(this.modalId);     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;     
    },

    onRowSelected(){

    },

    FindPrior(){
      this.PatientInsurance.priorAuthorizations = this.PriorAuthoListCopy;
        var arr = this.PatientInsurance.priorAuthorizations;
        var temp = [];
        if(this.searchPrior){
         arr.forEach(element => {
           if(element.entityDescription && element.entityDescription.toUpperCase().includes(this.searchPrior.toUpperCase())
           || element.entityCode.toString() && element.entityCode.toString().toUpperCase().includes(this.searchPrior.toUpperCase())
           || element.datefrom && element.datefrom.toUpperCase().includes(this.searchPrior.toUpperCase())
           || element.dateto && element.dateto.toUpperCase().includes(this.searchPrior.toUpperCase())
           || element.unit !== null && element.unit.toString() && element.unit.toString().toUpperCase().includes(this.searchPrior.toUpperCase())
           || element.quantityUsed !== null && element.quantityUsed.toString() && element.quantityUsed.toString().toUpperCase().includes(this.searchPrior.toUpperCase()) 
           || element.priorauthorization && element.priorauthorization.toString().toUpperCase().includes(this.searchPrior.toUpperCase()) 
           || element.status && element.status.toString().toUpperCase() === this.searchPrior.toUpperCase()){
            temp.push(element);
           }
        });
        }else{
           temp = this.PriorAuthoListCopy;
        }
      
         this.PatientInsurance.priorAuthorizations = temp;
         this.totalRows = this.PatientInsurance.priorAuthorizations.length;
    },

    getPosList(key){
        for(var i = 0; i < this.PatientInsurance.priorAuthorizations.length; i++){
           if(this.PatientInsurance.priorAuthorizations[i].key === key){           
              return i;              
           }
        }
        return -1;
     },

     getPosListCopy(key){
        for(var i = 0; i < this.PriorAuthoListCopy.length; i++){
           if(this.PriorAuthoListCopy[i].key === key){           
              return i;              
           }
        }
        return -1;
     },

    Remove(row){     
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$t('Insurance.PriorAuthorization'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value) {
        var i = this.getPosList(row.key);
        if(i !== -1){
           this.PatientInsurance.priorAuthorizations.splice(i, 1);
           this.pendingChanges = true;
           this.totalRows = this.PatientInsurance.priorAuthorizations.length;
           this.checkUserAction();
        }

          var j = this.getPosListCopy(row.key);
          if(j !== -1){
            this.PriorAuthoListCopy.splice(j, 1);
          }

        }
      })
    },

    Edit(row){   
      this.DataPriorAutho = 
        {          
           "datefrom":row.datefrom,
           "dateto":row.dateto,
           "entityCode":row.entityCode,
           "entityDescription":row.entityDescription,
           "status":row.status,
           "isActive":row.isActive,
           "priorauthorization":row.priorauthorization,
           "quantityUsed":row.quantityUsed,
           "key":row.key,
           "recid":row.recid,
           "unit":row.unit,
           "priorAuthorizationBaseOn":this.PatientInsurance.priorAuthorizationBaseOn
        };
        this.ModalPriorModeEdit = true;
        var data = {
           row: this.DataPriorAutho,
           ModeEdit:true
        } 
        EventBus.$emit('OnReceivedData', data);       
         this.$bvModal.show(this.modalId);     
        
    },

    pageChanged (page) {            
      this.currentPage = page;         
    },

    onFiltered(){

    },

    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;

          if(infoList[0].dob){
              this.PatientTitle.dob = Utils.formatterDateToString(infoList[0].dob);
          }   
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
          this.patient = infoList[0];
      }
    },
    getInsuranceCodeValues(value) {
      this.PatientInsurance.insCode = value.code;
      this.Insurance.company = value.description;

      this.getInsuranceByCode(this.PatientInsurance.insCode);
      this.getPriorAuthoByPatientAndinsCode();
    },

    getMedicareInsuranceTypeValues(value) {
      this.PatientInsurance.medicareType = value.code;
      this.MedicareInsuranceType.code = value.code;
      this.MedicareInsuranceType.description = value.description;
    },

    ChangeDropDownInsType(value) {
      this.PatientInsurance.insType = value ? value.code : '';
    },

    ChangeDropDownRelation(value) {
      
      this.PatientInsurance.relation = value ? value.code : '';
      this.enabledInsuredChecked = (this.PatientInsurance.relation.indexOf("01") == 0);


      if (value.code !== '01') {
        if (this.checkedInfo == false){
          this.OnUpdateInsuredFieldsPatientInfo({});
          this.PatientInsurance.insFirstname = "";
          this.PatientInsurance.initial = "";
          this.PatientInsurance.insLastname = "";
          this.PatientInsurance.dob = null;
          this.PatientInsurance.employment = "";
          this.PatientInsurance.sex = "U";
          this.checkedUsePatientInfo = false;
        }
      } else {
         this.OnUpdateInsuredFieldsPatientInfo({});
         this.$nextTick(() => {
            this.checkedInfo = false;
        });
      }
    },

    ChangeDropDownSex(value) {
      this.PatientInsurance.sex = value ? value.value : '';
    },

    getEnabledInsuredFields(result) {
      this.enabledInsuredChecked = (result.indexOf("01") < 0);
    },

    async usePatientInfo(event) {
      this.checkedUsePatientInfo = event;
      if (event) {        
        if(this.$route.name.includes('Clinical-insurance')){     
          var infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0){
              this.patientAddress = infoList[0];
              this.OnUpdateInsuredFieldsPatientInfo(this.patientAddress);
            }
        }else{
          this.OnUpdateInsuredFieldsPatientInfo(this.patient);
        }
      } else {
        this.OnUpdateInsuredFieldsPatientInfo({});
      }
      this.$forceUpdate();
    },

    patientLegalZipChange: function() {
      this.patientLegalZipChanged = true;
    },

    patientLegalZipBlur: function(value) {     
      if (this.patientLegalZipChanged && value.trim()!== '') {
            this.$emit('load', true); 
            GlobalService.getCityAndStateByZipCode(value)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        var obj = response.data;                      
                       if(obj.city) {
                          this.PatientInsurance.city = obj.city;                           
                          this.PatientInsurance.state = obj.state; 
                        }                        
                        this.patientLegalZipChanged = false;
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false); 
                      this.PatientInsurance.city = '';
                      this.PatientInsurance.state = '';                   
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('Msg.GetCityAndState'),error,"error");
                    }
                  }
                    
                });  
            }
    },
    
    OnUpdateInsuredFieldsPatientInfo(result) {
      if (result) {
        this.PatientInsurance.address1 = result.address1;
        this.PatientInsurance.address2 = result.address2;
        this.PatientInsurance.city = result.city;
        this.PatientInsurance.country = result.country;
        this.PatientInsurance.homePhone = result.nightPhone;
        this.PatientInsurance.state = result.state;
        this.PatientInsurance.workPhone = result.dayPhone;
        this.PatientInsurance.zip = result.zip;
      }
    },

    lookupFieldChange: function() {
      this.lookupFieldChanged = true;
      this.isInsCodeInvalid = this.PatientInsurance.insCode && this.PatientInsurance.insCode.length > 0 ? false : true;
    },

    lookupNameByCode: function(control, value) {
      if (this.lookupFieldChanged) {
        this.getLookup(control, value);
        this.lookupFieldChanged = false;
      }
      this.isInsCodeInvalid = this.PatientInsurance.insCode && this.PatientInsurance.insCode.length > 0 ? false : true;
    },

    lookupEnterEvent: function(control, value) {
      this.lookupNameByCode(control, value);
    },

    onContextEffectiveDate(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
      if (ctx.selectedFormatted != "No date selected") {
        this.PatientInsurance.effectiveDate = ctx.selectedFormatted;
      }
    },

    focusOut() {
      this.PatientInsurance.paymentpercent = this.formattedCurrencyValue ? parseFloat(
        this.formattedCurrencyValue.toString().replace("$ ", "")) : null;
      if (isNaN(this.PatientInsurance.paymentpercent)) {
        this.PatientInsurance.paymentpercent = 0;
      }
      this.formattedCurrencyValue = this.formattedCurrencyValue ?
        "$" +
        this.PatientInsurance.paymentpercent
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") : null;
    },

    focusIn() {
      this.formattedCurrencyValue = this.PatientInsurance.paymentpercent;
    },

    focusOut1() {
      this.PatientInsurance.discount = this.formattedCurrencyValue1 ? parseFloat(
        this.formattedCurrencyValue1.replace("$ ", "")
      ): null;
      if (isNaN(this.PatientInsurance.discount)) {
        this.PatientInsurance.discount = 0;
      }
      this.formattedCurrencyValue1 = this.formattedCurrencyValue1 ?
        "$ " +
        this.PatientInsurance.discount
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"): null;
    },

    focusIn1() {
      this.formattedCurrencyValue1 = this.PatientInsurance.discount;
    },

    getMedicareInsuranceType() {
      if (this.PatientInsurance.medicareType) {
        InsuranceService.getInsuranceMedicareTypesById(this.PatientInsurance.medicareType)
          .then((response) => {
            if (response.data) {
              this.MedicareInsuranceType.code = this.PatientInsurance.medicareType;
              this.MedicareInsuranceType.description = response.data.description;
            }
          })
          .catch((error) => {
            if (!error.response) {
              // network error
              this.showMessage(
                this.$tc('Insurance.Insurance',1),
                this.$t('Msg.retrieveInfo'),
                "error"
              );
            } else {
              this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
            }
          });
      }
    },

    getInsuranceByCode(code){  
        InsuranceService.getInsuranceByCode(code)
                .then((response) => {
                    if(response.status == '200' && response.data){
                      this.InsuranceByCode = response.data;   
                      this.PatientInsurance.showPriorAuthorization = this.InsuranceByCode.showPriorAuthorization;
                      this.PatientInsurance.priorAuthorizationBaseOn = this.InsuranceByCode.priorAuthorizationBaseOn;

                      let visible = this.PatientInsurance.priorAuthorizationBaseOn !== 'NONE' ? true:false;                   
                      this.FieldsPrior.forEach(element => {
                        if(!visible){
                          if(element.key === 'entityDescription' || element.key === 'entityCode'){
                            element.visible = false;
                            element.class = 'hidden';                           
                          }
                        }else{
                          if(element.key === 'entityDescription' || element.key === 'entityCode'){
                            element.visible = true;
                            element.class = 'text-rigth';
                          }
                        }
                      });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage('getInsurance',error,"error");
                    }
                });
    },

    SelfPay() {
      this.PatientInsurance.insCode = "SELF";
      this.PatientInsurance.relation = "01";
      this.PatientInsurance.insType = "P";
      this.PatientInsurance.insNo = "SELF";
      this.PatientInsurance.catcode = "SELF";
      this.PatientInsurance.paymentpercent = 0;
      this.isInsTypeInvalid = false;
      this.isRelationInvalid = false;
      this.isInsCodeInvalid = false;
      this.pendingChanges = true;
      this.checkUserAction();
    },

    getPatientInsuranceID() {
      this.$emit('load', true);   
      InsuranceService.getPatientInsurancesById(this.InsuranceCodeTable)
        .then((response) => {
          if (response.data) {
            this.PatientInsurance = response.data;
            this.hasInsuranceData();
            this.totalRows = this.PatientInsurance.priorAuthorizations && this.PatientInsurance.priorAuthorizations.length > 0 ? this.PatientInsurance.priorAuthorizations.length : 0; 
            this.PriorAuthoListCopy = response.data.priorAuthorizations;
            this.PatientInsurance.priorAuthorizations.forEach(function(element) { 
                element.key = Math.floor(new Date().valueOf() * Math.random());            
                if (element.datefrom)	
                  element.datefrom = Utils.formatterDateToString(element.datefrom);
                if (element.dateto)	
                  element.dateto = Utils.formatterDateToString(element.dateto); 
            });           
            this.getInsuranceId(this.PatientInsurance.insCode);
            this.PatientInsurance.effectiveDate = this.formatterDate(
              this.PatientInsurance.effectiveDate
            );
            this.PatientInsurance.dob = this.formatterDate(
              this.PatientInsurance.dob
            );
            this.formattedCurrencyValue1 = this.PatientInsurance.discount
              ? "$ " + this.PatientInsurance.discount
              : "";

            this.formattedCurrencyValue = this.PatientInsurance.paymentpercent
              ? "$ " + this.PatientInsurance.paymentpercent
              : "";
            this.focusOut();
            this.focusOut1();
            
            this.enabledInsuredChecked = (this.PatientInsurance.relation.indexOf("01") == 0);
            this.checkedUsePatientInfo = this.enabledInsuredChecked;
            if (this.checkedUsePatientInfo) {
              this.OnUpdateInsuredFieldsPatientInfo({});
            }
            if (this.editMode) {
              this.getMedicareInsuranceType();
            }
            this.$emit('load', false);   
          }
        })
        .catch((error) => {
          this.$emit('load', false);   
          if (!error.response) {
            // network error
            this.showMessage(
              this.$tc('Insurance.Insurance',1),
              this.$t('Msg.retrieveInfo'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
          }
        });
    },

    //Obtener El Nombre de la Categoria de Insurance del Paciente
    getInsuranceCategoryList() {
      if (this.PatientInsurance.catcode) {
        InsuranceService.getInsuranceCategoryById(this.PatientInsurance.catcode)
          .then((response) => {
            if (response.data) {
              this.InsuranceCategoryList = response.data;
            }
            this.Loading = false;
            this.$emit("load", this.Loading);
          })
          .catch((error) => {
            if (error.response) {
              this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
            }
          });
      }
    },

    
    getPriorAuthoByPatientAndinsCode() { 
      this.Loading = true;
      this.$emit("load", this.Loading);
      var params = {
        SearchCriteria:"",       
        PatientCode:this.$route.params.id,
        InsuranceCode:this.PatientInsurance.insCode
      }   
      PriorAuthorizationService.getAll(params)
          .then((response) => {
            if (response.data) {
              this.PatientInsurance.priorAuthorizations = response.data;

              this.PatientInsurance.priorAuthorizations.forEach(function(element) { 
                element.key = Math.floor(new Date().valueOf() * Math.random());            
                if (element.datefrom)	
                  element.datefrom = Utils.formatterDateToString(element.datefrom);
                if (element.dateto)	
                  element.dateto = Utils.formatterDateToString(element.dateto); 

              this.totalRows = this.PatientInsurance.priorAuthorizations.length;
          });
            }
            this.Loading = false;
            this.$emit("load", this.Loading);
          })
          .catch((error) => {
            this.Loading = false;
            this.$emit("load", this.Loading);
            if (error.response) {
              this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
            }
          });      
    },

    //COMBO -- Obtener Patients Insurance Relations
    async getPatientInsuranceRelationsList() {
     await InsuranceService.getPatientInsuranceRelations()
        .then((response) => {
          if (response.data) {
            this.PatientInsuranceRelations = response.data;
          }
          this.Loading = false;
          this.$emit("load", this.Loading);
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
          }
        });
    },

    //COMBO -- Obtener Patients Insurance Relations
    async getInsuranceTypesList() {
      await InsuranceService.getPatientInsuranceTypes()
        .then((response) => {
          if (response.data) {
            this.InsuranceTypesList = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
          }
        });
    },

    getInsuranceId(idInsurance) {
      InsuranceService.getInsuranceByCode(idInsurance)
        .then((response) => {
          if (response.status == '200' && response.data) {
            this.Insurance = response.data;
            this.PatientInsurance.catcode = this.Insurance.category;
            this.InsuranceCategory = this.Insurance.categoryDescription;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.showMessage(
              this.$tc('Insurance.Insurance',1),
              this.$t('Msg.retrieveInfo'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance',1), this.$t('Insurance.NotValidInsuranceCode', { idInsurance: idInsurance }), "error");
          }
        });
    },

    AddInsurance() {
      this.PatientInsurance.patientcode = this.PatientCode;
      this.PatientInsurance.priorAuthorizations = this.PriorAuthoListCopy;   
      this.PrepareInsurance();         
      InsuranceService.addPatientInsurances(this.PatientInsurance)
        .then((response) => {
          if (response.status == '200') {
                this.pendingChanges = false;
                if(this.ClickSave){
                 if(this.$route.name === 'add-patient-insurance'){
                        this.$router.push({
                           name: "patient-insurance",
                           params: { id: this.$route.params.id },
                        });
                 }
                 else if(this.$route.name === 'add-appointments-insurance'){
                        this.$router.push({
                           name: "appointments-insurance",
                           params: { id: this.$route.params.id, actId: this.$route.params.actId },
                        });
                 } 
                 else{
                  this.$router.push({
                           name: "Clinical-insurance",
                           params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                        });
                 } 
                }                    
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Insurance.InsuranceSavedSuccessfully'), {
                         title: this.$t('Insurance.SavingInsurance'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
          }
        })
        .catch((error) => {
          this.$emit('load', false);
          this.PatientInsurance.priorAuthorizations.forEach(function(element) {           
              if (element.datefrom)	
                  element.datefrom = Utils.formatterDateToString(element.datefrom);
              if (element.dateto)	
                  element.dateto = Utils.formatterDateToString(element.dateto); 
            });

          elemSaveBtn.removeAttribute("disabled");
        
          if (!error.response) {
                   this.showMessage(this.$tc('Insurance.Insurance',1),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$tc('Insurance.Insurance',1),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$tc('Insurance.Insurance',1),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$tc('Insurance.Insurance',1),error.response.data.errorMessage,"error");
                     } 
        });
    },

    UpdateInsurance() {
      this.PatientInsurance.priorAuthorizations = this.PriorAuthoListCopy;
      this.PrepareInsurance();
      InsuranceService.updatePatientInsurances(
        this.InsuranceCodeTable,
        this.PatientInsurance
      )
        .then((response) => {
          if (response.status == '200') {
               this.pendingChanges = false;
               if(this.ClickSave){
                 if(this.$route.name === 'patient-insurance-details'){
                        this.$router.push({
                           name: "patient-insurance",
                           params: { id: this.$route.params.id },
                        });
                 }
                 else if(this.$route.name === 'appointments-insurance-details'){
                        this.$router.push({
                           name: "appointments-insurance",
                           params: { id: this.$route.params.id, actId: this.$route.params.actId },
                        });
                 }
                 else{
                      this.$router.push({
                           name: "Clinical-insurance",
                           params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                        });
                } 
              }

                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Insurance.InsuranceSavedSuccessfully'), {
                         title: this.$t('Insurance.SavingInsurance'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50);       
          }
          this.$emit('load', false);
        })
        .catch((error) => {
            this.$emit('load', false);
            this.PatientInsurance.priorAuthorizations.forEach(function(element) {           
              if (element.datefrom)	
                  element.datefrom = Utils.formatterDateToString(element.datefrom);
              if (element.dateto)	
                  element.dateto = Utils.formatterDateToString(element.dateto); 
            });
            elemSaveBtn.removeAttribute("disabled");      

              if (!error.response) {
                   this.showMessage(this.$tc('Insurance.Insurance',1),this.$t('Msg.NotAbleToUpdate'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$tc('Insurance.Insurance',1),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$tc('Insurance.Insurance',1),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$tc('Insurance.Insurance',1),error.response.data.errorMessage,"error");
                     } 
        });
    },

    EmitUpdateInsuranceList() {
      this.$emit("UpdateInsuranceList", {});
    },

    PrepareInsurance(){     
      this.PatientInsurance.priorAuthorizations.forEach(element => {      
         element.datefrom = Utils.formatterToISO(element.datefrom, Utils.getShortDateFormat());
         element.dateto = Utils.formatterToISO(element.dateto, Utils.getShortDateFormat());               
      });
    },

    ClickSaveCall(){
      this.ClickSave = true;
      this.SaveOrEdit();
    },

    SaveOrEdit() {
      if(this.validate_entries()){
        if (this.validate_required()) {
          elemSaveBtn.setAttribute("disabled","disabled");
          this.$emit('load', true);
          if (this.editMode) {
            this.pendingChanges = false 
            this.UpdateInsurance();
          } else {
            this.AddInsurance();
          }
        } else {
          this.showMessage(this.$tc('Insurance.Insurance',1), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
        }
      } else {
        this.showMessage(this.$tc('Insurance.Insurance',1),this.$t('Msg.InvalidFieldValuesDetected'),"errorAutoHide");
        // this.$route.router.go('/');
      }
    },


    DiscardChanges() {    
      if(this.$route.name === 'patient-insurance-details' || this.$route.name === 'add-patient-insurance'){
                        this.$router.push({
                           name: "patient-insurance",
                           params: { id: this.$route.params.id },
                        });
        }
        else if(this.$route.name === 'appointments-insurance-details' || this.$route.name === 'add-appointments-insurance'){
                        this.$router.push({
                           name: "appointments-Insurance",
                           params: { id: this.$route.params.id, actId: this.$route.params.actId },
                        });
        }
        
        else{
                      this.$router.push({
                           name: "Clinical-insurance",
                           params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                        });
         }                    
     /* this.$router.push({
        name: "patient-insurance",
        params: { id: this.$route.params.id },
      });*/
    },

    EnabledItemRightMenu() {
      this.OptionsRightMenu.PatientCode = this.$route.params.id;
      this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
      this.OptionsRightMenu.visible = true;
      this.OptionsRightMenu.itemdisabled = false;
      this.$emit("getVisibleMenu", this.OptionsRightMenu);
    },

    EnableBtnRightMenu() {
      this.OptionsRightMenu.btnvisible = true;
      this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
      this.OptionsRightMenu.Mode = this.edit_mode;
      this.$emit("getBtnRightMenu", this.OptionsRightMenu);
    },

    formatterDate(value) {
      return Utils.formatterDate(value);
    },

    formatterDateToString(date) {
      return Utils.formatterDateToString(date);
    },

    validate_required() {
      this.isInsTypeValid = this.PatientInsurance.insType && this.PatientInsurance.insType.length > 0 ? true : false
      this.isRelationValid = this.PatientInsurance.relation && this.PatientInsurance.relation.length > 0 ? true : false
      this.isInsCodeValid = this.PatientInsurance.insCode && this.PatientInsurance.insCode.length > 0 ? true : false
      this.isInsNoValid = this.PatientInsurance.insNo && this.PatientInsurance.insNo.trim() != "" && this.PatientInsurance.insNo.length > 0 ? true : false
      
      if (!this.isInsTypeValid || !this.isRelationValid || !this.isInsCodeValid || !this.isInsNoValid ) {
        return false;
      }
      return true;
    },

    validate_entries() {
      if (this.PatientInsurance.dob) {
        this.isDOBValid = Utils.validateBirthDate(this.PatientInsurance.dob) ? true : false;
        if(  this.isDOBValid) {
            return true;
            } else {
            return false;   
            }
        } else {
          return true
        }
      },
    beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		},
    checkUserAction() {
      if (elemSaveBtn.disabled) {
        elemSaveBtn.removeAttribute('disabled');
      }
    },
    PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);	
      if(this.$route.name.includes('Clinical-insurance')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = Utils.formatterDateToString(this.RegistrationInfo.dob);
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-insurance') || this.$route.name.includes('appointments-insurance')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    },
    hasInsuranceData() {
      // Define the keys you want to check
      const requiredKeys = [
        'insFirstname', 'insLastname', 'address1', 'address2', 'city','country',
        'dob', 'employment', 'homePhone', 'initial',  'state', 'workPhone' ,'zip'
      ];

      this.checkedInfo = false
      // Check if *at least one* key has a non-empty value
      for (const key of requiredKeys) {
        if (this.PatientInsurance[key]) {
          this.checkedInfo = true
          return true; // If one is found, return true immediately
        }
      }
      // If none of the keys have values, return false
      return false; 
    }
  },

  beforeUpdate() { },

  async mounted() {
    //this.EnabledItemRightMenu();
    //this.EnableBtnRightMenu();   
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-insurance') ? EnumService.ClinicalItems : this.$route.name.includes('appointments-insurance') ? EnumService.AppointmentItems : EnumService.PatientItems;
    this.OptionsRightMenu.visible = true;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);
    this.PatientCode = this.$route.params.id;
    await this.getPatientInsuranceRelationsList();
    await this.getInsuranceTypesList();
    //this.getPatient(this.$route.params.id);
   
    if (this.editMode) {
      this.InsuranceCodeTable = this.$route.params.idInsurance;
      this.getPatientInsuranceID();
      this.isReadOnly = this.$store.state.isPatientInsuranceEHRReadOnly;
    } else {
      //this.PatientInsurance = {};
      this.InsuranceCodeTable = "";
      this.enabledInsuredChecked = true;
      this.checkedUsePatientInfo = false;
    }
    
    if(this.$route.name.includes('patient-insurance') || this.$route.name.includes('appointments-insurance')){  
        this.GetPatientByCode();
     } 

    if(this.$route.name.includes('Clinical-insurance')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  Utils.formatterDateToString(this.RegistrationInfo.dob);

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;

    }

    this.PopulatePatientTitle();    
    setTimeout( function() {
        elemSaveBtn = document.getElementById('btn_save_patient_insurance');
        elemSaveBtn.setAttribute('disabled','');
    }, 50, this );
  },

  destroyed() {
    this.OptionsRightMenu.ItemsList = [];
    this.OptionsRightMenu.Mode = false;
    this.OptionsRightMenu.visible = false;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
          this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },
    
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
};
</script>
