<template>
    <div>
        <a 
            :class="{'isDisabled': itemdisabled, rmActiveItem : active_el == it.value,'rm-optionssum':PatientSummary.includes(it.value)}" 
            v-for="it in this.searchInMenuItems(this.ItemsListAuthorized,searchOptModel)" 
            :key="it.value" @click="triggerClick(it.value,it.text,it.href, $event)" :id="it.value"> 

        {{it.text}}    

        </a>        
    </div>   
</template>

<script>
//import Enum from '@/services/enum.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import LocalStorage from '@/services/storage.service'
import AppointmentService from '@/services/appointment.service'
import ApptCheckinsService from '@/services/appointmentcheckins.service'

import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'Item',
  mixins: [ShowMessage, DBFunctions],
 data(){
    return{
        ItemsList:[],      
        Class:'', 
        PatientSummary:["PatientSummary","ClinicalSummary","SchedulerSummary"],      
        itemdisabled : false,
        Appointment:{},
        CheckInsAppt:{
            timeCheckIn:"",
        },
      
        PatientCode : '',
        ItemsListAuthorized:[],
        parameter: {
          UserId: "",
        },
        active_el:'',
        message:this.$t('Layout.displayedScreen'),
        searchOptModel:'',
        AppointmentRouteNames: [
            'treatmentplanbase-Scheduler',
            'schedulerdb-activitiesList',
            'scheduler-appointment-notes',
            'appointment-Dashboard',
            'Appointment-Editor',
            'Appointments-PatientInfo',
            'MdTreatmentplan-Appointment',
            'MdTreatmentplan-add-Appointment',
            'WyTreatmentplan-add-Appointment',
            'WyTreatmentplan-Appointment',
            'appointments-admissionList',
            'appointments-admission-add',
            'appointments-admission-edit',
            'appointments-allergies',
            'appointments-allergy-details',
            'appointments-allergy-add',
            'appointments-special-precautions',
            'appointments-asam',
            'appointments-chartreview',
            'appointments-contacts',
            'appointments-contact-details',
            'appointments-contact-add',
            'appointments-diagnosis',
            'appointments-document',
            'appointments-document-detail',
            'appointments-document-add',
            'appointments-insurance',
            'appointments-insurance-details',
            'add-appointments-insurance',
            'appointments-vital-sign',
            'appointments-vs-add',
            'appointments-vs-details',
            'appointments-discharge-admission',
            'appointments-discharge',
            'appointments-transfer-admission',
            'appointments-transfer',
            'appointments-epharmacy',
            'Appointments-Medications',
            'Appointments-medications-add',
            'Appointments-medications-details',
            'appointments-Insurance'
        ],
    }
  },
 
  methods: {    
  
    triggerClick: function (eventName,text,href, event){        
        if(event.target.className != 'isDisabled'){
            this.active_el = eventName;
             // Favorites Actions
            this.checkExistFavorite(href);
            EventBus.$emit('onUpdatedMenu', '');

            // Mapping Functions
            const actionsMap = {
                'PatientSearch': this.GotoPatientList.bind(this),
                'PatientSummary': this.GotoPatientSummary.bind(this),
                'SchedulerSummary': this.GotoSchedulerSummary.bind(this),
                'ClinicalSummary':this.GotoClinicalSummary.bind(this),
                'AppointmentList': () => this.launchPage(this.$t('MdNotesByAppt.Appointment'), 'appointments', true),
                'SchedulerNotes': this.GotoSchedulerNotes.bind(this),
                'Activities': this.GotoActivities.bind(this),
                'ShowUpAppt': () => this.ShowUpAppt(text),
                'ConfirmAppt': () => this.ConfirmAppt(text),
                'CancelAppt': () => this.OpenAppointmentReason(text),
                'PatientInfo': () => {
                    this.GotoPatientInfo();
                    this.isActive = !this.isActive;
                },
                'Appointment': () => this.showAppointment(text),
                'GeneralNotes': this.GotoGeneralNotes.bind(this),
                'PatientAsam': () => this.GotoAsam(),
                'PatientContact': () => this.GotoPatientContact(),
                'PatientInsurance': () => this.GotoInsurances(),
                'PatientDocument': () => this.GotoDocuments(),
                'ClinicalNotes': () => this.launchPage(this.$t('ClinicalNotes.ClinicalNotes'), 'Clinical-Notes'),
                'PatientListClinical': this.GotoClinical.bind(this),
                'ClinicalAsam': () => this.launchPage(this.$t('Asam.ASAM'), 'Clinical-Asam'),
                'PatientAllergy': () => this.GotoAllergies(),
                'PatientSpecialPrecautions': () => this.GotoSpecialPr(),
                'ClinicalVitalSign': () => this.GotoVitalSign(),
                'Medications': () =>  this.GotoMedications(),
                'Admission': () => this.GotoAdmission(),
                'CHARTREVIEWCLINICAL': () => this.launchPage(this.$t('ChartReview.CHARTREVIEW'), 'Clinicalchartreview'),
                'ePharmacyPatient': () => this.launchPage(this.$t('ePharmacyBase.ePharmacyTitle'), 'patientePharmacy', true),
                'ePharmacyClinical': () => this.GotoePharmacy(),
                'CHARTREVIEWPATIENT': () => this.GotoChartReview(),
                'Transfer': () => this.GotoTransfer(),
                'Discharge': () => this.GotoDischarge(),
                'Diagnosis': () => this.GotoDiagnosis(),
                'DailyNotes': () => this.launchPage(this.$t('DailyNoteList.DailyNotesTitle'), 'Clinical-daily-note', true),
                'LogNotes': () => this.launchPage(this.$t('LogNoteList.LogNotesTitle'),'Clinical-log-note', true),
                'ClaimBatchList': this.GotoClaimBatchList.bind(this),
                'DiagnosisClinical': () =>  this.launchPage(this.$t('MdPatientDiagCodes.Diagnosis'),'Clinical-diagnosis', false),
                'InsuranceClinical': () =>  this.launchPage(this.$tc('Insurance.Insurance'),'Clinical-insurance', false),
                'AllergyClinical': () =>  this.launchPage(this.$t('Allergy.PatientAllergies'),'Clinical-allergies', false),
                'incident-categories': () =>  this.launchPage(this.$t('ClinicalNotes.CATEGORIES'), 'incident-categories'),
                'DocumentClinical': () =>   this.launchPage(this.$t('employee.Document'),'Clinical-document', false),
                'ContactClinical': () =>   this.launchPage(this.$tc('Contact.Contact'),'Clinical-cont', false),
                'ADMISSIONINFOCLINICAL': () =>   this.launchPage(text,'admission-clinical', true),
                'WYTREATMENTPLAN': () =>    this.launchPage(text,'WyTreatmentplan-Clinical', false),
                'MDTREATMENTPLAN': () =>    this.launchPage(text,'MdTreatmentplan-Clinical', false),
                'TreatmentPlanClinical': () =>    this.launchPage(text,'treatmentplanbase-Clinical', false),
                'TreatmentPlanScheduler': () =>    this.launchPage(text,'treatmentplanbase-Scheduler', false),
                'SchedulerClinical': () =>    this.launchPage(text,'SchedulerClinical', false),
                'Appointments': () =>    this.launchPage(text,'patient-appoint', false),
                'ClinicalAppointments': () =>    this.launchPage(text,'clinical-appoint', false),
                'AppointmentInsurance': () =>    this.launchPage(text,'appointments-Insurance', false),
                'ClinicalSpecialPrecautions': () =>  this.launchPage(this.$t('SpecialPrecautions.PatientSpecialPrecautions'),'Clinical-special-precautions', false),
            };
            // Execute mapped actions, if exist
            const menuAction = actionsMap[eventName];
            if (menuAction) {
                menuAction(); // call action
            }
        }
    },
    // Helper function to display toast
    showToast(titleKey, msgKey, variant = "success") {
        this.$bvToast.toast( msgKey, {
            title: titleKey,
            autoHideDelay: 4000,
            variant: variant
        });
    },

    handleError(error) {
        if (!error.response) {               
            this.showMessage(this.$t('MdNotesByAppt.Appointment'),this.$t('Msg.RetrieveInformation'),"error");
        } else {
            this.showMessage(this.$t('MdNotesByAppt.Appointment'),error,"error");
        }
    },

    async GetPermission(){  
        if(this.$route.name.includes('appointment')){
            this.getCheckinsByActivityId();
            this.Appointment = await this.getAppointmentByActId(this.$route.params.actId); 
            !this.Appointment.private ? EventBus.$emit('onVisibleApptType', false) : EventBus.$emit('onVisibleApptType', true);           
        }
        this.GetItemsListAuthorized(); 
    },
          
    GotoPatientList(){
       this.$router.push('/app/patients').catch(()=>{});
    },

  

    GotoActivities(){        
        this.$router.push({ name: "schedulerdb-activitiesList", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
    },

    OpenAppointmentReason(text){    
        if(text === this.$t('Appointments.cancelAppt')){
            if(!this.Appointment.private){
                EventBus.$emit('onAppointmentReasonModal', text);   
            }else{
                this.showToast(this.$t('MdNotesByAppt.Appointment'),this.$t('Appointments.noCancelMsg'));
            }
        } else{
            this.CancelAppt(text,'');
        }
    },

    GotoGeneralNotes(){
      if(this.$route.params.id != ''){
        this.PatientCode = this.$route.params.id;
      }
      this.$router.push({ name: "patient-generalnotes", params: { id: this.PatientCode } }).catch(()=>{});
    },

    GotoSchedulerNotes(){
        if (this.$router.currentRoute.name === "scheduler-appointment-notes") {
                this.showToast(
                    this.$t('ENUM.CITEMS.SchedulerNotes'),
                    this.message
                );
                return; // Early return
            }   
      this.$router.push({ name: "scheduler-appointment-notes", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
    },

    GotoPatientInfo() {
        const { id, recordid, actId } = this.$route.params;

        if (id) { this.PatientCode = id; }// Assign PatientCode only if id exists

        const pRouteNames = ['patient', 'contact', 'allergy'];
        const cRouteNames = ['Clinical', 'vs', 'lognote-add', 'dailynote-add'];
        // Handle patient-related routes
        if (pRouteNames.some(route => this.$route.name.includes(route))) {
            if (this.$router.currentRoute.name === 'patient-details') {
                this.showToast(
                    this.$t('MdPatientInfoBox.PatientInfo'),
                    this.message
                );
                return; // Early return to prevent further processing
            } 
            this.$router.push({ name: 'patient-details', params: { id: this.PatientCode } });
            return; // Early return
        }
        // Handle clinical-related routes
        if (cRouteNames.some(route => this.$route.name.includes(route))) {
            if (this.$router.currentRoute.name === "Clinical-PatientInfo") {
                this.showToast(
                    this.$t('MdPatientInfoBox.PatientInfo'),
                    this.message
                );
                return; // Early return
            }
            this.$router.push({ name: "Clinical-PatientInfo", 
                params: { 
                    id: this.PatientCode, 
                    recordid: recordid 
                } 
            }).catch(() => {});
            return; // Early return
        }

        
        if (this.AppointmentRouteNames.some(route => this.$route.name.includes(route))) {
            if (this.$router.currentRoute.name === 'Appointments-PatientInfo') {
                this.showToast(
                    this.$t('MdPatientInfoBox.PatientInfo'),
                    this.message
                );
                return;
            } 
            this.$router.push({ name: 'Appointments-PatientInfo', 
                params: { 
                    id: this.PatientCode, 
                    actId: actId 
                }  
            });
            return;
        }
    },

    gotoPage(pageName, titleKey, routeName, v_patient) {
        const { id, actId } = this.$route.params;

        if (id) { this.PatientCode = id; }

        const currentRouteName = this.$router.currentRoute.name;
        const isAppointmentRoute = this.AppointmentRouteNames.some(route => currentRouteName.includes(route));

        if (isAppointmentRoute) {
            if (currentRouteName === routeName) {
                this.showToast(titleKey, this.message);
                return;
            }
            this.$router.push({
                name: routeName,
                params: { id: this.PatientCode, actId: actId },
            });
            return;
        } else {
            this.launchPage(titleKey, pageName, v_patient);
        }
    },
   
    GotoPatientSummary(){ this.gotoPage('patient-summary', this.$t('PatientSummary.PatientDashboard'), 'patient-summary', true);}, 
    GotoSchedulerSummary(){ this.gotoPage('appointment-Dashboard', this.$t('schedulerDB.ScheDashboard'), 'appointment-Dashboard', false);},   
    GotoClinicalSummary(){ this.gotoPage('Clinical-Dashboard', this.$t('Clinical Dashboard'), 'Clinical-Dashboard', false);},
    GotoAdmission(){ this.gotoPage('patient-admissionList', this.$t('Admission.Admission'), 'appointments-admissionList', true);},
    GotoPatientContact(){ this.gotoPage('patient-contacts', this.$tc('Contact.PatientContact', 2), 'appointments-contacts', true);},   
    GotoAllergies(){ this.gotoPage('patient-allergies', this.$t('Allergy.PatientAllergies'), 'appointments-allergies', true);},
    GotoSpecialPr(){ this.gotoPage('patient-special-precautions', this.$t('SpecialPrecautions.PatientSpecialPrecautions'), 'appointments-special-precautions', true);},
    GotoChartReview(){ this.gotoPage('patientchartreview', this.$t('ChartReview.CHARTREVIEW'), 'appointments-chartreview', true);},
    GotoAsam(){ this.gotoPage('patient-asam', this.$t('Asam.ASAM'), 'appointments-asam', true);},
    GotoDiagnosis(){this.gotoPage('patient-diagnosis', this.$t('MdPatientDiagCodes.Diagnosis'), 'appointments-diagnosis', true);},
    GotoDocuments(){ this.gotoPage('patient-document', this.$t('employee.Document'), 'appointments-document', true);},
    GotoInsurances(){ this.gotoPage('patient-insurance', this.$tc('Insurance.Insurance'), 'appointments-insurance', true);},
    GotoVitalSign(){ this.gotoPage('Clinical-vital-sign', this.$t('vitalSignsRep.ReportTitle'), 'appointments-vital-sign');},
    GotoDischarge(){ this.gotoPage('Clinical-Discharge', this.$t('discharge.Discharge'), 'appointments-discharge-admission');},
    GotoTransfer(){ this.gotoPage('Clinical-Transfer', this.$t('transfer.Transfer'), 'appointments-transfer-admission');},
    GotoePharmacy(){ this.gotoPage('clinicalePharmacy', this.$t('ePharmacyBase.ePharmacyTitle'), 'appointments-epharmacy', false);},
    GotoMedications(){ this.gotoPage('Clinical-Medications', this.$t('Medication.Medications'), 'Appointments-Medications', true);},

    GotoApptInsurance(){ this.gotoPage('patient-contacts', this.$tc('Contact.PatientContact', 2), 'appointments-insurance', true);},

    GotoSchedulerDB(){       
        this.$router.push('/app/appointments').catch(()=>{});
    },

    GotoClinical(){
       this.$router.push('/app/clinical').catch(()=>{});
    },

    ExecuteShowUp(param,text){
        AppointmentService.ShowUp(param)
            .then((response) => {
                if(response.status == '200'){
                    this.GetPermission();
                    if(text === this.$t('Appointments.uShowUp')){ 
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.uShowUpMsg')
                        );
                    } else {
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.showUpMsg')
                        );
                    }
                }
            })
            .catch(error => {
                this.handleError(error);
            });      
    },

    getCheckinsByActivityId(){
        var param = {
            Code:this.$route.params.actId
        }
        ApptCheckinsService.getAll(param)
            .then((response) => {
                if(response.status == '200' && response.data.length > 0){
                    this.CheckInsAppt = response.data[0];                   
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    },

    ShowUpAppt(text){
        var param = {
            PatientCode : this.$route.params.id,
            ActivityIdList : this.$route.params.actId,
            Action:text,
            DoctorCode:this.Appointment.therapistcode,
        };

        if(text === this.$t('Appointments.uShowUp')){           
            param.State = "F";
            if( (this.CheckInsAppt.timeCheckIn === "") || (this.CheckInsAppt.timeCheckIn === null)){
                this.ExecuteShowUp(param,text);
            }else{
                 this.showToast(
                    this.$t('MdNotesByAppt.Appointment'),
                    this.$t('Appointments.pAlreadyMsg')
                );
            }            
        }
        else{
            param.State = "T";
            if( !this.Appointment.reason ) {// This checks for "", null, undefined, etc.
                this.ExecuteShowUp(param,text);
            } else {
                 this.showToast(
                    this.$t('MdNotesByAppt.Appointment'),
                    this.$t('Appointments.aCancelMsg'),
                    "danger"
                );
            }
        }
    },

    ConfirmAppt(text){
        var param = {           
            ActivityIdList: this.$route.params.actId, // must be List          
        };

        if(text === this.$t('Appointments.uconfirm')){           
            param.IsConfirmed = "F";
        }
        else{
            param.IsConfirmed = "T";
        }

        AppointmentService.ConfirmAppt(param)
            .then((response) => {
                if(response.status == '200'){
                    this.GetPermission();
                    if(text === this.$t('Appointments.uconfirm')){ 
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.nConfirmMsg')
                        );
                    } else {
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.confirmMsg')
                        );
                    }
                }
            })
            .catch(error => {
                this.handleError(error);
        });
    },

    CancelAppt(text,reason){
        var param = {           
            ActivityIdList: this.$route.params.actId, // must be List          
        };
        param.Reason = "";
        if(text === this.$t('Shared.BtnCancel')){           
            param.Reason = reason;
        }

        if (param.ActivityIdList) {
            AppointmentService.CancelAppt(param)
                .then((response) => {
                    if(response.status == '200'){
                        this.GetPermission();
                        if(text === this.$t('Appointments.uCancel')){ 
                            this.showToast(
                                this.$t('MdNotesByAppt.Appointment'),
                                this.$t('Appointments.cancelUnDoneMsg')
                            );
                        } else {
                            this.showToast(
                                this.$t('MdNotesByAppt.Appointment'),
                                this.$t('Appointments.cancelMsg')
                            );
                        }
                    }
                })
                .catch(error => {
                    this.handleError(error);
                });
        }
    },

    GotoClaimBatchList(){
      this.$router.push('/app/claimbatch').catch(()=>{});
    },

    showAppointment() {
        const { id, actId } = this.$route.params;

        if (id) { this.PatientCode = id; }// Assign PatientCode only if id exists

        if (this.$router.currentRoute.name === 'Appointment-Editor') {
            this.showToast(
                this.$t('MdNotesByAppt.Appointment'),
                this.message
            );
            return;
        } 
        this.$router.push({ name: 'Appointment-Editor', 
            params: { 
                id: this.PatientCode, 
                actId: actId 
            }  
        });
        return;
    },

    launchPage(v_title, v_name, v_patient) {
        const { id, recordid } = this.$route.params; // Destructure for clarity

        if (id) { this.PatientCode = id; }

        if (this.$router.currentRoute.name === v_name) {
            // show toast if on the same route
            this.$bvToast.toast(this.message, {
                title: v_title,
                autoHideDelay: 4000,
                variant: "success",
            });
            return; // Early return
        } 
        // Construct params based on v_patient value
        const params = {
            id: this.PatientCode,
            ...(v_patient ? {} : { recordid }), // Include recordid only if v_patient is false
        };
        // Navigate to the new route
        this.$router.push({ name: v_name, params }).catch(() => {});
    },
    
    GetItemsListAuthorized(){
        this.ItemsListAuthorized = [];
        this.parameter.UserId = LocalStorage.getUserId(); 

        const addMenuItemConditionally = (element, condition = true, path) => {
            if (condition) {
                this.addMenuItem(element.text, element.value, path);
            }
        };
        this.ItemsList.forEach(element => { 
            switch (element.value) {
                case 'PatientSearch':
                    addMenuItemConditionally(element, true, '/app/patients');
                    break;
                case 'PatientSummary':
                    addMenuItemConditionally(element, true);
                    break;
                case 'SchedulerSummary':
                    addMenuItemConditionally(element, true);
                    break;
                case 'ClinicalSummary':
                    addMenuItemConditionally(element, true);
                    break;
                case 'SchedulerNotes':
                    addMenuItemConditionally(element, this.$store.state.isUseSchedulerClinical);
                    break;
                case 'Activities':
                case 'AppointmentList':
                    addMenuItemConditionally(element, true);
                    break;
                case 'ShowUpAppt':
                    element.text = this.Appointment.private ?
                        this.$t('Appointments.uShowUp') :
                        this.$t('Appointments.showUp');
                    addMenuItemConditionally(element);
                    break;
                case 'CancelAppt':
                    element.text = this.Appointment.reason ?
                        this.$t('Appointments.uCancel') :
                        this.$t('Appointments.cancelAppt');
                    addMenuItemConditionally(element);
                    break;
                case 'ConfirmAppt':
                    element.text = this.Appointment.isConfirmed ?
                        this.$t('Appointments.uconfirm') :
                        this.$t('Appointments.confirm');
                    addMenuItemConditionally(element);
                    break;
                case 'PatientInfo':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientInfo);
                    break;
                case 'PatientInsurance':
                case 'InsuranceClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientInsurance);
                    break;
                case 'PatientDocument':
                case 'DocumentClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientDocument);
                    break;
                case 'PatientContact':
                case 'ContactClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientContact);
                    break;
                case 'PatientAsam':
                case 'ClinicalAsam':
                    addMenuItemConditionally(element, this.$store.state.isUseASAM);
                    break;
                case 'PatientListClinical':
                    addMenuItemConditionally(element, true, '/app/clinical');
                    break;
                case 'ClinicalNotes':
                    addMenuItemConditionally(element, this.$store.state.isUseClinicalNotes);
                    break;
                case 'PatientAllergy':
                case 'AllergyClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientAllergies);
                    break;
                case 'ClinicalVitalSign':
                    addMenuItemConditionally(element, this.$store.state.isUseVitalSign);
                    break;
                case 'Medications':
                    addMenuItemConditionally(element, this.$store.state.isUseMedications);
                    break;
                case 'Admission':
                case 'ADMISSIONINFOCLINICAL':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientAdmission);           
                    break;
                case 'CHARTREVIEWCLINICAL':
                case 'CHARTREVIEWPATIENT':
                    addMenuItemConditionally(element, this.$store.state.isUseChartReview);  
                    break; 
                case 'ePharmacyPatient':
                case 'ePharmacyClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsepharmacyPatientNewCrop);  
                    break; 
                case 'DailyNotes':
                    addMenuItemConditionally(element, this.$store.state.isUseDailyNotes); 
                    break;
                case 'LogNotes':
                    addMenuItemConditionally(element, this.$store.state.isUseLogNotes);
                    break;
                case 'Transfer':
                    addMenuItemConditionally(element, this.$store.state.isUseTransfer);
                    break;   
                case 'Discharge':
                    addMenuItemConditionally(element, this.$store.state.isUseDischarge);
                    break;  
                case 'Diagnosis':
                case 'DiagnosisClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientDiagnosis);
                    break;  
                case 'ClaimBatchList':
                case 'PatientInfoClaimBatch':    
                case 'DiagnosisWorksSheet':
                case 'Doctors':
                case 'Insurances':
                case 'incident-report':
                case 'incident-dashboard':
                case 'incident-report-general-info':
                case 'incident-categories':
                case 'incident-involved':
                case 'incident-participants':
                    addMenuItemConditionally(element);
                    break;                 
                case 'WYTREATMENTPLAN':
                case 'MDTREATMENTPLAN':
                case 'TreatmentPlanClinical':
                case 'TreatmentPlanScheduler':
                    addMenuItemConditionally(element, this.$store.state.isUseTreatmentPlanClinical); 
                    break;
                case 'SchedulerClinical':
                    addMenuItemConditionally(element, this.$store.state.isUseSchedulerClinical); 
                    break;
                case 'Appointment':
                case 'Appointments':
                case 'ClinicalAppointments':
                case 'AppointmentInsurance':
                    addMenuItemConditionally(element, this.$store.state.isAppointments); 
                    break;  
                case 'PatientSpecialPrecautions':
                case 'ClinicalSpecialPrecautions':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientSpecialPr);
                    break;
                default:
                    break;
            }
      });

      if (this.ItemsListAuthorized.length > 2) {
        const sorted = this.ItemsListAuthorized
            .filter((v, index) => index > 1)
            .sort((a, b) => {
                const x = a.text.toLowerCase();
                const  y = b.text.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });

        this.ItemsListAuthorized = [this.ItemsListAuthorized[0],this.ItemsListAuthorized[1], ...sorted];
      }
    },
    
    addMenuItem(text, value, href) {
        const item = { text, value, href };
        this.ItemsListAuthorized.push(item);
    }
},

created(){
    EventBus.$on("onSelectAppointmentReason", function (param) { 
		this.CancelAppt(param.Text,param.Reason);
    }.bind(this));

    EventBus.$on("onSearchOpt", function (value) {
      this.searchOptModel = value
	}.bind(this));
}, 

updated(){
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;
},

beforeCreate(){
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;
},

 mounted() {
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;   
    this.ItemsList = this.$attrs.ItemList;   
     this.GetPermission(); 
     if(this.$route.name === 'patient-summary'){
        this.active_el = "PatientSummary";
     }  
     else if(this.$route.name === 'Clinical-Dashboard'){
        this.active_el = "ClinicalSummary";
     }
     else if(this.$route.name === 'appointment-Dashboard'){
        this.active_el = "SchedulerSummary";
     } 
       
 },
  destroyed(){
	EventBus.$off('onSearchOpt');
  }
}
    

</script>

<style>
.isDisabled {
  cursor: not-allowed!important;
  opacity: 0.5!important;
}
.isDisabled > a {
  color: currentColor!important;
  display: inline-block!important;  /* For IE11/ MS Edge bug */
  pointer-events: none!important;
  text-decoration: none!important;
}
.rmActiveItem {
  background-position: -230px !important;
  color: #FFF !important;
}
</style>


