<template>
   <div class="row page-height">
      <div class="col-12">
      <h4 v-if="this.Mode" class="page-title text-truncate">
			{{ $t('VitalSign.EditPatientVitalSign') }} - 
         <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
        <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
        <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
        <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
        <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
        <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
        <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
        <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
        <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
		<span v-else>- {{ $t('Shared.Active') }}  </span>
		</h4>  
       <h4 v-if="!this.Mode" class="page-title text-truncate">
			{{ $t('VitalSign.AddPatientVitalSign') }} - 
         <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
        <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
        <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
        <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
        <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
        <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
        <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
        <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
        <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
        <span v-else>- {{ $t('Shared.Active') }}  </span>
		</h4>    

      <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
          </div>
        </div>
         <!-- !! Important ,Added for testing purpose -->
          <div style='display:none;'><MdEdit 
                v-bind:TypeProp="'text'" 
                v-model="this.VitalSId"
                v-bind:Id="'input_vitals_id'">                                     
              </MdEdit>
          </div>
          <!-- End -->
         <b-row>
            <b-col md="3" lg="3" sm="12">
               <MdSelect
                  v-bind:Label="$tc('Shared.Employee')"
                  v-bind:Options="EmployeeList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="vitalObj.therapistCode"   
                  v-bind:isRequired="true" 
                  v-bind:isValid="isEmployeeValid"                    
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownEmployees"
                  :IsDisabled="isReadOnly"
               />
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.DateAndTime')"
                  v-bind:Name="'transDate'"
                  v-bind:TypeProp="'text'" 
                  v-bind:Mask="'##/##/#### ##:## AA'"
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"              
                  v-bind:isRequired="true"
                  v-bind:isValid="IsValidDateandTime"
                  v-model="TransDate"
                  v-bind:isReadOnly="isReadOnly"
               >                                     
               </MdEdit>
            </b-col>
         </b-row>
         <b-row>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Temperature') + label('Temperature')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'temp'"
                  v-model.number="vitalObj.temp"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_temperature'"
                  v-on:keydown="onKeydown"
                  :StepProp="'0.1'"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdSelect
                     v-bind:Label="$t('VitalSign.Method')"
                     v-bind:Options="OptionTempMethod"
                     v-bind:FieldDescription="'name'"
                     v-bind:FieldCode="'value'"
                     v-bind:Model="vitalObj.tempMethod"                       
                     v-bind:Multiple="false"
                     :Input="ChangeDropDowntempMethod"
                     :IsDisabled="isReadOnly"
                     v-bind:Name="'method_select'"
                     id="method_select"
               />
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Pulse')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'pulse'"
                  v-model.number="vitalObj.pulse"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_pulse'"
                  v-on:keydown="onKeydown"
               >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('VitalSign.Position')"
                  v-bind:Options="OptionPulsePosition"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Model="vitalObj.pulsePosition"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownPulsePosition"
                  :IsDisabled=this.isReadOnly
               />
            </b-col>
         </b-row>
         <b-row>
            
            <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('VitalSign.Location')"
                  v-bind:Options="OptionPulseLocation"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Model="vitalObj.pulseLocation"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownPulseLocation"
                  :IsDisabled=this.isReadOnly
               />
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Systolic')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'systolic'"
                  v-model.number="vitalObj.systolic"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_systolic'"
                  v-on:keydown="onKeydown"
               >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Diastolic')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'diastolic'"
                  v-model.number="vitalObj.diastolic"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_diastolic'"
                  v-on:keydown="onKeydown"
               >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('VitalSign.Position')"
                  v-bind:Options="OptionPulsePosition"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Model="vitalObj.bpposition"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownBpposition"
                  :IsDisabled=this.isReadOnly
               />
            </b-col>
         </b-row>
         <b-row>
            <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('VitalSign.CuffLocation')"
                  v-bind:Options="OptionCuffLocation"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Model="vitalObj.cuffLocation"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownCuffLocation"
                  :IsDisabled=this.isReadOnly
               />
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Resp')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'resp'"
                  v-model.number="vitalObj.resp"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_resp'"
                  v-on:keydown="onKeydown"
               >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.O2Sat')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'o2sat'"
                  v-model.number="vitalObj.o2sat"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_o2sat'"
                  v-on:keydown="onKeydown"
               >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('VitalSign.O2delivery')"
                  v-bind:Options="Optiono2delivery"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Model="vitalObj.o2delivery"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownOptiono2delivery"
                  :IsDisabled=this.isReadOnly
               />
            </b-col>
         </b-row>
         <b-row>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Weight') + label('Weightlbs')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'weight1'"
                  v-model.number="vitalObj.weight1"
                  @blur="calcBmiBsa"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_weight1'"
                  v-on:keydown="onKeydown"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Weight') + label('Weightoz')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'weight2'"
                  v-model.number="vitalObj.weight2"
                  v-bind:isReadOnly=this.isReadOnly
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Height') + label('Heightft')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'height1'"
                  v-model.number="vitalObj.height1"
                  @blur="calcBmiBsa"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_height1'"
                  v-on:keydown="onKeydown"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.Height') + label('Heightin')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'height1'"
                  v-model.number="vitalObj.height2"
                  @blur="calcBmiBsa"
                  v-bind:isReadOnly=this.isReadOnly
                  >                                     
               </MdEdit>
            </b-col>
         </b-row>
         <b-row>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.InhaledO2')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'o2inhaled'"
                  v-model.number="vitalObj.o2inhaled"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_o2inhaled'"
                  v-on:keydown="onKeydown"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.HeadCir') + label('HeadCirin')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'headCir'"
                  v-model.number="vitalObj.headCir"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_headcir'"
                  v-on:keydown="onKeydown"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.BSAM2')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'bsa'"
                  v-model="vitalObj.bsa"
                  v-bind:isReadOnly="true"
                  v-bind:Id="'input_bsam2_id'"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.BMI') + label('BMIlbsin2')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'bmi'"
                  v-model="vitalObj.bmi"
                  v-bind:isReadOnly="true"
                  v-bind:Id="'input_bmilbsin2_id'"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.SugarLevel') + label('SugarLevelmgdL')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'sugarLevel'"
                  v-model.number="vitalObj.sugarLevel"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_sugarlevel'"
                  v-on:keydown="onKeydown"
                  >                                     
               </MdEdit>
            </b-col>
         </b-row>
         <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
         <b-row>
            <b-col md="12" lg="12">
               <MdMemo 
                  v-bind:Label="$t('Shared.Notes')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'notes'"
                  v-bind:Rows="'3'"
                  v-bind:MaxRows="'6'"
                  v-bind:MaxLength="'500'"
                  v-model="vitalObj.notes"
                  v-bind:isReadOnly=this.isReadOnly
                  >                                     
               </MdMemo>
            </b-col>
         </b-row>
         <div class="space-actions"></div>
         <div class="page-actions">
            <MdButton
               v-bind:Label="$t('Shared.Back')"
               v-bind:Name="'btnpatientlist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>
            <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly=this.isReadOnly
             v-bind:Name="'btn_save_patient_vitals'"/>
         </div>
      </div>
   </div>
</template>

<script>

import EnumService from '@/services/enum.service' 
import GlobalService from '@/services/global.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
// import LocalStorage from '@/services/storage.service'
import EmployeeService from '@/services/employee.service'
import VitalSignService from '@/services/vitalsigns.service'
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';
import Companies from '@/services/companies.service'

let elemSaveBtn = '';
export default {
   beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
   beforeRouteLeave(to, from, next) {
      if (!this.isReadOnly && this.pendingChanges) {
         this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
            title: this.$t('VitalSign.PatientVitalsSigns'),
            okTitle: this.$t('Shared.BtnSave'),
            cancelTitle: this.$t('Shared.BtnDismiss'),
            footerClass: 'p-2',
            titleClass: 'modal-title modal-type-warning'
         })
            .then(value => {
               if(!value){
               next();
               } else {
                  this.SaveOrEdit();
                  next();
               }
            })		
      } else {
         next()
      }
    },

   mixins: [ShowMessage, DBFunctions],
   name: 'VitalSignDetails',  
   data() {
      return { 
         permission:[],
         Menu : this.$store.state.leftMenu,
         isEmployeeValid:true,
         PatientTitle:{      
            programName:'',
            fullName:'',
            admitDate:'',
            dischargeDate:'',
            dob:'',
            activitytypename:'',
            startdatetime:'',
            ageFormatted:''
         },  
         Mode:this.$attrs.editMode,
         isReadOnly: false,  
         Loading:true,
         VitalSId: '',
         Employees: [],
         EmployeeCode:'',
         EmployeeList:[],
         EmployeeModel:{"code":"","name":""},
         OptionTempMethod: EnumService.TempMethod,
         OptionPulsePosition: EnumService.Position,
         OptionPulseLocation: EnumService.Location,
         OptionCuffLocation: EnumService.CuffLocation,
         Optiono2delivery: EnumService.O2Delivery,
         serverDateTime: null,
         vitalObj:{
            recNo: '',
            patientCode: '',
            therapistCode: '',
            transDate: null,
            headerId:'',
            unitSys: '',
            temp: null,
            tempMethod: '',
            pulse:null,
            pulsePosition:'',
            pulseLocation: '',
            systolic: null,
            diastolic: null,
            bpposition: '',
            cuffLocation: '',
            resp: null,
            o2sat: null,
            o2delivery: '',
            weight1: null,
            weight2: null,
            height1: null,
            height2: null,
            headCir: null,
            notes: '',
            bmi: '',
            bsa: '',
            o2inhaled: null,
            sugarLevel: null
         },
         OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
         },
         pendingChanges: false,
         IsValidDateandTime:true,
         TransDate:null,
         Message:"",
         currentAppointmentDB:{
            guid:'',
            activitytypecode:'',
            employee:{
               firstname:"",
               lastname:""
            }
         },
         unitSys: 'U'
      }
   },

   methods:{
        label(value) {
            if (value === 'Temperature' ) {
                return this.unitSys === 'U' ? ' (°F)' :  ' (°C)';
            }

            if (value === 'Weightlbs') {
                return this.unitSys === 'U' ? ' (lbs)' :  ' (Kgs)';
            }

            if (value === 'Weightoz') {
                return this.unitSys === 'U' ? ' (oz)' :  ' (g)';
            }

            if (value === 'Heightft' || value === 'HeadCirin') {
                return this.unitSys === 'U' ? ' (ft)' :  ' (cm)';
            }

            if (value === 'Heightin') {
                return this.unitSys === 'U' ? ' (in)' :  ' (mm)';
            }

            if (value === 'SugarLevelmgdL') {
                return this.unitSys === 'U' ? ' (mg/dL)' :  ' (mM)';
            }

            if (value === 'BMIlbsin2') {
                return this.unitSys === 'U' ? ' (lbs/in2)' :  ' (kgs/m2)';
            }

            return '';
        },

      async GetPatientByCode(){
      this.infoList = await this.getInfoTitle(this.$route.params.id);
      if(this.infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = this.infoList[0].age;
          this.PatientTitle.ageFormatted = this.infoList[0].ageFormatted;
          if(this.infoList[0].dob){
              this.PatientTitle.dob = Utils.formatterDateToString(this.infoList[0].dob);
          }  
          this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
      }
    },
   CanView(){
      return this.permission[0].roleCanView;
  },

      async getDateTime() { 
         await GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                  if (!this.VitalSId) {                  
                    // this.TransDate = this.formatterDate(response.data);
                    this.TransDate = Utils.formatterDateTimeToString(response.data);                  
                  }
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
            }
         }); 
      },

      getDateTimeToTranform(dateToParse) {
         const dateArray = dateToParse.split('T').slice(0,2);
         const time = dateArray[1];
         const date = dateArray[0];
         this.vitalObj.transDate =  date + 'T' + time;
      },

      getDateTimeToTranformToUpd(dateToParse) {
         const dateArray = dateToParse.split(' ');
         const time = dateArray[1];
         const date = new Date(dateArray[0]);
         this.vitalObj.transDate = Utils.formatterDate(date) + 'T' + time;
      },

      DiscardChanges() {
         if (this.$route.name.includes('appointments-vs-add') 
             || this.$route.name.includes('appointments-vs-details')) {
               this.$router.push({
                  name: "appointments-vital-sign",
                  params: { 
                           id: this.$route.params.id, 
                           actId: this.$route.params.actId 
                  },
               });
         } else {
            this.$router.push({
            name: "Clinical-vital-sign",
            params: { id: this.$route.params.id },
         });
         }
      },

      getEmployeebyCode(code){
         var arr = this.EmployeeList;
         var l = arr.length;
         for(var i = 0; i < l; i++){
            if(arr[i].code === code){
               return arr[i];
            }
         }
         return null;
      },

     async getAllEmployee(){
        await EmployeeService.getAllEmployees()
            .then((response) => { 
               if(response.data && response.data.length > 0){
                  this.Employees = response.data;
                 
                  if(!this.Mode){
                      this.EmployeeCode = this.$store.state.employeeCode //LocalStorage.getEmployeeCode();
                     if (!this.VitalSId) 
                        this.vitalObj.therapistCode = this.EmployeeCode;
                  }  
                var arr = response.data;
                var l = arr.length;
                for(var i = 0; i < l; i++){
                   var obj = {
                     "code":arr[i].code,
                     "name":arr[i].name
                  }
                  this.EmployeeList.push(obj);                
                }            
                 
               }             
            })
            .catch((error) => {
               if (error.response) {
                  this.showMessage(this.$tc('Shared.Employee'),error,"error");
               }
            });
      },

      formatterDate(value){
         return Utils.formatterDateTimeToString(value);
      },

      getVitalSignById(){
         if (this.VitalSId) {
            VitalSignService.get( this.VitalSId )
               .then((response) => {
                  if(response.status == '200' && response.data){    
                        this.vitalObj = response.data; 
                        
                        this.vitalObj.unitSys = this.vitalObj.unitSys ?? this.unitSys ?? 'U';
                    // this.vitalObj.transDate = this.formatterDate(this.vitalObj.transDate);
                    this.TransDate = this.formatterDate(this.vitalObj.transDate);
                      if(this.Mode){                
                        this.EmployeeModel = this.getEmployeebyCode(this.vitalObj.therapistCode);
                     }
                  }

                  this.Loading = false;
                  this.$emit('load', this.Loading);
               })
               .catch((error) => {
                    this.Loading = false;
                    this.$emit('load', this.Loading);
                  if (!error.response) {
                     this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.retrieveInfo'),"error");
                  } else {
                     this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
                  }
               });
         } else {
            this.Loading = false;
            this.$emit('load', this.Loading);
         }

      },

      AddVitalSign(){
         this.vitalObj.patientCode = this.$route.params.id;  
         this.vitalObj.unitSys = this.unitSys ?? 'U';       
            VitalSignService.addVital(this.vitalObj)
            .then((response) => {
                if(response.status == '200'){
                     this.pendingChanges = false;
                      if(this.$route.name === 'vs-add'){
                        this.$router.push({
                           name: "Clinical-vital-sign",
                           params: { id:  this.$route.params.id },
                        });
                     }
                     if (this.$route.name.includes('appointments-vs-add')) {
                        this.$router.push({
                           name: "appointments-vital-sign",
                           params: { 
                              id: this.$route.params.id, 
                              actId: this.$route.params.actId 
                           },
                        });
                     }    
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('VitalSign.VitalsSavedSuccessfully'), {
                         title: this.$t('VitalSign.SavingVitals'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                }
            })
            .catch((error) => {
               this.$emit('load', false);
               elemSaveBtn.removeAttribute("disabled");
               if (!error.response) {
                  this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.NotAbleToInsert'),"error");
               }
               else if(error.response.status === 400 ){
                 this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 500 ){
                 this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.ThereWereSomeError'),"error");
            }
              else{
                  this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error.response.data.errorMessage,"error");
              }  
              this.vitalObj.transDate = this.formatterDate(this.vitalObj.transDate);
                this.$route.router.go('/');
         });
      },

      UpdateVitalSign(){        
         this.vitalObj.patientCode = this.$route.params.id; 
         VitalSignService.update(this.VitalSId, this.vitalObj)
          .then((response) => {
            if(response.status == '200'){
                this.pendingChanges = false;  
                 if(this.$route.name === 'vs-details'){
                        this.$router.push({
                           name: "Clinical-vital-sign",
                           params: { id: this.vitalObj.patientCode },
                        });
                 }
                 if (this.$route.name.includes('appointments-vs-details')) {
                        this.$router.push({
                           name: "appointments-vital-sign",
                           params: { 
                              id: this.vitalObj.patientCode , 
                              actId: this.$route.params.actId 
                           },
                        });
                 }
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('VitalSign.VitalsSavedSuccessfully'), {
                         title: this.$t('VitalSign.SavingVitals'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                }
            })
        .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
           if (!error.response) {
               this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.NotAbleToUpdate'),"error");
           }
            else if(error.response.status === 400 ){
                 this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 500 ){
                 this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.ThereWereSomeError'),"error");
            }
            else{
               this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error.response.data.errorMessage,"error");
              }             
              this.vitalObj.transDate = this.formatterDate(this.vitalObj.transDate);
            this.$route.router.go('/');
        });
      },

      calcBmiBsa(){
        if (this.vitalObj.weight1 > 0 && this.vitalObj.height1 > 0) {
           this.vitalObj.bmi = ((( this.vitalObj.weight1 ) * 703 )/ Math.pow( (this.vitalObj.height1 * 12 ),2)).toFixed(2);
           this.vitalObj.bsa = (Math.sqrt(((this.vitalObj.height1) * 0.3048 * ((this.vitalObj.weight1 ) / 2.2046 ) )/3600 ) ).toFixed(2);
           if ( this.vitalObj.height2 > 0) {
              this.vitalObj.bmi = ((( this.vitalObj.weight1 ) * 703 )/ Math.pow( ((this.vitalObj.height1 * 12 ) + this.vitalObj.height2),2)).toFixed(2)
           }
           else {
              this.vitalObj.bsa = '';
               this.vitalObj.bmi = '';
           }
        }
        else {
            this.vitalObj.bsa = '';
            this.vitalObj.bmi = '';
        } 
      },

   validateDate(){
      return Utils.IsValidDateAndRange(this.TransDate, Utils.getDateTimeFormat()) ? true : false;
    },

       validate_required(){
         /* var result = false;
          if(this.TransDate){
               var valid = Utils.IsValidDateAndRange(this.TransDate);
               this.IsValidDateandTime = this.TransDate && valid ? true : false;               
               if(!this.IsValidDateandTime){
                  this.Message = this.$t('Msg.InvalidDate');
               }  

               return result;  
          }
          else{
            this.Message = "Check red-labeled fields, please.";
             return false;
          }  */     
          
        this.IsValidDateandTime = this.TransDate ? true : false;
        this.isEmployeeValid = this.vitalObj.therapistCode && this.vitalObj.therapistCode.length > 0 ? true : false;         
        return this.IsValidDateandTime  && this.isEmployeeValid; 
    },

  

      PrepareVitals(){
          if(this.TransDate){
            this.vitalObj.transDate = Utils.formatterToISO(this.TransDate, Utils.getDateTimeFormat());
          }
         this.vitalObj.temp = this.vitalObj.temp === "" ? null : this.vitalObj.temp;         
         this.vitalObj.o2sat = this.vitalObj.o2sat === "" ? null : this.vitalObj.o2sat;
         this.vitalObj.weight1 = this.vitalObj.weight1 === "" ? null : this.vitalObj.weight1;
         this.vitalObj.weight2 = this.vitalObj.weight2 === "" ? null : this.vitalObj.weight2;
         this.vitalObj.height1 = this.vitalObj.height1 === "" ? null : this.vitalObj.height1;
         this.vitalObj.height2 = this.vitalObj.height2 === "" ? null : this.vitalObj.height2;
         this.vitalObj.o2inhaled = this.vitalObj.o2inhaled === "" ? null : this.vitalObj.o2inhaled;
         this.vitalObj.headCir = this.vitalObj.headCir === "" ? null : this.vitalObj.headCir;
         this.vitalObj.sugarLevel = this.vitalObj.sugarLevel === "" ? null : this.vitalObj.sugarLevel; 
         this.vitalObj.pulse = this.vitalObj.pulse === "" ? null : this.vitalObj.pulse;    
         this.vitalObj.systolic = this.vitalObj.systolic === "" ? null : this.vitalObj.systolic; 
         this.vitalObj.diastolic = this.vitalObj.diastolic === "" ? null : this.vitalObj.diastolic; 
         this.vitalObj.resp = this.vitalObj.resp === "" ? null : this.vitalObj.resp;
          
      },

      DoAction(){        
         this.PrepareVitals();
         !this.Mode ? this.AddVitalSign() : this.UpdateVitalSign();             
        
      },

       SaveOrEdit(){  
         this.$emit('load', true);
         elemSaveBtn.setAttribute("disabled",'');
         if (this.validate_required()) {
            if(this.validateDate()){
               this.DoAction();
            }else{
               this.$emit('load', false);
               elemSaveBtn.removeAttribute("disabled");
               this.showMessage(this.$t('VitalSign.PatientVitalsSigns'), this.$t('Msg.InvalidDate'), "errorAutoHide");          
            }
         } else {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled")
            this.showMessage(this.$t('VitalSign.PatientVitalsSigns'), this.Message, "errorAutoHide");          
         }

      },

      

      ChangeDropDowntempMethod(value){
         this.vitalObj.tempMethod = value ? value.value : '';
      },
      ChangeDropDownPulsePosition(value){
         this.vitalObj.pulsePosition = value ? value.value : '';
      },
       ChangeDropDownPulseLocation(value){
         this.vitalObj.pulseLocation = value ? value.value : '';
      },
      ChangeDropDownBpposition(value){
         this.vitalObj.bpposition = value ? value.value : '';
      },
      ChangeDropDownCuffLocation(value){
         this.vitalObj.cuffLocation = value ? value.value : '';
      },
       ChangeDropDownOptiono2delivery(value){
         this.vitalObj.o2delivery = value ? value.value : '';
      },
      ChangeDropDownEmployees(value){
         this.vitalObj.therapistCode = value ? value.code : '';
      },
      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
      ,
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      },

      async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    },

        onKeydown(event) {
            //38 - up arrow pressed
            //40 - down arrow pressed
            if (!this.isReadOnly && (event.keyCode == 38 || event.keyCode == 40)) {
                if (event.target.value == '') {
                    if (event.target.id == 'input_temperature') {
                        this.vitalObj.temp = this.unitSys == 'U' ? 98.6 : 37;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_pulse') {
                        this.vitalObj.pulse = 80;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_systolic') {
                        this.vitalObj.systolic = 120;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_diastolic') {
                        this.vitalObj.diastolic = 80;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_resp') {
                        this.vitalObj.resp = 16;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_o2sat') {
                        this.vitalObj.o2sat = 100;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_o2inhaled') {
                        this.vitalObj.o2inhaled = 100;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_weight1') {
                        this.vitalObj.weight1 = this.unitSys == 'U' ? 120 : 120 / 2.2046;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_height1') {
                        this.vitalObj.height1 = this.unitSys == 'U' ? 5 : 160;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_headcir') {
                        this.vitalObj.headCir = this.unitSys == 'U' ? 5 : ( 5 / 12 ) * 30.48;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    } else if (event.target.id == 'input_sugarlevel') {
                        this.vitalObj.sugarLevel = this.unitSys == 'U' ? 70 : 3.9;
                        this.pendingChanges = true;
                        this.checkUserAction();
                        event.preventDefault();
                    }
                }
            }
        }
   },

  async mounted() {
        this.$emit('load', this.Loading);
      this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-vital-sign') ? EnumService.AppointmentItems : EnumService.ClinicalItems;
      this.OptionsRightMenu.visible = true;
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
      this.isReadOnly = await this.$store.state.isVitalSignEHRReadOnly;
      this.OptionsRightMenu.Mode = this.Mode;
      this.VitalSId =  this.$route.params.vsid;
      this.GetPatientByCode();
      if (this.$route.name.includes('appointments-vs-add') || this.$route.name.includes('appointments-vs-details'))
         await this.getCurrentAppointment()

      this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
      if(this.RegistrationInfo.dateFrom)
         this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
      if(this.RegistrationInfo.dateTo)
         this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);
         
      if(this.RegistrationInfo.dob)
         this.RegistrationInfo.dob =  Utils.formatterDateToString(this.RegistrationInfo.dob);

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
      
      await this.getAllEmployee();
      this.pendingChanges = false;
      this.getVitalSignById();

      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_patient_vitals');
         elemSaveBtn.setAttribute('disabled',''); 
      }, 50, this );

      Companies.getAll().then((response) => {
        var data=response.data;
        if(data && data.length > 0){
            this.unitSys = data[0].unitSystem ?? 'U';
        }
      });
   },

  created () {
   this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
       if (!this.Mode)
         this.getDateTime();
       EventBus.$on("onChanged", function (e) { 
         this.pendingChanges = e.Changed
         this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload);
      //EventBus.$off('onChanged');
    },
}
</script>


