<template>
      <div :class="UseColumnDinamic ? Column : 'col-md-4'">		
		<MdPatientAllergiesOutCome v-if="activateModalAllergies"
			v-bind:ModalId="ModalIdAddAllergy"
			v-bind:TitleWindow="$t('AllergiesList.AddAllergy')"
			@getCodeConditionOK="onCodeConditionOk($event)"
			@getCancel="onCancel($event)"
			v-bind:NoValidateChanges="NoValidateChanges"
		></MdPatientAllergiesOutCome>
					<div class="card activeCard">
						<div class="card-body">
							
					<div v-if="isUsePatientAllergies" class="d-flex align-items-start justify-content-between pointer">
							<h6 v-on:click="GoToPatientAllergies" class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning mr-1"></i>{{ $t('MdPatientAllergies.Allergies') }}</h6>
							<i v-if="!HideViewAllergy" class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="GoToPatientAllergies" ></i>
							<!--<i v-if="VisibleAddAllergy" v-b-modal="ModalIdAddAllergy" class="mdi mdi-plus text-corporate mx-1" ></i>-->
					</div>
					<div v-if="!isUsePatientAllergies" class="d-flex align-items-start justify-content-between">
							<h6 class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning"></i>{{ $t('MdPatientAllergies.Allergies') }}</h6>
					</div>
					<div :class="NoUseClass ? 'text-muted cutescroll': UseFixedMiddleClass ? 'text-muted p-middle-fixed cutescroll' : 'text-muted p-fixed cutescroll'">
						<p v-if="Allergies.length === 0">{{ $t('MdPatientAllergies.NoKnownAllergies') }}</p>
						<ul>							
							<li v-for="(it, index) in Allergies" :key="`it.allergy-${index}`">{{it.allergy}}</li>						
						</ul>
						</div>
						</div>
					</div>


					


					</div>
					
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
//import { EventBus } from '@/event-bus.js';
  export default {
    props: {
    PatientCode: String,
	UseColumnDinamic:Boolean,
	Column:String,
	NoUseClass:Boolean,
	HideViewAllergy:Boolean,
	VisibleAllergy:Boolean,
	ModalIdAddAllergy:String,
    UseFixedMiddleClass:Boolean,
	NoValidateChanges: Boolean,	
	},
    mixins: [DBFunctions],
	
	//components: { Allergy },
    data() {
      return {
		Allergies:[],
		activateModalAllergies:false,
		isUsePatientAllergies: this.$store.state.isUsePatientAllergies,

      }
    },
     
  methods:{
	async getAllergies(){			
		this.Allergies.splice(0,this.Allergies.length);   	
		this.Allergies = await this.getPatientAllergy(this.PatientCode);
	},

	GoToAddAllergy(){

	},

	async deActivateModal(){
		this.Allergies.splice(0,this.Allergies.length);    
		this.activateModalAllergies = false; 
		this.Allergies = await this.getPatientAllergy(this.PatientCode); 		
     },

	onCancel(){	
		this.deActivateModal();	
     },

	onCodeConditionOk() {		
		this.deActivateModal();		
     },

	GoToPatientAllergies(){		
        
		if(!this.VisibleAllergy){
			if(this.$route.name.includes('patient')){
				this.$router.push({ name: "patient-allergies", params: { id: this.$route.params.id } }).catch(()=>{});
			}
			if(this.$route.name.includes('Clinical')){
				this.$router.push({ name: "Clinical-PatientAllergies", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
			}
            if(this.$route.name.includes('appointment')){
				this.$router.push({ name: "appointments-allergies", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
			}
		}else{
			this.activateModalAllergies = true;   
		}
			
    },
  },
  created(){
	/*EventBus.$on("CloseModalAddAllergyMed", function () {        
        this.closeModal(); 		
    }.bind(this));*/
  },
  mounted() {
	this.getAllergies();
  },
  beforeDestroy () {     
       //EventBus.$off('CloseModalAddAllergyMed');        
	},
}

</script>