<template>
  <div id="ReportClinicalNotes">
    <MdTitle v-bind:Text="$t('dailyServiceCharge.RepTitle')" />
      <b-row>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateFrom'"
            v-model="DateFrom"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateFromChange($event)"
            v-bind:isValid="this.isDateFromValid">                                     
          </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.dateTo')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateTo'"
            v-model="DateTo"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateToChange($event)"
            v-bind:isValid="this.isDateToValid">                                     
           </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.DateType')"
            v-bind:Options="dateTypeOptions"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"
            v-bind:Model="DateTypeModel"  
            v-bind:Multiple="false" 
            :Input="ChangeDateType"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$tc('Shared.Patient',2)"
            v-bind:Options="PatientsList"
            v-bind:Model="PatientCodeModel"
            v-bind:FieldDescription="'patientName'"
            v-bind:FieldCode="'patientcode'"
            v-bind:Multiple="true"             
            :Input="ChangeDropDownPatients"
            :CustomLabel="customLabelPatients"/>
        </b-col>
      </b-row> 
      <b-row>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$tc('Shared.Program',2)"
            v-bind:Options="ProgramList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"
            v-bind:Model="ProgramModel"  
            :Input="ChangeDropDownPrograms"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.BillTher')"
            v-bind:Options="therapistList"
            v-bind:Model="billingTherapists"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"             
            :Input="ChangeBillingTherapists"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.SignedTher')"
            v-bind:Options="therapistList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"
            v-bind:Model="signedTherapists"  
            :Input="ChangeSignedTherapists"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Therapies')"
            v-bind:Options="TherapiesList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Model="TherapiesModel"  
            v-bind:Multiple="true" 
            :Input="ChangeDropDownTherapies"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
      </b-row>
      <!--
      <b-row>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="'Facilities'"
            v-bind:Options="facilitiesOptions"
            v-bind:FieldDescription="'company'"
            v-bind:FieldCode="'code'"
            v-bind:Model="FacilitiesModel"  
            v-bind:Multiple="true" 
            :Input="ChangeFacilities"/>
        </b-col>
      </b-row>
      -->
      <MdTitle v-bind:Text="$tc('Insurance.Insurance')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="3" lg="3" sm="12">
          <MdCheckBox
            v-bind:Label="$t('dailyServiceCharge.UseCategory')"
            v-bind:Name="'UseCategory'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="useCategory"
            v-on:change="ChangeUseCategory"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            ref="primaryInsurances"
            v-bind:Label="$t('HouseFile.PrimaryInsurances')"
            v-bind:Options="primaryInsuranceOptions"
            v-bind:FieldDescription="'company'"
            v-bind:FieldCode="'insurancecode'"
            v-bind:Model="primaryInsurances"  
            v-bind:Multiple="true" 
            :Input="ChangePrimaryInsurances"
            :CustomLabel="customLabelCompanyCode"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdCheckBox
            v-bind:Label="$t('dailyServiceCharge.NoSecondaryInsurance')"
            v-bind:Name="'NoSecondaryInsurance'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="noSecondaryInsurance"
            v-on:change="ChangeNoSecondaryInsurance"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            ref="secondaryInsurances"
            v-bind:Label="$t('dailyServiceCharge.SecInsurances')"
            v-bind:Options="secondaryInsuranceOptions"
            v-bind:FieldDescription="'company'"
            v-bind:FieldCode="'insurancecode'"
            v-bind:Model="secondaryInsurances"  
            v-bind:Multiple="true" 
            :Input="ChangeSecondaryInsurances"
            v-bind:IsDisabled="isDisableSecondaryInsurances"
            :CustomLabel="customLabelCompanyCode"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('dailyServiceCharge.DisplayOpt')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.GroupBy')"
            v-bind:Options="groupByOptions"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"
            v-bind:Model="groupBy"  
            v-bind:Multiple="false" 
            :Input="ChangeGroupBy"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.Include')"
            v-bind:Options="showOptions"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"
            v-bind:Model="showModel"  
            v-bind:Multiple="false" 
            :Input="ChangeShow"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Show')"
            v-bind:Options="displayOptions"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"
            v-bind:Model="displayModel"  
            v-bind:Multiple="false" 
            :Input="ChangeDisplay"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdCheckBox
            v-bind:Label="$t('dailyServiceCharge.FiltersReport')"
            v-bind:Name="'IncludeFiltersInReport'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="IncludeFiltersInReport"
            v-on:change="ChangeIncludeFiltersInReport"/>
         </b-col>        
      </b-row>
      <div class="page-actions">
        <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="reportList"
          v-bind:Name="'btn_ReportList'"/>
        <MdButton
          v-bind:Label="$t('Shared.Report')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="print"
          v-bind:Name="'btn_Print'"/>   
      </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        title="Daily Service Charges Report"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="dailyServiceCharge"
          :ReportName="'DailyServiceCharge'"
          :ReportTitle="$t('dailyServiceCharge.RepTitle')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
  </div>
</template>

<script>
import Utils from '@/common/utils'
import PatientService from '@/services/patient.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import ProgramService from '@/services/programs.services'
import ClinicalService from '@/services/clinical.service'
import EmployeeService from '@/services/employee.service';
import InsuranceService from '@/services/insurance.service'
import { DxPopup } from 'devextreme-vue/popup';
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'DailyServiceCharge',
  mixins: [ShowMessage,Utils,DBFunctions],
  components: { DxPopup },
  data(){
    return{
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
        isReportVisible: false,
        useCategory: false,
        noSecondaryInsurance: false,
        facilitiesOptions: [],
        groupBy: {
          "value":"patients",
          "text":this.$tc('Shared.Patient',2)
        },
        groupByOptions: [
          { value: 'patients', text: this.$tc('Shared.Patient',2) },
          { value: 'billingtherapist', text: this.$t('dailyServiceCharge.BillTherapist')  },
          { value: 'insurances', text: this.$tc('Insurance.Insurance',2) },
          { value: 'programs', text: this.$tc('Shared.Program',2)  },
          { value: 'therapies', text: this.$t('Shared.Therapies') },
          { value: 'signedtherapist', text: this.$t('dailyServiceCharge.SigTherapist')  }         
        ],
        showModel: {
          "value":"all",
          "text":this.$t('Shared.All')
        },
        displayModel: {
          "value":"detail",
          "text":this.$t('dailyServiceCharge.Detail')
        },
        showOptions: [
          { value: 'all', text: this.$t('Shared.All') },
          { value: 'billed', text: this.$t('modalClaimDetail.Billed') },
          { value: 'unbilled', text: this.$t('dailyServiceCharge.Unbilled') },
        ],
        displayOptions: [
          { value: 'detail', text: this.$t('dailyServiceCharge.Detail') },
          { value: 'summary', text: this.$t('dailyServiceCharge.Summary') },
        ],
        DateTypeModel: {
          "value":"service",
          "text":this.$t('MdNote.Service')
        },
        dateTypeOptions: [
          { value: 'service', text: this.$t('MdNote.Service') },
          { value: 'post', text: this.$t('dailyServiceCharge.Post') },
        ],
        summaryModel:false,
        therapistList:[],
        billingTherapists: '',
        signedTherapists: '',
        DateFrom: Utils.formatterDate(Date(), "LLLL"),
        DateTo: Utils.formatterDate(Date(), "LLLL"),
        PatientCodeModel:'',
        ProgramModel:'',
        TherapiesModel:'',
        FacilitiesModel:'',
        PatientsList:[],
        ProgramList:[],
        TherapiesList:[],
        parameter:{
          SearchCriteria:'',
          PageNumber : null,
          PageSize:null,
          Status: true,
        },
        primaryInsuranceOptions: [],
        secondaryInsuranceOptions: [],
        primaryInsurances: null,
        secondaryInsurances: null,
        isDateFromValid: true,
        isDateToValid: true,
        isDisableSecondaryInsurances: false,
        reportParams: [],
        IncludeFiltersInReport: false,
        params: {
          "PatientCode": [],
          "ProgramCode": [],
          "BillingTherapists": [],
          "SignedTherapists": [],
          "TherapiesCode": [],
          "PrimaryInsurances": [],
          "SecondaryInsurances": [],
          "Facilities": [],
          "NoSecondaryInsurance": false,
          "DateFrom": Utils.formatterDate(Date(), "LLLL"),
          "DateTo": Utils.formatterDate(Date(), "LLLL"),
          "UseCategory": false,
          "GroupBy": 'patients',
          "DateType": 'service',
          "Show": 'all',
          "IsSummary": false,
          "IncludeFiltersInReport": false,
        },
    }
  },
  methods:{

    customLabelNameCode ({ name, code }) {
      return `${name} – ${code}`
    },

    customLabelCompanyCode ({ company, insurancecode }) {
      return `${company} – ${insurancecode}`
    },

    customLabelPatients ({ patientName, patientcode }) {
      return `${patientName} – ${patientcode}`
    },

    print() {
      this.isDateFromValid = true
      this.isDateToValid = true
      
      if (this.DateFrom && this.DateTo) {
        if (Utils.isDateFromAndDateTo(this.DateFrom,this.DateTo)) {
          this.onClickShowReport()
        } else {
          this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),this.$t('Msg.DateOutRange'),"error");
        }
      } else {
        this.isDateFromValid = this.DateFrom ? true: false
        this.isDateToValid = this.DateTo ? true : false
        this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),this.$t('Msg.CheckRedLabeledFields'),"error");
      } 
    },

    onHiddenReport() {
      this.isReportVisible = false
      //this.closeWindow()
    },

    onShowing(e) {
      let contentElement = e.component.content();
      let titleElement = e.component._$title[0]
      contentElement.style.padding = '0px';
      titleElement.style.padding = '0px 20px'
    },

    getAllEmployee() {
      EmployeeService.getAllEmployees()
        .then((response) => { 
        if(response.data && response.data.length > 0){
          this.therapistList = response.data;
        }             
        })
        .catch((error) => {
        if (error.response) {
          this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),error,"error");
        }
        });
    },
    
    DateFromChange(value) {
      this.params.DateFrom = value
    },

    DateToChange(value) {
      this.params.DateTo = value
    },

    ChangeDropDownPatients(values) {
      this.params.PatientCode = this.getItemsByComma(values, 'patientcode')
    },

    ChangeDropDownPrograms(values) {
      this.params.ProgramCode = this.getItemsByComma(values, 'code')
    },

    ChangeBillingTherapists(values) {
      this.params.BillingTherapists = this.getItemsByComma(values, 'code')
    },

    ChangeSignedTherapists(values) {
      this.params.SignedTherapists = this.getItemsByComma(values, 'code')
    },

    ChangeDropDownTherapies(values) {
      this.params.TherapiesCode = this.getItemsByComma(values, 'code')
    },

    ChangePrimaryInsurances(values) {
      this.params.PrimaryInsurances = this.getItemsByComma(values, 'insurancecode')
    },

    ChangeSecondaryInsurances(values) {
      this.params.SecondaryInsurances = this.getItemsByComma(values, 'insurancecode')
      this.secondaryInsurances = this.getItemsByComma(values, 'insurancecode')
    },

    ChangeNoSecondaryInsurance(value) {
      this.isDisableSecondaryInsurances = value
      this.params.NoSecondaryInsurance = value
      this.params.SecondaryInsurances = value ? [] : this.secondaryInsurances
    },

    async ChangeUseCategory(value) {
      this.params.UseCategory = value

      this.primaryInsuranceOptions= []
      this.secondaryInsuranceOptions = []
      
      this.params.PrimaryInsurances = []
      this.params.SecondaryInsurances = []

      this.$refs.primaryInsurances.clearSelection()
      this.$refs.secondaryInsurances.clearSelection()
      
      if (value) {
        await this.getInsurancePriSec('P')
        await this.getInsurancePriSec('S')
      } else 
        await this.getInsurances();
    },

    ChangeGroupBy(item) {
      this.params.GroupBy = item.value
    },

    ChangeDateType(item) {
      this.params.DateType = item.value
    },

    ChangeShow(item) {
      this.params.Show = item.value
    },
    ChangeDisplay(item) {
      this.params.IsSummary = (item.value == 'detail')?false:true
    },
    ChangeSummary(value) {
      this.params.IsSummary = value
    },

    ChangeIncludeFiltersInReport(value) {
      this.params.IncludeFiltersInReport = value
    },

    ChangeFacilities(values) {
      this.params.Facilities = this.getItemsByComma(values, 'code')
      this.FacilitiesModel = this.getItemsByComma(values, 'code')
    },

    getPatients() {              
     this.parameter.PageSize = this.perPage;
     this.parameter.PageNumber = this.currentPage;        
      PatientService.getAll(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.PatientsList = response.data; 
        this.PatientsList.forEach(function(element) {
            element.patientName = element.firstname + " " + element.lastname;   
          });
        }      
        this.$emit('load', false);
      })
      .catch(error => {
        //this.Loading = false;
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),error,"error");
        }
      });
    },

      getTherapies() {
        ClinicalService.getTherapies()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.TherapiesList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),error,"error");
            } 
          });  
      },

      getPrograms() {
        ProgramService.getAll(this.parameter)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
                this.ProgramList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),error,"error");
            } 
          });  
      },

      getInsurances() {
        InsuranceService.getAllInsurances(this.parameter)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.primaryInsuranceOptions = response.data;
              this.secondaryInsuranceOptions = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),error,"error");
            } 
          });  
      },

      async getInsurancePriSec(Ins_Type) {
        let param = []
        param.Ins_Type = Ins_Type
        await InsuranceService.getInsurancePriSec(param)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              if (Ins_Type == 'P')
                this.primaryInsuranceOptions = response.data
              if (Ins_Type == 'S')
                this.secondaryInsuranceOptions = response.data
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),error,"error");
            } 
          });  
      },

      getFacilities() { 
        PatientService.getFacilities('')
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.facilitiesOptions = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('dailyServiceCharge.DaChargesMsgTitle'),error,"error");
            }
          });
      },

      getItemsByComma(values, field) {
        let len = values.length;
        let valuesSeparated = [];
        if (len > 0){
          var i = 0;
          values.forEach(function(e) {   
          if(i < len-1){
            valuesSeparated += e[field]+",";
          }else{
            valuesSeparated += e[field];
          }
          i++;                    
          });
          return valuesSeparated;
        }else{
          return [];
        }
      },

      reportList() {
        this.checkExistFavorite('/app/reports/billing');
        this.$router.push({ name: "reportListBilling" }).catch(()=>{});
      },
    
      onClickShowReport() {

        this.reportParams = []
        
        this.reportParams.push({ paramName: 'patientCode', paramValue: this.params.PatientCode})
        this.reportParams.push({ paramName: 'programCode', paramValue: this.params.ProgramCode})
        this.reportParams.push({ paramName: 'billingTherapists', paramValue: this.params.BillingTherapists})
        this.reportParams.push({ paramName: 'signedTherapists', paramValue: this.params.SignedTherapists})
        this.reportParams.push({ paramName: 'therapiesCode', paramValue: this.params.TherapiesCode})
        this.reportParams.push({ paramName: 'primaryInsurances', paramValue: this.params.PrimaryInsurances})
        this.reportParams.push({ paramName: 'secondaryInsurances', paramValue: this.params.SecondaryInsurances})
        this.reportParams.push({ paramName: 'facilities', paramValue: this.params.Facilities})
        this.reportParams.push({ paramName: 'noSecondaryInsurance', paramValue: this.params.NoSecondaryInsurance})
        this.reportParams.push({ paramName: 'dateFrom', paramValue: this.params.DateFrom})
        this.reportParams.push({ paramName: 'dateTo', paramValue: this.params.DateTo})
        this.reportParams.push({ paramName: 'useCategory', paramValue: this.params.UseCategory})
        this.reportParams.push({ paramName: 'groupBy', paramValue: this.params.GroupBy})
        this.reportParams.push({ paramName: 'dateType', paramValue: this.params.DateType})
        this.reportParams.push({ paramName: 'show', paramValue: this.params.Show})
        this.reportParams.push({ paramName: 'isSummary', paramValue: this.params.IsSummary})
        this.reportParams.push({ paramName: 'includeFiltersInReport', paramValue: this.params.IncludeFiltersInReport})

        this.isReportVisible = true
        this.$refs.dailyServiceCharge.buildDocument(this.reportParams)
      }
 },

  created() {
    this.getPatients();
    this.getPrograms();
    this.getTherapies();
    this.getAllEmployee();
    this.getInsurances();
    //this.getFacilities()
  },

  mounted() {
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }

  }
};
</script>
<style>
  #fileList{
    padding: 1em;
  }
</style>