<template>
    <div>
      <b-row>
    <b-col md="12">   
    <b-table
      :ref="refTable"
      
      :items="items"
      :fields="fFields"
      responsive
      :per-page="pPage"
      class="medezTables cutescroll"    
      :show-empty="showMessageIsEmpty"
      :current-page="currentPage"   
      @row-clicked="LocalrowClicked" 
      @row-selected="rowSelected"                  
      :tbody-tr-class="tbodyRowClass"
      :id="refTable"
      :tbody-tr-attr="tbodyRowAttr"
      :sort-compare="Compare"
      :empty-text="$t('MdTable.nRecShow')"
    >


     <template v-if="editIconEvent || deleteIconEvent || viewIconEvent || otherIconEvent && this.items.length > 0" #cell(actions)="row">              
        <b-icon v-if="editIconEvent && isReadOnly" icon="eye-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="editIconEvent && !isReadOnly" icon="pencil-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Delete')" class="rounded-circle tableActionBTN-danger ml-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="viewIconEvent" icon="play-fill" @click="viewButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.View')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="Warning && row.item.warningList.length > 0" id="tWarning" icon="exclamation-triangle-fill" @click="WarningLocal(row.item, $event.target)" class="rounded-circle tableActionBTN ml-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="Error && row.item.errorList.length > 0" id="tError" icon="wrench" @click="ErrorLocal(row.item, $event.target)" class="rounded-circle tableActionBTN ml-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="otherIconEvent" :icon="otherIcon" @click="otherButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>                     
     </template>

    <template v-if="IsInsurancePrior" #cell(status)="row" class="dateNotes">            
      <div>
        <span v-if="row.item.status === 'Inactive'" class="DocumentExpired">{{row.item.status}}</span>
        <span v-else>{{ $t('Shared.Active') }}</span>
      </div>     
    </template>
  
     <template v-if="this.isSchedulerDB" #cell(startDate)="row" class="dateNotes">            
        {{row.item.startDate}}
          <div>
            <div class="pushItDown" v-if="row.item.status === 'Showed Up'"></div>
            <div v-bind:class="row.item.status === 'Showed Up'  ? 'chRstatusfinished completeDraft':'chRstatus mt-2'">
              {{row.item.status}}
            </div>
          </div>
     </template>

    </b-table>
        <b-row>
      <b-col v-if="pageOptions" sm="7" md="6" class="my-1">
        <b-pagination 
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pPage"
          align="fill"
          size="sm"
          class="my-0"
          @change="fpageChanged"
        ></b-pagination>
      </b-col>
      <b-col v-if="pageOptions" align="right" class="mr-2">
          <b-form-group
            >{{ $t('Shared.Show') }}
              <b-form-select
                v-model="pPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                style="width:auto;"
                @change="pageFunction($event)"
              ></b-form-select>
              {{ $t('Shared.Of') }} {{totalRows}}
            </b-form-group>
        </b-col>
      </b-row>
        </b-col>
      </b-row>
    </div>
   

  
</template>

<script>

import { EventBus } from '../../../event-bus.js';
import Utils from '@/common/utils'
import moment from 'moment'

export default {
  name: 'MdTablePaginationClient',
   props: {
     fields:Array,    
     items:  Array, 
     perPageFunction: Function,
     perPage: Number,
     pageChanged: Function,   
     totalRows:Number,  
     onRowSelected: Function, 
     editIconEvent: Function,
     deleteIconEvent: Function, 
     ShowDetails:Boolean,
     showMessageIsEmpty:Boolean,
     otherIconEvent: Function,
     otherIcon: String,
     viewIconEvent: Function,
     Error: Function,
     Warning: Function,
     refTable: String,
     RowClick:Function,   
     isReadOnly:Boolean,
     IsInsurancePrior:Boolean,
     VisibleDeleteIcon:Boolean,
     VisibleDownloadIcon:Boolean,
     tbodyRowAttr: Function,
     isSchedulerDB:[Boolean],
     FieldsDate:Array,
   },
    mixins: [EventBus],
  data(){
    return{  
        pageOptions: [5,10,15,20,30,40,50],
        //perPage: 10,
        pPage: this.perPage,
        itemlocal:this.items,
        cPage:1,
        currentPage: 1, 
         fFields: this.fields,      
    }
  },

  methods: {

    Compare(itemA, itemB, key) {
      if(this.FieldsDate !== undefined && this.FieldsDate !== null){
        if(!this.FieldsDate.includes(key)){
          return false;
        } else {  
          let a = (itemA[key] === null || itemA[key] === ""?'00/00/0000 00:00:00':itemA[key]);
          let b = (itemB[key] === null || itemA[key] === ""?'00/00/0000 00:00:00':itemB[key]); 
          const dateA = new Date(moment(Utils.formatterDate(a, Utils.getFullDateTimeFormat())).format('MM/DD/YYYY HH:mm:ss')) 
          const dateB = new Date(moment(Utils.formatterDate(b, Utils.getFullDateTimeFormat())).format('MM/DD/YYYY HH:mm:ss'))
          return dateA - dateB;
        }
      }
    },

    tbodyRowClass(item) { 
      if(item != null){
         if(item.selected){
          return ["b-table-row-selected", "table-active", "cursor-pointer"]
        } else {
          return ["cursor-pointer"]
        }
      }
      },
      
    rowSelected(items) {     
      this.onRowSelected(items);
    },

    LocalrowClicked(item){
      this.RowClick(item);
    },

    viewButton(row) {
      this.viewIconEvent(row);
      EventBus.$emit('shwoHideFavorite', false);
      EventBus.$emit('onUpdatedMenu', '');
    },

    ErrorLocal(row){
      this.Error(row);
    },

    WarningLocal(row){
      this.Warning(row);
    },


     editButton(row) {
        this.editIconEvent(row);
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },
    selectRow(index){
      this.onSelectRow(index);
    },

    selectRow2(row) {
      this.$refs[this.refTable].selectRow(row)
    },
     
    unselectRow2(row) {
      this.$refs[this.refTable].unselectRow(row)
    },

    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },
    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    otherButton(row) {
      this.otherIconEvent(row);
    },

    },
  created() { 
     //this.fFields.unshift({ key: 'show_details'}); 
    if(this.editIconEvent || this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent) {     
      if(!this.isReadOnly){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
      }      
    }

    EventBus.$on("rowWasClicked", function (e) { 
      this.tbodyRowClass(e);      
     }.bind(this));
  }
}
</script>

