<template>
  <div id="ContainerPatient">
    <div class="row">
        <div class="col-12">
          <MdTitle v-bind:Text="$t('PatientsList.dashboard')"/>
          <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
               <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
               <div class="flex-grow-1">
                 <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
               </div>
             </div>
          <div class="row">
              <div class="col-sm-12 col-md-3">                      
                  <MdButton
                    v-bind:Label="$t('PatientsList.addPatient')"
                    v-bind:ClassProp="this.isReadOnly || !this.disableAddPatient ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                    v-on:click="GetAddPatient"
                    v-bind:IconClass="'ti-plus pr-1'"
                    v-bind:isReadOnly="this.isReadOnly || !this.disableAddPatient"
                    v-bind:Name="'btn_add_patient'">
                  </MdButton>
              </div>
              <div class="col-sm-12 col-md-6 mb-4">
                  <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                      <div class="input-group">
                          <input 
                            type="text" 
                            class="form-control"
                            v-model="parameter.SearchCriteria"
                            @keyup.enter="SearchPatient()"
                          >
                          <div class="input-group-append">
                              <MdButton 
                                v-bind:Name="'btnsearchinput'"
                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                v-bind:IconClass="'mdi mdi-magnify'"
                                v-on:click="SearchPatient">
                              </MdButton>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 col-md-3">                      
                  <MdSelect
                  v-bind:Label="$t('Shared.Status')"
                  v-bind:Options="PatientListStatus"
                  v-bind:ModelArray="status"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"                      
                  :Input="ChangeStatus"/>
              </div>
          </div>
          <b-toast id="error-toast" title="Error" static no-auto-hide>
            {{ error_toast }}  
          </b-toast>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div>
              <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
              <!--<div class="">
                <div class="row">
                  <div class="col-md-12">-->
                      <MdTable 
                        v-bind:fields="fields"
                        v-bind:items="items"
                        v-bind:totalRows="totalRows"
                        v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                        v-bind:currentPage="currentPage"
                        v-bind:perPage="perPage"
                        v-bind:pageOptions="pageOptions"
                        v-bind:refTable="refTable"
                        v-bind:FieldsDate="FieldsDateArr"
                        :viewIconEvent="PatientSummarie"
                        :onRowSelected="onRowSelected"
                        :onFiltered="onFiltered"
                        :pageChanged="pageChanged"
                        :perPageFunction="PerPageFunction">
                      </MdTable>
                    <!--</div>
                  </div>
                </div> -->
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import PatientService from '@/services/patient.service'
import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
//import Patient from '@/components/pages/patients/Patient'

export default {
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  mixins: [ShowMessage, DBFunctions],
  name: 'PatientList',
  components: {
   // Patient
  },
  data() {
    return { 
      search_criteria: "",
      error_toast: "",
      ShowMessageIsEmpty:false,
      Menu : this.$store.state.leftMenu,
      permission: [],
      Loading:true,
      Mode:'',  
      FieldsDateArr:['dob'],
      status:{
        selected:true,
        value:true,
        text:this.$t('Shared.Active')
      },
      PatientListStatus:EnumService.PatientListStatus,       
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: true,       
      },
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:false,
            itemdisabled: false,
            PatientCode:'',
            Mode:this.edit_mode,
            favButtonAtion:false          
        },
     
      fields: [
        { key: 'PatientName', sortable: true, label: this.$t('Shared.PatientName'), class: 'text-rigth' },         
        { key: 'nickname', sortable: true, label: this.$t('PatientsList.colNickname'), class: 'text-rigth' },
        { key: 'patientcode', sortable: true, label: this.$t('Shared.PatientCode'), class: 'text-rigth' },        
        { key: 'dob', sortable: true, label: this.$t('Shared.Dob'), class: 'text-rigth' },
        { key: 'patientid', sortable: true, label: this.$t('PatientsList.colPatientId'), class: 'text-rigth' },
        { key: 'cellPhone', sortable: true, label: this.$t('Shared.CellPhone'), class: 'text-rigth' },
        { key: 'dayPhone', sortable: true, label: this.$t('Shared.DayPhone'), class: 'text-rigth' },       
        { key: 'nightPhone', sortable: true, label: this.$t('Shared.NightPhone'), class: 'text-rigth' },
        { key: 'email', sortable: true, label: this.$t('Shared.Email'), class: 'text-rigth' },
        { key: 'primaryInsurance', sortable: true, label: this.$t('PatientsList.colPrimaryInsurance'), class: 'text-rigth' },
        { key: 'city', sortable: true, label: this.$t('Shared.City'), class: 'text-rigth' },
        { key: 'state', sortable: true, label: this.$t('Shared.State'), class: 'text-center' },
          
        ],

      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{},
      isReadOnly: false,
      refTable: 'selectableTable',
      disableAddPatient: false
    }
  },

  computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }
  },
    
  methods: {

    pageChanged (page) {            
      this.currentPage = page;
      this.getPatients();
    },

    CanView(){
      return this.permission[0].roleCanView;
  },

    PerPageFunction(perpage){
      this.perPage = perpage;
      this.currentPage = 1;
      this.getPatients();
    },

    getPatients() {               
      this.parameter.PageSize = this.perPage;
       this.parameter.PageNumber = this.currentPage;   
      this.parameter.Status = this.status.value;
      if(this.permission.length > 0 && this.permission[0].roleCanView){
      this.$emit('load', true)
      PatientService.getAll(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.items = response.data; 
        this.items.forEach(function(element) {
						if (element.dob)	
              element.dob = Utils.formatterDateToString(element.dob); 
              
            element.PatientName = element.firstname + " " + element.lastname;   
          });
         this.$emit('load', false);
        let headerString = response.headers["x-pagination"];
        this.Header = JSON.parse(headerString);
        this.totalRows = this.Header.TotalCount;
        }
        //this.Loading = false;
        this.$emit('load', false);
        if(this.items.length === 0){
          this.ShowMessageIsEmpty = true;
        } 
      })
      .catch(error => {
        //this.Loading = false;
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$tc('Shared.Patient',2),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$tc('Shared.Patient',2),error,"error");
        }
      });
    }
    },

    PatientSummarie(item) {
      this.Mode= "Edit";
      this.$router.push({ name: "patient-summary", params: { id: item.patientcode } });

      this.OptionsRightMenu.btnvisible = true;
      this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
      // console.log(this.OptionsRightMenu.ItemsList)

      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R0':
            this.$store.commit('setlayoutStatus', 'L0R1');
        break;
        default:
            this.$store.commit('setlayoutStatus', 'L1R1');
      }
      this.$emit('getVisibleMenu', this.OptionsRightMenu); 
    },

    focusInput() {
      this.$refs.SearchPatient.focus();
    },

    onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    GetAddPatient(){
      this.Mode="Add"; 
      this.OptionsRightMenu.btnvisible = true;
      this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
      this.OptionsRightMenu.Mode = "Add";
      this.$emit('getBtnRightMenu', this.OptionsRightMenu);
      EventBus.$emit('shwoHideFavorite', false);
      this.$store.commit('setBtnFavoriteState', false);      
      this.$router.push({ name: "patient-add"}).catch(()=>{});
    },

    info(item, index) {
      alert(item.FirstName,index);
    },

    SearchPatient(){
      this.items = []
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getPatients();
    },

    ChangeStatus(status){
     // this.status.value = status ? status.value : '';
      this.status = { value:''};
      if(status){
           this.status.value = status.value;
           this.status.text = status.text;
           this.status.selected = status.selected;
      }
      this.items = [];
      this.totalRows = 0;
      this.getPatients();
    }
  },

 async created(){
   await this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
      if(this.permission.length == 0){
        this.$emit('load', false);
        this.$router.push({name: "Main"});    
          setTimeout(() => 
            this.showPermissionMessage()
          ,10);  
      }
  },

  mounted() {
    this.getPatients();
    this.OptionsRightMenu.ItemsList = []
    let layoutStatus = this.$store.state.layoutStatus;
    switch(layoutStatus) {
      case 'L0R1':
        this.OptionsRightMenu.favButtonAtion = true;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      case 'L1R0':
      case 'L1R1':
          this.OptionsRightMenu.btnvisible = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      default:
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
    this.isReadOnly = this.$store.state.isPatientInfoEHRReadOnly
    this.disableAddPatient = this.$store.state.isUsePatientInfo
  },

  updated() {
    this.OptionsRightMenu.btnvisible = false;
    this.OptionsRightMenu.ItemsList = [];
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);    
  },

};
</script>

<style>
 @import '../../../assets/css/Patients/PatientList.css';
</style>
