
export default {
    methods: {
        showMessage(title,message,type){
          if (message instanceof Error) { 
            message = message?.response?.data?.errorMessage || this.$t('Msg.sError');
            type = "error";
          }

          this.$bvToast.toast(message, {
            title: title,
            solid: true,
            variant: type == 'error' || type == 'errorAutoHide'  ? 'danger' : type == 'warning' ? 'warning' : 'success',
            autoHideDelay: 10000,
           // noAutoHide: type == 'error' ? true : false
          })
        },

        showPermissionMessage(){
          this.$bvToast.toast(this.$t('DBFunctions.mNotAllowed'), {
            title: this.$t('Msg.Permissions'),
            solid: true,
            variant: "danger",
            noAutoHide: true
          })
        }
    }
}