<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditObjective" @close="this.emitEventCancel" @ok="handleOk" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
      <form ref="form_ObjectiveEdit" @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col md="12">
          <div class="textlabel" :data-label="$t('Shared.Description')"></div>
              <MdMemo v-bind:Name="'goaldescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="true"
                v-bind:DynamicClass="'modalTextarea ml-2'" v-bind:MaxRows="'6'"
                v-model="ObjectiveEdit.description" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <MdEdit 
            v-bind:Label="$t('modalEditGoal.dIdentified')" v-bind:Name="'dateidentified'" v-bind:TypeProp="'text'"
            v-bind:Mask="'##/##/#### ##:## AA'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"            
            v-model="ObjectiveEdit.dateIdentified" v-bind:Id="'input_dateidentified_id'"/>   
        </b-col>
        <b-col md="3">
          <MdEdit 
            v-bind:Label="$t('modalEditGoal.eDate')" v-bind:Name="'ExpectedDate'" v-bind:TypeProp="'text'"                 
            v-bind:Mask="'##/##/#### ##:## AA'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"            
            v-model="ObjectiveEdit.dateExpected" v-bind:Id="'input_ExpectedDate_id'"/>   
        </b-col>
        <b-col md="3">
          <MdEdit 
            v-bind:Label="$t('modalEditGoal.aDate')" v-bind:Name="'AchievedDate'" v-bind:TypeProp="'text'"                 
            v-bind:Mask="'##/##/#### ##:## AA'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"            
            v-model="ObjectiveEdit.dateAchieve" v-bind:Id="'input_AchievedDate_id'"/>   
        </b-col>
        <b-col md="3">
          <MdSelect 
            v-bind:Label="$t('Shared.Status')" v-bind:Options="StatusList" v-bind:FieldDescription="'description'" 
            v-bind:FieldCode="'code'" v-bind:Model="ObjectiveEdit.status" v-bind:Multiple="false" v-bind:AllowEmpty="false"
            :Input="ChangeDropDownStatus" v-bind:Name="'status_select'" :id="'status_select_'" />
        </b-col>
    </b-row>
    </form>
     </b-modal>  
    </div>
</template>
 
 <script>
 import Utils from '@/common/utils'
 import moment from 'moment'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import TreatmentPlan from '@/services/treatmentplan.service'
 

 export default {
   name: 'ModalEditObjective',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String,
     ObjectiveEdit:Object
   },
   
   data(){
     return {
        goaldescriptionBlank:"",
        GoalsList:[],
        GoalsModel:[],
        StatusList: [
                { code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS') },
                { code: "MET", description: this.$t('modalEditGoal.MET') },
                { code: "UNMET", description: this.$t('modalEditGoal.UNMET') },
                { code: "REVISED", description: this.$t('modalEditGoal.REVISED') },
                { code: "EXTENDED", description: this.$t('modalEditGoal.EXTENDED') },
                { code: "DEFERRED", description: this.$t('modalEditGoal.DEFERRED') },
                { code: "REFERRED", description: this.$t('modalEditGoal.REFERRED') }
            ],
     }
   },
 
   methods:{

    handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

    checkFormValidity() {
      if(this.ObjectiveEdit.dateIdentified !== '' && this.ObjectiveEdit.dateExpected !== '' && this.ObjectiveEdit.dateIdentified !== null && this.ObjectiveEdit.dateExpected !== null){
        let DI = moment(this.ObjectiveEdit.dateIdentified, Utils.getDateTimeFormat());
        let DE = moment(this.ObjectiveEdit.dateExpected, Utils.getDateTimeFormat());  

        if(DI > DE){	        
         return false;       
				}else{
          return true;
        }
      }else{
        return true;
      }      
      },

      validDI(){
       if(this.ObjectiveEdit.dateIdentified !== "" && this.ObjectiveEdit.dateIdentified !== null && !Utils.IsValidDateAndRange(this.ObjectiveEdit.dateIdentified, Utils.getDateTimeFormat())){
        this.showMessage(this.$t('modalEditObjective.Objective'),this.$t('modalEditGoal.inIdentifiedMsg'),"error");
        return false;
       }
       return true;
      },

      validDE(){
       if(this.ObjectiveEdit.dateExpected !== "" && this.ObjectiveEdit.dateExpected !== null && !Utils.IsValidDateAndRange(this.ObjectiveEdit.dateExpected, Utils.getDateTimeFormat())){
        this.showMessage(this.$t('modalEditObjective.Objective'),this.$t('modalEditGoal.iExpectedMsg'),"error");
        return false;
       }
       return true;
      },

    handleSubmit() { 
      if(!this.checkFormValidity()){
        this.showMessage(this.$t('modalEditObjective.Objective'),this.$t('modalEditGoal.dIdentifiedMsg'),"error")
      }else{
        if(this.validDI() && this.validDE()){
          this.emitEventOK();
        }       
      } 
    },

     emitEventOK(/*bvModalEvent*/){
      if( (Utils.isValidDate(this.ObjectiveEdit.dateAchieve, Utils.getDateTimeFormat()) && Utils.IsValidDateAndRange(this.ObjectiveEdit.dateAchieve, Utils.getDateTimeFormat())) || this.ObjectiveEdit.dateAchieve === "" || this.ObjectiveEdit.dateAchieve === null){
        this.UpdateObjective(); 
      }else{
        this.showMessage(this.$t('modalEditObjective.Objective'),this.$t('modalEditGoal.iArchiveMsg'),"error");
      }
           
     },

     ChangeDropDownStatus(value){
      this.ObjectiveEdit.status = value ? value : {code:"",description:""};
     },

     UpdateObjective(){        
          let data = {          
            objDesc:this.ObjectiveEdit.description,
            dateSet:Utils.formatterToISO(this.ObjectiveEdit.dateIdentified, Utils.getDateTimeFormat()),
            dateExp:Utils.formatterToISO(this.ObjectiveEdit.dateExpected, Utils.getDateTimeFormat()),
            dateAchieve:Utils.formatterToISO(this.ObjectiveEdit.dateAchieve, Utils.getDateTimeFormat()),
            status:this.ObjectiveEdit.status.code
          };
         TreatmentPlan.UpdateObjective(this.ObjectiveEdit.objRecId,data)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.ObjectiveSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 5); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalEditObjective.Objective'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalEditObjective.Objective'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalEditObjective.Objective'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalEditObjective.Objective'),error.response.data.errorMessage,"error");
                     }    
            });
       
    },
     

     OnChangeGoals(){

     },


     emitEventCancel(){
      this.$emit('ModalTPEditObjective');
    },    
   },
 
   mounted(){
    //await this.getWileyTxGoals();
   },
 };
 </script>

