<template>
	<div id="ContainerGroupNotes">
		<div class="row">
			<div class="col-12">
			<MdTitle v-bind:Text="$t('GroupNotes.GroupNotes')" />  
			<div class="flex-container">
				<b-row>
					<b-col md="4" lg="4" sm="12">
						<MdEdit 
							v-bind:Label="$t('Shared.datefrom')"
							v-bind:TypeProp="'date'" 
							v-bind:Name="'DateFrom'"
							v-model="DateFrom"
							v-bind:Min="'1900-01-01'"
							v-bind:Max="'2200-12-31'"
							v-bind:isValid="this.isDateFromValid"
							v-bind:isRequired="true"
							v-on:change="DateFromChange($event)"
							v-bind:OnPressEnter="Search"           
						>                                     
						</MdEdit>
					</b-col>
					<b-col md="4" lg="4" sm="12">
						<MdEdit 
							v-bind:Label="$t('Shared.dateTo')"
							v-bind:TypeProp="'date'" 
							v-bind:Name="'DateTo'"
							v-model="DateTo"
							v-bind:Min="'1900-01-01'"
							v-bind:Max="'2200-12-31'"
							v-on:change="DateToChange($event)"          
							v-bind:isValid="this.isDateToValid"
							v-bind:OnPressEnter="Search">                                     
						</MdEdit>
					</b-col>
					<b-col md="4" lg="4" sm="12">									
						<MdSelect
							v-bind:Label="$tc('Shared.Program',2)"
							v-bind:Options="ProgramList"
							v-bind:Model="ProgramVmodel"
							v-bind:FieldDescription="'name'"
							v-bind:FieldCode="'code'"
							v-bind:Placeholder="selectPlaceholder"
							v-bind:Multiple="false"
							v-bind:isRequired="true" 
							v-bind:FeedBackText="$t('GroupNotes.fBakProCodeMsg')"     
							v-bind:RequireEvent="true"
							v-bind:isValid="isProgramCodeValid"
							v-bind:IfUsevalidation="false"
							:Input="ChangeDropDownPrograms"
							v-bind:AllowEmpty="false"/>
					</b-col>
				</b-row>	
				<b-row>
					<b-col md="4" lg="4" sm="12">								
					<div class="form-group">
						<MdSelect
							v-bind:Label="$t('Shared.Therapies')"
							v-bind:Options="TherapyList"
							v-bind:Model="TherapyVModel"
							v-bind:Placeholder="selectPlaceholder"																
							v-bind:FieldDescription="'name'"
							v-bind:Multiple="false"
							v-bind:isRequired="true" 
							v-bind:isValid="isTherapyCodeValid"
							v-bind:FeedBackText="$t('GroupNotes.fBakTheCodeMsg')"  
							v-bind:FieldCode="'code'"
							:Input="ChangeDropDownTherapies"
							v-bind:Name="'therapies_select'"
							id="therapies_select"
							v-bind:AllowEmpty="false"/>
					</div>		
					</b-col>
					<b-col md="4" lg="4" sm="12">													
					<div class="form-group">
						<MdSelect
							v-bind:Label="$t('Shared.Notes')"
							v-bind:FieldDescription="'description'"
							v-bind:FieldCode="'notesid'"
							v-bind:Options="NotesList"
							v-bind:ModelArray="NotesVmodel"
							v-bind:Placeholder="selectPlaceholder"
							v-bind:Multiple="false"
							v-bind:isRequired="true" 
							v-bind:isValid="isNoteValid"
							v-bind:FeedBackText="$t('GroupNotes.fBakNoteMsg')"  
							:Input="ChangeDropDownNotes"
							v-bind:Name="'note_notes_select'"
							id="note_notes_select"
							v-bind:AllowEmpty="false"/>
					</div>									
					</b-col>
					<b-col md="4" lg="4" sm="12">
						<MdSelect
						v-bind:Label="$tc('Shared.Therapist')"
						v-bind:FieldDescription="'name'"
						v-bind:FieldCode="'code'"
						v-bind:Options="TherapistList"
						v-bind:Model="TherapistVModel"
						v-bind:Placeholder="selectPlaceholder"
						v-bind:Multiple="false"
						:Input="ChangeDropDownTherapist"/>
					</b-col>																		
				</b-row>	
				<b-row>
					<b-col md="4" lg="4" sm="12">													
					<div class="form-group">
						<MdSelect
							v-bind:Label="$t('Shared.Status')"
							v-bind:Options="OptionNoteStatus"
							v-bind:ModelArray="NoteStatusVModel"
							v-bind:Placeholder="selectPlaceholder"																
							v-bind:FieldDescription="'name'"
							v-bind:Multiple="false"
							v-bind:FieldCode="'value'"
							v-bind:IfUsevalidation="false"
							:Input="ChangeDropDownNoteStatus"
							v-bind:Name="'note_status_select'"
							id="note_status_select"/>
					</div>										
					</b-col>
					<b-col md="12">
						<div class="text-center text-md-right mt-3">
							<button 
								v-on:click="Search"
								type="button"
								class="btn btn-primary btn-login mx-2 pull-right"												
								id="btnsearchinputpending">
								{{$t('Shared.Search')}}								
							</button>							
						</div>
					</b-col>
				</b-row>		
			</div>				
	</div>
</div>
		<div class="row">
			<div class="col-12">
				<div class="mt-4">
					<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
					<div class="row">
						<div class="col-md-12">
							<div>
							<MdTable
								v-bind:fields="fields"
								v-bind:items="items"
								v-bind:totalRows="totalRows"
								v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
								v-bind:currentPage="currentPage"
								v-bind:perPage="perPage"
								v-bind:pageOptions="pageOptions"
								v-bind:refTable="refTable"
								v-bind:FieldsDate="FieldsDateArr"
								v-bind:IsGroupNotes="true"
								:viewIconEvent="ViewIconFunc"
								:onRowSelected="onRowSelected"
								:onFiltered="onFiltered"
								:pageChanged="pageChanged"
								:perPageFunction="PerPageFunction">
							</MdTable>
						</div>  
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
  <script>
  
import EnumService from "@/services/enum.service";
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import EmployeeService from '@/services/employee.service'
import ProgramService from '@/services/programs.services'
import GroupNotesService from '@/services/groupnotes.service'
import TherapyService from '@/services/therapy.service'
import GlobalService from '@/services/global.service'
import Utils from '@/common/utils'
//import { EventBus } from '@/event-bus.js';

export default {
	beforeRouteEnter(to, from, next) {
		next(async vm => {
			const iPI = await vm.CanView();
			if(iPI) next()
			else next('/accessdenied');
		}) 
	},
	mixins: [ShowMessage, DBFunctions],
	name: 'GroupNotes',
	components: {
		
	},
	data() {
		return { 
			ProgramList:[],
			TherapistList:[],
			TherapyList: [],
			aGroupCreationID:"",
			DateList:[],
			NotesList:[],
			isProgramCodeValid:true,
			isTherapyCodeValid:true,
			isNoteValid:true,
			ProgramVmodel:{code:"",name:""},
			NotesVmodel: {},
			DateFrom: Utils.formatterDate(Date(), "LLLL"),
			DateTo: Utils.formatterDate(Date(), "LLLL"),
			isDateFromValid: true,
			isDateToValid: true,
			TherapistVModel:{code:"",name:this.$t('Shared.All')},
			TherapyVModel: {},
			StartDate:null,
			ProgramSelected:"",
			ProgramSelectedList:[],
			selectPlaceholder: this.$t('MdSelect.sOption'),
			NoteStatusVModel: {value:'',name: this.$t('Shared.All')},
			pageOptions: [5, 10, 15],			
			totalRows: 0,		
			currentPage: 1,		
			perPage: 10,			
			filter: null,
			filterOn: [],
			refTable: "selectableTable",
			NotesId:'',
			OptionsRightMenu:{
			btnGroupNotesvisible:false,
			visible:false,
			ItemsList:[],
			btnvisible:false,
			itemdisabled: false,		
			PatientCode:''			
			},
			OptionNoteStatus: EnumService.StatusGroupNotes,
			items: [],
			ShowMessageIsEmpty: false,
			fields: [
				{ key: 'transDate', label: this.$t('GroupNotes.NoteDate'), sortable: true},	
				{ key: 'therapistName', label: this.$tc('Shared.Therapist',1), sortable: true},	
				{ key: 'billedStatus', label:  this.$t('MdNote.Billable'), sortable: true},
				{ key: 'groupCreationID', label: this.$t('GroupNotes.groupCreId'), sortable: true,class:'hidden'},	
				{ key: 'status', label: this.$t('Shared.Status'), sortable: true,class:'hidden'},
					
			],
			FieldsDateArr:['transDate'],

		}
	},
  
computed: {
		
},

methods: { 

	async SaveFilters(groupCreationID, noteId, transDate, status, programCode, therapistCode, therapyCode, noteName) {
		await this.$store.commit('setGroupNotesFilters',[]);
		var filters = {
			GroupCreationId: groupCreationID,
			NoteName: noteName,
			TransDate: Utils.formatterDate(transDate, Utils.getShortDateFormat()),
			ProgramCode: programCode,
			TherapistCode: therapistCode,
			TherapyCode: therapyCode,
			NoteId: noteId,
			Status: status,
		}
        
		await this.$store.commit('setGroupNotesFilters',filters);
	},

	async ViewIconFunc(row){
		await this.SaveFilters(row.groupCreationID, row.noteId,row.transDate,row.status, row.programCode, 
								row.therapistCode, row.therapyCode, row.noteName);	
        this.$router.push({ name: "groupnotes-Dashboard",params: { id: row.groupCreationID }});
	},

	getTwoRandom(min, max) {
		return Math.round(Math.random() * (max - min) + min);  
	},

	GetTimedKey(){
		return ""+new Date().getTime()+this.getTwoRandom(10,99);
	},

	validate_required() {       
        this.isProgramCodeValid =  this.ProgramVmodel.code ? true : false;  
		this.isTherapyCodeValid = this.TherapyVModel.code ? true : false;
		this.isNoteValid = this.NotesVmodel.notesID  ? true : false;
		this.isDateFromValid = this.DateFrom === null || this.DateFrom === '' || Utils.IsValidDateAndRange(this.DateFrom, Utils.getShortDateFormat()) ? false : true
		this.isDateToValid = this.DateTo === null || this.DateTo === '' || Utils.IsValidDateAndRange(this.DateTo, Utils.getShortDateFormat()) ? false : true

        return this.isProgramCodeValid && this.isTherapyCodeValid && this.isNoteValid && this.isDateFromValid && this.isDateToValid;
    },

	Search(){
		if(this.validate_required()){
			this.currentPage = 1;
			this.getDates();	
		}else{
			this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
		}	
	},

	CanView(){
		return true;
    },


	ChangeDropDownPrograms(programs){		
		this.ProgramVmodel = programs;
		this.currentPage = 1;
		this.ProgramSelected = programs.code;

		if(this.ProgramSelected !== ""){			
			this.getTherapyGroupNotes();
			this.TherapyVModel = "";
			this.isProgramCodeValid = true
			this.items = []
		}else{
			this.TherapyList = [];
			this.TherapyVModel = "";
		}
		
		/*if(this.ProgramVmodel){
          this.$store.commit('setgroupNotesProgramSelected',this.ProgramVmodel);
        }else{
          this.$store.commit('setgroupNotesProgramSelected',{});
        }*/
	},

	ChangeDropDownTherapist(therapist){
		this.TherapistVModel = therapist ? therapist: "";
		this.currentPage = 1;
	},

	ChangeDropDownNoteStatus(NoteStatus){
		this.NoteStatusVModel = NoteStatus ? NoteStatus: "";
		this.currentPage = 1;
	},

	async ChangeDropDownTherapies(value){		
		this.TherapyVModel = value ? value : "";
		this.currentPage = 1;
		this.NotesList = [];			
		this.NotesVmodel = {};
		var therapy = null;
		this.items = []
		if(typeof this.TherapyVModel === 'object'){
			therapy = this.TherapyVModel.code;
			this.isTherapyCodeValid = true
		}
		await this.getNotes(this.ProgramSelected,therapy);
		if (this.NotesList.length > 0) {
			this.NotesVmodel = this.NotesList[0]
			this.isNoteValid = true
		}
	},

	ChangeDropDownNotes(note){	
		this.NotesVmodel = note ? note : {};
		this.currentPage = 1;
		this.items = []
	},

	DateFromChange(value) {
		this.DateFrom = value
		this.currentPage = 1
    },

    DateToChange(value) {
		this.DateTo = value
		this.currentPage = 1
    },

	onRowSelected(){

	},

	onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

	pageChanged (page) {            
		this.currentPage = page;
		this.getDates();
	},

	PerPageFunction(perpage){
		this.perPage = perpage;    
		this.currentPage = 1;
		this.getDates();
    },

	getProgramsGroupNotes(){
		ProgramService.getProgramsGroupNotes()
			.then((response) => {
				if(response.data && response.data.length > 0){
					this.ProgramList = response.data;					
					if(this.ProgramList.length === 1){						
						this.ProgramVmodel.code = this.ProgramList[0].code; 
						this.ProgramVmodel.name = this.ProgramList[0].name; 
						this.ProgramSelected = this.ProgramList[0].code;
						this.getTherapyGroupNotes();
					}

				}             
			})
			.catch((error) => {
				if (error.response) {
					//this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
				}
			});
	},

	getEmployeeGroupNotes(){
		EmployeeService.getEmployeeGroupNotes()
			.then((response) => {
				if(response.data && response.data.length > 0){
				this.TherapistList = response.data;	
				this.TherapistList.unshift({code:"",name:this.$t('Shared.All')});	
				}             
			})
			.catch((error) => {
				if (error.response) {
					//this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
				}
			});
	},

	getTherapyGroupNotes(){	
		this.$emit('load', true); 	
		TherapyService.getTherapyGroupNotes(this.ProgramSelected)
			.then((response) => {
				if(response.data && response.data.length > 0){
					this.TherapyList = response.data;					
				}  
				this.$emit('load', false);            
			})
			.catch((error) => {
				this.$emit('load', false); 
				if (error.response) {
					//this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
				}
			});
	},


	async getNotes(program,therapy){		
		this.NotesList = [];
		this.$emit('load', true); 
		await GroupNotesService.getNotes(program,therapy)
			.then((response) => {
				if(response.data && response.data.length > 0){
					this.NotesList = response.data;	
				}  
				this.$emit('load', false);            
			})
			.catch((error) => {
				this.$emit('load', false); 
				if (error.response) {
					//this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
				}
			});
	},

	PrepareCallDate(){
		var data = {};
		data.TherapyCode = typeof this.TherapyVModel === 'object' ? this.TherapyVModel.code.trim() : ""; 
		data.NoteID = typeof this.NotesVmodel === 'object' ? this.NotesVmodel.notesID.trim() : ""; 
		data.NoteStatus = typeof this.NoteStatusVModel === 'object' ? this.NoteStatusVModel.value : ""; 
		data.TherapistCode = typeof this.TherapistVModel === 'object' ? this.TherapistVModel.code.trim() : ""; 	
		data.DateFrom = this.DateFrom
		data.DateTo = this.DateTo
		return data;
	},

	getDates(){	
		this.$emit('load', true);  
		var param = this.PrepareCallDate();
		param.ProgramCode = this.ProgramSelected; 
		param.PatientCode = null;
		param.PageNumber = this.currentPage;
        param.PageSize = this.perPage;

		GroupNotesService.getDates(param)
			.then((response) => {
				if(response.data){
					this.items = response.data;
					
					let headerString = response.headers["x-pagination"];
					this.Header = JSON.parse(headerString);
					this.totalRows = this.Header.TotalCount;
					
					var noteName = this.NotesVmodel.description;
					this.items.forEach(element => {
						if(element.transDate)
							element.transDate = Utils.formatterDateToString(element.transDate); //moment(element.transDate).format("ddd, ll");
					element.therapyName = noteName;
					})
				} 
				this.ShowMessageIsEmpty = response.data.length > 0 ? false : true
				
				this.$emit('load', false);
			})
			.catch((error) => {
				this.$emit('load', false);
				if (error.response) {
					//this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
				}
			});
	},

	async getDateTime() { 
        await GlobalService.getDateTime()
            .then((response) => {      
				if (response.data) {
					this.StartDate = Utils.formatterDate(response.data);
				}  
			})
			.catch(error => {
				if (!error.response) {
					this.showMessage(this.$t('GroupNotes.GroupNotes'),this.$t('Msg.retrieveInfo'),"error");
				}     
				else if(error.response.status === 400 ){
					this.showMessage(this.$t('GroupNotes.GroupNotes'),error.response.data.errorMessage,"error");
				}   
				else if(error.response.status === 500){
					this.showMessage(this.$t('GroupNotes.GroupNotes'),this.$t('Msg.ThereWereSomeError'),"error");
				} else{
					this.showMessage(this.$t('GroupNotes.GroupNotes'),error.response.data.errorMessage,"error");
				}    
			}); 
    },
},
  
	async mounted() {
		this.$emit('load', true);
		await this.getDateTime()
		await this.getProgramsGroupNotes();
		await this.getEmployeeGroupNotes();		
		//await this.getNotes("",null);
		this.$emit('load', false); 
		this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
			this.OptionsRightMenu.btnGroupNotesvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
		}
		/*if(this.$store.state.groupNotesProgramSelected){
			this.ProgramVmodel = this.$store.state.groupNotesProgramSelected;      
			EventBus.$emit('ChangedDrop', this.ProgramVmodel);  
			this.ChangeDropDownPrograms(this.ProgramVmodel);       
		}*/

		/*if(this.ProgramList.length === 1){
			this.ProgramVmodel.code = this.ProgramList[0].code; 
			this.ProgramVmodel.name = this.ProgramList[0].name; 
		}*/
	},
  };
  </script>
  
