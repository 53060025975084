<template>
    <b-row>
      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="ModelCurrentPage"
          :total-rows="totalRows"
          :per-page="perPageSubItems"
          align="fill"
          size="sm"
          class="my-0"
          @change="fpageChangedSubItems"
        ></b-pagination>
      </b-col>
      <b-col align="right" class="mr-2">
          <b-form-group
            >{{ $t('Shared.Show') }}
              <b-form-select
              id="perPageSelect"
                v-model="perPageSubItemModel"               
                size="sm"
                :options="pageOptionsSubItems"
                style="width:auto;"
                @change="pageFunctionSubItems($event)"
              ></b-form-select>
              {{ $t('Shared.Of') }} {{totalRows}}
            </b-form-group>
        </b-col>
      </b-row>
</template>

<script>

export default {
  name: 'MdPaginationItems',
  props : {
    totalRows:Number,
    currpage:Number,
    pageChanged: Function,
    perPageFunction:Function,
    NotesId:[String],
    perPageSubItems: Number
  },
  data(){
      return {
          pPageSubItems:10,
          pageOptionsSubItems:  [5,10,20,30,40,50],
          cPageSubItems: 1,        
          ModelCurrentPage:1,
          NoteId: this.NotesId,
          perPageSubItemModel:this.perPageSubItems
          
      }
  },

  methods:{
    pageFunctionSubItems(perpage) {
       // this.perPageFunction(perpage);
      //perpage
        this.perPageFunction({
            page: perpage,
            NoteId:this.NoteId
        });
    
    },
      fpageChangedSubItems(page) {
        this.pageChanged({
           page: page,
           NoteId:this.NoteId
        });
    },  
  },


   
};
</script>


