<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditSignature" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="this.$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
       
       
      <b-row v-for="(item, index) in this.problemAggregateList" :key="index">
        <span class="InterventionByObjWizard">{{item.name}}</span>
        <b-col lg="12" md="12" sm="12" class="mb-3">
            <MdCheckListBox2 
                v-model="item.model"
                v-bind:Options="item.list"
                v-bind:OnChangeProp="OnChangeBehavioral"
                v-bind:columnCount="2"
                :textField="'name'"
                :valueField="'id'"/>
        </b-col>
    </b-row>   
       
     </b-modal>  
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'

 import TreatmentPlanService from '@/services/treatmentplan.service' 

 export default {
   name: 'ModalEditBehavioral',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     BehavioralsProp:Array,
     ProblemRecId:String,
     probCode:String,
     ItemSelected:Array,
   },
   
   data(){
     return {
        behavioraldescription:"",   
        problemAggregateList:[],    
        ListPsychosocial:[],
        ModalBehavioralsList: [...this.BehavioralsProp]       
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
       /*if (this.validateFields()) {
         this.categoryItems.incidentTypes = this.IncidentCodes
         this.setInterventions()
         if (this.ModeEdit == false) {
           this.categoryItems.id = Utils.generateRecID()
         }               
         this.$emit('getCodeConditionOK',this.categoryItems);
       } else {       
         bvModalEvent.preventDefault()
       }    */  
       this.UpdateBehavioral();
     },

     OnChangeBehavioral(){

     },

     getIdList(arr){
      let result = [];
      for(var i = 0; i < arr.length; i++){
        result.push(arr[i].id);
      }
      return result;
     },

     AssignModel(pos,list){
      for(var i = 0; i < list.length; i++){       
        for(var j = 0; j < this.problemAggregateList[pos].list.length; j++){
          if(list[i].name === this.problemAggregateList[pos].list[j].name){
            this.problemAggregateList[pos].model.push(this.problemAggregateList[pos].list[j].id);
          }
        }
      }
     },

     PopulateSelected(){
      if(this.ItemSelected.length > 0){
        for(var i = 0; i < this.ItemSelected.length; i++){
          for(var j = 0; j < this.problemAggregateList.length; j++){            
            if(this.ItemSelected[i].aggName === this.problemAggregateList[j].name){
              this.problemAggregateList[j].probAggrRecId = this.ItemSelected[i].probAggrRecId;
              this.AssignModel(j,this.ItemSelected[i].list);
            }
          }
        }
      }
     },

     getMedTxProblemAggregates() {
            this.$emit('load', true);
            var parameter = {
                ProblemNumber: this.probCode,
                HeaderId: null
            };
            TreatmentPlanService.getMedTxProblemAggregates(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.problemAggregateList = response.data.map(item => ({ ...item, model: [] }));
                    this.PopulateSelected();
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), error.response.data.errorMessage, "error");
                }
            });
        },


    getList(model,list){
      let result = [];
      for(var i = 0; i < model.length; i++){
        for(var j = 0; j < list.length; j++){
          if(model[i] === list[j].id){
            result.push({id:list[j].id,name:list[j].name});
          }
        }
      }
      return result;
    },


    CreateStructure(){
      for(var i = 0; i < this.problemAggregateList.length; i++){
        let result = this.getList(this.problemAggregateList[i].model,this.problemAggregateList[i].list);
        this.ListPsychosocial.push(
          {
            probAggrRecId: this.problemAggregateList[i].probAggrRecId,
            aggName: this.problemAggregateList[i].name,
            selectionValues: "",
            otherValues: null,
            probRecId: this.ProblemRecId,
            list:result
          }
        )
      }      
    },

     UpdateBehavioral(){        
         this.CreateStructure();        
         let aggregates = {
          aggregates:this.ListPsychosocial
         }
          TreatmentPlanService.UpdateProblemAggregates(this.ProblemRecId,aggregates)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.BehavioralSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 20); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),error.response.data.errorMessage,"error");
                     }    
            });
       
    },

       
     emitEventCancel(){
       this.$emit('ModalTPEditBehavioralCANCEL');
    },    
   },
 
  mounted(){
    this.getMedTxProblemAggregates();
   },
 };
 </script>

