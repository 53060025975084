<template>
    <div :class="UseColumnDinamic ? Column : 'col-md-4'">
        <b-modal
            :no-close-on-backdrop="true" 
            scrollable 
            centered 
            size="xl"  
            @cancel="this.emitEventCancel" 
            :id="ModalIdAddSpecialPrecautions" 
            :title="this.$t('SpecialPrecaution.AddSpecialPrecaution')"  
            :ok-title="$t('Shared.Save')" 
            @ok="handleOk" 
            style="padding:none!important" 
            :cancel-title="$t('Shared.BtnCancel')"
            :ok-disabled="isReadOnly"
        >
            <b-row>
                <b-col md="12" lg="12">
                    <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
                        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                        <div class="flex-grow-1">
                            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('SpecialPrecautions.PatientSpecialPrecautions')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-col md="12" lg="12">
                <MdMemo
                    v-bind:Name="'specialprecautionmedtextarea_notes'"
                    v-bind:Rows="'10'"
                    v-bind:MaxRows="'10'"           
                    v-model="PrecautionNote"
                    v-bind:isReadOnly="isReadOnly"
                    v-bind:NoValidateChanges="NoValidateChanges"
                />                                     
            </b-col>
        </b-modal>

        <div class="card activeCard">
            <div class="card-body">
                <div v-if="isUsePatientSpecialPr" class="d-flex align-items-start justify-content-between pointer">
                    <h6 v-on:click="GoToPatientSpecialPrecaution" class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning mr-1"></i>{{ $t('MdPatientSpecialPrecaution.SpecialPrecaution') }}</h6>
                    <i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="GoToPatientSpecialPrecaution" ></i>
                    <!-- <i v-if="VisibleAddSpecialPrecautions" v-b-modal="ModalIdAddSpecialPrecautions" class="mdi mdi-plus text-corporate mx-1" ></i>-->
                </div>
                <div v-if="!isUsePatientSpecialPr" class="d-flex align-items-start justify-content-between">
                        <h6 class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning"></i>{{ $t('MdPatientSpecialPrecaution.SpecialPrecaution') }}</h6>
                </div>
                <div :class="NoUseClass ? 'text-muted cutescroll': UseFixedMiddleClass ? 'text-muted p-middle-fixed cutescroll' : 'text-muted p-fixed cutescroll'">
                    <p v-if="isNullOrEmpty()">{{ $t('MdPatientSpecialPrecaution.NoKnownSpecialPrecaution') }}</p>
                    <ul v-if="!isNullOrEmpty()">							
                        <li v-for="(it, index) in getNotEmptySpecialPrecautions()" :key="`it.name-${index}`">{{it.specialPrecaution}}</li>						
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
 import SpecialPrecaution from '@/services/specialprecaution.service'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
export default {
  props: {
  PatientCode: String,
  UseColumnDinamic:Boolean,
  Column:String,
  NoUseClass:Boolean,
  Mode:Boolean,
  SpecialPrecaution:String,
  HideViewSpecialPrecautions:Boolean,
  VisibleSpecialPrecautions:Boolean,
  ModalIdAddSpecialPrecautions:String,	
  UseFixedMiddleClass:Boolean,
  NoValidateChanges: Boolean,
  },
  mixins: [DBFunctions],
  
  //components: { AddSpecialPrecautions },
  data() {
    return {
        PrecautionNote:"",
        SpecialPrecautions:[],
        isUsePatientSpecialPr: this.$store.state.isUsePatientSpecialPr,
        isReadOnly: false,
    }
  },
   
methods:{
  async getSpecialPrecautions(){
      this.SpecialPrecautions = await this.getPatientSpecialPrecaution(this.$route.params.id);    
      this.PrecautionNote = this.SpecialPrecautions.length > 0 ? this.SpecialPrecautions[0].specialPrecaution : "";
  },

  AddSpecialPrecaution(){
    let param = {
      PatientCode:this.$route.params.id,
      SpecialPrecaution:this.PrecautionNote
    }
    if(this.SpecialPrecautions.length === 0){
      SpecialPrecaution.Add(param)
            .then((response) => {      
               if (response.status == '200') {  
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                        this.getSpecialPrecautions();
               }  
         })
         .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
         }); 
    }else{
      SpecialPrecaution.Update(this.SpecialPrecautions[0].id,param)
            .then((response) => {      
               if (response.status == '200') {  
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                        this.getSpecialPrecautions();
               }  
         })
         .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
         }); 
    }
 
  },

  handleOk(){
    this.AddSpecialPrecaution();
  },

  emitEventCancel(){
      //this.$emit('ModalTPGoal');
      this.$bvModal.hide(this.ModalIdAddSpecialPrecautions);
      //this.SpecialPrecautions = [];     
      this.PrecautionNote = "";
    },  

  async closeModal(){
      this.$bvModal.hide(this.ModalIdAddSpecialPrecautions);
      this.SpecialPrecautions = [];     
      this.PrecautionNote = ""; 
      this.SpecialPrecautions = await this.getPatientSpecialPrecaution(this.PatientCode);      
  },

  GoToPatientSpecialPrecaution(){	
    if(!this.VisibleSpecialPrecautions){
        if(this.$route.name.includes('patient')){
            this.$router.push({ name: "patient-special-precautions", params: { id: this.$route.params.id } }).catch(()=>{});
        }
        if(this.$route.name.includes('Clinical')){
            this.$router.push({ name: "Clinical-special-precautions", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
        }
        if(this.$route.name.includes('appointment')){
            this.$router.push({ name: "appointments-special-precautions", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
        }
    } else {
        this.$bvModal.show(this.ModalIdAddSpecialPrecautions);
        this.getSpecialPrecautions();
    }
   // this.VisibleSpecialPrecautions = true;
    
    
      /*if(this.$route.name.includes('patient')){
          this.$router.push({ name: "patient-allergies", params: { id: this.$route.params.id } }).catch(()=>{});
      }
      if(this.$route.name.includes('Clinical')){
          this.$router.push({ name: "Clinical-PatientAllergies", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
      }*/
  },

  isNullOrEmpty() {
     return this.getNotEmptySpecialPrecautions().length === 0;
  },

    getNotEmptySpecialPrecautions() {
        return this.SpecialPrecautions.filter(x => x.specialPrecaution && x.specialPrecaution !== "");
    },
},
created(){
  EventBus.$on("CloseModalAddSpecialPrecautionsMed", function () {        
      this.closeModal(); 		
  }.bind(this));
},
mounted() {
    this.isReadOnly = this.$store.state.isPatientSpecialPrEHRReadOnly; 
  this.getSpecialPrecautions();
  this.$root.$on('bv::modal::hide', () => {
   // this.SpecialPrecautions = [];     
    this.PrecautionNote = ""; 
    }); 
},
beforeDestroy () {     
     EventBus.$off('CloseModalAddSpecialPrecautionsMed');        
  },
}

</script>