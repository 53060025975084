<template>

 <div id="ContainerAsam">
    <div class="row">
      <div class="col-12">
        <h4 class="page-title text-truncate">
          {{ $t('Asam.ASAM') }} - 
          <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span>
          <span v-if="this.$route.params.id"> - {{this.$route.params.id}}</span>
          <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
          <span v-if="this.PatientTitle.ageFormatted"> - {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
          <span v-if="this.PatientTitle.activitytypename"> - {{this.PatientTitle.activitytypename}} - </span>
          <span v-if="this.PatientTitle.startdatetime"> {{this.PatientTitle.startdatetime}} </span>
          <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
          <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
          <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
          <span v-else v-show="IsClinical">- {{ $t('Asam.Active') }}  </span>
        </h4>
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
              <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
          </div>
        <div class="row">
          <div class="col-md-5">  
            <div>
              <b-dropdown id="btndropdown-asam" :text="$t('Shared.Add')" v-bind:variant="this.isReadOnly ? 'secondary' : 'primary'"  menu-class="w-100" 
              v-bind:class="this.isReadOnly ? 'drop m-md-2 btn-block' : 'drop m-md-2 btn-block'" :disabled=this.isReadOnly 
              >
                <b-dropdown-item 
                  v-for="item in AsamAssessmentTypesList" 
                  :key="item.code" 
                  v-bind:value="item.code" v-on:click="Add(item.code)">
                  <p class="text-truncate" v-b-tooltip.hover :title="item.displayName">{{item.displayName}} </p>   
                  </b-dropdown-item>
              </b-dropdown>
            </div> 
          </div>
          <div class="col-md-7">
              <div class="textlabel" :data-label="$t('Shared.SearchElements')">
                  <div class="input-group">
                      <input 
                        type="text" 
                        class="form-control"
                        v-model="parameter.SearchCriteria"
                        @keyup.enter="getListingAsam"
                      >
                      <div class="input-group-append">
                            <MdButton 
                            v-bind:Name="'btnsearchinput'"
                            v-bind:VariantProp="'btn btn-sm search-attached'"
                            v-bind:IconClass="'mdi mdi-magnify'"
                            v-on:click="getListingAsam">
                          </MdButton>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        <b-toast id="error-toast" title="Error" static no-auto-hide>
          {{ error_toast }}  
        </b-toast>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div class="row">
          <div class="col-md-12">
            <MdTable 
              v-bind:fields="fields"
              v-bind:items="items"
              v-bind:totalRows="totalRows"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:pageOptions="pageOptions"
              v-bind:otherIcon="iconReport"
              v-bind:FieldsDate="FieldsDateArr"
              :editIconEvent="editPatientAsam"
              v-bind:VisibleEditIcon="true"
              :onRowSelected="onRowSelected"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction"
              :otherIconEvent="getReport">
            </MdTable>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-Asamreport" class="modal-fullscreen"  size="xl" scrollable centered :title="$t('Asam.AsamReport')" :cancel-title="$t('Shared.BtnCancel')">      
       <iframe id="pdf-iframe" :src="this.PdfBase64" width="100%" height="100%"></iframe>
    </b-modal>
  </div>

</template>

<script>

import EnumService from '@/services/enum.service'
import AsamService from '@/services/asam.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'

export default {
  name: 'Asam',
  props: {
     PatientCode:  String,
     RecordId:String
  },
  mixins: [ShowMessage, DBFunctions],
  data() {
    return {
     PatientId:'',
     FieldsDateArr:['sysDate'],
     AssessmentType:0,
     IsClinical:false,
     AssessmentId:'',
     ARecordId:this.RecordId,
     PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:'',
       activitytypename:'',
       startdatetime:'',
       ageFormatted:''
     },
     PdfBase64:'data:application/pdf;base64, ',
      Assessment:{},
      AsamAssessmentTypesList:[],
      search_criteria: "",
      error_toast: "",
      ShowMessageIsEmpty:false,
      Loading:true,
      patient:{},     
      Mode:'', 
      AsamListing:[],        
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,        
      },
      RegistrationInfo:{},
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
             btnvisible:false   
        },
       fields: [
        { key: 'mdAssessmentTypesDisplayName', sortable: true, label: this.$t('Asam.AssessmentType'), class: 'text-rigth' },
        { key: 'sysDate', sortable: true, label: this.$t('Shared.Date'), class: 'text-rigth' },
        { key: 'status', sortable: true, label: this.$t('Shared.Status'), class: 'hidden' },
        { key: 'assessmentId', sortable: true, label: this.$t('Asam.AssessmentId'), class: 'hidden' }, 
        { key: 'mdAssessmentTypesWellKnownName', sortable: true, label: this.$t('Asam.WellKnownName'), class: 'hidden' },
        { key: 'ctnPatientId', sortable: true, label: this.$t('Asam.CtnPatientId'), class: 'hidden' }, 
        { key: 'patientCode', sortable: true, label: this.$t('Shared.PatientCode'), class: 'hidden' }, 
        { key: 'mdAssessmentTypesId', sortable: true, label: this.$t('Asam.AssessmentTypesId'), class: 'hidden' },    
        { key: 'userName', sortable: true, label: this.$t('Shared.WrittenBy'), class: 'text-rigth' },
              
        ],

      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{}, 
      AsamSetupArr:[],
      AsamConfig:{},
      Gender:'',
      Url:'',
      isReadOnly: false,
      iconReport: 'eye-fill',
      patientCode: this.PatientCode,
      currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee:{
          firstname:"",
              lastname:""
          }
      },
    }
  },

  computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      },
        computedAction: function() {
               return this.Assessment.baseUrl;
           }
  },
    
  methods: {
  
   getAsamAssessmentTypes(){
       AsamService.getAsamAssessmentTypes()
            .then((response) => {
                if(response.data && response.data.length > 0){
                this.AsamAssessmentTypesList = response.data;   
            }             
            })
            .catch((error) => {
               if (error.response) {
                  this.showMessage(this.$t('Asam.ASAM'),error,"error");
               }
            });
   },

  pageChanged (page) {            
      this.currentPage = page;
      this.getListingAsam();
    },

    PerPageFunction(perpage){
      this.perPage = perpage;
      this.currentPage = 1;
      this.getListingAsam();
    },

    editPatientAsam(item) {
      var apidata = {
        "PatientId": item.ctnPatientId,
        "AssessmentType": item.mdAssessmentTypesWellKnownName,
        "AssessmentId": item.assessmentId
      };
      AsamService.EditAssessmentSSO(apidata)
      .then((response) => {
        if(response.status == '200' && response.data){
          this.AssessmentSSO = response.data;
          var params = {
            "EhrId" : this.AssessmentSSO.ehrId,
            "OrganizationId" : this.AssessmentSSO.organizationId,
            "UserId" : this.AssessmentSSO.userId,
            "UserName" : this.AssessmentSSO.userName,
            "UserEmail" : this.AssessmentSSO.email,
            "PatientId" : this.AssessmentSSO.patientId,
            "Timestamp" : this.AssessmentSSO.timestamp,
            "AssessmentType" : this.AssessmentSSO.assessmentType,
            "AssessmentId" : this.AssessmentSSO.assessmentId,            
            "Token" : this.AssessmentSSO.token,
          };
          const form = document.createElement('form');
          form.method = 'post';
          form.action = this.AssessmentSSO.baseUrl;
          for (const key in params) {
              const hiddenField = document.createElement('input');
              hiddenField.type = 'hidden';
              hiddenField.name = key;
              hiddenField.value = params[key];
              form.appendChild(hiddenField);
          }
          document.body.appendChild(form);
          form.target = "_blank";
          form.submit();
        } else {
          this.$emit('load', false);
        }
      })
      .catch((error) => {
          this.$emit('load', false);
          this.showMessage(this.$t('Asam.ASAM'),error,"error");
      });
    },

    OpenPdf(base64) {
              var xwin = window.open();
              var iframepdf = xwin.document.createElement('iframe')
              iframepdf.width = '100%'
              iframepdf.height = '100%'
              xwin.document.title = 'Asam Report'
              iframepdf.frameBorder = 0
              iframepdf.style = "border: 0"
              iframepdf.src = "data:application/pdf;base64, " +base64
              xwin.document.body.appendChild(iframepdf);
    },

    getReport(item) {
      //this.$bvModal.show('bv-modal-Asamreport');
      this.$emit('load', true);
      //this.$bvModal.show('bv-modal-Asamreport');
      var data = {
        "PatientId":item.ctnPatientId,
        "AssessmentType":item.mdAssessmentTypesId,
        "AssessmentId":item.assessmentId
      };

      AsamService.getReport(data)
            .then((response) => {
              if(response.status == '200' && response.data){         
                  var base64report = response.data;
                  this.PdfBase64 += base64report.content;               
                  this.OpenPdf(base64report.content);     
              }    
                 this.$emit('load', false);                
            })
            .catch((error) => {
                this.$emit('load', false);
                this.showMessage(this.$t('Asam.ASAM'),error.response.data.errorMessage,"error"); 
            });
    },
    onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    info(item, index) {
      alert(item.FirstName,index);
    },

    GetAsamSetup(){
        AsamService.getAsamSetup()
            .then((response) => {
                if(response.status == '200' && response.data &&  response.data.length > 0){         
                  this.AsamSetupArr = response.data;  
                  this.AsamConfig = this.AsamSetupArr[0];  
                }                       
            })
            .catch((error) => {
               if (error.response) {
                this.showMessage(this.$t('Asam.ASAM'),error,"error");
               } 
            });
    },

     async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.patientCode);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.dob =  Utils.formatterDateToString(infoList[0].dob);
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          this.patient = infoList[0];
      }
    },

    getListingAsam(){
      this.$emit('load', true);        
       this.parameter.PageNumber = this.currentPage;           
       this.parameter.PageSize = this.perPage;
       this.parameter.PatientCode = this.patientCode;
       this.parameter.RecordId = this.ARecordId;
       AsamService.getListingAsam(this.parameter)
            .then((response) => {
                if(response.status == '200' && response.data && response.data.length){         
                 this.items = response.data; 
                 let headerString = response.headers["x-pagination"];
                 this.Header = JSON.parse(headerString);
                 this.totalRows = this.Header.TotalCount;       
                 this.items.forEach(function(item) {
                if (item.sysDate)	
                    item.sysDate = Utils.formatterDateToString(item.sysDate);
                });
            }else{
              this.items = [];
            }
                this.$emit('load', false);
                if(this.items.length === 0){
                  this.ShowMessageIsEmpty = true;
                }
            })
            .catch((error) => {
               this.$emit('load', false);
               if (!error.response) {
                // network error
                this.showMessage(this.$t('Asam.ASAM'),this.$t('Msg.retrieveInfo'),"error");
              } else {
               this.showMessage(this.$t('Asam.ASAM'),error,"error");
              }  
            });
    },
    

  Add(Id){
    if(this.PatientTitle.dob == null || this.PatientTitle.dob == ''){
        this.showMessage(this.$t('Asam.ASAM'),this.$t('Asam.NotBirthday'),"error"); 
      }
      else{
        this.$emit('load', true); 
        if(this.$route.name.includes('patient')){      
          this.ARecordId = "";
        }
        const data = {
          "PatientCode":this.patientCode,
          "AssesmentType":Id,
          "RecordId":this.ARecordId
      };

      AsamService.CreatePatient(this.patientCode)
                .then((response) => { 
                  if(response.status == '200'){               
          AsamService.AsamAssessment(data)
                .then((response) => {
                  if(response.status == '200' && response.data){
                      this.Assessment = response.data;                

                    this.getListingAsam();

                    var item = {
                      "ctnPatientId": this.Assessment.patientId,
                      "mdAssessmentTypesWellKnownName": this.Assessment.assessmentType,
                      "assessmentId": this.Assessment.assessmentId
                    };

                    this.editPatientAsam(item);
                  } else {
                    this.$emit('load', false); 
                  }
                })
                .catch((error) => {
                  this.$emit('load', false);
                  this.showMessage(this.$t('Asam.ASAM'),error.response.data.errorMessage+'.',"error"); 
                });
                }else{
                  this.$emit('load', false); 
                }
                })
                .catch((error) => {
                    this.$emit('load', false);
                    if (!error.response) {
                      // network error
                      this.showMessage(this.$t('Asam.ASAM'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                      this.showMessage(this.$t('Asam.ASAM'),error.response.data.errorMessage+'.',"error");
                    }
                });
      }          
    },

    PopulatePatientTitle(){
      if(this.RegistrationInfo.dateFrom)
        this.RegistrationInfo.dateFrom = Utils.formatterDateToString(this.RegistrationInfo.dateFrom);
      if(this.RegistrationInfo.dateTo)
        this.RegistrationInfo.dateTo = Utils.formatterDateToString(this.RegistrationInfo.dateTo);	

      if(this.$route.name.includes('Clinical-Asam')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = Utils.formatterDateToString(this.RegistrationInfo.dob);
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient') || this.$route.name.includes('appointments-asam')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob =  Utils.formatterDateToString(this.patient.dob);
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;          
      }
    },
    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }

  },
  
 async mounted() {  
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-asam') ? EnumService.AppointmentItems : EnumService.PatientItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.getAsamAssessmentTypes();
    if(this.$route.name.includes('patient') || this.$route.name.includes('appointments-asam')){  
          this.GetPatientByCode();
    }
    
    if (this.$route.name.includes('appointments-asam'))
      await this.getCurrentAppointment()

    this.getListingAsam(); 
    this.GetAsamSetup();
    this.patientCode = this.$route.params.id;
    this.isReadOnly = this.$store.state.isASAMEHRReadOnly;
    if(this.$route.name.includes('Clinical-Asam')){
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
        this.IsClinical = this.RegistrationInfo.dateTo?false:true;
    }

    this.PopulatePatientTitle();

    
  },
  updated(){
     this.patientCode = this.$route.params.id;
  },
  
    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
};
</script>

<style>
.modal-fullscreen{
    max-width: 100%;
    height: 100%;
    margin: 0;
}
</style>




