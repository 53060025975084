<template>
<div>
  <b-modal :id="ControlId" size="xl" scrollable centered :title="$t('MdGetLastAnswers.pAnswers')" :visible="true" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="emitEventCancel" :cancel-title="$t('Shared.BtnCancel')">
    <MdTable
       :ref="refTable"
       v-bind:fields="fields"
       v-bind:items="itemsValues"
       v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
       v-bind:currentPage="1"
       v-bind:perPage="10"
       :onRowSelected="onRowSelectedTable"
       :ondblClick="ondblClicked"
       :empty-text="$t('MdTable.nRecShow')">
    </MdTable>
    <div v-bind:style="style">

      <MdMemo v-if="showMemoComponent"
        v-model="componentValue"
        v-bind:Rows="'7'"
        v-bind:isReadOnly="true" 
      />

      <MdRichEdit v-if="showRichEditComponent"
        v-model="componentValue"
        v-bind:isReadOnly="true"
        v-bind:HideToolBar="true" 
      />

      <MdTable v-if="showTableComponent"
       v-bind:fields="tableFields"
       v-bind:items="tableValue"
       v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
       v-bind:currentPage="1"
       v-bind:refTable="refTableValues"  
       :onRowSelected="onRowSelectedTableValues"  
       v-bind:small="true"
       v-bind:stickyHeader="true"
       >
    </MdTable>

    <MdCheckListBox v-if="showCheckListBoxComponent"
       v-model="checkListBoxValues"
       v-bind:Options="Options"
       v-bind:isReadOnly="true"
       v-bind:ColumnSize="ColumnSize"
    />
    </div>
    <Loading v-bind:IsLoading="this.Loading" />
  </b-modal>
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import ClinicalService from '@/services/clinical.service'
import Loading from '@/components/template/loading/Loading'
import Utils from '@/common/utils'
import moment from 'moment'
export default {
    name: 'GetLastAnswers',
    components: { 
        Loading
    }, 
    mixins: [ShowMessage, DBFunctions],
    props: {
        ControlId: String,
        PatientCode: String,
        TransDate: String,
        Options: Array,
        ColumnSize: Number
    },
    data() {
        return {
            items: [],
            arrlist:[],
            arrDateList:[],
            HaveFieldTime:false,
            selected: [],
            Header:{}, 
            isReadOnly: false,
            destination:[],
            fields: [
                { key: 'transdate', sortable: true, label: this.$t('Shared.Date'), class: 'text-rigth' },         
                { key: 'noteDescription', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },
                { key: 'contents', sortable: true, label: this.$t('MdGetLastAnswers.Value'), class: 'text-rigth' },
                ],
            ShowMessageIsEmpty:true,
            refTable: 'selectableTable',
            refTableValues: 'tableValues',
            itemsValues: [],
            style: {
                border: 'solid 1px #e0e0e0 !important',
                marginTop: '20px',
            },
            componentValue: '',
            tableValue: [],
            showMemoComponent: false,
            showTableComponent: false,
            showRichEditComponent: false,
            Loading:false,
            tableFields: [],
            checkListBoxValues: [],
            showCheckListBoxComponent: false,
        }
    },

    methods: {
        emitEventOK(){
            if(this.tableValue && this.tableValue.length > 0) {    
                for(var i = 0; i < this.arrlist.length; i++){
                    for(var key in this.arrlist[i]) {	  
                        this.tableValue[i][key] = this.arrlist[i][key];	 
                    }
                }
                for(i = 0; i < this.arrDateList.length; i++){
                    for(key in this.arrDateList[i]) {	  
                        this.tableValue[i][key] = this.arrDateList[i][key];	 
                    }
                }   
                this.$emit('getOK', this.tableValue)
            }
            if(this.checkListBoxValues && this.checkListBoxValues.length > 0) {
                this.$emit('getOK', this.checkListBoxValues)
            }
            if(this.componentValue) {
                this.$emit('getOK', this.componentValue)
            }
            this.ResetProps()
        },

        emitEventCancel(){
            this.$emit('getCancel');
            this.ResetProps()
        },

        ResetProps(){
            this.tableValue= [],
            this.componentValue= '';
            this.tableFields= []
        },

        onRowSelectedTable(items) {        
            if(items && items.length > 0) {
                if(items[0].controlType === 'TIssMemo' || items[0].controlType === 'TIssRichEdit')  {
                    this.showMemoComponent = true
                    this.componentValue = items[0].contents
                }
                if(items[0].controlType === 'TIssHtmlEditor')  {
                    this.showRichEditComponent = true
                    this.componentValue = items[0].tableValues[0]
                }
                if(items[0].controlType === 'TIssEdit' || items[0].controlType === 'TIssCheckBox' ||
                    items[0].controlType === 'TIssComboBox' || items[0].controlType === 'TIssRadioGroupNoBorder' ||
                    items[0].controlType === 'TIssRadioGroup'|| items[0].controlType === 'TIssDateTimePicker' || 
                    items[0].controlType === 'TIssMaskEdit')  {

                    this.componentValue = items[0].contents
                }
                if(items[0].controlType === 'TIssFormTable')  {
                    this.showTableComponent = true;
                    this.FormatTime(items[0]);
                    this.tableValue = this.FormatToDateTime(items[0]);
                }
                if(items[0].controlType === 'TIssCheckListBox')  {
                    this.showCheckListBoxComponent = true
                    this.checkListBoxValues = items[0].tableValues
                }
            }
        },

        onRowSelectedTableValues() {

        },

        TableHaveFieldTime(arr){
            for(var i = 0; i < arr.length; i++){
                if(arr[i].dataType === "time"){
                    this.HaveFieldTime = true;         
                    return true;
                }else{
                    this.HaveFieldTime = false;   
                }
            }
            return false;
        },

        getKeyFieldTime(arr){
            var keylist = [];
            for(var i = 0; i < arr.length; i++){
                if(arr[i].dataType === "time"){
                    keylist.push(arr[i].key);
                }
            }
            return keylist;
        },

        FormatTimeMilitary(time){
            return moment(time, "hh:mm A").format("HH:mm")
        },

        getValues(){
            var params = {
                "ControlId":this.ControlId,
                "PatientCode":this.PatientCode,
                "TransDate":this.TransDate
            };
            ClinicalService.getLastTenValues(params)
                .then((response) => { 
                    if(response.data && response.data.length > 0){
                        this.itemsValues = response.data;
                        this.itemsValues.forEach(function(element) {
                            if (element.transdate) {
                                element.transdate = new Date(element.transdate).toLocaleDateString();
                            }	
                            if(element.controlType === 'TIssMemo' || element.controlType === 'TIssRichEdit'){
                                let _items = "";
                                for(var i = 0; i < 1; i++){
                                    _items = element.contents ? _items + element.contents[i]+"\n": '';
                                }
                                element.contents = _items;
                            }
                            if(element.controlType === 'TIssHtmlEditor'){
                                element.tableValues = element.contents;
                                element.contents = ''
                            }
                            if(element.controlType === 'TIssCheckListBox'){
                                element.tableValues = element.contents;
                                element.contents = ''
                            }
                            if(element.controlType === 'TIssEdit' || element.controlType === 'TIssDateTimePicker' || 
                                element.controlType === 'TIssMaskEdit'){
                                
                                element.contents = element.contents ? element.contents[0] : ''
                            }
                            if(element.controlType === 'TIssFormTable'){
                                element.contents = '';               
                            }
                            if(element.controlType === 'TIssCheckBox' || element.controlType === 'TIssComboBox' ||
                                element.controlType === 'TIssRadioGroupNoBorder' || element.controlType === 'TIssRadioGroup'){
                                element.contents = element.contents ? element.contents[0] : ''
                            }
                        });
                        this.HideColumnRecId(this.itemsValues);
                    }  
                    this.Loading = false
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Shared.GetLast10'),error,"error");
                    }
                    this.Loading = false
                });
        },

        HideColumnRecId(itemsValues){
            if(itemsValues && itemsValues.length > 0) {
                this.tableFields = ( itemsValues[0].tableValues.columns && itemsValues[0].tableValues.columns.length > 0) ? 
                    itemsValues[0].tableValues.columns.map(function(element){ 
                if(element.key != 'RecID') {
                    let Items = {};
                    Items['key'] = element.key;
                    Items['label'] = element.label;
                    return Items;
                }
            }): null;
            }
        },

        FormatTime(item){
            let obj = {};
            this.arrlist = [];   
            if (item && item.tableValues && item.tableValues.rows && item.tableValues.columns){
                for(var i = 0; i < item.tableValues.rows.length; i++){
                    obj = {};
                    for(var j = 0; j < item.tableValues.columns.length; j++){
                        if(item.tableValues.columns[j].dataType.toLowerCase() === 'time'.toLowerCase()){
                            let columnName = item.tableValues.columns[j].key;
                            let time = this.FormatTimeMilitary(item.tableValues.rows[i][columnName]);                  
                            obj[columnName] = time;
                        }
                    }

                    this.arrlist.push(obj);
                }         
            }       
        },

        FormatToDateTime(item){
            this.arrDateList = [];
            let dt = {};
            if (item && item.tableValues && item.tableValues.rows && item.tableValues.columns){
                for(var i = 0; i < item.tableValues.rows.length; i++){
                    dt = {};
                    for(var j = 0; j < item.tableValues.columns.length; j++){
                        if(item.tableValues.columns[j].dataType.toLowerCase() === 'datetime' || item.tableValues.columns[j].dataType.toLowerCase() === 'date') {
                            let columnName = item.tableValues.columns[j].key;
                            let value = item.tableValues.rows[i][columnName];
                            let mask = item.tableValues.columns[j].mask;
                            dt[columnName] = item.tableValues.rows[i][columnName];

                            if (Utils.isValidDate(value)){  
                                if (mask && mask == '##/##/####') {
                                    item.tableValues.rows[i][columnName] = Utils.formatterDateToString(value);
                                } else {
                                    item.tableValues.rows[i][columnName] = Utils.formatterDateTimeToString(value);
                                }
                            } 
                        }
                    }
                    this.arrDateList.push(dt);
                }
                return item.tableValues.rows;
            }
            else
                return null;
        },

        ondblClicked() {
            this.emitEventOK()
        }
    },

    mounted() {
        this.Loading = true
        this.getValues()
    },
};

</script>